import React, { useContext } from 'react'

export interface StartOptionsType {
  slug: string
  data?: Record<string, any>
  onCompleted?: (data: Record<string, any>) => void
}

export interface continueWorkflowOptionsType {
  id: string
  onCompleted?: (data: Record<string, any>) => void
}

interface IWorkflowsContext {
  start: (options: StartOptionsType) => void
  continueWorkflow: (options: continueWorkflowOptionsType) => void
}

export const WorkflowsContext = React.createContext<IWorkflowsContext>({
  start: () => {},
  continueWorkflow: () => {},
})

export const useWorkflows = (): IWorkflowsContext =>
  useContext(WorkflowsContext)
