import { useApolloClient, useSubscription } from '@apollo/client'
import { useFormikContext } from 'formik'
import { set, update } from 'lodash/fp'
import React, { useEffect, useState } from 'react'

import Center from 'components/atoms/Center'
import Spin from 'components/atoms/Spin'
import { ONE_SECOND } from 'config/date'
import { WorkflowType, useWorkflow } from 'providers/Workflows'
import { ASYNC_WORKFLOW } from 'providers/Workflows/components/WorkflowAsync/ASYNC_WORKFLOW'
import { WorkflowFullPage } from 'providers/Workflows/components/WorkflowFullPage'
import { last } from 'utils'
import { getErrors } from 'utils/graphql'

export const WorkflowAsync = ({
  workflowId,
  workflows,
  setWorkflows,
  getComponent,
}: {
  workflowId: string
  workflows: WorkflowType[]
  setWorkflows: React.Dispatch<React.SetStateAction<WorkflowType[]>>
  getComponent: (name: string) => any
}) => {
  const { close } = useWorkflow()
  const client = useApolloClient()
  const { errors, setErrors } = useFormikContext()
  const [progress, setProgress] = useState(0)

  const { data, error } = useSubscription<{
    asyncWorkflow: {
      id?: string
      currentStep?: {
        slug: string
      }
      data?: Record<string, any>
      updatedEntities?: {
        id?: string
        typename: string
      }[]
      asyncProgress?: number
    }
  }>(ASYNC_WORKFLOW, {
    variables: { workflowId },
    skip: Boolean(Object.keys(errors).length),
  })

  useEffect(() => {
    if (data?.asyncWorkflow?.id && data.asyncWorkflow.currentStep?.slug) {
      setProgress(1)

      const component = getComponent(data.asyncWorkflow.currentStep.slug)

      setWorkflows(
        update(workflows.length - 1, (w) => ({
          ...w,
          workflow: data.asyncWorkflow,
          loading: false,
          length: w.length + 1,
          component,
        }))
      )

      setTimeout(() => {
        setWorkflows(set([workflows.length - 1, 'async'], false))
      }, 2 * ONE_SECOND)
    } else if (data?.asyncWorkflow?.data) {
      setProgress(1)

      client.reFetchObservableQueries()

      last(workflows).onCompleted(data.asyncWorkflow.data)

      setTimeout(() => {
        close()
      }, 2 * ONE_SECOND)
    } else if (
      data?.asyncWorkflow?.asyncProgress &&
      data.asyncWorkflow.asyncProgress > progress
    ) {
      setProgress(Math.min(1, data.asyncWorkflow.asyncProgress))
    }
  }, [data])

  useEffect(() => {
    if (error) {
      const errors = getErrors(error)
      console.error('Error: ' + JSON.stringify(errors))

      setErrors(errors)

      setWorkflows(
        update(workflows.length - 1, (w) => ({
          ...w,
          async: false,
          loading: false,
        }))
      )
    }
  }, [error])

  return (
    <WorkflowFullPage maxWidth="600px" buttons={null}>
      <Center h="120px" marginBottom="54px">
        <Spin />
      </Center>
    </WorkflowFullPage>
  )
}
