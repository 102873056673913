import {
  AlignLeftOutlined,
  FileTextOutlined,
  MailOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { MenuInfo } from 'rc-menu/lib/interface.d'
import React from 'react'

import FontAwesomeIcon from 'components/atoms/FontAwesomeIcon'
import Menu from 'components/atoms/Menu'
import { CorporationPermissions, PortfolioPermissions } from 'config/roles'
import { useDrawer } from 'providers/Drawer'
import { GroupDrawerTab } from 'providers/Drawer/drawers/Group'
import { usePermissions } from 'providers/Permissions'

const Sider = () => {
  const { tab, changeTab } = useDrawer()
  const { checkPermissions } = usePermissions()

  const handleClick = ({ key }: MenuInfo) => {
    changeTab(key as GroupDrawerTab)
  }

  return (
    <Menu
      selectedKeys={[tab]}
      mode="inline"
      style={{ height: '100%' }}
      menu={[
        {
          icon: <AlignLeftOutlined />,
          title: 'word.details',
          key: GroupDrawerTab.details,
          onClick: handleClick,
        },
        checkPermissions(CorporationPermissions.PORTFOLIOS_READ)
          ? {
              icon: <TeamOutlined />,
              title: 'word.members',
              key: GroupDrawerTab.members,
              onClick: handleClick,
            }
          : undefined,
        {
          icon: <FontAwesomeIcon name="gavel" />,
          title: 'word.meetings',
          key: GroupDrawerTab.meetings,
          onClick: handleClick,
        },
        checkPermissions(CorporationPermissions.GOVERNANCE_READ)
          ? {
              icon: <MailOutlined />,
              title: 'word.communications',
              key: GroupDrawerTab.communications,
              onClick: handleClick,
            }
          : undefined,
        checkPermissions(CorporationPermissions.DOCUMENTS_READ) ||
        checkPermissions(PortfolioPermissions.DOCUMENTS_READ)
          ? {
              icon: <FileTextOutlined />,
              title: 'word.documents',
              key: GroupDrawerTab.documents,
              onClick: handleClick,
            }
          : undefined,
      ]}
    />
  )
}

export default React.memo(Sider)
