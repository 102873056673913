// https://ant.design/components/select/

import { PlusOutlined } from '@ant-design/icons'
import { Select as AntdSelect } from 'antd'
import {
  SelectProps as AntdSelectProps,
  SelectValue,
  RefSelectProps,
} from 'antd/lib/select'
import { OptGroupProps as AntdOptionGroupDataType } from 'rc-select/lib/OptGroup'
import { DefaultOptionType as AntdOptionsType } from 'rc-select/lib/Select'
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react'

import Divider from 'components/atoms/Divider'
import Message, {
  MessageType,
  useFormatMessage,
} from 'components/atoms/Intl/Message'
import { cleanArray, sanitize } from 'utils'
import { x } from 'utils/emotion'

export interface SelectOptionCoreDataType
  extends Omit<AntdOptionsType, 'value'> {
  label: MessageType
  value: string | number | boolean
}

interface SelectOptionGroupDataType
  extends Omit<AntdOptionGroupDataType, 'options'> {
  label: MessageType
  options: (SelectOptionCoreDataType | undefined)[]
}

export type SelectOptionsType =
  | SelectOptionGroupDataType
  | SelectOptionCoreDataType

export interface SelectProps
  extends Omit<AntdSelectProps<SelectValue>, 'options'> {
  options: (SelectOptionsType | undefined)[]
  placeholder?: MessageType
  onChange?: (value: any) => void
  myRef?: React.MutableRefObject<RefSelectProps>
  footerIcon?: ReactNode
  footerMessage?: MessageType
  footerOnClick?: () => void
}

const Select = ({
  options,
  placeholder,
  defaultValue,
  style,
  value,
  myRef,
  onChange = () => {},
  footerIcon = <PlusOutlined />,
  footerMessage = 'word.new',
  footerOnClick,
  ...rest
}: SelectProps) => {
  const ref = useRef<RefSelectProps>(null)
  const formatMessage = useFormatMessage()

  const formatOptions = useCallback(
    (options: SelectOptionsType[]): AntdOptionsType[] => {
      return options?.map((option: any) => {
        if (typeof option === 'string') {
          return {
            label: option,
          }
        }
        if ('options' in option) {
          const { label, options: subOptions, ...rest } = option
          return {
            label: formatMessage(label),
            options: formatOptions(cleanArray(subOptions)),
            ...rest,
          }
        }
        const { label, ...rest } = option
        return {
          label: formatMessage(label),
          ...rest,
        }
      })
    },
    [formatMessage]
  )

  const formattedOptions = useMemo(() => {
    return formatOptions(cleanArray(options))
  }, [options, formatOptions])

  useEffect(() => {
    if (defaultValue && !value) {
      onChange(defaultValue)
    }
  }, [defaultValue, value])

  return (
    <AntdSelect
      ref={myRef || ref}
      showArrow
      defaultValue={defaultValue}
      value={value}
      style={{ width: '100%', ...style }}
      options={formattedOptions}
      filterOption={(input, option) =>
        option?.label
          ? sanitize(String(option.label)).indexOf(sanitize(input)) >= 0
          : false
      }
      placeholder={placeholder ? formatMessage(placeholder) : undefined}
      onClear={() => {
        onChange(null)
      }}
      dropdownRender={
        footerOnClick
          ? (menu) => (
              <>
                {menu}
                <Divider style={{ margin: '4px 0' }} />
                <x.div
                  color="black"
                  display="flex"
                  flexWrap="nowrap"
                  alignItems="center"
                  my={-4}
                  py={9}
                  px={12}
                  cursor="pointer"
                  fontWeight="bold"
                  backgroundColor="background"
                  onClick={() => {
                    footerOnClick()
                    if (myRef?.current?.blur) {
                      myRef.current.blur()
                    }
                    if (ref.current?.blur) {
                      ref.current.blur()
                    }
                  }}
                >
                  {footerIcon}{' '}
                  <x.div marginLeft={8}>
                    <Message value={footerMessage} />
                  </x.div>
                </x.div>
              </>
            )
          : undefined
      }
      onChange={onChange}
      {...rest}
    />
  )
}

export default React.memo(Select)
