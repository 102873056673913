import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { TagProps } from 'components/atoms/Tag'
import TagSecurityType from 'components/molecules/Tag/SecurityType'
import { useAggregatedWalletSecurityId } from 'hooks/aggregatedWallets/useAggregatedWalletSecurityId'

const TagAggregatedWalletSecurityType = ({
  aggregatedWalletId,
}: TagProps & {
  aggregatedWalletId: string
}) => {
  const { securityId, loading } =
    useAggregatedWalletSecurityId(aggregatedWalletId)

  return (
    <SkeletonText loading={loading}>
      {securityId && <TagSecurityType securityId={securityId} />}
    </SkeletonText>
  )
}

export default React.memo(TagAggregatedWalletSecurityType)
