import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_PORTFOLIO_HOLDER_TYPENAME } from 'hooks/portfolios/usePortfolioHolderTypename/GET_PORTFOLIO_HOLDER_TYPENAME'

export const usePortfolioHolderTypename = (portfolioId?: string) => {
  const result = useQuery<{
    portfolio: { holder: { __typename: string; id: string } }
  }>(GET_PORTFOLIO_HOLDER_TYPENAME, {
    variables: { portfolioId },
    skip: !portfolioId,
  })

  return useMemo(
    () => ({
      ...result,
      __typename: result?.data?.portfolio?.holder?.__typename,
      id: result?.data?.portfolio?.holder?.id,
    }),
    [result]
  )
}
