import React, { Dispatch, SetStateAction, useContext } from 'react'

interface IFilePreviewContext {
  filePreviewIsOpen: boolean
  openFilePreview: Dispatch<SetStateAction<string | undefined>>
}

export const FilePreviewContext = React.createContext<IFilePreviewContext>({
  filePreviewIsOpen: false,
  openFilePreview: () => {},
})

export const useFilePreview = (): IFilePreviewContext =>
  useContext(FilePreviewContext)
