// https://ant.design/components/tag/

import { Tag as AntdTag } from 'antd'
import { TagProps as AntdTagProps } from 'antd/lib/tag'
import React from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'
import eqDSTheme from 'styles/eqDSTheme'

export interface TagProps extends AntdTagProps {
  message?: MessageType
}

const Tag = ({ message, children, color, ...rest }: TagProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdTag
      // @ts-ignore
      color={eqDSTheme.color[color] || color}
      {...rest}
    >
      {children || formatMessage(message)}
    </AntdTag>
  )
}

export default React.memo(Tag)
