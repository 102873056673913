// https://ant.design/components/popover/

import { Popover as AntdPopover } from 'antd'
import { PopoverProps as AntdPopoverProps } from 'antd/lib/popover'
import React from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'

export interface PopoverProps extends Omit<AntdPopoverProps, 'title'> {
  title?: MessageType
  content?: MessageType
}

const Popover = ({ title, content, ...rest }: PopoverProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdPopover
      title={title ? formatMessage(title) : undefined}
      content={content ? formatMessage(content) : undefined}
      {...rest}
    />
  )
}

export default React.memo(Popover)
