import React from 'react'

import ApiSearchSelect, {
  ApiSearchSelectProps,
} from 'components/atoms/ApiSearch/Select'
import GovernanceGroupName from 'components/molecules/Group/Name'
import { ApiSearchCorporationGovernanceGroups } from 'providers/ApiSearch/CorporationGovernanceGroups'
import { ApiSearchFiltersProvider } from 'providers/ApiSearchFilters'

const ApiSearchSelectCorporationGovernanceGroups = ({
  corporationId,
  ...rest
}: Omit<ApiSearchSelectProps, 'renderItem'> & {
  corporationId: string
}) => {
  return (
    <ApiSearchFiltersProvider>
      <ApiSearchCorporationGovernanceGroups corporationId={corporationId}>
        <ApiSearchSelect
          placeholder="word.searchForAGovernanceGroup"
          renderItem={(id) => <GovernanceGroupName governanceGroupId={id} />}
          {...rest}
        />
      </ApiSearchCorporationGovernanceGroups>
    </ApiSearchFiltersProvider>
  )
}

export default React.memo(ApiSearchSelectCorporationGovernanceGroups)
