import { EditOutlined } from '@ant-design/icons'
import React, { useState } from 'react'

import ButtonMoreActions from 'components/atoms/Button/MoreActions'
import Menu from 'components/atoms/Menu'
import ChangeTypeModal from 'components/molecules/ButtonMoreActions/CorporationInfo/ChangeType/Modal'
import { CorporationPermissions } from 'config/roles'
import { SLUGS } from 'config/slugs'
import { usePermissions } from 'providers/Permissions'
import { useStartWorkflow } from 'providers/Workflows/hooks/useStartWorkflow'

const ButtonMoreActionsCorporationInfo = ({
  corporationInfoId,
  corporationId,
}: {
  corporationInfoId: string
  corporationId: string
}) => {
  const { checkPermissions } = usePermissions()
  const [visible, setVisible] = useState(false)

  const [editCorporationInfo] = useStartWorkflow({
    data: { corporationId, corporationInfoIds: [corporationInfoId] },
    slug: SLUGS.EDIT_CORPORATION_INFO,
  })

  if (!checkPermissions(CorporationPermissions.CONTACTS_MANAGE)) {
    return null
  }

  return (
    <>
      <ButtonMoreActions
        overlay={
          <Menu
            menu={[
              {
                key: 'editCorporationInfo',
                icon: <EditOutlined />,
                message: 'word.edit',
                onClick: editCorporationInfo,
              },
              {
                key: 'changeTypeCorporationInfo',
                icon: <EditOutlined />,
                message: 'word.changeType',
                onClick: () => setVisible(true),
              },
            ]}
          />
        }
      />
      <ChangeTypeModal
        open={visible}
        setVisible={setVisible}
        corporationInfoId={corporationInfoId}
        onCancel={() => setVisible(false)}
      />
    </>
  )
}

export default React.memo(ButtonMoreActionsCorporationInfo)
