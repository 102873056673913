import {
  AlignLeftOutlined,
  FileTextOutlined,
  SwapOutlined,
} from '@ant-design/icons'
import { MenuInfo } from 'rc-menu/lib/interface.d'
import React from 'react'

import Menu from 'components/atoms/Menu'
import { CorporationPermissions, PortfolioPermissions } from 'config/roles'
import { useDrawer } from 'providers/Drawer'
import { PoolDrawerTab } from 'providers/Drawer/drawers/Pool'
import { usePermissions } from 'providers/Permissions'

const Sider = () => {
  const { tab, changeTab } = useDrawer()
  const { checkPermissions } = usePermissions()

  const handleClick = ({ key }: MenuInfo) => {
    changeTab(key as PoolDrawerTab)
  }

  return (
    <Menu
      selectedKeys={[tab]}
      mode="inline"
      style={{ height: '100%' }}
      menu={[
        {
          icon: <AlignLeftOutlined />,
          title: 'word.details',
          key: PoolDrawerTab.details,
          onClick: handleClick,
        },
        checkPermissions(CorporationPermissions.TRANSACTIONS_READ) ||
        checkPermissions(PortfolioPermissions.TRANSACTIONS_READ)
          ? {
              icon: <SwapOutlined />,
              title: 'word.transactions',
              key: PoolDrawerTab.aggregatedTransactions,
              onClick: handleClick,
            }
          : undefined,
        checkPermissions(CorporationPermissions.DOCUMENTS_READ) ||
        checkPermissions(PortfolioPermissions.DOCUMENTS_READ)
          ? {
              icon: <FileTextOutlined />,
              title: 'word.documents',
              key: PoolDrawerTab.documents,
              onClick: handleClick,
            }
          : undefined,
      ]}
    />
  )
}

export default React.memo(Sider)
