import { gql } from '@apollo/client'

export const GET_FILES_CORPORATION_IDS = gql`
  query GET_FILES_CORPORATION_IDS($fileIds: [ID!]!) {
    files(ids: $fileIds) {
      id
      corporation {
        id
      }
    }
  }
`
