import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import SecurityName, {
  SecurityNameProps,
} from 'components/molecules/Security/Name'
import { useWalletSecurityId } from 'hooks/wallets/useWalletSecurityId'

const WalletSecurityName = ({
  walletId,
  ...rest
}: Omit<SecurityNameProps, 'securityId'> & { walletId: string }) => {
  const { securityId, loading } = useWalletSecurityId(walletId)

  return (
    <SkeletonText loading={loading}>
      <SecurityName securityId={securityId as string} {...rest} />
    </SkeletonText>
  )
}

export default React.memo(WalletSecurityName)
