import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  FontColorsOutlined,
} from '@ant-design/icons'
import { useApolloClient } from '@apollo/client'
import copy from 'copy-to-clipboard'
import { get } from 'lodash/fp'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Button from 'components/atoms/Button'
import Dropdown from 'components/atoms/Dropdown'
import Menu from 'components/atoms/Menu'
import Tooltip from 'components/atoms/Tooltip'
import { GET_COMMUNICATION_TITLE } from 'components/molecules/Communication/Title/GET_COMMUNICATION_TITLE'
import { GET_LAW_FIRM_NAME } from 'components/molecules/LawFirm/Name/GET_LAW_FIRM_NAME'
import { DrawerNames } from 'config/drawer'
import { EndpointGraphql } from 'config/endpointGraphql'
import { LocalStorage } from 'config/localStorage'
import { WORKSPACE } from 'config/workspace'
import { GET_CORPORATION_NAME } from 'hooks/corporations/useCorporationName/GET_CORPORATION_NAME'
import { GET_EVENT_NAME } from 'hooks/events/useEventName/GET_EVENT_NAME'
import { GET_GOVERNANCE_GROUP_NAME } from 'hooks/governanceGroups/useGovernanceGroupName/GET_GOVERNANCE_GROUP_NAME'
import { GET_MEETING_NAME } from 'hooks/meetings/useMeetingName/GET_MEETING_NAME'
import { GET_POOL_NAME } from 'hooks/pools/usePoolName/GET_POOL_NAME'
import { GET_PORTFOLIO_NAME } from 'hooks/portfolios/usePortfolioName/GET_PORTFOLIO_NAME'
import { GET_SECURITY_NAME } from 'hooks/securities/useSecurityName/GET_SECURITY_NAME'
import { useIsLaptopWindowSize } from 'hooks/useWindowSize'
import { useWorkspace } from 'hooks/useWorkspace'
import { useAuth } from 'providers/Auth'
import { useDrawers } from 'providers/Drawers'
import { useLocale } from 'providers/Locale'
import { useMessage } from 'providers/Message'
import { cleanArray, last } from 'utils'

const workspacesData = {
  [WORKSPACE.LAW_FIRM]: {
    type: 'Law firm',
    query: GET_LAW_FIRM_NAME,
    variableName: 'lawFirmId',
    path: 'lawFirm.name',
  },
  [WORKSPACE.CORPORATION]: {
    type: 'Corporation',
    query: GET_CORPORATION_NAME,
    variableName: 'corporationId',
    path: 'corporation.corporationInfo.name',
  },
  [WORKSPACE.PORTFOLIO]: {
    type: 'Holder',
    query: GET_PORTFOLIO_NAME,
    variableName: 'portfolioId',
    path: 'portfolio.name',
  },
}

type DrawerDataType = {
  [key: string]: {
    type: string
    query?: any
    variableName?: string
    path?: string
    wundergraph?: boolean
  }
}

export const URL = ({
  copyIdWithName,
  setCopyIdWithName,
}: {
  copyIdWithName: boolean
  setCopyIdWithName: (value: any) => void
}) => {
  const { isAuth } = useAuth()
  const { locale } = useLocale()
  const { drawers } = useDrawers()
  const { query } = useApolloClient()
  const { infoMessage } = useMessage()
  const { workspace, workspaceId } = useWorkspace()
  const isLaptopWindowSize = useIsLaptopWindowSize()

  const [visible, setVisible] = useState(false)
  const [workspaceName, setWorkspaceName] = useState<string>()
  const [drawerName, setEntityName] = useState<string>()

  const lastDrawer = useMemo(() => last(drawers), [drawers])

  const drawersData: DrawerDataType = useMemo(() => {
    return {
      [DrawerNames.aggW]: {
        type: 'Aggregated wallet',
      },
      [DrawerNames.contact]: {
        type: 'Contact',
      },
      [DrawerNames.event]: {
        type: 'Event',
        query: GET_EVENT_NAME,
        variableName: 'eventId',
        path: `event.name.${locale}`,
      },
      [DrawerNames.file]: {
        type: 'File',
      },
      [DrawerNames.grp]: {
        type: 'Group',
        query: GET_GOVERNANCE_GROUP_NAME,
        variableName: 'governanceGroupId',
        path: 'governanceGroup.name',
      },
      [DrawerNames.legalE]: {
        type: 'LegalEntity',
      },
      [DrawerNames.meet]: {
        type: 'Meeting',
        query: GET_MEETING_NAME,
        variableName: 'meetingId',
        path: 'meeting.name',
      },
      [DrawerNames.communication]: {
        type: 'Communication',
        query: GET_COMMUNICATION_TITLE,
        variableName: 'communicationId',
        path: 'communication.title',
      },
      [DrawerNames.pool]: {
        type: 'Pool',
        query: GET_POOL_NAME,
        variableName: 'poolId',
        path: 'pool.name',
      },
      [DrawerNames.port]: {
        type: 'Holder',
        query: GET_PORTFOLIO_NAME,
        variableName: 'portfolioId',
        path: 'portfolio.name',
      },
      [DrawerNames.sec]: {
        type: 'Security',
        query: GET_SECURITY_NAME,
        variableName: 'securityId',
        wundergraph: true,
        path: 'findSecurityById.name',
      },
      [DrawerNames.aggT]: {
        type: 'Aggregated transaction',
      },
      [DrawerNames.wall]: {
        type: 'Wallet',
      },
      [DrawerNames.intH]: {
        type: 'Integration History',
      },
    }
  }, [locale])

  const formatMenuItem = useCallback(
    ({ type, id, name }: { type: string; id: string; name?: string }) => {
      return {
        icon: <CopyOutlined />,
        key: id,
        message: name ? `${type}: ${name} · ${id}` : `${type}: ${id}`,
        onClick: () => {
          copy(
            copyIdWithName
              ? name
                ? `${type}: ${name} · ${id}`
                : `${type}: ${id}`
              : id ?? 'undefined'
          )
          infoMessage(
            `${type} ID ${copyIdWithName ? 'and name ' : ''}copied to clipboard`
          )
          setVisible(false)
        },
      }
    },
    [copyIdWithName]
  )

  const entities = useMemo(() => {
    return cleanArray([
      workspaceId && workspace
        ? {
            id: workspaceId,
            type: workspacesData[workspace].type,
            name: workspaceName,
          }
        : undefined,
      lastDrawer?.id
        ? {
            id: lastDrawer.id,
            type: drawersData[lastDrawer.name]?.type ?? '',
            name: drawerName,
          }
        : undefined,
    ])
  }, [workspace, workspaceId, lastDrawer, workspaceName, drawerName])

  const group = useMemo(() => {
    return cleanArray([
      entities.length > 1
        ? {
            icon: <CopyOutlined />,
            key: 'both',
            message: `Copy all`,
            onClick: () => {
              copy(
                entities
                  .map(({ id, type, name }) =>
                    name ? `${type}: ${name} · ${id}` : `${type}: ${id}`
                  )
                  .join('\n')
              )
              infoMessage(`Copied all ids to clipboard`)
              setVisible(false)
            },
          }
        : undefined,
      ...entities.map(formatMenuItem),
    ])
  }, [entities, formatMenuItem])

  useEffect(() => {
    if (isAuth && workspaceId && workspace) {
      const workspaceData = workspacesData[workspace]
      query({
        query: workspaceData.query,
        variables: { [workspaceData.variableName]: workspaceId },
      }).then(({ data }) => {
        setWorkspaceName(get(workspaceData.path, data))
      })
      return
    }
    setWorkspaceName(undefined)
  }, [workspaceId, workspace])

  useEffect(() => {
    if (lastDrawer) {
      const drawerData = drawersData[lastDrawer.name]
      if (
        'query' in drawerData &&
        !!drawerData.variableName &&
        !!drawerData.path
      ) {
        query({
          query: drawerData.query,
          variables: { [drawerData.variableName]: lastDrawer.id },
          context:
            'wundergraph' in drawerData && drawerData.wundergraph
              ? { endpoint: EndpointGraphql.WUNDERGRAPH }
              : undefined,
        }).then(({ data }) => {
          setEntityName(get(drawerData.path as string, data))
        })
        return
      }
    }
    setEntityName(undefined)
  }, [lastDrawer])

  if (!entities.length) {
    return null
  }

  return (
    <Dropdown
      key="url"
      trigger={['click']}
      open={visible}
      onOpenChange={setVisible}
      dropdownRender={() => (
        <Menu
          selectedKeys={cleanArray([
            'copyId',
            copyIdWithName ? LocalStorage.COPY_ID_WITH_NAME : undefined,
          ])}
          menu={[
            {
              icon: <CheckOutlined />,
              key: 'copyId',
              message: 'Copy ID',
            },
            {
              icon: copyIdWithName ? <CheckOutlined /> : <CloseOutlined />,
              key: LocalStorage.COPY_ID_WITH_NAME,
              message: 'Copy name',
              onClick: () => {
                setCopyIdWithName(!copyIdWithName)
              },
            },
            {
              key: 'ids',
              title: 'IDs',
              group: group,
            },
          ]}
        />
      )}
    >
      <Tooltip title={isLaptopWindowSize ? undefined : 'URL'}>
        <Button
          icon={<FontColorsOutlined />}
          message={isLaptopWindowSize ? 'URL' : undefined}
        />
      </Tooltip>
    </Dropdown>
  )
}
