import eqDSTheme from 'styles/eqDSTheme'

export enum SecurityType {
  ASA = 'ASA',
  BSA = 'BSA',
  SAFE = 'SAFE',
  BSPCE = 'BSPCE',
  CONVERTIBLE_NOTE = 'CONVERTIBLE_NOTE',
  DEFERRED_SHARE = 'DEFERRED_SHARE',
  FREE_SHARE = 'FREE_SHARE',
  GROWTH_SHARE = 'GROWTH_SHARE',
  NON_CONVERTIBLE_NOTE = 'NON_CONVERTIBLE_NOTE',
  OPTION = 'OPTION',
  ORDINARY_SHARE = 'ORDINARY_SHARE',
  PHANTOM_SHARE = 'PHANTOM_SHARE',
  PREFERRED_SHARE = 'PREFERRED_SHARE',
  REDEEMABLE_SHARE = 'REDEEMABLE_SHARE',
  WARRANT = 'WARRANT',
}

export const SecurityShareTypes: SecurityType[] = [
  SecurityType.DEFERRED_SHARE,
  SecurityType.ORDINARY_SHARE,
  SecurityType.PREFERRED_SHARE,
  SecurityType.REDEEMABLE_SHARE,
  SecurityType.GROWTH_SHARE,
]

export const SecurityEquityAwardsTypes: SecurityType[] = [
  SecurityType.BSA,
  SecurityType.BSPCE,
  SecurityType.FREE_SHARE,
  SecurityType.OPTION,
]

export const SecurityOtherTypes: SecurityType[] = [
  SecurityType.ASA,
  SecurityType.CONVERTIBLE_NOTE,
  SecurityType.NON_CONVERTIBLE_NOTE,
  SecurityType.PHANTOM_SHARE,
  SecurityType.SAFE,
  SecurityType.WARRANT,
]

export enum SecurityDeletable {
  DELETABLE = 'DELETABLE',
  HAS_LINKED_TRANSACTIONS = 'HAS_LINKED_TRANSACTIONS',
  IS_UNDERLYING_OF_OTHER_SECURITY = 'IS_UNDERLYING_OF_OTHER_SECURITY',
}

export const SECURITY_COLOR = {
  [SecurityType.ASA]: eqDSTheme.color.palette.pink50,
  [SecurityType.BSA]: eqDSTheme.color.palette.orange50,
  [SecurityType.SAFE]: eqDSTheme.color.palette.purple50,
  [SecurityType.BSPCE]: eqDSTheme.color.palette.blue100,
  [SecurityType.CONVERTIBLE_NOTE]: eqDSTheme.color.palette.pink100,
  [SecurityType.DEFERRED_SHARE]: eqDSTheme.color.palette.green50,
  [SecurityType.FREE_SHARE]: eqDSTheme.color.palette.yellow100,
  [SecurityType.GROWTH_SHARE]: eqDSTheme.color.palette.orange100,
  [SecurityType.NON_CONVERTIBLE_NOTE]: eqDSTheme.color.palette.pink100,
  [SecurityType.OPTION]: eqDSTheme.color.palette.purple100,
  [SecurityType.ORDINARY_SHARE]: eqDSTheme.color.palette.green100,
  [SecurityType.PHANTOM_SHARE]: eqDSTheme.color.palette.purple100,
  [SecurityType.PREFERRED_SHARE]: eqDSTheme.color.palette.red100,
  [SecurityType.REDEEMABLE_SHARE]: eqDSTheme.color.palette.blue50,
  [SecurityType.WARRANT]: eqDSTheme.color.palette.gold100,
}
