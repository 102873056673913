import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_MEETING_NAME } from 'hooks/meetings/useMeetingName/GET_MEETING_NAME'

export const useMeetingName = (meetingId?: string) => {
  const result = useQuery<{ meeting: { name: string } }>(GET_MEETING_NAME, {
    variables: { meetingId },
    skip: !meetingId,
  })

  return useMemo(
    () => ({
      ...result,
      name: result?.data?.meeting?.name,
    }),
    [result]
  )
}
