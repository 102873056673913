import React from 'react'

import Date from 'components/atoms/Intl/Date'
import SkeletonText from 'components/atoms/Skeleton/Text'
import { useEventDate } from 'hooks/events/useEventDate'

const EventDate = ({
  eventId,
  noSkeleton,
}: {
  eventId: string
  noSkeleton?: boolean
}) => {
  const { date, loading } = useEventDate(eventId)

  if (loading) {
    return <SkeletonText loading={!noSkeleton && loading} />
  }

  if (date) {
    return <Date value={date} />
  }

  return null
}

export default React.memo(EventDate)
