import { gql } from '@apollo/client'

export const GET_FILE_IS_TEMPLATE = gql`
  query GET_FILE_IS_TEMPLATE($fileId: ID!) {
    file(id: $fileId) {
      id
      isTemplate
    }
  }
`
