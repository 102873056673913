import { uniq } from 'lodash/fp'
import React, { ReactNode, useMemo, useState } from 'react'

import { useAuth } from 'providers/Auth'
import {
  FilesEditContext,
  FilesEditModalSection,
  IFilesEditContext,
} from 'providers/FilesEdit/FilesEditContext'
import { FilesEditModal } from 'providers/FilesEdit/Modal'

export const FilesEditProvider = ({
  children,
  onCompleted,
}: {
  children: ReactNode | ((context: IFilesEditContext) => ReactNode)
  onCompleted?: (fileIds: string[]) => void
}) => {
  const { isAuth } = useAuth()
  const [visible, setVisible] = useState(false)
  const [sections, setSections] = useState<
    FilesEditModalSection[] | undefined
  >()
  const [fileIds, setFileIds] = useState<string[]>([])

  const context: IFilesEditContext = useMemo(
    () => ({
      editFiles: (fileIds, sections) => {
        setVisible(true)
        setSections(sections)
        setFileIds((old) => uniq([...old, ...fileIds]))
      },
    }),
    []
  )

  return (
    <FilesEditContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
      {isAuth && (
        <FilesEditModal
          visible={visible}
          sections={sections}
          setVisible={setVisible}
          fileIds={fileIds}
          setFileIds={setFileIds}
          onCompleted={onCompleted}
        />
      )}
    </FilesEditContext.Provider>
  )
}
