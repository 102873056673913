import React, {
  isValidElement,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react'
import { useIntl } from 'react-intl'

import { useFormatNumber } from 'components/atoms/Intl/Number'
import { useEquibar } from 'providers/Equibar'
import { useLocale } from 'providers/Locale'

export interface TranslatedMessage {
  enGB: string
  frFR: string
}

export type MessageType =
  | ReactElement
  | ReactNode
  | string
  | { id: string; values: Record<string, any> }
  | TranslatedMessage

export const useCheckMessage = () => {
  const intl = useIntl()

  return useCallback(
    (message?: MessageType) => {
      if (!message) {
        return false
      }

      if (isValidElement(message)) {
        return true
      }

      if (typeof message === 'string') {
        return Boolean(intl.messages[message])
      }

      if (
        typeof message === 'object' &&
        'enGB' in message &&
        'frFR' in message
      ) {
        return true
      }

      if (typeof message === 'object' && 'id' in message) {
        const { id } = message
        // @ts-ignore
        return Boolean(intl.messages[id])
      }
      return message
    },
    [intl]
  )
}

export const useFormatMessage = () => {
  const intl = useIntl()
  const { locale } = useLocale()
  const { seeTranslationKeys } = useEquibar()
  const formatNumber = useFormatNumber()

  return useCallback(
    (message?: MessageType) => {
      if (!message) {
        return ''
      }
      if (isValidElement(message)) {
        return message
      }
      if (typeof message === 'string') {
        if (!seeTranslationKeys && intl.messages[message]) {
          return intl.formatMessage({ id: message })
        }
        return message
      }
      if (typeof message === 'object' && 'enGB' in message) {
        return message[locale]
      }

      if (
        typeof message === 'object' &&
        'id' in message &&
        'values' in message
      ) {
        const { id, values } = message

        // @ts-ignore
        if (!seeTranslationKeys && intl.messages[id]) {
          return intl.formatMessage(
            // @ts-ignore
            { id },
            // @ts-ignore
            Object.entries(values).reduce(
              (acc, [key, value]) => ({
                ...acc,
                [key]: typeof value === 'number' ? formatNumber(value) : value,
              }),
              {}
            )
          )
        }
        return id
      }

      return message
    },
    [locale, formatNumber, seeTranslationKeys]
  )
}

const Message = ({ value, style }: { value: MessageType; style?: any }) => {
  const formatMessage = useFormatMessage()

  return (
    <span style={{ whiteSpace: 'pre-line', ...(style ?? {}) }}>
      {formatMessage(value)}
    </span>
  )
}

export default React.memo(Message)
