import { gql } from '@apollo/client'

export const GET_SELECTED_FILES_POOLS = gql`
  query GET_SELECTED_FILES_POOLS($ids: [ID!]!) {
    files(ids: $ids) {
      id
      pools {
        pools {
          id
        }
      }
    }
  }
`
