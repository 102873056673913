import { useQuery } from '@apollo/client'
import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import Tag, { TagProps } from 'components/atoms/Tag'
import { GET_TAG_DATA } from 'components/molecules/Tag/Generic/GET_TAG_DATA'

const TagGeneric = ({ tagId, ...rest }: TagProps & { tagId: string }) => {
  const { data, loading } = useQuery(GET_TAG_DATA, {
    variables: { tagId: tagId },
  })

  return (
    <SkeletonText loading={loading} style={{ marginRight: '8px' }}>
      <Tag color={data?.tag?.color} message={data?.tag?.name} {...rest} />
    </SkeletonText>
  )
}

export default React.memo(TagGeneric)
