import { useMutation } from '@apollo/client'
import React, { ReactNode, useEffect, useMemo } from 'react'

import { ONE_MINUTE, ONE_SECOND } from 'config/date'
import { AuthContext } from 'providers/Auth/AuthContext'
import { REFRESH_TOKEN } from 'providers/Auth/graphql/mutations/REFRESH_TOKEN'
import { useToken } from 'providers/Token'

export const getCurrentTokenExpiredIn = (tokenExpiredAt: Number) => {
  // The token is refreshed twenty minutes before it expires
  return Math.max(
    0,
    Number(tokenExpiredAt) * ONE_SECOND - new Date().getTime() - 20 * ONE_MINUTE
  )
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { token, expiresAt, setToken } = useToken()
  const [refreshToken] = useMutation(REFRESH_TOKEN)

  // Logout when token expires (check every second)
  useEffect(() => {
    const interval = setInterval(() => {
      if (expiresAt && expiresAt * ONE_SECOND <= new Date().getTime()) {
        setToken()
      }
    }, ONE_SECOND)

    return () => {
      clearInterval(interval)
    }
  }, [expiresAt])

  useEffect(() => {
    if (Number(expiresAt) * ONE_SECOND > new Date().getTime()) {
      const timeout = setTimeout(() => {
        refreshToken()
          .then(({ data }) => {
            if (data?.refreshToken) {
              setToken(data.refreshToken)
            }
          })
          .catch((error) => {
            console.error(error)
            setToken()
          })
      }, getCurrentTokenExpiredIn(Number(expiresAt)))

      return () => {
        clearTimeout(timeout)
      }
    }
    setToken()
  }, [expiresAt])

  const context = useMemo(
    () => ({
      isAuth: Boolean(token),
    }),
    [Boolean(token)]
  )

  return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
}
