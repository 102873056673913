import React, { useContext } from 'react'

import { Email } from 'config/email'

export interface IUserContext {
  id?: string
  name?: string
  firstName?: string
  lastName?: string
  primaryEmail?: string
  emails: Email[]
  phone?: string
  isFromEquify: boolean
  loading: boolean
}

export const UserContext = React.createContext<IUserContext>({
  loading: false,
  emails: [],
  isFromEquify: false,
})

export const useUser = (): IUserContext => useContext(UserContext)
