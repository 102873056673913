// https://ant.design/components/spin/

import { Spin as AntdSpin } from 'antd'
import { SpinProps as AntdSpinProps } from 'antd/lib/spin'
import React from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'

export interface SpinProps extends Omit<AntdSpinProps, 'tip'> {
  tip?: MessageType
}

const Spin = ({ tip, ...rest }: SpinProps) => {
  const formatMessage = useFormatMessage()

  return <AntdSpin tip={formatMessage(tip) as string} {...rest} />
}

export default React.memo(Spin)
