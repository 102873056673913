import React from 'react'

import PDFPreview from 'components/atoms/PDF/Preview'
import Border from 'components/atoms/Section/Border'
import { BorderType } from 'config/border'
import { x } from 'utils/emotion'

export const PreviewFile = ({ selectedIds }: { selectedIds: string[] }) => {
  if (!selectedIds.length || selectedIds.length > 1) {
    return null
  }

  return (
    <>
      <Border type={BorderType.vertical} />
      <x.div
        display="flex"
        alignItems="center"
        flex="1"
        overflowX="hidden"
        overflowY="auto"
        backgroundColor="#525659"
      >
        <PDFPreview fileId={selectedIds[0]} />
      </x.div>
    </>
  )
}
