import {
  AlignLeftOutlined,
  FileTextOutlined,
  MailOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { MenuInfo } from 'rc-menu/lib/interface.d'
import React from 'react'

import Menu from 'components/atoms/Menu'
import { CorporationPermissions, PortfolioPermissions } from 'config/roles'
import { useIsCorporationWorkspace } from 'hooks/useWorkspace'
import { useDrawer } from 'providers/Drawer'
import { CommunicationDrawerTab } from 'providers/Drawer/drawers/Communication'
import { usePermissions } from 'providers/Permissions'

const Sider = () => {
  const { tab, changeTab } = useDrawer()
  const { checkPermissions } = usePermissions()
  const isCorporationWorkspace = useIsCorporationWorkspace()

  const handleClick = ({ key }: MenuInfo) => {
    changeTab(key as CommunicationDrawerTab)
  }

  return (
    <Menu
      selectedKeys={[tab]}
      mode="inline"
      style={{ height: '100%' }}
      menu={[
        isCorporationWorkspace
          ? {
              icon: <AlignLeftOutlined />,
              title: 'word.details',
              key: CommunicationDrawerTab.details,
              onClick: handleClick,
            }
          : undefined,
        {
          icon: <MailOutlined />,
          title: 'word.content',
          key: CommunicationDrawerTab.contentTab,
          onClick: handleClick,
        },
        isCorporationWorkspace
          ? {
              icon: <TeamOutlined />,
              title: 'word.holders',
              key: CommunicationDrawerTab.recipients,
              onClick: handleClick,
            }
          : undefined,
        checkPermissions(CorporationPermissions.DOCUMENTS_READ) ||
        checkPermissions(PortfolioPermissions.DOCUMENTS_READ)
          ? {
              icon: <FileTextOutlined />,
              title: 'word.documents',
              key: CommunicationDrawerTab.documents,
              onClick: handleClick,
            }
          : undefined,
      ]}
    />
  )
}

export default React.memo(Sider)
