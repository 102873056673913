import { gql } from '@apollo/client'

export const RUN_START = gql`
  mutation RUN_START($slug: String!, $data: JSON!) {
    startWorkflow(slug: $slug, data: $data) {
      ... on Workflow {
        id
        slug
        data
        stateKey
        canGoBack
        updatedEntities {
          id
          typename
        }
        currentStep {
          slug
          payload
        }
        progress {
          current
          steps
        }
      }
      ... on FinishedWorkflow {
        data
        updatedEntities {
          id
          typename
        }
      }
    }
  }
`
