import { useQuery } from '@apollo/client'
import React from 'react'

import { DateType } from 'components/atoms/Form/DatePicker'
import SkeletonText from 'components/atoms/Skeleton/Text'
import Tag, { TagProps } from 'components/atoms/Tag'
import { GET_POOL_STATUS } from 'components/molecules/Tag/PoolStatus/GET_POOL_STATUS'
import { PoolStatus } from 'config/pool'
import eqDSTheme from 'styles/eqDSTheme'

const TagPoolStatus = ({
  poolId,
  status,
  date,
  noSkeleton,
  ...rest
}: TagProps & {
  poolId?: string
  status?: PoolStatus
  date?: DateType
  noSkeleton?: boolean
}) => {
  const { data, loading } = useQuery(GET_POOL_STATUS, {
    variables: { poolId, date },
    skip: !poolId,
  })

  const color = {
    [PoolStatus.EXPIRED]: eqDSTheme.color.darkgrey,
    [PoolStatus.FULLY_ALLOCATED]: eqDSTheme.color.darkgrey,
    [PoolStatus.NOT_STARTED]: eqDSTheme.color.palette.red100,
    [PoolStatus.ONGOING]: eqDSTheme.color.palette.green100,
    [PoolStatus.TERMINATED]: eqDSTheme.color.darkgrey,
  }[status || (data?.pool?.status as PoolStatus)]

  return (
    <SkeletonText loading={!noSkeleton && loading}>
      {(status || data?.pool?.status) && (
        <Tag
          color={color}
          message={`pool.status.${status || data?.pool?.status}`}
          {...rest}
        />
      )}
    </SkeletonText>
  )
}

export default React.memo(TagPoolStatus)
