import React, { lazy } from 'react'

import Modal, { ModalProps } from 'components/atoms/Modal'

const FormChangeCorporationInfoType = lazy(
  () =>
    import(
      'components/molecules/ButtonMoreActions/CorporationInfo/ChangeType/Modal/Form'
    )
)

const ChangeTypeModal = ({
  visible,
  setVisible,
  corporationInfoId,
  ...rest
}: ModalProps & {
  corporationInfoId: string
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <Modal
      destroyOnClose
      title="word.changeType"
      okText="word.edit"
      cancelText="word.close"
      bodyStyle={{ padding: 0 }}
      open={visible}
      footer={null}
      {...rest}
    >
      <FormChangeCorporationInfoType
        setVisible={setVisible}
        corporationInfoId={corporationInfoId}
      />
    </Modal>
  )
}

export default React.memo(ChangeTypeModal)
