import { gql } from '@apollo/client'

export const FILES_SHARE = gql`
  mutation FILES_SHARE($ids: [ID!]!, $portfolioIds: [ID!]!) {
    files(ids: $ids) {
      share(portfolioIds: $portfolioIds) {
        id
        portfolios {
          sharedWith {
            id
          }
        }
      }
    }
  }
`
