import React, { ReactNode, useCallback, useMemo, useState } from 'react'

import { LocalStorage } from 'config/localStorage'
import { LocalStorageValue, TokenContext } from 'providers/Token/TokenContext'

export const TokenProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<LocalStorageValue>(
    localStorage[LocalStorage.TOKEN_VALUE]
  )
  const [expiresAt, setExpiresAt] = useState<LocalStorageValue>(
    localStorage[LocalStorage.TOKEN_EXPIRES_AT]
  )

  const setTokenFn = useCallback(
    (opts?: { token: string; expiresAt: number }) => {
      if (!opts) {
        setToken(undefined)
        setExpiresAt(undefined)
        localStorage.removeItem(LocalStorage.TOKEN_VALUE)
        localStorage.removeItem(LocalStorage.TOKEN_EXPIRES_AT)
        return
      }

      setToken(opts.token)
      setExpiresAt(String(opts.expiresAt))
      localStorage.setItem(LocalStorage.TOKEN_VALUE, opts.token)
      localStorage.setItem(
        LocalStorage.TOKEN_EXPIRES_AT,
        String(opts.expiresAt)
      )
    },
    []
  )

  const context = useMemo(
    () => ({
      token,
      expiresAt: expiresAt ? Number(expiresAt) : undefined,
      setToken: setTokenFn,
    }),
    [token, expiresAt]
  )

  return (
    <TokenContext.Provider value={context}>{children}</TokenContext.Provider>
  )
}
