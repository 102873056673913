import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_WALLET_SECURITY_ID } from 'hooks/wallets/useWalletSecurityId/GET_WALLET_SECURITY_ID'

export const useWalletSecurityId = (walletId?: string) => {
  const result = useQuery<{ wallet: { security: { id: string } } }>(
    GET_WALLET_SECURITY_ID,
    {
      variables: { walletId },
      skip: !walletId,
    }
  )

  return useMemo(
    () => ({
      ...result,
      securityId: result?.data?.wallet?.security?.id,
    }),
    [result]
  )
}
