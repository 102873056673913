export enum FileStatus {
  WAITING = 'WAITING',
  UPLOADING = 'UPLOADING',
  PARSING = 'PARSING',
  GENERATING = 'GENERATING',
  READY = 'READY',
}

export enum SmartFieldsGroupType {
  UNKNOWN = 'UNKNOWN',
  CORPORATION = 'CORPORATION',
  HOLDER = 'HOLDER',
  CONTACT = 'CONTACT',
  SECURITY = 'SECURITY',
  TRANSACTION = 'TRANSACTION',
  POOL = 'POOL',
}

export enum SmartFieldsProperty {
  UNKNOWN = 'UNKNOWN',
  DATE = 'DATE',
  EXPIRATION_DATE = 'EXPIRATION_DATE',
  VESTING_START_DATE = 'VESTING_START_DATE',
  APPROVAL_DATE = 'APPROVAL_DATE',
  SIGNATURE_DEADLINE = 'SIGNATURE_DEADLINE',
  BIRTH_DATE = 'BIRTH_DATE',
  ISSUE_DATE = 'ISSUE_DATE',
  PRICE = 'PRICE',
  PRICE_PER_SHARE = 'PRICE_PER_SHARE',
  EXERCISE_PRICE = 'EXERCISE_PRICE',
  QUANTITY = 'QUANTITY',
  FROM_QUANTITY = 'FROM_QUANTITY',
  TO_QUANTITY = 'TO_QUANTITY',
  CAP_SHARES_AUTHORIZED = 'CAP_SHARES_AUTHORIZED',
  NAME = 'NAME',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  BIRTH_PLACE = 'BIRTH_PLACE',
  ADDRESS = 'ADDRESS',
  LEGAL_FORM = 'LEGAL_FORM',
  INCORPORATION_NUMBER = 'INCORPORATION_NUMBER',
  INCORPORATION_PLACE = 'INCORPORATION_PLACE',
  NATIONALITY = 'NATIONALITY',
  RELATION = 'RELATION',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  SIGNATURE = 'SIGNATURE',
}

export enum DocxErrorType {
  UNOPENED_SMART_FIELD = 'UNOPENED_SMART_FIELD',
  UNCLOSED_SMART_FIELD = 'UNCLOSED_SMART_FIELD',
  UNOPENED_LOOP = 'UNOPENED_LOOP',
  UNCLOSED_LOOP = 'UNCLOSED_LOOP',
  DUPLICATE_OPEN_TAG = 'DUPLICATE_OPEN_TAG',
  DUPLICATE_CLOSE_TAG = 'DUPLICATE_CLOSE_TAG',
  CLOSING_TAG_DOES_NOT_MATCH_OPENING_TAG = 'CLOSING_TAG_DOES_NOT_MATCH_OPENING_TAG',
  RAW_XML_TAG_SHOULD_BE_ONLY_TEXT_IN_PARAGRAPH = 'RAW_XML_TAG_SHOULD_BE_ONLY_TEXT_IN_PARAGRAPH',
  UNKNOWN = 'UNKNOWN',
  CORRUPT = 'CORRUPT',
}

export type DocxError = {
  context: String
  type: DocxErrorType
}
