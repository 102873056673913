// https://ant.design/components/alert/

import { Alert as AntdAlert, AlertProps as AntdAlertProps } from 'antd'
import React, { ReactNode } from 'react'

export interface AlertProps extends AntdAlertProps {
  message?: ReactNode
  type?: 'success' | 'info' | 'warning' | 'error'
  description?: ReactNode
  showIcon?: boolean
  closable?: boolean
  afterClose?: () => void
  style?: any
}

const Alert = (props: AlertProps) => {
  return <AntdAlert {...props} />
}

export default React.memo(Alert)
