import { gql } from '@apollo/client'

export const FILES_LINK_SECURITIES = gql`
  mutation FILES_LINK_SECURITIES($ids: [ID!]!, $securityIds: [ID!]) {
    files(ids: $ids) {
      link(securityIds: $securityIds) {
        id
        securities {
          securities {
            id
          }
        }
      }
    }
  }
`
