import dayjs from 'dayjs'
import React, { useCallback, useMemo } from 'react'
import { FormatDateOptions, useIntl } from 'react-intl'

import { useFormatMessage } from 'components/atoms/Intl/Message'
import { stringToDate } from 'utils/date'

export const useDateFormat = () => {
  const intl = useIntl()

  return useMemo(() => {
    return intl
      .formatDate(stringToDate('1999-11-22'))
      .replace('1999', 'YYYY')
      .replace('11', 'MM')
      .replace('22', 'DD')
  }, [intl])
}

export const useIntlDateFormat = () => {
  const dateFormat = useDateFormat()
  const formatMessage = useFormatMessage()

  const DD = (formatMessage('dateFormat.DD') as string) || 'DD'
  const MM = (formatMessage('dateFormat.MM') as string) || 'MM'
  const YYYY = (formatMessage('dateFormat.YYYY') as string) || 'YYYY'

  return useMemo(() => {
    return dateFormat.replace('DD', DD).replace('MM', MM).replace('YYYY', YYYY)
  }, [dateFormat, DD, MM, YYYY])
}

export const useParseDate = () => {
  const dateFormat = useDateFormat()

  return useCallback(
    (value: any) => {
      const parsedDate = dayjs(value.replace(/[^0-9]+/g, '/'), [
        dateFormat,
        'YYYY/MM/DD',
      ])

      return parsedDate.isValid() ? parsedDate.format('YYYY-MM-DD') : undefined
    },
    [dateFormat]
  )
}

export const useFormatDate = () => {
  const intl = useIntl()
  return (
    value?: Date | string,
    options: FormatDateOptions | undefined = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    }
  ) => {
    if (!value) {
      return ''
    }

    return intl.formatDate(stringToDate(value), options)
  }
}

const Date = ({
  value,
  options,
}: {
  value: Date | string
  options?: FormatDateOptions
}) => {
  const formatDate = useFormatDate()

  return <>{formatDate(value, options)}</>
}

export default React.memo(Date)
