import {
  CheckOutlined,
  CloseOutlined,
  HomeOutlined,
  RightOutlined,
} from '@ant-design/icons'
import React from 'react'

import Button from 'components/atoms/Button'
import Dropdown from 'components/atoms/Dropdown'
import Menu from 'components/atoms/Menu'
import Tooltip from 'components/atoms/Tooltip'
import { LocalStorage } from 'config/localStorage'
import { useIsLaptopWindowSize } from 'hooks/useWindowSize'
import { useAuth } from 'providers/Auth'
import { cleanArray } from 'utils'

export const Admin = ({
  recordLogRocket,
  setRecordLogRocket,
  showEquifyPrivileges,
  setShowEquifyPrivileges,
}: {
  recordLogRocket: boolean
  setRecordLogRocket: (value: any) => void
  showEquifyPrivileges: boolean
  setShowEquifyPrivileges: (value: any) => void
}) => {
  const { isAuth } = useAuth()
  const isLaptopWindowSize = useIsLaptopWindowSize()

  if (!isAuth) {
    return null
  }

  return (
    <Dropdown
      trigger={['click']}
      dropdownRender={() => (
        <Menu
          selectedKeys={cleanArray([
            recordLogRocket ? LocalStorage.RECORD_LOG_ROCKET : undefined,
            showEquifyPrivileges
              ? LocalStorage.SHOW_EQUIFY_PRIVILEGES
              : undefined,
          ])}
          menu={[
            {
              icon: <RightOutlined />,
              key: 'panel',
              message: 'Admin panel',
              to: '/admin/equify',
            },
            {
              icon: recordLogRocket ? <CheckOutlined /> : <CloseOutlined />,
              key: LocalStorage.RECORD_LOG_ROCKET,
              message: 'Record session (LogRocket)',
              onClick: () => {
                setRecordLogRocket(!recordLogRocket)
              },
            },
            {
              icon: showEquifyPrivileges ? (
                <CheckOutlined />
              ) : (
                <CloseOutlined />
              ),
              key: LocalStorage.SHOW_EQUIFY_PRIVILEGES,
              message: 'Show Equify privileges',
              onClick: () => {
                setShowEquifyPrivileges(!showEquifyPrivileges)
              },
            },
          ]}
        />
      )}
    >
      <Tooltip title={isLaptopWindowSize ? undefined : 'Admin'}>
        <Button
          icon={<HomeOutlined />}
          message={isLaptopWindowSize ? 'Admin' : undefined}
        />
      </Tooltip>
    </Dropdown>
  )
}
