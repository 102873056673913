import { gql } from '@apollo/client'

export const GET_FILE_ERRORS = gql`
  query GET_FILE_ERRORS($fileId: ID!) {
    file(id: $fileId) {
      id
      errors {
        ... on DocxError {
          context
          type
        }
      }
    }
  }
`
