import { gql } from '@apollo/client'

export const GET_MEETING_NAME = gql`
  query GET_MEETING_NAME($meetingId: ID!) {
    meeting(id: $meetingId) {
      id
      name
    }
  }
`
