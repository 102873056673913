import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_EVENT_CORPORATION_ID } from 'hooks/events/useEventCorporationId/GET_EVENT_CORPORATION_ID'

export const useEventCorporationId = (eventId?: string) => {
  const result = useQuery<{ event: { corporation: { id: string } } }>(
    GET_EVENT_CORPORATION_ID,
    {
      variables: { eventId },
      skip: !eventId,
    }
  )

  return useMemo(
    () => ({
      ...result,
      corporationId: result?.data?.event?.corporation?.id,
    }),
    [result]
  )
}
