// https://ant.design/components/table/
// https://ant.design/components/pagination/

import { InfoCircleOutlined } from '@ant-design/icons'
import { Table as AntdTable } from 'antd'
import {
  TableProps as AntdTableProps,
  ColumnType as AntdColumnType,
} from 'antd/lib/table'
import { TableRowSelection } from 'antd/lib/table/interface'
import React, { useCallback, useMemo, useState } from 'react'

import Button from 'components/atoms/Button'
import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'
import Number from 'components/atoms/Intl/Number'
import Tooltip from 'components/atoms/Tooltip'
import { AlignedType, FixedType } from 'config/table'
import { useSelection } from 'providers/Selection'
import eqDSTheme from 'styles/eqDSTheme'
import { cleanArray, uniqueId } from 'utils'
import { x } from 'utils/emotion'

export type RecordType = Record<string, any>

export interface ColumnType
  extends Omit<AntdColumnType<RecordType>, 'fixed' | 'aligned'> {
  title?: MessageType
  fixed?: FixedType
  aligned?: AlignedType
  notClickable?: boolean
  tooltip?: MessageType
}

export interface TableProps
  extends Omit<AntdTableProps<RecordType>, 'columns' | 'rowSelection'> {
  columns: (ColumnType | undefined)[]
  fullHeight?: boolean
  unit?: string
  rowSelection?: TableRowSelection<RecordType> | null
}

const Table = ({
  columns,
  fullHeight,
  scroll,
  onRow = () => ({}),
  dataSource,
  expandable,
  pagination = {},
  rowSelection,
  unit,
  ...rest
}: TableProps) => {
  const formatMessage = useFormatMessage()
  const tableUid = useMemo<string>(() => uniqueId(), [])
  const dragAndDropElementUid = useMemo<string>(() => uniqueId(), [])
  const { ids, set, count } = useSelection()
  const [tableRectY, setTableRectY] = useState(0)

  const formatColumns = useCallback(
    (columns: (ColumnType | undefined)[]) =>
      cleanArray(columns).map(
        ({ title, notClickable, tooltip, ...columnRest }: ColumnType) => {
          return {
            title: tooltip ? (
              <Tooltip title={tooltip}>
                {formatMessage(title)} <InfoCircleOutlined />
              </Tooltip>
            ) : (
              formatMessage(title)
            ),
            onCell: notClickable
              ? () => ({
                  style: { cursor: 'default' },
                  onClick: (event: any) => {
                    event.stopPropagation()
                  },
                })
              : undefined,
            ...columnRest,
          }
        }
      ),
    [formatMessage]
  )

  if (fullHeight) {
    setTimeout(() => {
      const tableRect = document
        .getElementById(tableUid)
        ?.getBoundingClientRect()
      if (tableRect?.y && tableRect.y !== tableRectY) {
        setTableRectY(tableRect.y)
      }
    }, 0)
  }

  const hasPagination =
    (
      document.getElementById(tableUid)?.nextSibling as Element | undefined
    )?.className?.includes('pagination') ?? false

  return (
    <>
      <AntdTable
        id={tableUid}
        rowSelection={
          (ids && rowSelection !== null) || rowSelection
            ? {
                fixed: true,
                type: 'checkbox',
                selectedRowKeys: ids,
                onChange: (currentIds: any) => {
                  const selection = new Set(ids)
                  dataSource?.forEach(({ id }) => {
                    if (currentIds.includes(id)) {
                      selection.add(id)
                    } else {
                      selection.delete(id)
                    }
                  })
                  set([...selection])
                },
                ...(rowSelection || {}),
                columnTitle: formatMessage(rowSelection?.columnTitle as string),
                columnWidth: eqDSTheme.size.header,
              }
            : undefined
        }
        dataSource={(dataSource || []).map((element, index) => ({
          ...element,
          key: element.id || element.key || index,
        }))}
        columns={formatColumns(columns)}
        scroll={{
          x: true,
          y:
            fullHeight && tableRectY
              ? `calc(100vh - ${tableRectY + 39 + (hasPagination ? 74 : 0)}px)`
              : undefined,
          ...scroll,
        }}
        rowKey={(record) => record.id || record.key}
        onRow={(record) => ({
          onDragStart: (event) => {
            const isSelected = ids?.includes(record.id)
            if (ids && !isSelected) {
              set([record.id])
            }
            event.dataTransfer.setData(
              'text/plain',
              JSON.stringify({
                index: record.index,
                ids: isSelected ? ids : [record.id],
              })
            )
            event.dataTransfer.effectAllowed = 'all'
            event.dataTransfer.setDragImage(
              document.getElementById(dragAndDropElementUid) as HTMLElement,
              0,
              0
            )
          },
          ...onRow(record),
        })}
        expandable={expandable}
        rowClassName={(record) => {
          if (onRow(record).draggable) {
            return 'row-classname-cursor-grab'
          }
          if (
            onRow(record).onClick ||
            onRow(record).onDoubleClick ||
            expandable?.expandRowByClick
          ) {
            return 'row-classname-cursor-pointer'
          }
          return ''
        }}
        pagination={{
          hideOnSinglePage: true,
          ...pagination,
        }}
        {...rest}
      />
      <x.div id={dragAndDropElementUid} position="fixed" top="-1000px">
        <Button
          type="primary"
          message={<Number value={count || 1} unit={unit || 'elements'} />}
        />
      </x.div>
    </>
  )
}

export default React.memo(Table)
