import { useMutation, useQuery } from '@apollo/client'
import { uniq } from 'lodash/fp'
import React, { useEffect, useState } from 'react'

import DatePicker, { DateType } from 'components/atoms/Form/DatePicker'
import { FILES_SET_DATE } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Date/FILES_SET_DATE'
import { GET_SELECTED_FILES_DATES } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Date/GET_SELECTED_FILES_DATES'

export const Date = ({ selectedIds }: { selectedIds: string[] }) => {
  const [value, setValue] = useState<DateType>(null)
  const [setDate] = useMutation(FILES_SET_DATE)

  const { data } = useQuery<{
    files: {
      id: string
      date?: string
    }[]
  }>(GET_SELECTED_FILES_DATES, {
    variables: { ids: selectedIds },
  })

  useEffect(() => {
    const values = uniq((data?.files || []).map((file) => file.date)).filter(
      Boolean
    ) as string[]
    if (values.length === 1) {
      setValue(values[0])
    } else {
      setValue(null)
    }
  }, [data])

  return (
    <DatePicker
      value={value}
      onChange={(newValue) => {
        setValue(newValue)
        setDate({
          variables: {
            ids: selectedIds,
            date: newValue,
          },
        })
      }}
    />
  )
}
