import React, { lazy, Suspense } from 'react'

import { IntegrationHistoryDrawerTab } from 'providers/Drawer/drawers/IntegrationHistory/index'
import { useDrawer } from 'providers/Drawer/index'

const Details = lazy(
  () => import('providers/Drawer/drawers/IntegrationHistory/Content/Details')
)

const Content = () => {
  const { tab } = useDrawer()

  const Component = {
    details: Details,
  }[tab as IntegrationHistoryDrawerTab]

  if (!Component) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  )
}

export default React.memo(Content)
