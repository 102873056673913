import { useApolloClient } from '@apollo/client'
import { get, isEqual } from 'lodash/fp'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'

import {
  ApiSearchContext,
  IApiSearchHit,
  DEFAULT_HITS_PER_PAGE,
  ApiSearchProviderProps,
  DEFAULT_DEBOUNCE_SEARCH,
  IApiSearchMemoVariables,
} from 'providers/ApiSearch/ApiSearchContext'
import { useApiSearchFilters } from 'providers/ApiSearchFilters'
import { sanitize } from 'utils'

export const ApiSearchProvider = ({
  children,
  query,
  path,
  variables,
  saveFacets,
  defaultFilters,
  filtersToUse,
  localSearchInKeys,
}: ApiSearchProviderProps) => {
  const client = useApolloClient()
  const { search, filters, sort } = useApiSearchFilters()
  const [debouncedSearch] = useDebounce(search, DEFAULT_DEBOUNCE_SEARCH)

  const [loading, setLoading] = useState<boolean>(true)
  const [hits, setHits] = useState<IApiSearchHit[]>([])
  const [nbHits, setNbHits] = useState<number>(0)
  const [page, setPage] = useState<number>(1)
  const [nbPages, setNbPages] = useState<number>(0)
  const [hitsPerPage, setHitsPerPage] = useState<number>(DEFAULT_HITS_PER_PAGE)
  const [facets, setFacets] = useState<
    Record<string, Record<string, number>> | undefined
  >()

  const result = useCallback(
    (data) => {
      setHits(get(path, data)?.hits ?? [])
      setNbHits(get(path, data)?.nbHits ?? 0)
      if (get(path, data)?.nbPages && get(path, data)?.nbPages < page) {
        setPage(get(path, data)?.nbPages)
      }
      if (Object.keys(get(path, data)?.facets || {})?.length > 0) {
        setFacets(get(path, data)?.facets ?? undefined)
      }
      setLoading(false)
    },
    [page]
  )

  const memoVariables = useMemo<IApiSearchMemoVariables>(() => {
    const filtersToSend = filtersToUse || filters
    return {
      ...variables,
      page: page - 1,
      hitsPerPage,
      search: !localSearchInKeys?.length
        ? debouncedSearch || undefined
        : undefined,
      filters:
        Object.keys({ ...defaultFilters, ...filtersToSend }).length > 0
          ? { ...defaultFilters, ...filtersToSend }
          : undefined,
      sort: sort || undefined,
    }
  }, [
    variables,
    defaultFilters,
    localSearchInKeys,
    page,
    hitsPerPage,
    debouncedSearch,
    filters,
    sort,
  ])

  const [previousMemoVariables, setPreviousMemoVariables] =
    useState(memoVariables)

  const watchQuery = useMemo(
    () =>
      client.watchQuery({
        query,
        fetchPolicy: 'network-only',
        variables: {
          ...memoVariables,
          facets:
            saveFacets && Object.keys(facets || {})?.length === 0
              ? ['*']
              : undefined,
        },
      }),
    []
  )

  useEffect(() => {
    const subscription = watchQuery?.subscribe({
      next({ data }) {
        result(data)
      },
      error(error) {
        console.error(error)
        setLoading(false)
      },
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (watchQuery && !isEqual(memoVariables, previousMemoVariables)) {
      setLoading(true)
      watchQuery
        .refetch({
          ...memoVariables,
          facets:
            saveFacets && Object.keys(facets || {})?.length === 0
              ? ['*']
              : undefined,
        })
        .then(({ data }) => {
          result(data)
        })
        .catch((error) => {
          console.error(error)
          setLoading(false)
        })
      setPreviousMemoVariables(memoVariables)
    }
  }, [watchQuery, memoVariables, previousMemoVariables, saveFacets, facets])

  const context = useMemo(
    () => ({
      loading: loading || search !== debouncedSearch,
      hits: !localSearchInKeys?.length
        ? hits
        : hits.filter((hit) =>
            localSearchInKeys.some((key) =>
              search
                ? sanitize(get(key, hit))?.includes(sanitize(search))
                : true
            )
          ),
      nbHits: !localSearchInKeys?.length
        ? nbHits
        : hits.filter((hit) =>
            localSearchInKeys.some((key) =>
              search
                ? sanitize(get(key, hit))?.includes(sanitize(search))
                : true
            )
          ).length,
      page,
      setPage,
      nbPages,
      setNbPages,
      hitsPerPage,
      setHitsPerPage,
      facets,
    }),
    [
      localSearchInKeys,
      loading,
      search,
      debouncedSearch,
      hits,
      nbHits,
      page,
      nbPages,
      hitsPerPage,
      facets,
    ]
  )

  return (
    <ApiSearchContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </ApiSearchContext.Provider>
  )
}
