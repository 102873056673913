import React, { ReactNode } from 'react'

import { IApiSearchContext } from 'providers/ApiSearch/ApiSearchContext'
import { ApiSearchProvider } from 'providers/ApiSearch/ApiSearchProvider'
import { GET_CORPORATION_SECURITIES } from 'providers/ApiSearch/CorporationSecurities/GET_CORPORATION_SECURITIES'

export type ApiSearchCorporationSecuritiesProps = {
  children: ReactNode | ((context: IApiSearchContext) => ReactNode)
  corporationId: string
}

export const ApiSearchCorporationSecurities = ({
  children,
  corporationId,
}: ApiSearchCorporationSecuritiesProps) => {
  if (!corporationId) {
    return null
  }

  return (
    <ApiSearchProvider
      query={GET_CORPORATION_SECURITIES}
      path={'corporation.securities.list'}
      variables={{ corporationId }}
    >
      {children}
    </ApiSearchProvider>
  )
}
