import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { useContactName } from 'hooks/contacts/useContactName'

const ContactName = ({ contactId }: { contactId?: string }) => {
  const { name, loading } = useContactName(contactId)

  return <SkeletonText loading={loading}>{name}</SkeletonText>
}

export default React.memo(ContactName)
