import { get } from 'lodash/fp'
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react'

import Label from 'components/atoms/Form/Label'
import RadioGroup from 'components/atoms/Form/RadioGroup'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import Modal, { ModalProps } from 'components/atoms/Modal'
import Null from 'components/atoms/Null'
import {
  ExportFormat,
  ExportType,
  TransactionExportOption,
} from 'config/export'
import { useExportModal } from 'providers/Export/ExportModalContext'
import { useCreateExport } from 'providers/Export/hooks/useCreateExport'
import { useSkalin } from 'providers/Skalin'
import { ErrorType, getErrors } from 'utils/graphql'

const CapTableExport = lazy(
  () => import('providers/Export/ExportModal/CapTable')
)
const HoldersListExport = lazy(
  () => import('providers/Export/ExportModal/HoldersList')
)
const HoldingStatementExport = lazy(
  () => import('providers/Export/ExportModal/HoldingStatement')
)
const TransactionLedgerExport = lazy(
  () => import('providers/Export/ExportModal/TransactionLedger')
)
const AwardsExport = lazy(() => import('providers/Export/ExportModal/Awards'))
const AwardsByPeriodExport = lazy(
  () => import('providers/Export/ExportModal/AwardsByPeriod')
)
const EventTransactionsExport = lazy(
  () => import('providers/Export/ExportModal/EventTransactions')
)
const PoolHistoryExport = lazy(
  () => import('providers/Export/ExportModal/PoolHistory')
)

const EXPORT_COMPONENT = {
  [ExportType.AWARDS]: AwardsExport,
  [ExportType.AWARDS_PERIOD]: AwardsByPeriodExport,
  [ExportType.CAP_TABLE]: CapTableExport,
  [ExportType.EVENT_TRANSACTIONS]: EventTransactionsExport,
  [ExportType.HOLDERS_LIST]: HoldersListExport,
  [ExportType.HOLDING_STATEMENT]: HoldingStatementExport,
  [ExportType.TRANSACTION_LEDGER]: TransactionLedgerExport,
  [ExportType.POOL_HISTORY]: PoolHistoryExport,
  [ExportType.YOU_SIGN_PROOF_FILE]: Null,
}

export const ExportModal = ({ onCancel = () => {}, ...rest }: ModalProps) => {
  const { params, formats, name, type } = useExportModal()
  const formatMessage = useFormatMessage()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<ErrorType>()
  const [format, setFormat] = useState<ExportFormat>(formats[0])
  const createExport = useCreateExport()
  const { skalin } = useSkalin()

  useEffect(() => {
    setFormat(formats[0])
  }, [formats])

  useEffect(() => {
    setErrors(undefined)
  }, [params])

  const Component = type ? get(type, EXPORT_COMPONENT) || null : null
  const isValid = useCallback(() => {
    switch (type) {
      case ExportType.CAP_TABLE:
        return Boolean(params.corporationId && params.date)
      case ExportType.EVENT_TRANSACTIONS:
        return Boolean(params.corporationId && params.eventId)
      case ExportType.HOLDERS_LIST:
      case ExportType.TRANSACTION_LEDGER:
      case ExportType.AWARDS:
        return Boolean(params.corporationId && params.date)
      case ExportType.AWARDS_PERIOD:
        return Boolean(
          params.corporationId && params.dateStart && params.dateEnd
        )
      case ExportType.HOLDING_STATEMENT: {
        if (format === ExportFormat.PDF && params.signer) {
          return Boolean(
            params.portfolioId &&
              params.corporationId &&
              params.date &&
              params.signer.contactId &&
              params.signer.email &&
              params.signer.phone
          )
        }
        return Boolean(
          params.portfolioId && params.corporationId && params.date
        )
      }
      case ExportType.POOL_HISTORY: {
        return Boolean(params.corporationId && params.date && params.poolId)
      }
      default:
        return false
    }
  }, [format, params, type])

  const getParams = (
    unsortedParams: Record<string, any>,
    type: ExportType
  ): Record<string, any> => {
    if (type === ExportType.TRANSACTION_LEDGER) {
      const { date, transactionIds, filters, mode, ...rest } = params

      if (mode) {
        if (mode === TransactionExportOption.SELECTED_TRANSACTIONS) {
          return {
            ...rest,
            aggregatedTransactionIds: transactionIds,
          }
        }
        if (mode === TransactionExportOption.FILTERED_TRANSACTIONS) {
          const { endDate, ...otherFiltersParams } = filters

          return {
            ...rest,
            filters: {
              ...otherFiltersParams,
            },
            date: endDate,
          }
        }
        return { ...rest, date }
      }
      return { ...rest, date }
    }
    return unsortedParams
  }

  const title = `${formatMessage('word.export')} · ${formatMessage(name)}`

  return (
    <Modal
      destroyOnClose
      title={title}
      okText={params.addFileToLibrary ? 'word.save' : 'word.toExport'}
      okButtonProps={{
        disabled: Boolean(!name || !type || !format || !isValid()),
        loading,
      }}
      onOk={(event) => {
        if (name && type && format) {
          setLoading(true)
          skalin.feature({
            name: `${type} Export Modal`,
          })
          createExport({
            name,
            type,
            format,
            params: getParams(params, type),
            onSuccess: () => {
              onCancel(event)
            },
          })
            .catch((error) => {
              console.error(error)
              setErrors(getErrors(error))
            })
            .finally(() => {
              setLoading(false)
            })
        }
      }}
      onCancel={onCancel}
      {...rest}
    >
      {Boolean(formats.length > 1) && (
        <Label label="word.format" required>
          <RadioGroup
            value={format}
            onChange={setFormat}
            optionType="button"
            buttonStyle="solid"
            options={formats.map((format) => ({
              label: `export.format.${format}`,
              value: format,
            }))}
          />
        </Label>
      )}
      {Component ? (
        <Suspense fallback={null}>
          <Component format={format} errors={errors} />
        </Suspense>
      ) : null}
    </Modal>
  )
}
