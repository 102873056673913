// https://ant.design/components/typography/

import { Typography } from 'antd'
import { TextProps as AntdTextProps } from 'antd/lib/typography/Text'
import React from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'

const { Text: AntdText } = Typography

export interface TextProps extends AntdTextProps {
  message?: MessageType
}

const Text = ({ children, message, ...rest }: TextProps) => {
  const formatMessage = useFormatMessage()

  return <AntdText {...rest}>{children || formatMessage(message)}</AntdText>
}

export default React.memo(Text)
