import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import SecurityName, {
  SecurityNameProps,
} from 'components/molecules/Security/Name'
import { useAggregatedTransactionFromSecurityId } from 'hooks/aggregatedTransactions/useAggregatedTransactionToSecurityId'

const AggregatedTransactionToSecurityName = ({
  aggregatedTransactionId,
  ...rest
}: Omit<SecurityNameProps, 'securityId'> & {
  aggregatedTransactionId: string
}) => {
  const { toSecurityId, loading } = useAggregatedTransactionFromSecurityId(
    aggregatedTransactionId
  )

  return (
    <SkeletonText loading={loading}>
      <SecurityName securityId={toSecurityId} {...rest} />
    </SkeletonText>
  )
}

export default React.memo(AggregatedTransactionToSecurityName)
