import { BarsOutlined, LinkOutlined } from '@ant-design/icons'
import { MenuInfo } from 'rc-menu/lib/interface.d'
import React from 'react'

import Menu from 'components/atoms/Menu'
import { useDrawer } from 'providers/Drawer/DrawerContext'
import { LegalEntityDrawerTab } from 'providers/Drawer/drawers/LegalEntity'

const Sider = () => {
  const { tab, changeTab } = useDrawer()

  const handleClick = ({ key }: MenuInfo) => {
    changeTab(key as LegalEntityDrawerTab)
  }

  return (
    <Menu
      selectedKeys={[tab]}
      mode="inline"
      style={{ height: '100%' }}
      menu={[
        {
          title: 'word.details',
          icon: <BarsOutlined />,
          key: LegalEntityDrawerTab.details,
          onClick: handleClick,
        },
        {
          title: 'word.links',
          icon: <LinkOutlined />,
          key: LegalEntityDrawerTab.links,
          onClick: handleClick,
        },
      ]}
    />
  )
}

export default React.memo(Sider)
