import React from 'react'

import Drawer from 'components/atoms/Drawer'
import TagWalletSecurityType from 'components/molecules/Tag/WalletSecurityType'
import WalletPortfolioName from 'components/molecules/Wallet/PortfolioName'
import WalletSecurityName from 'components/molecules/Wallet/SecurityName'
import { CorporationPermissions } from 'config/roles'
import { useIsCorporationWorkspace } from 'hooks/useWorkspace'
import { useWalletPortfolioName } from 'hooks/wallets/useWalletPortfolioName'
import { useWalletSecurityName } from 'hooks/wallets/useWalletSecurityName'
import { useDrawer } from 'providers/Drawer'
import Content from 'providers/Drawer/drawers/Wallet/Content'
import Sider from 'providers/Drawer/drawers/Wallet/Sider'
import { usePermissions } from 'providers/Permissions'

export enum WalletDrawerTab {
  details = 'details',
  vesting = 'vesting',
  simulator = 'simulator',
}

function Wallet() {
  const { id } = useDrawer()
  const isCorporationWorkspace = useIsCorporationWorkspace()
  const { checkPermissions } = usePermissions()
  const { portfolioName } = useWalletPortfolioName(id)
  const { securityName } = useWalletSecurityName(id)

  return (
    <Drawer
      open
      helmetTitle={
        portfolioName && securityName
          ? `${portfolioName} - ${securityName}`
          : 'word.wallet'
      }
      pageHeaderProps={{
        title: (
          <>
            <WalletPortfolioName
              walletId={id}
              withLink={
                isCorporationWorkspace &&
                checkPermissions(CorporationPermissions.PORTFOLIOS_READ)
              }
            />
            {' · '}
            <WalletSecurityName
              walletId={id}
              withLink={
                isCorporationWorkspace &&
                checkPermissions(CorporationPermissions.SECURITIES_READ)
              }
            />
          </>
        ),
        subTitle: 'word.wallet',
        tags: <TagWalletSecurityType walletId={id} />,
      }}
      sider={Sider}
      content={Content}
    />
  )
}

export default React.memo(Wallet)
