import React, { useContext } from 'react'

export type LocalStorageValue = string | undefined

interface ITokenContext {
  token?: string
  expiresAt?: number
  setToken: (opts?: { token: string; expiresAt: number }) => void
}

export const TokenContext = React.createContext<ITokenContext>({
  token: undefined,
  expiresAt: undefined,
  setToken: () => {},
})

export const useToken = (): ITokenContext => useContext(TokenContext)
