import React from 'react'

import {
  WorkflowField,
  WorkflowFieldProps,
} from 'providers/Workflows/components/WorkflowField'
import { cleanArray } from 'utils'
import { XProps, x } from 'utils/emotion'

type Field = WorkflowFieldProps | WorkflowFieldProps[]

export interface WorkflowFieldsProps extends XProps {
  fields: (Field | undefined)[]
}
const WorkflowFields = ({ fields = [], ...rest }: WorkflowFieldsProps) => {
  return (
    <x.div {...rest}>
      {cleanArray(fields).map((field, index, array) => {
        const isLast = index === array.length - 1

        if (Array.isArray(field)) {
          return (
            <x.div
              key={index}
              marginBottom={isLast ? '0px' : 'xlarge'}
              gridAutoFlow="column"
              gridAutoColumns="minmax(0, 1fr)"
              alignItems="start"
              display="grid"
              gap="24px"
              w="100%"
            >
              {field.map((subField, subIndex) => (
                <WorkflowFields key={subIndex} fields={[subField]} />
              ))}
            </x.div>
          )
        }
        return (
          <x.div key={index} marginBottom={isLast ? '0px' : 'xlarge'}>
            <WorkflowField {...field} />
          </x.div>
        )
      })}
    </x.div>
  )
}

export const WorkflowFieldsSymbol = Symbol('WorkflowFields')

WorkflowFields.displayName = WorkflowFieldsSymbol

export { WorkflowFields }
