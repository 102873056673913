import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'

import eqDSTheme from 'styles/eqDSTheme'

export enum SignatureStatus {
  PENDING = 'PENDING',
  REFUSED = 'REFUSED',
  SIGNED = 'SIGNED',
}
export const SIGNATURE_STATUS_TO_COLOR = {
  [SignatureStatus.PENDING]: eqDSTheme.color.palette.blue100,
  [SignatureStatus.REFUSED]: eqDSTheme.color.palette.red100,
  [SignatureStatus.SIGNED]: eqDSTheme.color.palette.green100,
}

export enum SignatureEventMessage {
  SIGNATURE_SUCCESS = 'SIGNATURE_SUCCESS',
  SIGNATURE_CANCEL = 'SIGNATURE_CANCEL',
  SIGNATURE_ERROR = 'SIGNATURE_ERROR',
}

export const SIGNATURE_STATUS_TO_ICON = {
  [SignatureStatus.PENDING]: ClockCircleOutlined,
  [SignatureStatus.REFUSED]: CloseCircleOutlined,
  [SignatureStatus.SIGNED]: CheckCircleOutlined,
}
