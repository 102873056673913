import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_FILE_IS_TEMPLATE } from 'hooks/files/useFileIsTemplate/GET_FILE_IS_TEMPLATE'

export const useFileIsTemplate = (fileId?: string) => {
  const result = useQuery<{
    file: {
      isTemplate: boolean
    }
  }>(GET_FILE_IS_TEMPLATE, {
    variables: { fileId },
    skip: !fileId,
  })

  return useMemo(
    () => ({
      ...result,
      isTemplate: result.data?.file?.isTemplate,
    }),
    [result]
  )
}
