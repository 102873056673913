import { gql } from '@apollo/client'

export const GET_MEETING_ATTENDEE_RESPONSE = gql`
  query GET_MEETING_ATTENDEE_RESPONSE($meetingAttendeeId: ID!) {
    meetingAttendee(id: $meetingAttendeeId) {
      id
      response
    }
  }
`
