import React, { ReactNode, useContext } from 'react'

export const SKALIN_CLIENT_ID = '7265f2956607c87e'

export type SkalinProviderProps = {
  children: ReactNode
}

export type Skalin = {
  clientId: (clientId: string) => void
  customerId: (customerId: string) => void
  identity: (identity: { id: string; email: string }) => void
  feature: (feature: { name: string }) => void
}

export const emptySkalin = {
  clientId: () => {},
  customerId: () => {},
  identity: () => {},
  feature: () => {},
}

export interface ISkalinContext {
  skalin: Skalin
  setCustomerId: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const SkalinContext = React.createContext<ISkalinContext>({
  skalin: emptySkalin,
  setCustomerId: () => {},
})

export const useSkalin = (): ISkalinContext => useContext(SkalinContext)
