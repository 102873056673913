// https://ant.design/components/input/

import { Input } from 'antd'
import { TextAreaProps as AntdTextAreaProps } from 'antd/lib/input'
import React from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'

export interface TextAreaProps
  extends Omit<AntdTextAreaProps, 'placeholder' | 'onChange' | 'value'> {
  placeholder?: MessageType
  onChange?: (
    value: string | null,
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void
  value?: string | null
  myRef?: any
}

const TextArea = ({
  placeholder,
  onChange = () => {},
  value,
  myRef,
  ...rest
}: TextAreaProps) => {
  const formatMessage = useFormatMessage()

  return (
    <Input.TextArea
      ref={myRef}
      autoComplete="off"
      data-lpignore="true"
      value={value ?? undefined}
      onChange={(event) => {
        event.stopPropagation()
        event.preventDefault()
        onChange(event?.target?.value || null, event)
      }}
      placeholder={
        placeholder ? (formatMessage(placeholder) as string) : undefined
      }
      {...rest}
    />
  )
}

export default React.memo(TextArea)
