import React from 'react'

import Space from 'components/atoms/Space'
import SecurityName from 'components/molecules/Security/Name'
import TagSecurityType from 'components/molecules/Tag/SecurityType'
import { DrawerNames } from 'config/drawer'
import { useDrawers } from 'providers/Drawers'
import { x } from 'utils/emotion'

const SecurityNameWithType = ({
  securityId,
  withLink = false,
}: {
  securityId?: string
  withLink?: boolean
}) => {
  const { openDrawer } = useDrawers()

  const content = (
    <Space>
      <SecurityName securityId={securityId} />
      <TagSecurityType securityId={securityId} noSkeleton />
    </Space>
  )

  if (withLink && securityId) {
    return (
      <x.div
        cursor="pointer"
        hoverTextDecoration="underline"
        onClick={() => {
          openDrawer({ name: DrawerNames.sec, id: securityId })
        }}
      >
        {content}
      </x.div>
    )
  }

  return content
}

export default React.memo(SecurityNameWithType)
