import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import PortfolioName, {
  PortfolioNameProps,
} from 'components/molecules/Portfolio/Name'
import { useAggregatedWalletPortfolioId } from 'hooks/aggregatedWallets/useAggregatedWalletPortfolioId'

const AggregatedWalletPortfolioName = ({
  aggregatedWalletId,
  ...rest
}: Omit<PortfolioNameProps, 'portfolioId'> & {
  aggregatedWalletId: string
}) => {
  const { portfolioId, loading } =
    useAggregatedWalletPortfolioId(aggregatedWalletId)

  return (
    <SkeletonText loading={loading}>
      <PortfolioName portfolioId={portfolioId} {...rest} />
    </SkeletonText>
  )
}

export default React.memo(AggregatedWalletPortfolioName)
