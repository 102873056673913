import React, { ReactNode } from 'react'

import { IApiSearchContext } from 'providers/ApiSearch/ApiSearchContext'
import { ApiSearchProvider } from 'providers/ApiSearch/ApiSearchProvider'
import { GET_CORPORATION_POOLS } from 'providers/ApiSearch/CorporationPools/GET_CORPORATION_POOLS'

export type ApiSearchCorporationPoolsProps = {
  children: ReactNode | ((context: IApiSearchContext) => ReactNode)
  corporationId: string
}

export const ApiSearchCorporationPools = ({
  children,
  corporationId,
}: ApiSearchCorporationPoolsProps) => {
  if (!corporationId) {
    return null
  }

  return (
    <ApiSearchProvider
      query={GET_CORPORATION_POOLS}
      path={'corporation.pools.list'}
      variables={{ corporationId }}
      localSearchInKeys={['name']}
    >
      {children}
    </ApiSearchProvider>
  )
}
