import { lazy } from 'react'

import { DrawerNames } from 'config/drawer'
import { AggregatedTransactionDrawerTab } from 'providers/Drawer/drawers/AggregatedTransaction'
import { AggregatedWalletDrawerTab } from 'providers/Drawer/drawers/AggregatedWallet'
import { CommunicationDrawerTab } from 'providers/Drawer/drawers/Communication'
import { ContactDrawerTab } from 'providers/Drawer/drawers/Contact'
import { EventDrawerTab } from 'providers/Drawer/drawers/Event'
import { FileDrawerTab } from 'providers/Drawer/drawers/File'
import { GroupDrawerTab } from 'providers/Drawer/drawers/Group'
import { IntegrationHistoryDrawerTab } from 'providers/Drawer/drawers/IntegrationHistory'
import { LegalEntityDrawerTab } from 'providers/Drawer/drawers/LegalEntity'
import { MeetingDrawerTab } from 'providers/Drawer/drawers/Meeting'
import { PoolDrawerTab } from 'providers/Drawer/drawers/Pool'
import { PortfolioDrawerTab } from 'providers/Drawer/drawers/Portfolio'
import { SecurityDrawerTab } from 'providers/Drawer/drawers/Security'
import { WalletDrawerTab } from 'providers/Drawer/drawers/Wallet'

const AggregatedTransaction = lazy(
  () => import('providers/Drawer/drawers/AggregatedTransaction')
)
const AggregatedWallet = lazy(
  () => import('providers/Drawer/drawers/AggregatedWallet')
)
const Contact = lazy(() => import('providers/Drawer/drawers/Contact'))
const Event = lazy(() => import('providers/Drawer/drawers/Event'))
const File = lazy(() => import('providers/Drawer/drawers/File'))
const Group = lazy(() => import('providers/Drawer/drawers/Group'))
const LegalEntity = lazy(() => import('providers/Drawer/drawers/LegalEntity'))
const Meeting = lazy(() => import('providers/Drawer/drawers/Meeting'))
const Communication = lazy(
  () => import('providers/Drawer/drawers/Communication')
)
const Pool = lazy(() => import('providers/Drawer/drawers/Pool'))
const Portfolio = lazy(() => import('providers/Drawer/drawers/Portfolio'))
const Security = lazy(() => import('providers/Drawer/drawers/Security'))
const Wallet = lazy(() => import('providers/Drawer/drawers/Wallet'))
const IntegrationHistory = lazy(
  () => import('providers/Drawer/drawers/IntegrationHistory')
)

export const DRAWER_DEFAULT_TAB = {
  [DrawerNames.aggW]: AggregatedWalletDrawerTab.details,
  [DrawerNames.contact]: ContactDrawerTab.details,
  [DrawerNames.event]: EventDrawerTab.details,
  [DrawerNames.file]: FileDrawerTab.details,
  [DrawerNames.grp]: GroupDrawerTab.details,
  [DrawerNames.legalE]: LegalEntityDrawerTab.details,
  [DrawerNames.meet]: MeetingDrawerTab.details,
  [DrawerNames.communication]: CommunicationDrawerTab.contentTab,
  [DrawerNames.pool]: PoolDrawerTab.details,
  [DrawerNames.port]: PortfolioDrawerTab.dashboard,
  [DrawerNames.sec]: SecurityDrawerTab.details,
  [DrawerNames.aggT]: AggregatedTransactionDrawerTab.details,
  [DrawerNames.wall]: WalletDrawerTab.details,
  [DrawerNames.intH]: IntegrationHistoryDrawerTab.details,
}

export const component = {
  [DrawerNames.aggW]: AggregatedWallet,
  [DrawerNames.contact]: Contact,
  [DrawerNames.event]: Event,
  [DrawerNames.file]: File,
  [DrawerNames.grp]: Group,
  [DrawerNames.legalE]: LegalEntity,
  [DrawerNames.meet]: Meeting,
  [DrawerNames.communication]: Communication,
  [DrawerNames.pool]: Pool,
  [DrawerNames.port]: Portfolio,
  [DrawerNames.sec]: Security,
  [DrawerNames.aggT]: AggregatedTransaction,
  [DrawerNames.wall]: Wallet,
  [DrawerNames.intH]: IntegrationHistory,
}
