import React, { ReactNode, Suspense } from 'react'
import { Switch } from 'react-router-dom'

const RouterSwitch = ({
  fallback = null,
  children,
}: {
  fallback?: ReactNode
  children: ReactNode
}) => {
  return (
    <Suspense fallback={fallback}>
      <Switch>{children}</Switch>
    </Suspense>
  )
}

export default React.memo(RouterSwitch)
