export enum LocalStorage {
  AUTO_COMPLETE_DELETE = 'autoCompleteDelete',
  COPY_ID_WITH_NAME = 'copyIdWithName',
  DISABLE_RESPONSIVE = 'disableResponsive',
  EQUIBAR = 'equibar',
  RECORD_LOG_ROCKET = 'recordLogRocket',
  REDIRECT_AFTER_LOGIN_PATH = 'redirect-after-login-path',
  REDIRECT_AFTER_LOGIN_TIME = 'redirect-after-login-time',
  SEE_TRANSLATION_KEYS = 'seeTraductionKeys',
  SEE_WORKFLOW_VALUES = 'seeWorkflowValues',
  SHOW_EQUIFY_PRIVILEGES = 'showEquifyPrivileges',
  TOKEN_EXPIRES_AT = 'token-expires-at',
  TOKEN_VALUE = 'token-value',
  USER_LOCALE = 'userLocale',
  VIRTUAL_WORKFLOWS = 'virtualWorkflows',
}
