import React, { ReactNode } from 'react'

import { IApiSearchContext } from 'providers/ApiSearch/ApiSearchContext'
import { ApiSearchProvider } from 'providers/ApiSearch/ApiSearchProvider'
import { GET_CORPORATION_GOVERNANCE_GROUPS } from 'providers/ApiSearch/CorporationGovernanceGroups/GET_CORPORATION_GOVERNANCE_GROUPS'

export type ApiSearchCorporationGovernanceGroupsProps = {
  children: ReactNode | ((context: IApiSearchContext) => ReactNode)
  corporationId: string
}

export const ApiSearchCorporationGovernanceGroups = ({
  children,
  corporationId,
}: ApiSearchCorporationGovernanceGroupsProps) => {
  if (!corporationId) {
    return null
  }

  return (
    <ApiSearchProvider
      query={GET_CORPORATION_GOVERNANCE_GROUPS}
      path={'corporation.governanceGroups.list'}
      variables={{ corporationId }}
    >
      {children}
    </ApiSearchProvider>
  )
}
