import { gql } from '@apollo/client'

export const GET_WALLET_SECURITY_EXERCISE_PRICE = gql`
  query GET_WALLET_SECURITY_EXERCISE_PRICE($walletId: ID!, $date: Date) {
    wallet(id: $walletId) {
      id
      security {
        id
        exercisePrice(date: $date) {
          centicents
          currency
        }
        originalExercisePrice {
          centicents
          currency
        }
      }
    }
  }
`
