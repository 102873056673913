import React from 'react'

import Drawer from 'components/atoms/Drawer'
import AggregatedWalletPortfolioName from 'components/molecules/AggregatedWallet/PortfolioName'
import AggregatedWalletSecurityName from 'components/molecules/AggregatedWallet/SecurityName'
import TagAggregatedWalletSecurityType from 'components/molecules/Tag/AggregatedWalletSecurityType'
import { CorporationPermissions } from 'config/roles'
import { usePortfolioName } from 'hooks/portfolios/usePortfolioName'
import { useSecurityName } from 'hooks/securities/useSecurityName'
import { useIsCorporationWorkspace } from 'hooks/useWorkspace'
import { useDrawer } from 'providers/Drawer'
import Content from 'providers/Drawer/drawers/AggregatedWallet/Content'
import Sider from 'providers/Drawer/drawers/AggregatedWallet/Sider'
import { usePermissions } from 'providers/Permissions'
import { deserializeAggregatedWalletId } from 'utils/aggregatedWallets'

export enum AggregatedWalletDrawerTab {
  details = 'details',
}

function AggregatedWallet() {
  const { id } = useDrawer()
  const isCorporationWorkspace = useIsCorporationWorkspace()
  const { checkPermissions } = usePermissions()
  const { name: portfolioName } = usePortfolioName(
    deserializeAggregatedWalletId(id).portfolioId
  )
  const { name: securityName } = useSecurityName(
    deserializeAggregatedWalletId(id).securityId
  )

  return (
    <Drawer
      open
      helmetTitle={
        portfolioName && securityName
          ? `${portfolioName} · ${securityName}`
          : 'word.wallet'
      }
      pageHeaderProps={{
        title: (
          <>
            <AggregatedWalletPortfolioName
              aggregatedWalletId={id}
              withLink={
                isCorporationWorkspace &&
                checkPermissions(CorporationPermissions.PORTFOLIOS_READ)
              }
            />
            {' · '}
            <AggregatedWalletSecurityName
              aggregatedWalletId={id}
              withLink={
                isCorporationWorkspace &&
                checkPermissions(CorporationPermissions.SECURITIES_READ)
              }
            />
          </>
        ),
        subTitle: 'word.wallet',
        tags: <TagAggregatedWalletSecurityType aggregatedWalletId={id} />,
      }}
      sider={Sider}
      content={Content}
    />
  )
}

export default React.memo(AggregatedWallet)
