import { LoadingOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import mime from 'mime'
import React, { ReactNode, useMemo, useState } from 'react'

import { useFormatMessage } from 'components/atoms/Intl/Message'
import Space from 'components/atoms/Space'
import { FileDownloadContext } from 'providers/FileDownload/FileDownloadContext'
import { GET_DOWNLOAD_FILE_DATA } from 'providers/FileDownload/graphql/queries/GET_DOWNLOAD_FILE_DATA'
import { useNotification } from 'providers/Notification'
import { HomeViewEnum, isHomeViewType } from 'utils/Helpers/routeHelper'
import { x } from 'utils/emotion'
import { downloadFile } from 'utils/files'

import { LOG_DOWNLOAD } from './graphql/mutations/LOG_DOWNLOAD'

export const FileDownloadProvider = ({ children }: { children: ReactNode }) => {
  const [fileId, setFileId] = useState<string | undefined>()

  const formatMessage = useFormatMessage()
  const { openNotification, successNotification, errorNotification } =
    useNotification()
  const [logDownload] = useMutation(LOG_DOWNLOAD)

  useQuery(GET_DOWNLOAD_FILE_DATA, {
    fetchPolicy: 'cache-and-network',
    variables: { fileId },
    skip: !fileId,
    onCompleted: (data) => {
      if (!data?.file?.url) {
        errorNotification({
          message: 'word.documentDownloadFailed',
          key: fileId,
        })
        console.error('File download failed due to undefined url', data)
      }
      const portfolioName = data?.file?.portfolios?.portfolios?.map(
        (portfolio: { name: string }) => portfolio.name
      )

      const name = [
        formatMessage(data?.file?.folder?.name),
        !isHomeViewType(HomeViewEnum.PORTFOLIO) ? portfolioName : undefined,
        formatMessage(data?.file?.subject),
      ]
        .filter(Boolean)
        .join(' - ')

      const nameWithExtension = name
        ? `${name}.${mime.getExtension(data?.file?.mimeType)}`
        : data?.file?.originalFileName

      const dateNameWithExtension = data?.file?.date
        ? `${data?.file?.date} - ${nameWithExtension}`
        : nameWithExtension

      downloadFile({
        url: data?.file?.url,
        name: dateNameWithExtension,
      })
        .catch(() => {
          errorNotification({
            message: 'word.documentDownloadFailed',
            key: fileId,
            description: (
              <Space>
                <x.div maxWidth="200px" color="grey">
                  {dateNameWithExtension}
                </x.div>
              </Space>
            ),
          })
          console.error('File download failed', data)
        })
        .then(() => {
          logDownload({
            variables: { fileId },
          })
          successNotification({
            message: 'word.documentSuccessfullyDownloaded',
            key: fileId,
            description: (
              <Space>
                <x.div maxWidth="200px" color="grey">
                  {dateNameWithExtension}
                </x.div>
              </Space>
            ),
          })
          setFileId(undefined)
        })
    },
  })

  const context = useMemo(
    () => ({
      fileDownload: (id: string) => {
        setFileId(id)
        openNotification({
          message: 'word.downloadingWithDots',
          duration: 0,
          icon: <LoadingOutlined />,
          key: id,
        })
      },
    }),
    []
  )

  return (
    <FileDownloadContext.Provider value={context}>
      {children}
    </FileDownloadContext.Provider>
  )
}
