import { FormOutlined } from '@ant-design/icons'
import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import Tooltip from 'components/atoms/Tooltip'
import { useAggregatedTransactionDraft } from 'hooks/aggregatedTransactions/useAggregatedTransactionDraft'

const AggregatedTransactionDraft = ({
  aggregatedTransactionId,
}: {
  aggregatedTransactionId: string
}) => {
  const { draft, loading } = useAggregatedTransactionDraft(
    aggregatedTransactionId
  )

  if (loading) {
    return <SkeletonText loading={loading} />
  }

  if (draft) {
    return (
      <Tooltip title={`aggregatedTransaction.draft`}>
        <FormOutlined />
      </Tooltip>
    )
  }

  return null
}

export default React.memo(AggregatedTransactionDraft)
