import { gql } from '@apollo/client'

export const FILES_LINK_EVENTS = gql`
  mutation FILES_LINK_EVENTS($ids: [ID!]!, $eventIds: [ID!]) {
    files(ids: $ids) {
      link(eventIds: $eventIds) {
        id
        event {
          id
        }
      }
    }
  }
`
