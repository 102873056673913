import React from 'react'
import { Redirect } from 'react-router-dom'

import Drawer from 'components/atoms/Drawer'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import AvatarPortfolio from 'components/molecules/Avatar/Portfolio'
import ButtonMoreActionsHolder from 'components/molecules/ButtonMoreActions/Holder'
import PortfolioName from 'components/molecules/Portfolio/Name'
import { CorporationPermissions } from 'config/roles'
import { usePortfolioCorporationId } from 'hooks/portfolios/usePortfolioCorporationId'
import { usePortfolioName } from 'hooks/portfolios/usePortfolioName'
import { useDrawer } from 'providers/Drawer'
import Content from 'providers/Drawer/drawers/Portfolio/Content'
import Sider from 'providers/Drawer/drawers/Portfolio/Sider'
import { usePermissions } from 'providers/Permissions'

export enum PortfolioDrawerTab {
  dashboard = 'dashboard',
  details = 'details',
  aggregatedTransactions = 'aggregatedTransactions',
  events = 'events',
  documents = 'documents',
  governanceGroups = 'governanceGroups',
  meetings = 'meetings',
  communications = 'communications',
}

function Portfolio() {
  const { id } = useDrawer()
  const { name } = usePortfolioName(id)
  const formatMessage = useFormatMessage()
  const { corporationId } = usePortfolioCorporationId(id)
  const { checkPermissions } = usePermissions()

  return !checkPermissions(CorporationPermissions.PORTFOLIOS_READ) ? (
    <Redirect to={`/corporations/${corporationId}`} />
  ) : (
    <Drawer
      open
      helmetTitle={
        name ? `${formatMessage('word.holder')} · ${name}` : 'word.holder'
      }
      pageHeaderProps={{
        avatar: { src: <AvatarPortfolio portfolioId={id} /> },
        title: <PortfolioName portfolioId={id} />,
        subTitle: 'word.holder',
        extra: <ButtonMoreActionsHolder portfolioId={id} />,
      }}
      sider={Sider}
      content={Content}
    />
  )
}

export default React.memo(Portfolio)
