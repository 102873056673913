import { QuestionOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import React, { useCallback, useMemo } from 'react'

import Panel from 'components/atoms/Collapse/Panel'
import Message, { TranslatedMessage } from 'components/atoms/Intl/Message'
import Number from 'components/atoms/Intl/Number'
import Space from 'components/atoms/Space'
import Tooltip from 'components/atoms/Tooltip'
import FileSignatureGroups from 'components/molecules/List/FileSignatureGroups'
import { HelpCenterLink } from 'config/helpCenterLink'
import { Locales } from 'config/locale'
import { FilesEditModalSection } from 'providers/FilesEdit'
import { GET_FILE_SIGNATURE_FIELDS } from 'providers/FilesEdit/Modal/FileProperties/Panels/SignatureFields/GET_FILE_SIGNATURE_FIELDS'
import { useLocale } from 'providers/Locale'

const SignatureFields = ({
  fileId,
  sections,
  ...rest
}: {
  fileId?: string
  sections: FilesEditModalSection[]
}) => {
  const { locale } = useLocale()
  const { data } = useQuery<{
    file: {
      id: string
      smartFields: {
        signatureGroups: {
          name: TranslatedMessage
        }[]
      }
    }
  }>(GET_FILE_SIGNATURE_FIELDS, {
    variables: { fileId },
    skip: !fileId,
  })

  const signatureFieldsCount = useMemo(
    () => data?.file?.smartFields?.signatureGroups?.length ?? 0,
    [data]
  )

  const openHelpCenter = useCallback(() => {
    window.open(
      locale === Locales.frFR
        ? HelpCenterLink.SIGNATURES_FR
        : HelpCenterLink.SIGNATURES_EN,
      '_blank'
    )
  }, [locale])

  if (
    !fileId ||
    !signatureFieldsCount ||
    !sections.includes(FilesEditModalSection.SMART_FIELDS)
  ) {
    return (
      <Panel
        key="signatureFields"
        collapsible="disabled"
        header="word.signatureFields"
      />
    )
  }

  return (
    <Panel
      {...rest}
      key="signatureFields"
      header={
        <Space>
          <Message value={'word.signatureFields'} />
          <Tooltip title="word.helpCenter">
            <QuestionOutlined onClick={openHelpCenter} />
          </Tooltip>
        </Space>
      }
      extra={fileId ? <Number value={signatureFieldsCount} /> : undefined}
      message={<FileSignatureGroups fileId={fileId} />}
    />
  )
}

export default React.memo(SignatureFields)
