// https://ant.design/components/badge/

import { Badge as AntdBadge } from 'antd'
import { BadgeProps as AntdBadgeProps } from 'antd/lib/badge'
import React from 'react'

import eqDSTheme from 'styles/eqDSTheme'

type StatusType = 'success' | 'processing' | 'default' | 'error' | 'warning'

export interface BadgeProps extends AntdBadgeProps {
  /** Number to show in badge */
  count?: React.ReactNode
  /** Max count to show */
  overflowCount?: number
  /** Whether to show red dot without number */
  dot?: boolean
  status?: StatusType
  color?: string
  text?: React.ReactNode
  size?: 'default' | 'small'
  children?: React.ReactNode
  showZero?: boolean
}

const Badge = (props: BadgeProps) => {
  const getColorFromStatus = (status: StatusType) => {
    switch (status) {
      case 'success':
        return eqDSTheme.color.success
      case 'error':
        return eqDSTheme.color.error
      case 'warning':
        return eqDSTheme.color.warning
      default:
        return eqDSTheme.color.info
    }
  }
  return (
    <AntdBadge
      {...props}
      color={
        !props.color && props.status
          ? getColorFromStatus(props.status)
          : props.color
      }
    />
  )
}

export default React.memo(Badge)
