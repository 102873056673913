import React from 'react'

import { BorderType } from 'config/border'
import { x, XProps } from 'utils/emotion'

interface BorderProps extends XProps {
  type?: BorderType
}

const Border = ({ type = BorderType.horizontal, ...rest }: BorderProps) => {
  if (type === BorderType.horizontal) {
    return (
      <x.div borderBottom="classic" borderBottomColor="lightgrey" {...rest} />
    )
  }

  return <x.div borderRight="classic" borderRightColor="lightgrey" {...rest} />
}

export default React.memo(Border)
