import React, { ReactNode, useCallback, useMemo, useState } from 'react'

import Modal from 'components/atoms/Modal'
import { useProcedureSignUrl } from 'hooks/procedures/useProcedureSignUrl'
import { useAuth } from 'providers/Auth'
import { ProcedureContext } from 'providers/Procedure/ProcedureContext'

import {
  EmbeddedSignatureComponentFactory,
  EmbeddedSignatureType,
} from './factories/EmbeddedSignatureComponentFactory'

export const ProcedureProvider = ({ children }: { children: ReactNode }) => {
  const { isAuth } = useAuth()
  const [procedureId, setProcedureId] = useState<string | undefined>()
  const { signUrl } = useProcedureSignUrl(procedureId)

  const closeModal = useCallback(() => {
    setProcedureId(undefined)
  }, [])

  const context = useMemo(
    () => ({
      openProcedure: setProcedureId,
    }),
    []
  )

  return (
    <ProcedureContext.Provider value={context}>
      {children}
      {isAuth && (
        <Modal
          open={Boolean(signUrl)}
          footer={null}
          onCancel={closeModal}
          centered
          width="90%"
          zIndex={1100}
          bodyStyle={{
            padding: '0px',
            height: '90vh',
          }}
          closable={false}
        >
          {EmbeddedSignatureComponentFactory.render(
            EmbeddedSignatureType.YOUSIGN,
            signUrl,
            closeModal
          )}
        </Modal>
      )}
    </ProcedureContext.Provider>
  )
}
