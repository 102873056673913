import React from 'react'

import Date from 'components/atoms/Intl/Date'
import SkeletonText from 'components/atoms/Skeleton/Text'
import { useAggregatedTransactionDate } from 'hooks/aggregatedTransactions/useAggregatedTransactionDate'

const AggregatedTransactionDate = ({
  aggregatedTransactionId,
}: {
  aggregatedTransactionId: string
}) => {
  const { date, loading } = useAggregatedTransactionDate(
    aggregatedTransactionId
  )

  if (loading) {
    return <SkeletonText loading={loading} />
  }

  if (date) {
    return <Date value={date} />
  }

  return null
}

export default React.memo(AggregatedTransactionDate)
