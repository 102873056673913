import React, { lazy, Suspense } from 'react'

import { useDrawer } from 'providers/Drawer'
import { AggregatedWalletDrawerTab } from 'providers/Drawer/drawers/AggregatedWallet'

const Details = lazy(
  () => import('providers/Drawer/drawers/AggregatedWallet/Content/Details')
)

const Content = () => {
  const { tab } = useDrawer()

  const Component = {
    details: Details,
  }[tab as AggregatedWalletDrawerTab]

  if (!Component) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  )
}

export default React.memo(Content)
