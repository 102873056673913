import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { TranslatedMessage } from 'components/atoms/Intl/Message'
import { GET_EVENT_NAME } from 'hooks/events/useEventName/GET_EVENT_NAME'
import { useLocale } from 'providers/Locale'

export const useEventName = (eventId?: string) => {
  const { locale } = useLocale()
  const result = useQuery<{ event: { name: TranslatedMessage } }>(
    GET_EVENT_NAME,
    {
      variables: { eventId },
      skip: !eventId,
    }
  )

  return useMemo(
    () => ({
      ...result,
      name: result?.data?.event?.name?.[locale],
    }),
    [result, locale]
  )
}
