import { CloseOutlined, LogoutOutlined } from '@ant-design/icons'
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import Button from 'components/atoms/Button'
import Search from 'components/atoms/Form/Search'
import PageHeader from 'components/atoms/PageHeader'
import Popover from 'components/atoms/Popover'
import Tag from 'components/atoms/Tag'
import Tooltip from 'components/atoms/Tooltip'
import Text from 'components/atoms/Typography/Text'
import SelectLocale from 'components/molecules/Select/Locale'
import { Env, LEGACY_ENDPOINT, NODE_ENV } from 'config/env'
import { LocalStorage } from 'config/localStorage'
import { QueryParams } from 'config/queryParams'
import { useLocalStorageBoolean } from 'hooks/useLocalStorage'
import { useIsLaptopWindowSize } from 'hooks/useWindowSize'
import { useAuth } from 'providers/Auth'
import { useLogout } from 'providers/Auth/hooks/useLogout'
import { Admin } from 'providers/Equibar/Admin'
import { Auth } from 'providers/Equibar/Auth'
import {
  EQUIBAR_SHORTCUT,
  EquibarContext,
} from 'providers/Equibar/EquibarContext'
import { Permissions } from 'providers/Equibar/Permissions'
import { UI } from 'providers/Equibar/UI'
import { URL } from 'providers/Equibar/URL'
import { useUser } from 'providers/User'
import eqDSTheme from 'styles/eqDSTheme'
import { x } from 'utils/emotion'
import { getEnvColor } from 'utils/env'

import { ClearCacheButton } from './ClearCacheButton/ClearCacheButton'

export const EquibarProvider = ({ children }: { children: ReactNode }) => {
  const history = useHistory()
  const location = useLocation()
  const isLaptopWindowSize = useIsLaptopWindowSize()

  const { isAuth } = useAuth()
  const { logout } = useLogout()
  const { isFromEquify } = useUser()

  const equibarShortcut = useRef('')

  const [hide, setHide] = useState(false)
  const [isStartWorkflowVisible, setIsStartWorkflowVisible] = useState(false)

  const [visible, setVisible] = useLocalStorageBoolean(LocalStorage.EQUIBAR)
  const [virtualWorkflows, setVirtualWorkflows] = useLocalStorageBoolean(
    LocalStorage.VIRTUAL_WORKFLOWS
  )
  const [seeWorkflowValues, setSeeWorkflowValues] = useLocalStorageBoolean(
    LocalStorage.SEE_WORKFLOW_VALUES
  )
  const [autoCompleteDelete, setAutoCompleteDelete] = useLocalStorageBoolean(
    LocalStorage.AUTO_COMPLETE_DELETE
  )
  const [showEquifyPrivileges, setShowEquifyPrivileges] =
    useLocalStorageBoolean(LocalStorage.SHOW_EQUIFY_PRIVILEGES)
  const [disableResponsive, setDisableResponsive] = useLocalStorageBoolean(
    LocalStorage.DISABLE_RESPONSIVE
  )
  const [seeTranslationKeys, setSeeTranslationKeys] = useLocalStorageBoolean(
    LocalStorage.SEE_TRANSLATION_KEYS
  )
  const [recordLogRocket, setRecordLogRocket] = useLocalStorageBoolean(
    LocalStorage.RECORD_LOG_ROCKET
  )
  const [copyIdWithName, setCopyIdWithName] = useLocalStorageBoolean(
    LocalStorage.COPY_ID_WITH_NAME,
    true
  )

  useEffect(() => {
    if (!isAuth) {
      setRecordLogRocket(false)
      setShowEquifyPrivileges(false)
    }
  }, [isAuth])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        (event.shiftKey && event.key === 'Meta') ||
        (event.metaKey && event.key === 'Shift')
      ) {
        setHide((old) => !old)
      } else {
        const shortcut = equibarShortcut.current + event.key

        if (shortcut === EQUIBAR_SHORTCUT) {
          setVisible(true)
          setHide(false)
        } else if (EQUIBAR_SHORTCUT.startsWith(shortcut)) {
          equibarShortcut.current = shortcut
        } else {
          equibarShortcut.current = ''
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown, true)

    return () => {
      document.removeEventListener('keydown', handleKeyDown, true)
    }
  }, [])

  const context = useMemo(
    () => ({
      autoCompleteDelete,
      copyIdWithName,
      disableResponsive,
      isStartWorkflowVisible,
      recordLogRocket,
      seeTranslationKeys,
      seeWorkflowValues,
      setIsStartWorkflowVisible,
      showEquifyPrivileges,
      virtualWorkflows,
    }),
    [
      autoCompleteDelete,
      copyIdWithName,
      disableResponsive,
      isStartWorkflowVisible,
      recordLogRocket,
      seeTranslationKeys,
      seeWorkflowValues,
      showEquifyPrivileges,
      virtualWorkflows,
    ]
  )

  return (
    <EquibarContext.Provider value={context}>
      {children}
      {visible && !hide && (NODE_ENV !== Env.PROD || isFromEquify) && (
        <x.div
          zIndex={1015}
          opacity="0.95"
          position="fixed"
          left={0}
          bottom={0}
          right={0}
          backgroundColor="lightgrey"
        >
          <PageHeader
            className={'eq-page-header-custom'}
            title={
              <Popover
                placement="topLeft"
                content={
                  <>
                    Hide with <Text keyboard>shift ⇧</Text> +{' '}
                    <Text keyboard>command ⌘</Text>
                  </>
                }
              >
                <Text style={{ fontSize: eqDSTheme.fontSize.xlarge }}>
                  Equibar
                </Text>
              </Popover>
            }
            backIcon={<CloseOutlined />}
            style={{ padding: '1px 13px' }}
            onBack={() => setVisible(false)}
            tags={[
              <Tag
                key="env"
                message={NODE_ENV}
                color={getEnvColor(NODE_ENV)}
              />,
            ]}
            extra={[
              NODE_ENV !== Env.PROD && (
                <Search
                  key="api"
                  placeholder={LEGACY_ENDPOINT}
                  style={{ width: '195px' }}
                  onSearch={(value) => {
                    if (value) {
                      const searchParams = new URLSearchParams(location.search)
                      searchParams.set(QueryParams.OVERRIDE_API_ENDPOINT, value)
                      history.push({
                        pathname: location.pathname,
                        search: searchParams.toString(),
                      })
                      window.location.reload()
                    }
                  }}
                />
              ),
              <ClearCacheButton key="clearCacheButton" />,
              <SelectLocale key="locale" style={{ width: '98px' }} />,
              <Admin
                key="admin"
                recordLogRocket={recordLogRocket}
                setRecordLogRocket={setRecordLogRocket}
                showEquifyPrivileges={showEquifyPrivileges}
                setShowEquifyPrivileges={setShowEquifyPrivileges}
              />,
              <Permissions key="permissions" />,
              <UI
                key="ui"
                autoCompleteDelete={autoCompleteDelete}
                disableResponsive={disableResponsive}
                seeTranslationKeys={seeTranslationKeys}
                seeWorkflowValues={seeWorkflowValues}
                setAutoCompleteDelete={setAutoCompleteDelete}
                setDisableResponsive={setDisableResponsive}
                setIsStartWorkflowVisible={setIsStartWorkflowVisible}
                setSeeTranslationKeys={setSeeTranslationKeys}
                setSeeWorkflowValues={setSeeWorkflowValues}
                setVirtualWorkflows={setVirtualWorkflows}
                virtualWorkflows={virtualWorkflows}
              />,
              <URL
                key="url"
                copyIdWithName={copyIdWithName}
                setCopyIdWithName={setCopyIdWithName}
              />,
              <Auth key="auth" />,
              isAuth && (
                <Tooltip
                  key="logout"
                  title={isLaptopWindowSize ? undefined : 'Logout'}
                >
                  <Button
                    icon={<LogoutOutlined />}
                    message={isLaptopWindowSize ? 'Logout' : undefined}
                    onClick={logout}
                  />
                </Tooltip>
              ),
            ]}
          />
        </x.div>
      )}
    </EquibarContext.Provider>
  )
}
