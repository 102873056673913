// https://ant.design/components/drawer/

import { CloseOutlined, LeftOutlined } from '@ant-design/icons'
import { Drawer as AntdDrawer, Layout } from 'antd'
import { DrawerProps as AntdDrawerProps } from 'antd/lib/drawer'
import React, { ElementType } from 'react'
import { Helmet } from 'react-helmet'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'
import PageHeader, { PageHeaderProps } from 'components/atoms/PageHeader'
import Border from 'components/atoms/Section/Border'
import Text from 'components/atoms/Typography/Text'
import { useDrawer } from 'providers/Drawer'
import { useDrawers } from 'providers/Drawers'
import eqDSTheme from 'styles/eqDSTheme'
import { x } from 'utils/emotion'

const { Content: AntdContent, Sider: AntdSider } = Layout

export interface DrawerProps extends AntdDrawerProps {
  pageHeaderProps: PageHeaderProps
  sider: ElementType
  content: ElementType
  id?: string
  helmetTitle?: MessageType
}

const Drawer = ({
  pageHeaderProps,
  sider: Sider,
  content: Content,
  helmetTitle,
  ...rest
}: DrawerProps) => {
  const { closeAllDrawers } = useDrawers()
  const { closeDrawer, index } = useDrawer()
  const formatMessage = useFormatMessage()

  return (
    <>
      {typeof helmetTitle === 'string' && (
        <Helmet>
          <title>Equify · {formatMessage(helmetTitle)}</title>
        </Helmet>
      )}
      <AntdDrawer
        onClose={closeAllDrawers}
        closable={false}
        bodyStyle={{ padding: '0px' }}
        width={`calc(1000px - ${Math.min(index, 2) * 5}px)`}
        maskStyle={index > 0 ? { background: 'transparent' } : undefined}
        {...rest}
      >
        <Layout style={{ minHeight: '100%', maxHeight: '100%' }}>
          <PageHeader
            {...pageHeaderProps}
            title={
              pageHeaderProps.title ? (
                <x.div maxWidth={500} textOverflow="ellipsis" overflow="hidden">
                  <Text style={{ fontSize: eqDSTheme.fontSize.xlarge }}>
                    {pageHeaderProps.title}
                  </Text>
                </x.div>
              ) : undefined
            }
            ghost={false}
            backIcon={
              index > 0 ? (
                <LeftOutlined
                  style={{
                    marginLeft: eqDSTheme.space.xsmall,
                    marginRight: eqDSTheme.space.medium,
                  }}
                />
              ) : (
                <CloseOutlined
                  style={{
                    marginLeft: eqDSTheme.space.xsmall,
                    marginRight: eqDSTheme.space.medium,
                  }}
                />
              )
            }
            onBack={closeDrawer}
          />
          <Border />
          <Layout>
            <AntdSider collapsed collapsedWidth="64px">
              <Sider />
            </AntdSider>
            <Layout style={{ overflow: 'auto' }}>
              <AntdContent>
                <Content />
              </AntdContent>
            </Layout>
          </Layout>
        </Layout>
      </AntdDrawer>
    </>
  )
}

export default React.memo(Drawer)
