import { gql } from '@apollo/client'

export const RUN_SAVE_CURRENT = gql`
  mutation RUN_SAVE_CURRENT($id: ID!, $payload: JSON!) {
    workflow(id: $id) {
      saveCurrent(payload: $payload) {
        id
        slug
        data
        stateKey
        canGoBack
        currentStep {
          slug
          payload
        }
        progress {
          current
          steps
        }
      }
    }
  }
`
