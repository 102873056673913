import React from 'react'

import Date from 'components/atoms/Intl/Date'
import SkeletonText from 'components/atoms/Skeleton/Text'
import { useFileDate } from 'hooks/files/useFileDate'

const FileDate = ({ fileId }: { fileId: string }) => {
  const { date, loading } = useFileDate(fileId)

  if (loading) {
    return <SkeletonText loading={loading} />
  }

  if (date) {
    return <Date value={date} />
  }

  return null
}

export default React.memo(FileDate)
