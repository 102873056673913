import { gql } from '@apollo/client'

export const GET_EVENT_NAME = gql`
  query GET_EVENT_NAME($eventId: ID!) {
    event(id: $eventId) {
      id
      name {
        frFR
        enGB
      }
    }
  }
`
