import { set } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import React, { useEffect, useMemo, useState } from 'react'

import Input from 'components/atoms/Form/Input'
import TextArea from 'components/atoms/Form/TextArea'
import Message, { useFormatMessage } from 'components/atoms/Intl/Message'
import Modal, { ModalProps } from 'components/atoms/Modal'
import { useMeetingDeadlineDate } from 'hooks/meetings/useMeetingDeadlineDate'
import { useMeetingFromDate } from 'hooks/meetings/useMeetingFromDate'
import { useMeetingTimezone } from 'hooks/meetings/useMeetingTimezone'
import { useEquibar } from 'providers/Equibar'
import { useStartWorkflow } from 'providers/Workflows/hooks/useStartWorkflow'
import Alert from 'stories/components/atoms/Alert/Alert'
import eqDSTheme from 'styles/eqDSTheme'
import { sanitize } from 'utils'

const ModalDeleteMeeting = ({
  meetingId,
  onCancel,
  ...rest
}: ModalProps & { meetingId: string }) => {
  const formatMessage = useFormatMessage()
  const { autoCompleteDelete } = useEquibar()

  const { timezone } = useMeetingTimezone(meetingId)
  const { fromDate } = useMeetingFromDate(meetingId)
  const { deadlineDate } = useMeetingDeadlineDate(meetingId)

  const limitDate = useMemo(() => {
    if (deadlineDate && timezone) {
      return set(zonedTimeToUtc(deadlineDate, timezone), {
        hours: 23,
        minutes: 59,
        seconds: 59,
      })
    }
    if (fromDate && timezone) {
      return zonedTimeToUtc(fromDate, timezone)
    }
  }, [deadlineDate, fromDate, timezone])

  const deleteWord = useMemo(
    () => formatMessage('word.delete') as string,
    [formatMessage]
  )
  const [inputValue, setInputValue] = useState<string | null>(
    autoCompleteDelete ? deleteWord : null
  )

  const [reasonForCancellation, setReasonForCancellation] = useState<
    string | null
  >(null)

  useEffect(() => {
    if (autoCompleteDelete && deleteWord) {
      setInputValue(deleteWord)
    }
  }, [deleteWord, autoCompleteDelete])

  const [deleteMeeting, loading] = useStartWorkflow({
    data: { meetingId, reasonForCancellation },
    slug: 'deleteMeeting',
    notification: {
      type: 'success',
      message: 'modal.deleteMeeting.success',
    },
  })

  return (
    <Modal
      centered
      destroyOnClose
      title="word.deleteMeeting"
      okText="word.delete"
      cancelText="word.close"
      okButtonProps={{
        loading,
        danger: true,
        disabled: !inputValue || sanitize(inputValue) !== sanitize(deleteWord),
      }}
      confirmLoading
      onOk={(event) => {
        deleteMeeting()
        if (onCancel) {
          onCancel(event)
        }
      }}
      onCancel={onCancel}
      {...rest}
    >
      <Alert
        showIcon
        type="warning"
        message={formatMessage('word.warning')}
        description={formatMessage({
          id:
            limitDate && new Date() <= limitDate
              ? 'modal.deleteMeetingWithReason.content'
              : 'modal.deleteMeeting.content',
          values: { newLine: <br /> },
        })}
        {...alert}
      />
      {limitDate && new Date() <= limitDate && (
        <div style={{ marginTop: eqDSTheme.space.xlarge }}>
          <Message value={'word.reasonForCancellation'} />
          <TextArea
            value={reasonForCancellation}
            onChange={(value) => setReasonForCancellation(value)}
          />
        </div>
      )}
      <div style={{ marginTop: eqDSTheme.space.xlarge }}>
        <Message
          value={{
            id: 'deleteVerification.label',
            values: { value: deleteWord },
          }}
        />
        <Input
          placeholder={'word.delete'}
          value={inputValue}
          onChange={setInputValue}
        />
      </div>
    </Modal>
  )
}

export default ModalDeleteMeeting
