import { gql } from '@apollo/client'

export const GET_DOWNLOAD_FILE_DATA = gql`
  query GET_DOWNLOAD_FILE_DATA($fileId: ID!) {
    file(id: $fileId) {
      id
      url
      previewUrl
      date
      mimeType
      originalFileName
      subject {
        frFR
        enGB
      }
      folder {
        id
        name {
          frFR
          enGB
        }
      }
      portfolios {
        portfolios {
          name
        }
      }
    }
  }
`
