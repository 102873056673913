import { gql } from '@apollo/client'

export const GET_FILE_PROCEDURE_ID = gql`
  query GET_FILE_PROCEDURE_ID($fileId: ID!) {
    file(id: $fileId) {
      id
      procedureFile {
        id
        procedure {
          id
        }
      }
    }
  }
`
