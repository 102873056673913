import { get } from 'lodash/fp'

import {
  CORPORATION_ROLES_PERMISSIONS,
  CorporationRolesType,
  PermissionsType,
  LAW_FIRM_ROLES_PERMISSIONS,
  LawFirmRolesType,
  PORTFOLIO_ROLES_PERMISSIONS,
  PortfolioRolesType,
  PortfolioRoles,
  CorporationRoles,
  LawFirmRoles,
  RolesType,
  LawFirmPermissions,
  CorporationPermissions,
  PortfolioPermissions,
} from 'config/roles'

const isIn = (
  set: Set<PermissionsType>
): ((value: PermissionsType) => boolean) => {
  return (value) => set.has(value)
}

const contains = (set: Set<PermissionsType>, arr: PermissionsType[]) => {
  return [...arr].every(isIn(set))
}

const remove = (set: Set<PermissionsType>, arr: PermissionsType[]) => {
  arr.forEach(Set.prototype.delete, set)
}

const getUserRoleFromPermissions = (
  permissions: PermissionsType[],
  workspaceRoles: CorporationRolesType | LawFirmRolesType | PortfolioRolesType
): (RolesType | PermissionsType)[] => {
  const set = new Set(permissions)
  const list = []

  for (const workspaceRole of Object.entries(workspaceRoles)) {
    const [role, permissions] = workspaceRole as [
      role: RolesType,
      permissions: PermissionsType[]
    ]
    if (contains(set, permissions)) {
      remove(set, permissions)
      list.push(role)
    }
  }

  const arr = Array.from(set.values())
  list.push(...arr)

  return list
}

const getPermissionsFromUserRole = (
  role: RolesType,
  workspaceRoles: CorporationRolesType | LawFirmRolesType | PortfolioRolesType
): PermissionsType[] => {
  if (!Object.keys(workspaceRoles).includes(role)) {
    return []
  }

  return get(role, workspaceRoles)
}

// Law firm
export const getLawFirmUserRoleFromPermissions = (
  permissions: LawFirmPermissions[]
): (LawFirmRoles | LawFirmPermissions)[] => {
  return getUserRoleFromPermissions(
    permissions,
    LAW_FIRM_ROLES_PERMISSIONS
  ) as (LawFirmRoles | LawFirmPermissions)[]
}

export const getLawFirmPermissionsFromUserRole = (
  role: LawFirmRoles
): LawFirmPermissions[] => {
  return getPermissionsFromUserRole(
    role,
    LAW_FIRM_ROLES_PERMISSIONS
  ) as LawFirmPermissions[]
}

// Corporation
export const getCorporationUserRoleFromPermissions = (
  permissions: CorporationPermissions[]
): (CorporationRoles | CorporationPermissions)[] => {
  return getUserRoleFromPermissions(
    permissions,
    CORPORATION_ROLES_PERMISSIONS
  ) as (CorporationRoles | CorporationPermissions)[]
}

export const getCorporationPermissionsFromUserRole = (
  role: CorporationRoles
): CorporationPermissions[] => {
  return getPermissionsFromUserRole(
    role,
    CORPORATION_ROLES_PERMISSIONS
  ) as CorporationPermissions[]
}

// Portfolio
export const getPortfolioUserRoleFromPermissions = (
  permissions: PortfolioPermissions[]
): (PortfolioRoles | PortfolioPermissions)[] => {
  return getUserRoleFromPermissions(
    permissions,
    PORTFOLIO_ROLES_PERMISSIONS
  ) as (PortfolioRoles | PortfolioPermissions)[]
}

export const getPortfolioPermissionsFromUserRole = (
  role: PortfolioRoles
): PortfolioPermissions[] => {
  return getPermissionsFromUserRole(
    role,
    PORTFOLIO_ROLES_PERMISSIONS
  ) as PortfolioPermissions[]
}
