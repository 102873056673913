import { gql } from '@apollo/client'

export const GET_CORPORATION_POOLS = gql`
  query GET_CORPORATION_POOLS(
    $corporationId: ID!
    $filters: CorporationPoolsFilters
  ) {
    corporation(id: $corporationId) {
      id
      pools {
        list(filters: $filters) {
          hits {
            id
            name
          }
          nbHits
        }
      }
    }
  }
`
