import { useQuery } from '@apollo/client'
import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { GET_AGGREGATED_TRANSACTION_FROM_PORTFOLIO_NAME } from 'components/molecules/AggregatedTransaction/FromPortfolioName/GET_AGGREGATED_TRANSACTION_FROM_PORTFOLIO_NAME'

const AggregatedTransactionFromPortfolioName = ({
  aggregatedTransactionId,
}: {
  aggregatedTransactionId: string
}) => {
  const { data, loading } = useQuery(
    GET_AGGREGATED_TRANSACTION_FROM_PORTFOLIO_NAME,
    {
      variables: { aggregatedTransactionId },
    }
  )

  return (
    <SkeletonText loading={loading}>
      {data?.aggregatedTransaction?.fromPortfolio?.name}
    </SkeletonText>
  )
}

export default React.memo(AggregatedTransactionFromPortfolioName)
