import React from 'react'

import Spin from 'components/atoms/Spin'
import { x } from 'utils/emotion'

const PDFPreviewUrl = ({ fileUrl }: { fileUrl: string }) => {
  if (!fileUrl) {
    return (
      <x.div margin="auto">
        <Spin />
      </x.div>
    )
  }

  return <iframe src={fileUrl + '#toolbar=0'} width="100%" height="100%" />
}

export default React.memo(PDFPreviewUrl)
