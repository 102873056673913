// https://ant.design/components/progress/
import {
  Progress as AntdProgress,
  ProgressProps as AntdProgressProps,
} from 'antd'
import React from 'react'

interface ProgressProps extends AntdProgressProps {}

const Progress = ({ ...rest }: ProgressProps) => {
  return <AntdProgress {...rest} />
}

export default React.memo(Progress)
