import { gql } from '@apollo/client'

export const GET_GENERATED_FILE = gql`
  query GET_GENERATED_FILE($generatedFileId: ID!) {
    generatedFile(id: $generatedFileId) {
      id
      status
      filePath
      format
      file {
        id
      }
    }
  }
`
