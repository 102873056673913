import React, { useMemo } from 'react'

import Drawer from 'components/atoms/Drawer'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import Tag from 'components/atoms/Tag'
import ButtonMoreActionsContact from 'components/molecules/ButtonMoreActions/Contact'
import ContactName from 'components/molecules/Contact/Name'
import { WORKSPACE } from 'config/workspace'
import { useContactName } from 'hooks/contacts/useContactName'
import { useWorkspace } from 'hooks/useWorkspace'
import { useDrawer } from 'providers/Drawer'
import Content from 'providers/Drawer/drawers/Contact/Content'
import Sider from 'providers/Drawer/drawers/Contact/Sider'

export enum ContactDrawerTab {
  details = 'details',
  links = 'links',
}

function Contact() {
  const { id } = useDrawer()
  const { name } = useContactName(id)
  const formatMessage = useFormatMessage()
  const { workspaceId, workspace } = useWorkspace()
  const corporationId = useMemo(
    () => (workspace === WORKSPACE.CORPORATION ? workspaceId : undefined),
    [workspace, workspaceId]
  )

  return (
    <Drawer
      open
      helmetTitle={
        name ? `${formatMessage('word.contact')} · ${name}` : 'word.contact'
      }
      pageHeaderProps={{
        title: <ContactName contactId={id} />,
        subTitle: 'word.contact',
        tags: <Tag color="#3DCC91" message={'word.individual'} />,
        extra: corporationId && (
          <ButtonMoreActionsContact
            contactId={id}
            corporationId={corporationId}
          />
        ),
      }}
      sider={Sider}
      content={Content}
    />
  )
}

export default React.memo(Contact)
