import React, { useState } from 'react'
import ReactJson from 'react-json-view'

import Input from 'components/atoms/Form/Input'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import Modal from 'components/atoms/Modal'
import { useEquibar } from 'providers/Equibar'
import { useWorkflows } from 'providers/Workflows'
import Alert from 'stories/components/atoms/Alert/Alert'

export const StartWorkflow = () => {
  const formatMessage = useFormatMessage()
  const { isStartWorkflowVisible, setIsStartWorkflowVisible } = useEquibar()
  const [name, setName] = useState<string | null>(null)
  const [data, setData] = useState({})
  const { start } = useWorkflows()

  return (
    <Modal
      title="Start workflow"
      okText="Start"
      onOk={() => {
        if (name) {
          start({ slug: name, data })
        }
      }}
      okButtonProps={{ disabled: !name }}
      open={isStartWorkflowVisible}
      onCancel={() => {
        setIsStartWorkflowVisible(false)
        setName(null)
        setData({})
      }}
    >
      <Input
        value={name}
        onChange={setName}
        placeholder="Name"
        style={{ marginBottom: 24 }}
      />
      <Alert
        message={formatMessage(
          <ReactJson
            name="data"
            src={data}
            // eslint-disable-next-line camelcase
            onAdd={({ updated_src }) => {
              setData(updated_src)
            }}
            // eslint-disable-next-line camelcase
            onEdit={({ updated_src }) => {
              setData(updated_src)
            }}
            // eslint-disable-next-line camelcase
            onDelete={({ updated_src }) => {
              setData(updated_src)
            }}
            enableClipboard={false}
          />
        )}
      />
    </Modal>
  )
}
