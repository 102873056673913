import { gql } from '@apollo/client'

export const GET_CORPORATION_CORPORATION_INFO_ID = gql`
  query GET_CORPORATION_CORPORATION_INFO_ID($corporationId: ID!) {
    corporation(id: $corporationId) {
      id
      corporationInfo {
        id
      }
    }
  }
`
