import { EditOutlined } from '@ant-design/icons'
import React from 'react'

import ButtonMoreActions from 'components/atoms/Button/MoreActions'
import Menu from 'components/atoms/Menu'
import { CorporationPermissions } from 'config/roles'
import { SLUGS } from 'config/slugs'
import { usePermissions } from 'providers/Permissions'
import { useStartWorkflow } from 'providers/Workflows/hooks/useStartWorkflow'

const ButtonMoreActionsContact = ({
  contactId,
  corporationId,
}: {
  contactId: string
  corporationId: string
}) => {
  const { checkPermissions } = usePermissions()

  const [editContacts] = useStartWorkflow({
    data: { corporationId, contactIds: [contactId] },
    slug: SLUGS.EDIT_CONTACTS,
  })

  if (!checkPermissions(CorporationPermissions.CONTACTS_MANAGE)) {
    return null
  }

  return (
    <ButtonMoreActions
      overlay={
        <Menu
          menu={[
            {
              key: 'editContacts',
              icon: <EditOutlined />,
              message: 'word.edit',
              onClick: editContacts,
            },
          ]}
        />
      }
    />
  )
}

export default React.memo(ButtonMoreActionsContact)
