import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_WALLET_PORTFOLIO_NAME } from 'hooks/wallets/useWalletPortfolioName/GET_WALLET_PORTFOLIO_NAME'

export const useWalletPortfolioName = (walletId?: string) => {
  const result = useQuery<{ wallet: { portfolio: { name: string } } }>(
    GET_WALLET_PORTFOLIO_NAME,
    {
      variables: { walletId },
      skip: !walletId,
    }
  )

  return useMemo(
    () => ({
      ...result,
      portfolioName: result?.data?.wallet?.portfolio?.name,
    }),
    [result]
  )
}
