import { gql } from '@apollo/client'

export const GET_AGGREGATED_TRANSACTION_LABEL = gql`
  query GET_AGGREGATED_TRANSACTION_LABEL($aggregatedTransactionId: ID!) {
    aggregatedTransaction(id: $aggregatedTransactionId) {
      id
      label
    }
  }
`
