import { gql } from '@apollo/client'

export const CREATE_EXPORT = gql`
  mutation CREATE_EXPORT(
    $type: GeneratedFileType!
    $format: GeneratedFileFormat!
    $params: JSON
  ) {
    createExport(type: $type, format: $format, params: $params) {
      id
      fileName
    }
  }
`
