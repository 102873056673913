import {
  EditOutlined,
  FileTextOutlined,
  SwapOutlined,
  ExportOutlined,
} from '@ant-design/icons'
import React from 'react'

import ButtonMoreActions from 'components/atoms/Button/MoreActions'
import FontAwesomeIcon from 'components/atoms/FontAwesomeIcon'
import Menu from 'components/atoms/Menu'
import { ExportFormat, ExportType } from 'config/export'
import { CorporationPermissions } from 'config/roles'
import { SLUGS } from 'config/slugs'
import { useEventCorporationId } from 'hooks/events/useEventCorporationId'
import { useExport } from 'providers/Export'
import { usePermissions } from 'providers/Permissions'
import { useSkalin } from 'providers/Skalin'
import { useStartWorkflow } from 'providers/Workflows/hooks/useStartWorkflow'
import { today } from 'utils/date'

const ButtonMoreActionsEvent = ({ eventId }: { eventId: string }) => {
  const { startExport } = useExport()
  const { skalin } = useSkalin()
  const { checkPermissions } = usePermissions()

  const [startEditEvent] = useStartWorkflow({
    data: { eventId },
    slug: SLUGS.EDIT_EVENT,
  })

  const [editEventLinkedAggregatedTransactions] = useStartWorkflow({
    data: { eventId },
    slug: SLUGS.EDIT_EVENT_LINKED_AGGREGATED_TRANSACTIONS,
  })

  const [editEventLinkedFiles] = useStartWorkflow({
    data: { eventId },
    slug: 'editEventLinkedFiles',
  })

  const [editEventMeetings] = useStartWorkflow({
    data: { eventId },
    slug: SLUGS.EDIT_EVENT_MEETINGS,
  })

  const { corporationId, loading } = useEventCorporationId(eventId)

  if (!checkPermissions(CorporationPermissions.TRANSACTIONS_MANAGE)) {
    return null
  }

  if (!corporationId) {
    return null
  }

  return (
    <ButtonMoreActions
      loading={loading}
      overlay={
        <Menu
          menu={[
            {
              key: 'edit',
              icon: <EditOutlined />,
              message: 'word.editEvent',
              onClick: startEditEvent,
            },
            {
              key: 'export',
              icon: <ExportOutlined />,
              message: 'word.export',
              onClick: () => {
                skalin.feature({
                  name: `${ExportType.EVENT_TRANSACTIONS} Export Direct Download`,
                })
                startExport({
                  name: 'word.export',
                  type: ExportType.EVENT_TRANSACTIONS,
                  format: ExportFormat.XLSX,
                  params: {
                    date: today(),
                    corporationId,
                    eventId,
                    addFileToLibrary: false,
                  },
                })
              },
            },
            {
              key: 'editTransactions',
              icon: <SwapOutlined />,
              message: 'word.editTransactions',
              onClick: editEventLinkedAggregatedTransactions,
            },
            {
              key: 'editDocuments',
              icon: <FileTextOutlined />,
              message: 'word.editDocuments',
              onClick: editEventLinkedFiles,
            },
            {
              key: 'editMeetings',
              icon: <FontAwesomeIcon name="gavel" />,
              message: 'word.editMeetings',
              onClick: editEventMeetings,
            },
          ]}
        />
      }
    />
  )
}

export default React.memo(ButtonMoreActionsEvent)
