import React, { useEffect, useMemo, useState } from 'react'

import {
  emptySkalin,
  Skalin,
  SKALIN_CLIENT_ID,
  SkalinContext,
  SkalinProviderProps,
} from 'providers/Skalin/SkalinContext'
import { useUser } from 'providers/User'

export const SkalinProvider = ({ children }: SkalinProviderProps) => {
  const { id, primaryEmail, isFromEquify } = useUser()
  const [skalin, setSkalin] = useState<Skalin>(emptySkalin)
  const [customerId, setCustomerId] = useState<string>()

  useEffect(() => {
    // @ts-ignore
    window.ska =
      // @ts-ignore
      window.ska ||
      function (...args: any[]) {
        // @ts-ignore
        ;(window.ska.p = window.ska.p || []).push([...args])
      }

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.defer = true
    script.async = true
    script.src = 'https://assets.skalin.io/scripts/skalin.min.js'
    document.body.appendChild(script)

    // @ts-ignore
    window.ska(setSkalin)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (id && primaryEmail && skalin && customerId && !isFromEquify) {
      skalin.clientId(SKALIN_CLIENT_ID)
      skalin.customerId(customerId)
      skalin.identity({ id, email: primaryEmail })
    }
  }, [id, primaryEmail, skalin, customerId, isFromEquify])

  const context = useMemo(() => ({ skalin, setCustomerId }), [skalin])

  return (
    <SkalinContext.Provider value={context}>{children}</SkalinContext.Provider>
  )
}
