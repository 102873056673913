import React from 'react'

import {
  SignatureLinkAndProvider,
  SignatureProvider,
} from 'hooks/procedures/useProcedureSignUrl'
import { useNotification } from 'providers/Notification'

import { YouSignEmbeddedSignatureComponentV3 } from '../components/YouSignEmbeddedSignatureComponentV3'
import { YouSignSignatureComponentV2 } from '../components/YousignSignatureComponentV2'

export enum EmbeddedSignatureType {
  YOUSIGN = 'yousign',
}

export interface EmbeddedSignatureInterface {
  onSuccess: () => void
  onDeclined: () => void
  onError: () => void
}

export class EmbeddedSignatureComponentFactory {
  /**
   * Renders the appropriate embedded signature component based on the provided type.
   *
   * @param type - The type of the embedded signature component to render.
   * @param signatureLinkAndProvider - The URL link and provider to the signature resource.
   * @param closeModal - A callback function to close the modal containing the signature component.
   * @returns The embedded signature component corresponding to the specified type.
   * @throws Will throw an error if the provided type is not a valid EmbeddedSignatureType.
   */
  static render(
    type: EmbeddedSignatureType,
    signatureLinkAndProvider: SignatureLinkAndProvider | undefined | null,
    closeModal: () => void
  ) {
    const { errorNotification, successNotification, warningNotification } =
      useNotification()

    const onSuccess = () => {
      closeModal()
      successNotification({
        message: 'procedure.signatureStatus.SIGNED',
      })
    }

    const onDeclined = () => {
      closeModal()
      warningNotification({
        message: 'procedure.signatureStatus.DECLINED',
      })
    }

    const onError = () => {
      closeModal()
      errorNotification({
        message: 'procedure.signatureStatus.ERROR',
      })
    }

    switch (type) {
      case EmbeddedSignatureType.YOUSIGN:
        if (
          signatureLinkAndProvider?.provider === SignatureProvider.YOUSIGNV2
        ) {
          return (
            <YouSignSignatureComponentV2
              signatureLink={signatureLinkAndProvider.url}
              onSuccess={onSuccess}
              onDeclined={onDeclined}
              onError={onError}
            />
          )
        }
        if (
          signatureLinkAndProvider?.provider === SignatureProvider.YOUSIGNV3
        ) {
          return (
            <YouSignEmbeddedSignatureComponentV3
              signatureLink={signatureLinkAndProvider?.url}
              onSuccess={onSuccess}
              onDeclined={onDeclined}
              onError={onError}
            />
          )
        }

        return <></>

      default:
        throw new Error('Invalid embedded signature type')
    }
  }
}
