import { gql } from '@apollo/client'

export const GET_EVENT_CORPORATION_ID = gql`
  query GET_EVENT_CORPORATION_ID($eventId: ID!) {
    event(id: $eventId) {
      id
      corporation {
        id
      }
    }
  }
`
