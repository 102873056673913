import React from 'react'

import Panel from 'components/atoms/Collapse/Panel'
import Space from 'components/atoms/Space'
import FileBadgeErrors from 'components/molecules/File/BadgeErrors'
import FileName from 'components/molecules/File/Name'
import { FilesEditModalSection } from 'providers/FilesEdit'
import { Folder as FolderInput } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Folder'
import { Subject } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Subject'

const Folder = ({
  selectedIds,
  fileId,
  sections,
  corporationId,
  ...rest
}: {
  fileId?: string
  corporationId: string
  selectedIds: string[]
  sections: FilesEditModalSection[]
}) => {
  if (!sections.includes(FilesEditModalSection.FOLDER)) {
    return (
      <Panel key="folder" header="word.documentType" collapsible="disabled" />
    )
  }

  return (
    <Panel
      {...rest}
      key="folder"
      header="word.documentType"
      extra={
        fileId ? (
          <Space>
            <FileBadgeErrors fileId={fileId} />
            <FileName fileId={fileId} />
          </Space>
        ) : undefined
      }
      message={
        <>
          <FolderInput
            selectedIds={selectedIds}
            corporationId={corporationId}
          />
          <Subject selectedIds={selectedIds} />
        </>
      }
    />
  )
}

export default React.memo(Folder)
