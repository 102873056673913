import { CheckOutlined, CloseOutlined, CopyOutlined } from '@ant-design/icons'
import copy from 'copy-to-clipboard'
import { useFormikContext } from 'formik'
import { isEqual } from 'lodash/fp'
import React from 'react'
import ReactJson from 'react-json-view'

import Button from 'components/atoms/Button'
import Collapse from 'components/atoms/Collapse'
import Panel from 'components/atoms/Collapse/Panel'
import Number from 'components/atoms/Intl/Number'
import { useMessage } from 'providers/Message'
import { useWorkflow } from 'providers/Workflows/WorkflowContext'
import eqDSTheme from 'styles/eqDSTheme'

export const WorkflowDatas = () => {
  const { infoMessage } = useMessage()
  const { values, errors } = useFormikContext()
  const { currentStep, data, initialPayload, slug } = useWorkflow()

  return (
    <Collapse defaultActiveKey={['value']}>
      <Panel
        key="values"
        header="Values"
        message={<ReactJson name="values" src={values as object} />}
        extra={
          <>
            <Button
              type="text"
              size="small"
              message={`Slug: ${slug}`}
              onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                event.stopPropagation()
                copy(slug)
                infoMessage(`Slug ${slug} copied to clipboard`)
              }}
            />{' '}
            <Button
              type="text"
              size="small"
              shape="circle"
              icon={<CopyOutlined />}
              onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                event.stopPropagation()
                copy(JSON.stringify(values, null, 2))
                infoMessage(`Values copied to clipboard`)
              }}
            />
          </>
        }
      />
      <Panel
        key="initialPayload"
        header="Initial payload"
        message={<ReactJson name="initialPayload" src={initialPayload} />}
        extra={
          <>
            Equal to values:{' '}
            {isEqual(values, initialPayload) ? (
              <CheckOutlined />
            ) : (
              <CloseOutlined />
            )}{' '}
            <Button
              type="text"
              size="small"
              shape="circle"
              icon={<CopyOutlined />}
              onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                event.stopPropagation()
                copy(JSON.stringify(initialPayload, null, 2))
                infoMessage(`Initial payload copied to clipboard`)
              }}
            />
          </>
        }
      />
      <Panel
        key="data"
        header="Data"
        message={<ReactJson name="data" src={data as object} />}
        extra={
          <>
            {!Object.keys(data as object)?.length ? 'Empty ' : null}
            <Button
              type="text"
              size="small"
              shape="circle"
              icon={<CopyOutlined />}
              onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                event.stopPropagation()
                copy(JSON.stringify(data, null, 2))
                infoMessage(`Data copied to clipboard`)
              }}
            />
          </>
        }
      />
      <Panel
        key="errors"
        header="Errors"
        message={<ReactJson name="errors" src={errors} />}
        style={{
          backgroundColor: Object.keys(errors).length
            ? eqDSTheme.color.error
            : undefined,
        }}
        extra={
          <>
            <Number value={Object.keys(errors).length} />{' '}
            <Button
              type="text"
              size="small"
              shape="circle"
              icon={<CopyOutlined />}
              onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
                event.stopPropagation()
                copy(JSON.stringify(errors, null, 2))
                infoMessage(`Errors copied to clipboard`)
              }}
            />
          </>
        }
      />
      <Panel
        key="currentStep"
        header="Current step"
        message={<ReactJson name="values" src={currentStep} />}
        extra={
          <Button
            type="text"
            size="small"
            shape="circle"
            icon={<CopyOutlined />}
            onClick={(event: React.MouseEvent<HTMLElement, MouseEvent>) => {
              event.stopPropagation()
              copy(JSON.stringify(currentStep, null, 2))
              infoMessage(`Current step copied to clipboard`)
            }}
          />
        }
      />
    </Collapse>
  )
}
