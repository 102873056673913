import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_AGGREGATED_TRANSACTION_DRAFT } from 'hooks/aggregatedTransactions/useAggregatedTransactionDraft/GET_AGGREGATED_TRANSACTION_DRAFT'

export const useAggregatedTransactionDraft = (
  aggregatedTransactionId?: string
) => {
  const result = useQuery<{
    aggregatedTransaction: {
      draft: string
    }
  }>(GET_AGGREGATED_TRANSACTION_DRAFT, {
    variables: { aggregatedTransactionId },
    skip: !aggregatedTransactionId,
  })

  return useMemo(
    () => ({
      ...result,
      draft: result?.data?.aggregatedTransaction?.draft,
    }),
    [result]
  )
}
