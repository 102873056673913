import { Theme } from '@emotion/react'
import { defaultTheme, ThemeProvider } from '@xstyled/emotion'
import React, { ReactNode } from 'react'

import eqDSTheme from 'styles/eqDSTheme'

export const xstyledTheme: Partial<Theme> = {
  ...defaultTheme,
  shadows: eqDSTheme.boxShadow,
  sizes: eqDSTheme.size,
  space: eqDSTheme.space,
  colors: eqDSTheme.color,
  fontSizes: eqDSTheme.fontSize,
  transitions: { classic: 'all 0.4s' },
  borders: { classic: '1px solid' },
  radius: eqDSTheme.borderRadius,
}

export const XStyledProvider = ({ children }: { children: ReactNode }) => {
  return <ThemeProvider theme={xstyledTheme}>{children}</ThemeProvider>
}
