import { gql } from '@apollo/client'

export const GET_PORTFOLIO_NAME = gql`
  query GET_PORTFOLIO_NAME($portfolioId: ID!) {
    portfolio(id: $portfolioId) {
      id
      name
    }
  }
`

export const GET_PORTFOLIOS_NAME = gql`
  query GET_PORTFOLIOS_NAME($portfolioIds: [ID!]!) {
    portfolios(ids: $portfolioIds) {
      id
      name
    }
  }
`
