import { gql } from '@apollo/client'

export const FILES_LINK_POOLS = gql`
  mutation FILES_LINK_POOLS($ids: [ID!]!, $poolIds: [ID!]) {
    files(ids: $ids) {
      link(poolIds: $poolIds) {
        id
        pools {
          pools {
            id
          }
        }
      }
    }
  }
`
