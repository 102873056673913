import NP from 'number-precision'
import React from 'react'

import { AntdProvider } from 'providers/Antd'
import { AuthProvider } from 'providers/Auth'
import { DrawerProvider } from 'providers/Drawer'
import { DrawersProvider } from 'providers/Drawers'
import { EquibarProvider } from 'providers/Equibar'
import { ExportProvider } from 'providers/Export'
import { FileDownloadProvider } from 'providers/FileDownload'
import { FilePreviewProvider } from 'providers/FilePreview'
import { FilePreviewUrlProvider } from 'providers/FilePreviewUrl'
import { FilesUploadProvider } from 'providers/FilesUpload'
import { GraphQLProvider } from 'providers/GraphQL'
import { IntlProvider } from 'providers/Intl'
import { LocaleProvider } from 'providers/Locale'
import { LogRocketProvider } from 'providers/LogRocket'
import { MessageProvider } from 'providers/Message'
import { NotificationProvider } from 'providers/Notification'
import { PermissionsProvider } from 'providers/Permissions'
import { ProcedureProvider } from 'providers/Procedure'
import { ReactErrorProvider } from 'providers/ReactError'
import { RolesProvider } from 'providers/Roles'
import { RouterProvider } from 'providers/Router'
import { SkalinProvider } from 'providers/Skalin'
import { TokenProvider } from 'providers/Token'
import { UserProvider } from 'providers/User'
import { WorkflowsProvider } from 'providers/Workflows'
import { XStyledProvider } from 'providers/XStyled'
import Root from 'routes'
import { isDevEnv } from 'utils/env'

NP.enableBoundaryChecking(isDevEnv)

function App() {
  return (
    <TokenProvider>
      <GraphQLProvider>
        <AuthProvider>
          <LocaleProvider>
            <IntlProvider>
              <RouterProvider>
                <XStyledProvider>
                  <AntdProvider>
                    <ReactErrorProvider>
                      <UserProvider>
                        <RolesProvider>
                          <SkalinProvider>
                            <NotificationProvider>
                              <ProcedureProvider>
                                <PermissionsProvider>
                                  <DrawersProvider>
                                    <MessageProvider>
                                      <EquibarProvider>
                                        <LogRocketProvider>
                                          <FilesUploadProvider>
                                            <FilePreviewProvider>
                                              <FilePreviewUrlProvider>
                                                <FileDownloadProvider>
                                                  <WorkflowsProvider>
                                                    <ExportProvider>
                                                      <DrawerProvider>
                                                        <Root />
                                                      </DrawerProvider>
                                                    </ExportProvider>
                                                  </WorkflowsProvider>
                                                </FileDownloadProvider>
                                              </FilePreviewUrlProvider>
                                            </FilePreviewProvider>
                                          </FilesUploadProvider>
                                        </LogRocketProvider>
                                      </EquibarProvider>
                                    </MessageProvider>
                                  </DrawersProvider>
                                </PermissionsProvider>
                              </ProcedureProvider>
                            </NotificationProvider>
                          </SkalinProvider>
                        </RolesProvider>
                      </UserProvider>
                    </ReactErrorProvider>
                  </AntdProvider>
                </XStyledProvider>
              </RouterProvider>
            </IntlProvider>
          </LocaleProvider>
        </AuthProvider>
      </GraphQLProvider>
    </TokenProvider>
  )
}

export default App
