import { gql } from '@apollo/client'

export const GET_FILE_SHARED_WITH = gql`
  query GET_FILE_SHARED_WITH($fileId: ID!) {
    file(id: $fileId) {
      id
      portfolios {
        sharedWith {
          id
        }
      }
    }
  }
`
