import { gql } from '@apollo/client'

export const GET_WALLET_SECURITY_NAME = gql`
  query GET_WALLET_SECURITY_NAME($walletId: ID!) {
    wallet(id: $walletId) {
      id
      security {
        id
        name
      }
    }
  }
`
