import { gql } from '@apollo/client'

export const GET_FILE_NAME = gql`
  query GET_FILE_NAME($fileId: ID!) {
    file(id: $fileId) {
      id
      originalFileName
      subject {
        frFR
        enGB
      }
      folder {
        id
        name {
          frFR
          enGB
        }
      }
    }
  }
`
