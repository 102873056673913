import { gql } from '@apollo/client'

export const GET_SELECTED_FILES_SECURITIES = gql`
  query GET_SELECTED_FILES_SECURITIES($ids: [ID!]!) {
    files(ids: $ids) {
      id
      securities {
        securities {
          id
        }
      }
    }
  }
`
