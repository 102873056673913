import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { Locales } from 'config/locale'
import { GET_FILE_LOCALE } from 'hooks/files/useFileLocale/GET_FILE_LOCALE'

export const useFileLocale = (fileId?: string) => {
  const result = useQuery<{
    file: {
      locale: Locales
    }
  }>(GET_FILE_LOCALE, {
    variables: { fileId },
    skip: !fileId,
  })

  return useMemo(
    () => ({
      ...result,
      locale: result.data?.file?.locale,
    }),
    [result]
  )
}
