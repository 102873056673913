import { gql } from '@apollo/client'

export const GET_FILE_PROCEDURE_ME_SIGN_URL = gql`
  query GET_FILE_PROCEDURE_ME_SIGN_URL($fileId: ID!) {
    file(id: $fileId) {
      id
      procedureFile {
        id
        procedure {
          id
          me {
            signUrl {
              url
            }
          }
        }
      }
    }
  }
`
