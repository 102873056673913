import { useFormikContext } from 'formik'
import { get } from 'lodash/fp'
import React, { ElementType, useMemo } from 'react'

import FormItem, { FormItemProps } from 'components/atoms/Form/FormItem'
import Formik from 'components/atoms/Formik'
import { MessageType } from 'components/atoms/Intl/Message'
import Alert, { AlertProps } from 'stories/components/atoms/Alert/Alert'
import { cleanArray } from 'utils'

export function fieldType<T extends ElementType>(
  workflowFieldProps: WorkflowFieldProps<T>
) {
  return workflowFieldProps
}

export interface WorkflowFieldProps<T extends ElementType = ElementType>
  extends FormItemProps {
  name?: string
  component?: T
  componentProps?: React.ComponentProps<T>
  wrapFormik?: boolean
  alert?: AlertProps
  errorHelp?: MessageType
}

export const WorkflowField = ({
  name,
  component: Component,
  componentProps = {},
  help,
  errorHelp,
  alert,
  wrapFormik,
  ...rest
}: WorkflowFieldProps) => {
  const { errors } = useFormikContext() || {}
  const error = name ? get(name, errors) : undefined

  const fieldErrors = useMemo(
    () =>
      cleanArray(
        Object.entries(errors)
          .filter(([key]) => {
            const arrayKey = key.split('.')
            if (name?.includes('.')) {
              const arrayName = name?.split('.')
              return (
                arrayKey?.length >= 2 &&
                arrayKey[0] === arrayName[0] &&
                arrayKey[1] === arrayName[1]
              )
            }
            return arrayKey?.length >= 2 && arrayKey[0] === name
          })
          .map(([key, value]) => {
            const [_, row, column] = key.replace(name || '', '').split('.')
            if (key) {
              return {
                row: +row,
                key: column,
                error: value,
              }
            }
            const path = get('path', get('context', value))
            if (path) {
              return {
                row: +row,
                key: path,
                error: value,
              }
            }
            return {
              row: +row,
              error: value,
            }
          })
      ),
    [errors]
  )

  return (
    <div>
      {alert && (
        <Alert
          style={{ marginBottom: Component ? '8px' : undefined }}
          {...alert}
        />
      )}
      {Component && (
        <FormItem
          name={name}
          validateStatus={error ? 'error' : undefined}
          help={
            error
              ? errorHelp ?? {
                  id: `error.field.${error.type}`,
                  values: error.context || {},
                }
              : help
          }
          {...rest}
        >
          {/* The empty tag below is here on purpose. This is to avoid FormItem to spread value and onChange to its child */}
          <>
            {wrapFormik && name ? (
              <Formik
                name={name}
                component={Component}
                errors={fieldErrors?.length ? fieldErrors : undefined}
                {...componentProps}
              />
            ) : (
              <Component {...componentProps} />
            )}
          </>
        </FormItem>
      )}
    </div>
  )
}
