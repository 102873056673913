// https://ant.design/components/message/

import { message } from 'antd'
import { MessageType as AntdMessageType } from 'antd/es/message/interface'
import { get } from 'lodash/fp'
import React, { ReactNode, useCallback, useMemo } from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'
import {
  MessageConfigType,
  MessageContext,
} from 'providers/Message/MessageContext'

export const MessageProvider = ({ children }: { children: ReactNode }) => {
  const formatMessage = useFormatMessage()
  const [api, contextHolder] = message.useMessage()

  const sendMessage = useCallback(
    (type: string) =>
      (config: MessageType | MessageConfigType): AntdMessageType | void => {
        if (type in message) {
          if (
            config &&
            typeof config !== 'string' &&
            typeof config === 'object' &&
            'content' in config
          ) {
            const { content, ...rest } = config
            return get(
              type,
              api
            )({
              content: formatMessage(content as string),
              ...rest,
            })
          }

          return get(type, api)(formatMessage(config))
        }
      },
    [formatMessage]
  )

  const context = useMemo(
    () => ({
      infoMessage: sendMessage('info'),
      successMessage: sendMessage('success'),
      errorMessage: sendMessage('error'),
      warningMessage: sendMessage('warning'),
      loadingMessage: sendMessage('loading'),
      destroyMessage: (key?: string) => message.destroy(key),
    }),
    [sendMessage]
  )

  return (
    <MessageContext.Provider value={context}>
      {children}
      {contextHolder}
    </MessageContext.Provider>
  )
}
