import { gql } from '@apollo/client'

export const GET_MEETING_TIMEZONE = gql`
  query GET_MEETING_TIMEZONE($meetingId: ID!) {
    meeting(id: $meetingId) {
      id
      timezone
    }
  }
`
