import React, { useEffect } from 'react'

import { SignatureEventMessage } from 'config/procedure'
import { EmbeddedSignatureInterface } from 'providers/Procedure/factories/EmbeddedSignatureComponentFactory'
import { x } from 'utils/emotion'

interface YousignServiceOptionsV2 extends EmbeddedSignatureInterface {
  signatureLink: string
}

/**
 *
 * @param {YousignServiceOptionsV2} props - The properties required to configure the YouSign instance.
 * @param {string} props.signatureLink - The link to the YouSign signature page.
 * @param {() => void} props.onSuccess - A callback function to execute when the signature is successfully completed.
 * @param {() => void} props.onDeclined - A callback function to execute when the signature is declined.
 * @param {() => void} props.onError - A callback function to execute when an error occurs during the signature process.
 *
 * @component
 */
export const YouSignSignatureComponentV2 = (
  props: YousignServiceOptionsV2
): JSX.Element => {
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data === SignatureEventMessage.SIGNATURE_SUCCESS) {
        props.onSuccess()
      } else if (event.data === SignatureEventMessage.SIGNATURE_CANCEL) {
        props.onDeclined()
      } else if (event.data === SignatureEventMessage.SIGNATURE_ERROR) {
        props.onError()
      }
    }

    window.addEventListener('message', handleMessage, false)

    return () => window.removeEventListener('message', handleMessage, false)
  }, [])

  return <x.iframe w="100%" h="100%" border="none" src={props.signatureLink} />
}
