import React, { ReactNode } from 'react'

import { IApiSearchContext } from 'providers/ApiSearch/ApiSearchContext'
import { ApiSearchProvider } from 'providers/ApiSearch/ApiSearchProvider'
import { GET_CORPORATION_AGGREGATED_TRANSACTIONS } from 'providers/ApiSearch/CorporationAggregatedTransactions/GET_CORPORATION_AGGREGATED_TRANSACTIONS'

export type ApiSearchCorporationAggregatedTransactionsProps = {
  children: ReactNode | ((context: IApiSearchContext) => ReactNode)
  corporationId: string
  saveFacets?: boolean
}

export const ApiSearchCorporationAggregatedTransactions = ({
  children,
  corporationId,
  saveFacets,
}: ApiSearchCorporationAggregatedTransactionsProps) => {
  if (!corporationId) {
    return null
  }

  return (
    <ApiSearchProvider
      query={GET_CORPORATION_AGGREGATED_TRANSACTIONS}
      path={'corporation.aggregatedTransactions.list'}
      variables={{ corporationId }}
      saveFacets={saveFacets}
    >
      {children}
    </ApiSearchProvider>
  )
}
