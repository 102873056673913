import flatten from 'flat'
import React, { ReactNode, useEffect, useState } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'

import { useLocale } from 'providers/Locale'
import { x } from 'utils/emotion'

export const IntlProvider = ({ children }: { children: ReactNode }) => {
  const [messages, setMessages] = useState<
    Record<string, Record<string, string>>
  >({})
  const { formattedLocale } = useLocale()

  useEffect(() => {
    if (formattedLocale) {
      import(`providers/Intl/messages/${formattedLocale}.json`)
        .then((json) => {
          setMessages((value) => ({
            ...value,
            [formattedLocale]: flatten(json.default),
          }))
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }, [formattedLocale])

  return (
    <ReactIntlProvider
      locale={formattedLocale}
      messages={messages[formattedLocale]}
    >
      {!messages[formattedLocale] && (
        <x.div
          zIndex={10000}
          position="fixed"
          top={0}
          right={0}
          bottom={0}
          left={0}
          background="#fafafa"
        />
      )}
      {children}
    </ReactIntlProvider>
  )
}
