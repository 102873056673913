import React from 'react'

import IconFont from 'components/atoms/IconFont'
import SkeletonText from 'components/atoms/Skeleton/Text'
import { LOCALE_ICON_FONT } from 'config/locale'
import { useFileLocale } from 'hooks/files/useFileLocale'

const FileLocaleIcon = ({ fileId }: { fileId: string }) => {
  const { locale, loading } = useFileLocale(fileId)

  return (
    <SkeletonText loading={loading}>
      {locale && <IconFont type={LOCALE_ICON_FONT[locale]} />}
    </SkeletonText>
  )
}

export default React.memo(FileLocaleIcon)
