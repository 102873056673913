import { gql } from '@apollo/client'

export const GET_SECURITY_PLAN_RULES = gql`
  query GET_SECURITY_PLAN_RULES($securityId: String!) {
    findSecurityById(securityId: $securityId) {
      departurePlan {
        expirationContractualTerminationInDays
        expirationWithCauseInMonths
        expirationWithCauseInDays
        expirationVoluntaryInMonths
        expirationVoluntaryInDays
        expirationRetirementInMonths
        expirationRetirementInDays
        expirationInvoluntaryInMonths
        expirationInvoluntaryInDays
        expirationDisabilityInMonths
        expirationDisabilityInDays
        expirationDeathInMonths
        expirationDeathInDays
        expirationContractualTerminationInMonths
      }
    }
  }
`
