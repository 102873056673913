// https://ant.design/components/typography/

import { Typography } from 'antd'
import { LinkProps as AntdLinkProps } from 'antd/lib/typography/Link'
import React from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'

const { Link: AntdLink } = Typography

export interface LinkProps extends AntdLinkProps {
  message?: MessageType
}

const Link = ({ children, message, ...rest }: LinkProps) => {
  const formatMessage = useFormatMessage()

  return <AntdLink {...rest}>{children || formatMessage(message)}</AntdLink>
}

export default React.memo(Link)
