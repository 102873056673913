import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_AGGREGATED_TRANSACTION_TO_SECURITY_ID } from 'hooks/aggregatedTransactions/useAggregatedTransactionToSecurityId/GET_AGGREGATED_TRANSACTION_TO_SECURITY_ID'

export const useAggregatedTransactionFromSecurityId = (
  aggregatedTransactionId?: string
) => {
  const result = useQuery<{
    aggregatedTransaction: {
      toSecurity: {
        id: string
      }
    }
  }>(GET_AGGREGATED_TRANSACTION_TO_SECURITY_ID, {
    variables: { aggregatedTransactionId },
    skip: !aggregatedTransactionId,
  })

  return useMemo(
    () => ({
      ...result,
      toSecurityId: result?.data?.aggregatedTransaction?.toSecurity?.id,
    }),
    [result]
  )
}
