import { useQuery } from '@apollo/client'
import React, { ReactNode, useMemo } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import { ROUTES } from 'config/routes'
import { useAuth } from 'providers/Auth'
import {
  RouterContext,
  SANITIZED_EQUIFY_ADMIN,
} from 'providers/Router/RouterContext'
import { GET_DEFAULT_ROUTE } from 'providers/Router/graphql/queries/GET_DEFAULT_ROUTE'
import { sanitize } from 'utils'

export const RouterProvider = ({ children }: { children: ReactNode }) => {
  const { isAuth } = useAuth()
  const { data, loading } = useQuery<{
    me: {
      id: string
      lawFirmRoles: {
        id: string
        lawFirm: {
          id: string
          name: string
        }
      }[]
      corporationRoles: {
        id: string
        corporation: {
          id: string
        }
      }[]
      portfolioRoles: {
        id: string
        portfolio: {
          id: string
        }
      }[]
    }
  }>(GET_DEFAULT_ROUTE, { skip: !isAuth })

  const defaultRoute = useMemo((): string => {
    if (data?.me?.lawFirmRoles?.[0]?.lawFirm.id) {
      const equifyAdmin = data?.me?.lawFirmRoles?.find(
        ({ lawFirm }) => sanitize(lawFirm.name) === SANITIZED_EQUIFY_ADMIN
      )
      if (equifyAdmin) {
        return `/${ROUTES.LAW_FIRMS}/` + equifyAdmin.lawFirm.id
      }
      return `/${ROUTES.LAW_FIRMS}/` + data.me.lawFirmRoles[0].lawFirm.id
    }

    if (data?.me?.corporationRoles?.[0]?.corporation.id) {
      return (
        `/${ROUTES.CORPORATIONS}/` + data.me.corporationRoles[0].corporation.id
      )
    }

    if (data?.me?.portfolioRoles?.[0]?.portfolio.id) {
      return `/${ROUTES.PORTFOLIOS}/` + data.me.portfolioRoles[0].portfolio.id
    }

    return '/'
  }, [data])

  const context = useMemo(
    () => ({
      defaultRoute,
    }),
    [defaultRoute]
  )

  return (
    <RouterContext.Provider value={context}>
      <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
          {loading ? null : children}
        </QueryParamProvider>
      </BrowserRouter>
    </RouterContext.Provider>
  )
}
