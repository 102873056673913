import React, { ComponentType, useEffect } from 'react'
import {
  Route,
  RouteComponentProps,
  RouteProps,
  useHistory,
} from 'react-router-dom'

import { ONE_MINUTE } from 'config/date'
import { LocalStorage } from 'config/localStorage'
import { useAuth } from 'providers/Auth'

interface PrivateRouteProps extends RouteProps {
  fallback: ComponentType<RouteComponentProps<any>>
}

const PrivateRoute = ({ component, fallback, ...rest }: PrivateRouteProps) => {
  const { isAuth } = useAuth()
  const history = useHistory()

  useEffect(() => {
    const nowTimestamp = new Date().getTime()

    if (!isAuth) {
      if (window.location.pathname !== '/') {
        localStorage.setItem(
          LocalStorage.REDIRECT_AFTER_LOGIN_PATH,
          window.location.pathname + window.location.search
        )
        localStorage.setItem(
          LocalStorage.REDIRECT_AFTER_LOGIN_TIME,
          nowTimestamp.toString()
        )
      }
    } else {
      if (
        nowTimestamp - localStorage[LocalStorage.REDIRECT_AFTER_LOGIN_TIME] <
        ONE_MINUTE
      ) {
        history.replace(localStorage[LocalStorage.REDIRECT_AFTER_LOGIN_PATH])
      }
      localStorage.removeItem(LocalStorage.REDIRECT_AFTER_LOGIN_PATH)
      localStorage.removeItem(LocalStorage.REDIRECT_AFTER_LOGIN_TIME)
    }
  }, [history, isAuth])

  return <Route {...rest} component={isAuth ? component : fallback} />
}

export default React.memo(PrivateRoute)
