import { gql } from '@apollo/client'

export const GET_DEFAULT_ROUTE = gql`
  query GET_DEFAULT_ROUTE {
    me {
      id
      lawFirmRoles {
        id
        lawFirm {
          id
          name
        }
      }
      corporationRoles {
        id
        corporation {
          id
        }
      }
      portfolioRoles {
        id
        portfolio {
          id
        }
      }
    }
  }
`
