import {
  CalendarOutlined,
  FileTextOutlined,
  MailOutlined,
  PieChartOutlined,
  SwapOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { MenuInfo } from 'rc-menu/lib/interface.d'
import React from 'react'

import FontAwesomeIcon from 'components/atoms/FontAwesomeIcon'
import Menu from 'components/atoms/Menu'
import { CorporationPermissions, PortfolioPermissions } from 'config/roles'
import { useDrawer } from 'providers/Drawer'
import { PortfolioDrawerTab } from 'providers/Drawer/drawers/Portfolio'
import { usePermissions } from 'providers/Permissions'

const Sider = () => {
  const { tab, changeTab } = useDrawer()
  const { checkPermissions } = usePermissions()

  const handleClick = ({ key }: MenuInfo) => {
    changeTab(key as PortfolioDrawerTab)
  }

  return (
    <Menu
      selectedKeys={[tab]}
      mode="inline"
      style={{ height: '100%' }}
      menu={[
        {
          title: 'word.dashboard',
          icon: <PieChartOutlined />,
          key: PortfolioDrawerTab.dashboard,
          onClick: handleClick,
        },
        {
          icon: <UserOutlined />,
          title: 'word.details',
          key: PortfolioDrawerTab.details,
          onClick: handleClick,
        },
        checkPermissions(CorporationPermissions.TRANSACTIONS_READ) ||
        checkPermissions(PortfolioPermissions.TRANSACTIONS_READ)
          ? {
              icon: <SwapOutlined />,
              title: 'word.transactions',
              key: PortfolioDrawerTab.aggregatedTransactions,
              onClick: handleClick,
            }
          : undefined,
        checkPermissions(CorporationPermissions.TRANSACTIONS_READ) ||
        checkPermissions(PortfolioPermissions.TRANSACTIONS_READ)
          ? {
              icon: <CalendarOutlined />,
              title: 'word.events',
              key: PortfolioDrawerTab.events,
              onClick: handleClick,
            }
          : undefined,
        checkPermissions(CorporationPermissions.GOVERNANCE_READ)
          ? {
              title: 'word.groups',
              icon: <TeamOutlined />,
              key: PortfolioDrawerTab.governanceGroups,
              onClick: handleClick,
            }
          : undefined,
        checkPermissions(CorporationPermissions.GOVERNANCE_READ)
          ? {
              title: 'word.meetings',
              icon: <FontAwesomeIcon name="gavel" />,
              key: PortfolioDrawerTab.meetings,
              onClick: handleClick,
            }
          : undefined,
        checkPermissions(CorporationPermissions.GOVERNANCE_READ)
          ? {
              title: 'word.communications',
              icon: <MailOutlined />,
              key: PortfolioDrawerTab.communications,
              onClick: handleClick,
            }
          : undefined,
        checkPermissions(CorporationPermissions.DOCUMENTS_READ) ||
        checkPermissions(PortfolioPermissions.DOCUMENTS_READ)
          ? {
              icon: <FileTextOutlined />,
              title: 'word.documents',
              key: PortfolioDrawerTab.documents,
              onClick: handleClick,
            }
          : undefined,
      ]}
    />
  )
}

export default React.memo(Sider)
