import { gql } from '@apollo/client'

export const GET_AGGREGATED_TRANSACTION_DATE = gql`
  query GET_AGGREGATED_TRANSACTION_DATE($aggregatedTransactionId: ID!) {
    aggregatedTransaction(id: $aggregatedTransactionId) {
      id
      date
    }
  }
`
