import { gql } from '@apollo/client'

export const GET_ME_ROLES_PERMISSIONS = gql`
  query GET_ME_ROLES_PERMISSIONS {
    me {
      id
      lawFirmRoles {
        id
        permissions
        lawFirm {
          id
        }
      }
      corporationRoles(includeIndirect: true) {
        id
        permissions
        corporation {
          id
        }
      }
      portfolioRoles {
        id
        permissions
        portfolio {
          id
          corporation {
            id
          }
        }
      }
    }
  }
`
