import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_FILE_PROCEDURE_FILE_ID } from 'hooks/files/useFileProcedureFileId/GET_FILE_PROCEDURE_FILE_ID'

export const useFileProcedureFileId = (fileId?: string) => {
  const result = useQuery<{ file: { procedureFile: { id: string } } }>(
    GET_FILE_PROCEDURE_FILE_ID,
    {
      variables: { fileId },
      skip: !fileId,
    }
  )

  return useMemo(
    () => ({
      ...result,
      procedureFileId: result?.data?.file?.procedureFile?.id,
    }),
    [result]
  )
}

export const useFileHasProcedure = (fileId: string) => {
  const { procedureFileId } = useFileProcedureFileId(fileId)

  return Boolean(procedureFileId)
}
