// https://ant.design/components/collapse/

import { Collapse as AntdCollapse } from 'antd'
import { CollapsePanelProps as AntdCollapsePanelProps } from 'antd/lib/collapse'
import React from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'

const { Panel: AntdPanel } = AntdCollapse

export interface CollapsePanelProps extends AntdCollapsePanelProps {
  header: MessageType
  message?: MessageType
  extra?: MessageType
}

const Panel = ({ header, extra, message, ...rest }: CollapsePanelProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdPanel
      header={formatMessage(header)}
      extra={formatMessage(extra)}
      {...rest}
    >
      {formatMessage(message)}
    </AntdPanel>
  )
}

export default React.memo(Panel)
