import { CloudUploadOutlined } from '@ant-design/icons'
import prettyBytes from 'pretty-bytes'
import React, { useEffect, useMemo } from 'react'

import Message from 'components/atoms/Intl/Message'
import Border from 'components/atoms/Section/Border'
import Space from 'components/atoms/Space'
import Table from 'components/atoms/Table'
import Tooltip from 'components/atoms/Tooltip'
import FileBadgeErrors from 'components/molecules/File/BadgeErrors'
import FileName from 'components/molecules/File/Name'
import FileSize from 'components/molecules/File/Size'
import { BorderType } from 'config/border'
import { UploadStatus, useFilesUpload } from 'providers/FilesUpload'
import { x } from 'utils/emotion'

export const SelectFiles = ({
  fileIds,
  selectedIds,
  setSelectedIds,
}: {
  fileIds: string[]
  selectedIds: string[]
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  const { queue } = useFilesUpload()

  const dataSource = useMemo(
    () =>
      fileIds.map((fileId) => {
        return (
          queue.find(({ id }) => id === fileId) ?? {
            id: fileId,
            status: UploadStatus.UPLOADED,
            file: {
              name: (
                <Space>
                  <FileBadgeErrors fileId={fileId} />
                  <FileName fileId={fileId} />
                </Space>
              ),
              size: <FileSize fileId={fileId} />,
            },
          }
        )
      }),
    [queue, fileIds]
  )

  useEffect(() => {
    if (!selectedIds.length && fileIds[0]) {
      setSelectedIds([fileIds[0]])
    }
  }, [fileIds, selectedIds])

  if (fileIds.length < 2) {
    return null
  }

  return (
    <>
      <x.div
        minWidth="250px"
        h="80vh"
        overflowY="auto"
        display="flex"
        flexDirection="column"
        borderRadius="medium 0 0 0"
      >
        <Table
          rowSelection={{
            fixed: true,
            type: 'checkbox',
            selectedRowKeys: selectedIds,
            onChange: (ids) => {
              setSelectedIds(ids as string[])
            },
          }}
          dataSource={dataSource}
          onRow={({ id }) => ({
            onClick: () => {
              setSelectedIds([id])
            },
          })}
          columns={[
            {
              key: 'name',
              title: 'word.file',
              render: ({ file, status }: any) => {
                return (
                  <x.div maxWidth="200px" overflow="hidden">
                    <Tooltip title={file?.name}>
                      <x.div overflow="hidden" textOverflow="ellipsis">
                        {file?.name}
                      </x.div>
                    </Tooltip>
                    <x.div fontSize="small" color="grey" maxHeight="20px">
                      {status === UploadStatus.UPLOADED ? (
                        typeof file?.size === 'number' ? (
                          prettyBytes(file?.size)
                        ) : (
                          file.size
                        )
                      ) : (
                        <>
                          <CloudUploadOutlined />{' '}
                          <Message value="word.uploadingWithDots" />
                        </>
                      )}
                    </x.div>
                  </x.div>
                )
              },
            },
          ]}
        />
      </x.div>
      <Border type={BorderType.vertical} />
    </>
  )
}
