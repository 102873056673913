import React, { lazy, Suspense, useMemo } from 'react'

import { useDrawer } from 'providers/Drawer'
import { CommunicationDrawerTab } from 'providers/Drawer/drawers/Communication'

const Details = lazy(
  () => import('providers/Drawer/drawers/Communication/Content/Details')
)
const ContentTab = lazy(
  () => import('providers/Drawer/drawers/Communication/Content/ContentTab')
)
const Documents = lazy(
  () => import('providers/Drawer/drawers/Communication/Content/Documents')
)
const Holders = lazy(
  () => import('providers/Drawer/drawers/Communication/Content/Holders')
)
const Content = () => {
  const { tab } = useDrawer()

  const Component = useMemo(() => {
    switch (tab) {
      case CommunicationDrawerTab.details:
        return Details
      case CommunicationDrawerTab.contentTab:
        return ContentTab
      case CommunicationDrawerTab.documents:
        return Documents
      case CommunicationDrawerTab.recipients:
        return Holders
    }
    return undefined
  }, [tab])

  if (!Component) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  )
}

export default React.memo(Content)
