import React from 'react'

import Drawer from 'components/atoms/Drawer'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import ButtonMoreActionsPool from 'components/molecules/ButtonMoreActions/Pool'
import PoolName from 'components/molecules/Pool/Name'
import TagPoolStatus from 'components/molecules/Tag/PoolStatus'
import { usePoolName } from 'hooks/pools/usePoolName'
import { useDrawer } from 'providers/Drawer'
import Content from 'providers/Drawer/drawers/Pool/Content'
import Sider from 'providers/Drawer/drawers/Pool/Sider'

export enum PoolDrawerTab {
  details = 'details',
  aggregatedTransactions = 'aggregatedTransactions',
  documents = 'documents',
}

function Pool() {
  const { id } = useDrawer()
  const { name } = usePoolName(id)
  const formatMessage = useFormatMessage()

  return (
    <Drawer
      open
      helmetTitle={
        name ? `${formatMessage('word.pool')} · ${name}` : 'word.pool'
      }
      pageHeaderProps={{
        title: <PoolName poolId={id} />,
        subTitle: 'word.pool',
        tags: <TagPoolStatus poolId={id} />,
        extra: <ButtonMoreActionsPool poolId={id} />,
      }}
      sider={Sider}
      content={Content}
    />
  )
}

export default React.memo(Pool)
