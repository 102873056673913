// https://ant.design/components/form/

import { Form } from 'antd'
import { FormItemProps as AntdFormItemProps } from 'antd/lib/form'
import { Rule } from 'rc-field-form/lib/interface'
import React, { useCallback } from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'

export type FormItemRuleType = Rule & {
  message?: MessageType
}
export interface FormItemProps extends AntdFormItemProps {
  tooltip?: MessageType
  label?: MessageType
  extra?: MessageType
  help?: MessageType
  rules?: FormItemRuleType[]
}

const FormItem = ({
  label,
  extra,
  rules,
  tooltip,
  help,
  ...rest
}: FormItemProps) => {
  const formatMessage = useFormatMessage()

  const formatRules = useCallback(
    (rules) => {
      return rules.map((rule: FormItemRuleType) => {
        if (typeof rule === 'function') {
          return rule
        }
        const { message, ...ruleRest } = rule
        return {
          ...ruleRest,
          message: message ? formatMessage(message) : undefined,
        }
      })
    },
    [formatMessage]
  )

  return (
    <Form.Item
      label={label ? formatMessage(label) : undefined}
      rules={rules ? formatRules(rules) : undefined}
      extra={extra ? formatMessage(extra) : undefined}
      tooltip={tooltip ? { title: formatMessage(tooltip) } : undefined}
      help={help ? formatMessage(help) : undefined}
      {...rest}
    />
  )
}

export default React.memo(FormItem)
