import { gql } from '@apollo/client'

export const GET_FILE_SMART_FIELDS = gql`
  query GET_FILE_SMART_FIELDS($fileId: ID!) {
    file(id: $fileId) {
      id
      smartFields {
        customSmartFields {
          name {
            enGB
            frFR
          }
        }
        smartFieldsGroups {
          name {
            enGB
            frFR
          }
        }
      }
    }
  }
`
