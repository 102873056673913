import { gql } from '@apollo/client'

export const GET_CORPORATION_GOVERNANCE_GROUPS = gql`
  query GET_CORPORATION_GOVERNANCE_GROUPS(
    $corporationId: ID!
    $page: BigInt
    $hitsPerPage: BigInt
    $search: String
  ) {
    corporation(id: $corporationId) {
      id
      governanceGroups {
        list(page: $page, hitsPerPage: $hitsPerPage, search: $search) {
          hits {
            id
            name
          }
          nbHits
          page
          nbPages
          hitsPerPage
        }
      }
    }
  }
`
