import React, { useEffect, useState } from 'react'

import Button from 'components/atoms/Button'
import Input from 'components/atoms/Form/Input'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import {
  REACT_APP_PAYFIT_CALLBACK,
  REACT_APP_PAYFIT_CLIENT_ID,
  REACT_APP_PAYFIT_SCOPE,
} from 'config/env'
import { IntegrationApiEnum } from 'config/integration'
import { useCorporationCorporationInfoId } from 'hooks/corporations/useCorporationCorporationInfoId'
import { useWorkspace } from 'hooks/useWorkspace'
import { useNotification } from 'providers/Notification'
import { useSkalin } from 'providers/Skalin'
import { useWorkflow } from 'providers/Workflows/WorkflowContext'
import { WorkflowFieldProps } from 'providers/Workflows/components/WorkflowField'
import { WorkflowFields } from 'providers/Workflows/components/WorkflowFields'
import { WorkflowFullPage } from 'providers/Workflows/components/WorkflowFullPage'
import { ConfigureSyncMappingData } from 'providers/Workflows/steps/configureSyncMapping/types'
import Alert from 'stories/components/atoms/Alert/Alert'
import { x } from 'utils/emotion'

const integrationsThatUseOauth = [IntegrationApiEnum.PAYFIT]

export const OAUTH_BROADCAST_CHANNEL_NAME = 'oauthEquifyConnexion'

const redirectUri = encodeURI(REACT_APP_PAYFIT_CALLBACK)
const clientId = REACT_APP_PAYFIT_CLIENT_ID
const scope = encodeURI(REACT_APP_PAYFIT_SCOPE)

const PAYFIT_OAUTH_CONNEXION_LINK = `https://oauth.payfit.com/authorize?redirect_uri=${redirectUri}&client_id=${clientId}&response_type=code&scope=${scope}`

const OauthLinkForIntegrations = {
  [IntegrationApiEnum.PAYFIT as string]: PAYFIT_OAUTH_CONNEXION_LINK,
}

function ConfigureSyncDetails() {
  const { data, next } = useWorkflow<ConfigureSyncMappingData>()
  const formatMessage = useFormatMessage()
  const { workspaceId } = useWorkspace()
  const { corporationInfoId } = useCorporationCorporationInfoId(workspaceId)
  const { errorNotification } = useNotification()
  const { skalin } = useSkalin()

  const [
    oAuthCallBackFinishedSuccessfully,
    setOAuthCallBackFinishedSuccessfully,
  ] = useState(false)
  const [oAuthCallBackFinishedWithError, setOAuthCallBackFinishedWithError] =
    useState(false)

  const integrationsThatNeedUsernameAndPassword = [IntegrationApiEnum.WORKDAY]
  const useAuthenticationByUsernameAndPassword =
    integrationsThatNeedUsernameAndPassword.includes(
      data.type as IntegrationApiEnum
    )

  const fields: WorkflowFieldProps[] = [
    {
      required: true,
      wrapFormik: true,
      name: 'apiUrl',
      label: `integration.${data.type}.activation.url.label`,
      component: Input,
      componentProps: {
        placeholder: `integration.${data.type}.activation.url.placeholder`,
      },
    },
  ]

  if (useAuthenticationByUsernameAndPassword) {
    fields.push(
      ...[
        {
          required: true,
          wrapFormik: true,
          name: 'username',
          label: 'word.username',
          component: Input,
        },
        {
          required: true,
          wrapFormik: true,
          name: 'password',
          label: 'word.password',
          component: Input,
        },
      ]
    )
  } else {
    fields.push({
      required: true,
      wrapFormik: true,
      name: 'apiKey',
      label: 'word.apiKey',
      component: Input,
    })
  }

  const useOauth = integrationsThatUseOauth.includes(
    data.type as IntegrationApiEnum
  )

  const urlOfOauthConnexion = OauthLinkForIntegrations[data.type as string]

  const openOauthConnexionPopin = () => {
    window.open(urlOfOauthConnexion, 'authPopup', 'width=500,height=600')
  }

  const OauthButton = (
    <Button
      type={'primary'}
      onClick={openOauthConnexionPopin}
      message={`integration.${data.type}.activation.oauthButton`}
    />
  )

  useEffect(() => {
    skalin.feature({ name: `HRIS Integration Sync Workflow - ${data.type}` })
  }, [data.type])

  // We use effects and state to be sure to only trigger the success or error scenario only once
  // because messages are sent multiple times in the BroadcastChannel
  useEffect(() => {
    if (oAuthCallBackFinishedSuccessfully && !oAuthCallBackFinishedWithError) {
      next({ corporationId: workspaceId, type: data.type })
    }
  }, [oAuthCallBackFinishedSuccessfully])

  useEffect(() => {
    if (oAuthCallBackFinishedWithError && !oAuthCallBackFinishedSuccessfully) {
      errorNotification({
        message: 'word.anErrorOccurred',
      })
    }
  }, [oAuthCallBackFinishedWithError])

  if (useOauth) {
    const bc = new BroadcastChannel(OAUTH_BROADCAST_CHANNEL_NAME)

    bc.onmessage = function (ev) {
      if (ev.data.equifyTrigger) {
        switch (ev.data.equifyTrigger) {
          // if an hrisCallback is called, we send the data it needs to do its work
          case 'hrisCallbackCalled':
            bc.postMessage({
              equifyTrigger: 'configureSyncDetails',
              equifyData: {
                corporationInfoId: corporationInfoId,
              },
            })
            break
          case 'hrisCallbackClosedSuccess':
            if (
              !oAuthCallBackFinishedSuccessfully &&
              !oAuthCallBackFinishedWithError
            ) {
              setOAuthCallBackFinishedSuccessfully(true)
            }
            break
          case 'hrisCallbackClosedError':
            if (
              !oAuthCallBackFinishedSuccessfully &&
              !oAuthCallBackFinishedWithError
            ) {
              setOAuthCallBackFinishedWithError(true)
            }
            break
        }
      }
    }
  }

  return (
    <WorkflowFullPage
      maxWidth="800px"
      title={`integration.${data.type}.activation.title`}
      buttons={useOauth ? null : undefined}
    >
      <x.div display="flex" flexDirection="column">
        <x.div marginBottom="xlarge">
          <Alert
            showIcon
            message={formatMessage(
              `integration.${data.type}.activation.description`
            )}
          />
        </x.div>
        {useOauth ? OauthButton : <WorkflowFields fields={fields} />}
      </x.div>
    </WorkflowFullPage>
  )
}

export default React.memo(ConfigureSyncDetails)
