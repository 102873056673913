import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { DrawerNames } from 'config/drawer'
import { useEventName } from 'hooks/events/useEventName'
import { useDrawers } from 'providers/Drawers'
import { x } from 'utils/emotion'

export interface EventNameProps {
  eventId?: string
  withLink?: boolean
}

const EventName = ({ eventId, withLink }: EventNameProps) => {
  const { openDrawer } = useDrawers()

  const { name, loading } = useEventName(eventId)

  const content = <SkeletonText loading={loading}>{name}</SkeletonText>

  if (withLink && eventId) {
    return (
      <x.span
        cursor="pointer"
        hoverTextDecoration="underline"
        onClick={() => {
          openDrawer({ name: DrawerNames.event, id: eventId })
        }}
      >
        {content}
      </x.span>
    )
  }

  return content
}

export default React.memo(EventName)
