import { saveAs } from 'file-saver'

export const downloadFile = async ({
  url,
  name,
  mimeType,
}: {
  url: string
  name: string
  mimeType?: string
}) => {
  const headers = new Headers(mimeType ? [['Content-Type', mimeType]] : [])

  try {
    const response = await fetch(url, {
      cache: 'default',
      headers,
      method: 'GET',
      mode: 'cors',
    })
    if (response.ok) {
      response.blob().then((blob) => {
        saveAs(blob, name)
      })
    }
  } catch (error) {
    console.error(error)
  }
}
