import { useMutation, useQuery } from '@apollo/client'
import { isEqual, uniqWith } from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import FormItem from 'components/atoms/Form/FormItem'
import ApiSearchSelectCorporationAggregatedTransactions from 'components/molecules/ApiSearchSelect/CorporationAggregatedTransactions'
import { ONE_SECOND } from 'config/date'
import { FILES_LINK_AGGREGATED_TRANSACTIONS } from 'providers/FilesEdit/Modal/FileProperties/Inputs/AggregatedTransactions/FILES_LINK_AGGREGATED_TRANSACTIONS'
import { GET_SELECTED_FILES_AGGREGATED_TRANSACTIONS } from 'providers/FilesEdit/Modal/FileProperties/Inputs/AggregatedTransactions/GET_SELECTED_FILES_AGGREGATED_TRANSACTIONS'

export const AggregatedTransactions = ({
  selectedIds,
  corporationId,
}: {
  selectedIds: string[]
  corporationId: string
}) => {
  const [value, setValue] = useState<string[]>([])
  const [changeCount, setChangeCount] = useState(0)
  const [debouncedChangeCount] = useDebounce(changeCount, ONE_SECOND)
  const [link] = useMutation(FILES_LINK_AGGREGATED_TRANSACTIONS)

  const { data, loading } = useQuery(
    GET_SELECTED_FILES_AGGREGATED_TRANSACTIONS,
    {
      variables: { ids: selectedIds },
    }
  )

  useEffect(() => {
    if (debouncedChangeCount) {
      link({
        variables: {
          ids: selectedIds,
          aggregatedTransactionIds: value,
        },
      })
    }
  }, [debouncedChangeCount])

  useEffect(() => {
    const values: any[] = uniqWith(
      isEqual,
      (data?.files || []).map((file: any) =>
        file.aggregatedTransactions.aggregatedTransactions.map(
          ({ id }: { id: string }) => id
        )
      )
    )
    if (values.length === 1) {
      setValue(values[0])
    } else {
      setValue([])
    }
  }, [data])

  return (
    <FormItem label="word.transactions">
      <ApiSearchSelectCorporationAggregatedTransactions
        loading={loading}
        corporationId={corporationId}
        mode="multiple"
        allowClear
        value={value}
        onChange={(newValue) => {
          setValue(newValue)
          setChangeCount((old) => old + 1)
        }}
      />
    </FormItem>
  )
}
