import React, { useEffect, useMemo, useState } from 'react'

import Button from 'components/atoms/Button'
import Input from 'components/atoms/Form/Input'
import Message, { useFormatMessage } from 'components/atoms/Intl/Message'
import Modal, { ModalProps } from 'components/atoms/Modal'
import { useEquibar } from 'providers/Equibar'
import { useWorkflow } from 'providers/Workflows'
import { ConfirmDeleteButton } from 'providers/Workflows/components/WorkflowButtons'
import Alert, { AlertProps } from 'stories/components/atoms/Alert/Alert'
import eqDSTheme from 'styles/eqDSTheme'
import { sanitize } from 'utils'

export const WorkflowModalDelete = ({
  open,
  onCancel,
  title,
  alert,
  payload,
  ...rest
}: ModalProps & { alert?: AlertProps; payload?: any }) => {
  const { loading } = useWorkflow()
  const formatMessage = useFormatMessage()
  const { autoCompleteDelete } = useEquibar()
  const deleteWord = useMemo(
    () => formatMessage(title) as string,
    [formatMessage]
  )
  const [inputValue, setInputValue] = useState<string | null>(
    autoCompleteDelete ? deleteWord : null
  )

  useEffect(() => {
    if (autoCompleteDelete && deleteWord) {
      setInputValue(deleteWord)
    }
  }, [deleteWord, autoCompleteDelete])

  return (
    <Modal
      centered
      destroyOnClose
      open={open}
      title={title}
      onCancel={onCancel}
      footer={[
        <Button
          key="loading"
          disabled={loading}
          onClick={(
            e: React.MouseEvent<
              HTMLAnchorElement | HTMLButtonElement,
              MouseEvent
            >
          ) =>
            onCancel &&
            onCancel(e as React.MouseEvent<HTMLButtonElement, MouseEvent>)
          }
          message="word.close"
        />,
        <ConfirmDeleteButton
          key="delete"
          loading={loading}
          payload={payload}
          disabled={
            !inputValue || sanitize(inputValue) !== sanitize(deleteWord)
          }
        />,
      ]}
      {...rest}
    >
      <Alert
        showIcon
        type="warning"
        message={formatMessage('word.warning')}
        description={formatMessage('deleteVerification.alert')}
        {...alert}
      />
      <div
        style={{
          marginTop: eqDSTheme.space.xlarge,
        }}
      >
        <Message
          value={{
            id: 'deleteVerification.label',
            values: { value: deleteWord },
          }}
        />
        <Input
          placeholder={title}
          value={inputValue}
          onChange={setInputValue}
        />
      </div>
    </Modal>
  )
}
