import { useCallback } from 'react'

import { useNumberSeparators } from 'components/atoms/Intl/Number'
import { parseNumber } from 'utils/numbers'

export const useParseInteger = () => {
  const separators = useNumberSeparators()

  return useCallback(
    (value?: any) => {
      const regex = new RegExp(`[${separators}]`)
      const separatorsRegex = new RegExp(`[${separators}]`, 'g')
      return parseNumber(
        String(value)
          .split(regex)[0]
          .replace(/[^-0-9]+/g, '')
          .replace(separatorsRegex, '.')
      )
    },
    [separators]
  )
}
