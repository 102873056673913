import { gql } from '@apollo/client'

export const GET_FILE_SIGNATURE_GROUPS = gql`
  query GET_FILE_SIGNATURE_GROUPS($fileId: ID!) {
    file(id: $fileId) {
      id
      smartFields {
        signatureGroups {
          rawName
          name {
            frFR
            enGB
          }
        }
      }
    }
  }
`
