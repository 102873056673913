import React from 'react'

import Tag from 'components/atoms/Tag'
import { useFileIsTemplate } from 'hooks/files/useFileIsTemplate'

const FileIsTemplateTag = ({ fileId }: { fileId: string }) => {
  const { isTemplate } = useFileIsTemplate(fileId)

  if (isTemplate) {
    return <Tag color="#6CA2FF" message="file.isTemplate" />
  }

  return null
}

export default React.memo(FileIsTemplateTag)
