import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
  TeamOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { useApolloClient } from '@apollo/client'
import { zonedTimeToUtc } from 'date-fns-tz'
import React, { useMemo, useState } from 'react'

import ButtonMoreActions from 'components/atoms/Button/MoreActions'
import Menu from 'components/atoms/Menu'
import ModalResendMeetingInvitations from 'components/molecules/Modal/ResendMeetingInvitations'
import ModalDeleteMeeting from 'components/molecules/Modal/deleteMeeting'
import { APPOINT_PROXY_CHOICES } from 'config/appointProxy'
import { DrawerNames } from 'config/drawer'
import { MEETING_ATTENDEES_RESPONSE } from 'config/meetingAttendees'
import { MeetingAttendeesType } from 'config/meetings'
import { CorporationPermissions, PortfolioPermissions } from 'config/roles'
import { SLUGS } from 'config/slugs'
import { useContactEmail } from 'hooks/contacts/useContactEmail'
import { GET_FILE_PROCEDURE_ID } from 'hooks/files/useFileProcedureId/GET_FILE_PROCEDURE_ID'
import { useMeetingAttendeeResponse } from 'hooks/meetingAttendees/useMeetingAttendeeResponse'
import { useMeetingFromDate } from 'hooks/meetings/useMeetingFromDate'
import { useMeetingMeetingAttendeeId } from 'hooks/meetings/useMeetingMeetingAttendeeId'
import { useMeetingTimezone } from 'hooks/meetings/useMeetingTimezone'
import {
  useIsCorporationWorkspace,
  useIsPortfolioWorkspace,
} from 'hooks/useWorkspace'
import { useDrawers } from 'providers/Drawers'
import { useNotification } from 'providers/Notification'
import { usePermissions } from 'providers/Permissions'
import { useProcedure } from 'providers/Procedure'
import { useUser } from 'providers/User'
import { useWorkflows } from 'providers/Workflows'
import { useStartWorkflow } from 'providers/Workflows/hooks/useStartWorkflow'

const ButtonMoreActionsMeeting = ({
  meetingId,
  meetingAttendeeContactId,
  portfolioId,
}: {
  meetingId: string
  meetingAttendeeContactId?: string
  portfolioId?: string
}) => {
  const { id: userId, primaryEmail } = useUser()
  const { start } = useWorkflows()
  const { query } = useApolloClient()
  const { openDrawer } = useDrawers()
  const { openProcedure } = useProcedure()
  const { checkPermissions } = usePermissions()
  const { successNotification } = useNotification()
  const isPortfolioWorkspace = useIsPortfolioWorkspace()
  const isCorporationWorkspace = useIsCorporationWorkspace()

  const { timezone } = useMeetingTimezone(meetingId)
  const { fromDate } = useMeetingFromDate(meetingId)
  const { meetingAttendeeId } = useMeetingMeetingAttendeeId(
    meetingId,
    meetingAttendeeContactId,
    portfolioId,
    userId
  )
  const { response, loading } = useMeetingAttendeeResponse(meetingAttendeeId)
  const { email } = useContactEmail(meetingAttendeeContactId)

  const [deleteMeetingVisible, setDeleteMeetingVisible] = useState(false)
  const [resendVotersVisible, setResendVotersVisible] = useState(false)
  const [resendObserversVisible, setResendObserversVisible] = useState(false)

  const limitDate = useMemo(() => {
    if (fromDate && timezone) {
      return zonedTimeToUtc(fromDate, timezone)
    }
  }, [fromDate, timezone])

  const [startEditMeetingAttendees] = useStartWorkflow({
    data: { meetingId },
    slug: 'editMeetingAttendees',
  })

  const [startEditMeetingLinkedFiles] = useStartWorkflow({
    data: { meetingId },
    slug: 'editMeetingLinkedFiles',
  })

  if (isPortfolioWorkspace && !meetingAttendeeId) {
    return null
  }

  if (
    isPortfolioWorkspace &&
    response === MEETING_ATTENDEES_RESPONSE.NOT_RESPONDED
  ) {
    return null
  }

  return (
    <>
      <ButtonMoreActions
        loading={loading}
        overlay={
          <Menu
            menu={[
              isCorporationWorkspace &&
              checkPermissions(CorporationPermissions.GOVERNANCE_MANAGE)
                ? {
                    key: 'editMeetingAttendees',
                    icon: <TeamOutlined />,
                    message: 'word.editMeetingAttendees',
                    onClick: startEditMeetingAttendees,
                  }
                : undefined,
              isCorporationWorkspace &&
              checkPermissions(CorporationPermissions.GOVERNANCE_MANAGE)
                ? {
                    key: 'editDocuments',
                    icon: <FileTextOutlined />,
                    message: 'word.editDocuments',
                    onClick: startEditMeetingLinkedFiles,
                  }
                : undefined,
              isCorporationWorkspace &&
              checkPermissions(CorporationPermissions.GOVERNANCE_MANAGE)
                ? {
                    key: 'resendVotersInvitations',
                    icon: <MailOutlined />,
                    message: 'word.resendVotersInvitations',
                    onClick: () => setResendVotersVisible(true),
                  }
                : undefined,
              isCorporationWorkspace &&
              checkPermissions(CorporationPermissions.GOVERNANCE_MANAGE)
                ? {
                    key: 'resendObserversInvitations',
                    icon: <MailOutlined />,
                    message: 'word.resendObserversInvitations',
                    onClick: () => setResendObserversVisible(true),
                  }
                : undefined,
              isCorporationWorkspace &&
              checkPermissions(CorporationPermissions.GOVERNANCE_MANAGE)
                ? {
                    danger: true,
                    key: 'delete',
                    icon:
                      limitDate && new Date() <= limitDate ? (
                        <CloseOutlined />
                      ) : (
                        <DeleteOutlined />
                      ),
                    message:
                      limitDate && new Date() <= limitDate
                        ? 'word.cancel'
                        : 'word.delete',
                    onClick: () => setDeleteMeetingVisible(true),
                  }
                : undefined,
              meetingAttendeeId &&
              primaryEmail === email &&
              isPortfolioWorkspace &&
              checkPermissions(PortfolioPermissions.CORPORATION_GOVERNANCE_READ)
                ? {
                    key: 'reply',
                    icon: <EditOutlined />,
                    message: 'word.editAnswer',
                    onClick: () => {
                      start({
                        slug: SLUGS.APPOINT_PROXY,
                        data: { meetingAttendeeId },
                        onCompleted: (data) => {
                          switch (data?.choice) {
                            case APPOINT_PROXY_CHOICES.DECLINE_PRESENCE:
                              successNotification({
                                message: 'word.presenceSuccessfullyDeclined',
                              })
                              break
                            case APPOINT_PROXY_CHOICES.CONFIRM_PRESENCE:
                              successNotification({
                                message: 'word.presenceSuccessfullyConfirmed',
                              })
                              break
                            case APPOINT_PROXY_CHOICES.APPOINT_PROXY:
                              if (data?.generatedProxyForm) {
                                openDrawer({
                                  name: DrawerNames.file,
                                  id: data?.generatedProxyForm,
                                })
                                query({
                                  query: GET_FILE_PROCEDURE_ID,
                                  variables: {
                                    fileId: data?.generatedProxyForm,
                                  },
                                }).then(({ data }) => {
                                  if (
                                    data?.file?.procedureFile?.procedure?.id
                                  ) {
                                    openProcedure(
                                      data?.file?.procedureFile?.procedure?.id
                                    )
                                  }
                                })
                              } else {
                                successNotification({
                                  message: 'word.proxySuccessfullyAppointed',
                                })
                              }
                              break
                          }
                        },
                      })
                    },
                  }
                : undefined,
            ]}
          />
        }
      />
      <ModalDeleteMeeting
        meetingId={meetingId}
        open={deleteMeetingVisible}
        onCancel={() => setDeleteMeetingVisible(false)}
      />
      <ModalResendMeetingInvitations
        meetingId={meetingId}
        type={MeetingAttendeesType.VOTER}
        open={resendVotersVisible}
        onCancel={() => setResendVotersVisible(false)}
      />
      <ModalResendMeetingInvitations
        meetingId={meetingId}
        type={MeetingAttendeesType.OBSERVER}
        open={resendObserversVisible}
        onCancel={() => setResendObserversVisible(false)}
      />
    </>
  )
}

export default React.memo(ButtonMoreActionsMeeting)
