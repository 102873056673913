import { Locales } from 'config/locale'

export const getLanguage = (locale: Locales): string => {
  return locale.substring(0, 2)
}

export const getCountry = (locale: Locales): string => {
  return locale.slice(2)
}

export const formatLocale = (locale: Locales, join: string) => {
  return getLanguage(locale) + join + getCountry(locale)
}
