import React from 'react'

import ApiSearchSelect, {
  ApiSearchSelectProps,
} from 'components/atoms/ApiSearch/Select'
import AggregatedTransactionName from 'components/molecules/AggregatedTransaction/Name'
import { ApiSearchCorporationAggregatedTransactions } from 'providers/ApiSearch/CorporationAggregatedTransactions'
import {
  ApiSearchFiltersProvider,
  ApiSearchFiltersProviderProps,
} from 'providers/ApiSearchFilters'

const ApiSearchSelectCorporationAggregatedTransactions = ({
  corporationId,
  filtersProps = {},
  ...rest
}: Omit<ApiSearchSelectProps, 'renderItem'> & {
  corporationId: string
  filtersProps?: Omit<ApiSearchFiltersProviderProps, 'children'>
}) => {
  return (
    <ApiSearchFiltersProvider
      defaultFilters={{ virtual: false }}
      {...filtersProps}
    >
      <ApiSearchCorporationAggregatedTransactions corporationId={corporationId}>
        <ApiSearchSelect
          placeholder="word.searchForATransaction"
          renderItem={(id) => (
            <AggregatedTransactionName
              aggregatedTransactionId={id}
              withTooltip
            />
          )}
          {...rest}
        />
      </ApiSearchCorporationAggregatedTransactions>
    </ApiSearchFiltersProvider>
  )
}

export default React.memo(ApiSearchSelectCorporationAggregatedTransactions)
