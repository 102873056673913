import { notification } from 'antd'
import { ArgsProps } from 'antd/es/notification/interface'
import React, { useContext } from 'react'

import { MessageType } from 'components/atoms/Intl/Message'

export interface NotificationConfigType extends ArgsProps {
  message: MessageType
  description?: MessageType
}

export interface INotificationContext {
  sendNotification: (type: string) => (options: NotificationConfigType) => void
  errorNotification: (options: NotificationConfigType) => void
  infoNotification: (options: NotificationConfigType) => void
  openNotification: (options: NotificationConfigType) => void
  successNotification: (options: NotificationConfigType) => void
  warningNotification: (options: NotificationConfigType) => void
  closeNotification: (key: string) => void
  destroyNotification: () => void
}

export const NotificationContext = React.createContext<INotificationContext>({
  sendNotification: () => () => {},
  errorNotification: () => {},
  infoNotification: () => {},
  openNotification: () => {},
  successNotification: () => {},
  warningNotification: () => {},
  closeNotification: (key: string) => {
    notification.destroy(key)
  },
  destroyNotification: () => {
    notification.destroy()
  },
})

export const useNotification = (): INotificationContext =>
  useContext(NotificationContext)
