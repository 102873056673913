import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { MEETING_ATTENDEES_RESPONSE } from 'config/meetingAttendees'
import { GET_MEETING_ATTENDEE_RESPONSE } from 'hooks/meetingAttendees/useMeetingAttendeeResponse/GET_MEETING_ATTENDEE_RESPONSE'

export const useMeetingAttendeeResponse = (meetingAttendeeId?: string) => {
  const result = useQuery<{
    meetingAttendee: { response: MEETING_ATTENDEES_RESPONSE }
  }>(GET_MEETING_ATTENDEE_RESPONSE, {
    variables: { meetingAttendeeId },
    skip: !meetingAttendeeId,
  })

  return useMemo(
    () => ({
      ...result,
      response: result?.data?.meetingAttendee?.response,
    }),
    [result]
  )
}
