import React from 'react'

import Message from 'components/atoms/Intl/Message'
import SkeletonText from 'components/atoms/Skeleton/Text'
import { AggregatedTransactionLabel as TransactionLabelEnum } from 'config/transaction'
import { useAggregatedTransactionLabel } from 'hooks/aggregatedTransactions/useAggregatedTransactionLabel'

const AggregatedTransactionLabel = ({
  aggregatedTransactionId,
  label,
}: {
  aggregatedTransactionId?: string
  label?: TransactionLabelEnum
}) => {
  const { label: labelData, loading } = useAggregatedTransactionLabel(
    aggregatedTransactionId
  )

  return (
    <SkeletonText loading={loading}>
      {Boolean(label || labelData) && (
        <Message value={`transaction.label.${label || labelData}`} />
      )}
    </SkeletonText>
  )
}

export default React.memo(AggregatedTransactionLabel)
