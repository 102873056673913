import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_MEETING_MEETING_ATTENDEE_ID } from 'hooks/meetings/useMeetingMeetingAttendeeId/GET_MEETING_MEETING_ATTENDEE_ID'

export const useMeetingMeetingAttendeeId = (
  meetingId?: string,
  contactId?: string,
  portfolioId?: string,
  userId?: string
) => {
  const result = useQuery<{ meeting: { meetingAttendee: { id: string } } }>(
    GET_MEETING_MEETING_ATTENDEE_ID,
    {
      variables: { meetingId, contactId, portfolioId, userId },
      skip: !meetingId || !portfolioId || !contactId,
    }
  )

  return useMemo(
    () => ({
      ...result,
      meetingAttendeeId: result?.data?.meeting?.meetingAttendee?.id,
    }),
    [result]
  )
}
