// https://ant.design/components/page-header/

import {
  PageHeader as AntdPageHeader,
  PageHeaderProps as AntdPageHeaderProps,
} from '@ant-design/pro-layout'
import React from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'
import { AvatarProps } from 'stories/components/atoms/Avatar/Avatar'
import eqDSTheme from 'styles/eqDSTheme'

export interface PageHeaderProps extends Omit<AntdPageHeaderProps, 'title'> {
  title?: MessageType
  subTitle?: MessageType
  avatar?: AvatarProps
}

/* Deprecated since antd 5.0.0* */
const PageHeader = ({ title, subTitle, ...rest }: PageHeaderProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdPageHeader
      style={{
        padding: eqDSTheme.space.medium,
        backgroundColor: eqDSTheme.color.white,
      }}
      title={title ? formatMessage(title) : undefined}
      subTitle={subTitle ? formatMessage(subTitle) : undefined}
      {...rest}
    />
  )
}

export default React.memo(PageHeader)
