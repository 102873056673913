import { DocumentNode } from '@apollo/client'
import React, { createContext, ReactNode, useContext } from 'react'

import { Sorting } from 'config/sorting'

export const DEFAULT_HITS_PER_PAGE = 20
export const DEFAULT_DEBOUNCE_SEARCH = 500

export type ApiSearchProviderProps = {
  children: ReactNode | ((context: IApiSearchContext) => ReactNode)
  query: DocumentNode
  path: string
  variables?: object
  saveFacets?: boolean
  defaultFilters?: object
  filtersToUse?: object
  localSearchInKeys?: [string, ...string[]]
}

export interface IApiSearchMemoVariables {
  page: number
  hitsPerPage: number
  search?: string
  filters?: object
  sort?: Sorting
}

export type IApiSearchHit = {
  id: string
  name?: string
  apiSearchSelectCategory?: string
}

export interface IApiSearchContext {
  loading: boolean
  hits: IApiSearchHit[]
  nbHits: number
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  nbPages: number
  setNbPages: React.Dispatch<React.SetStateAction<number>>
  hitsPerPage: number
  setHitsPerPage: React.Dispatch<React.SetStateAction<number>>
  facets?: Record<string, Record<string, number>>
}

export const ApiSearchContext = createContext<IApiSearchContext>({
  loading: false,
  hits: [],
  nbHits: 0,
  page: 1,
  setPage: () => {},
  nbPages: 1,
  setNbPages: () => {},
  hitsPerPage: DEFAULT_HITS_PER_PAGE,
  setHitsPerPage: () => {},
})

export const useApiSearch = (): IApiSearchContext =>
  useContext(ApiSearchContext)
