// https://ant.design/components/dropdown/

import { Dropdown as AntdDropdown } from 'antd'
import { DropDownProps as AntdDropdownProps } from 'antd/lib/dropdown'
import React, { ReactNode } from 'react'

export interface DropdownProps extends AntdDropdownProps {
  children?: ReactNode
}

const Dropdown = ({ children, ...rest }: DropdownProps) => {
  return <AntdDropdown {...rest}>{children}</AntdDropdown>
}

export default React.memo(Dropdown)
