import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { EndpointGraphql } from 'config/endpointGraphql'
import { GET_SECURITY_NAME } from 'hooks/securities/useSecurityName/GET_SECURITY_NAME'

export const useSecurityName = (securityId?: string) => {
  const result = useQuery<{ findSecurityById: { name: string } }>(
    GET_SECURITY_NAME,
    {
      variables: { securityId },
      skip: !securityId,
      context: { endpoint: EndpointGraphql.WUNDERGRAPH },
    }
  )

  return useMemo(
    () => ({
      ...result,
      name: result?.data?.findSecurityById?.name,
    }),
    [result]
  )
}
