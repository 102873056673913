import { gql } from '@apollo/client'

export const GET_ME_LANG = gql`
  query GET_ME_LANG {
    me {
      id
      lang
    }
  }
`
