import { gql } from '@apollo/client'

export const RUN_NEXT = gql`
  mutation RUN_NEXT($id: ID!, $stateKey: String!, $payload: JSON!) {
    workflow(id: $id) {
      next(payload: $payload, stateKey: $stateKey) {
        ... on Workflow {
          id
          slug
          data
          stateKey
          canGoBack
          updatedEntities {
            id
            typename
          }
          currentStep {
            slug
            payload
          }
          progress {
            current
            steps
          }
        }
        ... on FinishedWorkflow {
          data
          updatedEntities {
            id
            typename
          }
        }
        ... on AsyncWorkflow {
          id
          lastStep
        }
      }
    }
  }
`
