import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import Tag from 'components/atoms/Tag'
import { CorporationType } from 'config/corporation'
import { useCorporationInfoType } from 'hooks/corporationInfo/useCorporationInfoType'
import eqDSTheme from 'styles/eqDSTheme'

export const changeType = (type: CorporationType) => {
  if (type === CorporationType.CORPORATION) {
    return CorporationType.INVESTMENT_VEHICLE
  }

  return CorporationType.CORPORATION
}

const CorporationInfoType = ({
  corporationInfoId,
  showNext = false,
  fallbackValue,
}: {
  corporationInfoId?: string
  showNext?: boolean
  fallbackValue?: string
}) => {
  const { type, loading } = useCorporationInfoType(corporationInfoId)

  const corporationInfoType = showNext && type ? changeType(type) : type

  const color = {
    [CorporationType.CORPORATION]: eqDSTheme.color.palette.red100,
    [CorporationType.INVESTMENT_VEHICLE]: eqDSTheme.color.palette.purple100,
  }[corporationInfoType || CorporationType.CORPORATION]

  return (
    <SkeletonText loading={loading}>
      {corporationInfoType ? (
        <Tag color={color} message={`holderTypes.${corporationInfoType}`} />
      ) : (
        fallbackValue
      )}
    </SkeletonText>
  )
}

export default React.memo(CorporationInfoType)
