import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBalanceScale,
  faBuilding,
  faGavel,
  faSignature,
  faPhoneSlash,
  faUnlink,
  faBallPile,
  faTicketAlt,
  faEye,
  faCommentLines,
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faBalanceScale,
  faBuilding,
  faGavel,
  faSignature,
  faPhoneSlash,
  faUnlink,
  faBallPile,
  faTicketAlt,
  faEye,
  faCommentLines
)
