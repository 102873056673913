import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_EVENT_DATE } from 'hooks/events/useEventDate/GET_EVENT_DATE'

export const useEventDate = (eventId?: string) => {
  const result = useQuery<{ event: { date: string } }>(GET_EVENT_DATE, {
    variables: { eventId },
    skip: !eventId,
  })

  return useMemo(
    () => ({
      ...result,
      date: result?.data?.event?.date,
    }),
    [result]
  )
}
