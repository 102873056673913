import { gql } from '@apollo/client'

export const GET_AGGREGATED_TRANSACTION_SECURITY_IDS = gql`
  query GET_AGGREGATED_TRANSACTION_SECURITY_IDS($aggregatedTransactionId: ID!) {
    aggregatedTransaction(id: $aggregatedTransactionId) {
      id
      label
      fromSecurity {
        id
      }
      toSecurity {
        id
      }
      fromPortfolio {
        id
        name
      }
      toPortfolio {
        id
        name
      }
    }
  }
`
