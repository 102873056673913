import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { EndpointGraphql } from 'config/endpointGraphql'
import { GET_SECURITY_VESTING_SCHEDULE_ID } from 'hooks/securities/useSecurityVestingSchedule/GET_SECURITY_VESTING_SCHEDULE_ID'

export const useSecurityVestingScheduleId = (securityId?: string) => {
  const result = useQuery<{
    findSecurityById: {
      vestingId: string
    }
  }>(GET_SECURITY_VESTING_SCHEDULE_ID, {
    variables: { securityId },
    skip: !securityId,
    context: { endpoint: EndpointGraphql.WUNDERGRAPH },
  })

  return useMemo(
    () => ({
      ...result,
      vestingScheduleId: result?.data?.findSecurityById?.vestingId,
    }),
    [result]
  )
}
