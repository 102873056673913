import { LoadingOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { RcFile } from 'antd/lib/upload'
import prettyBytes from 'pretty-bytes'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'

import Space from 'components/atoms/Space'
import { useUpdateCache } from 'hooks/useUpdateCache'
import {
  CreateFile,
  FilesUploadContext,
  IFilesUploadContext,
  PARALLEL_DOWNLOADS,
  QueueElement,
  UploadStatus,
} from 'providers/FilesUpload'
import { CREATE_FILES } from 'providers/FilesUpload/graphql/mutations/CREATE_FILES'
import { FILES_MARK_AS_UPLOADED } from 'providers/FilesUpload/graphql/mutations/FILES_MARK_AS_UPLOADED'
import { INDEX_FILES } from 'providers/FilesUpload/graphql/mutations/INDEX_FILES'
import { useNotification } from 'providers/Notification'
import { x } from 'utils/emotion'

import { Env, NODE_ENV } from '../../config/env'

const getNotificationContent = (file: RcFile) => ({
  key: file.uid,
  description: (
    <Space>
      <x.div maxWidth="200px" color="grey">
        {file.name}
      </x.div>
      <x.div fontSize="small" color="darkgrey">
        {prettyBytes(file.size)}
      </x.div>
    </Space>
  ),
})

const setStatus = (id: string, status: UploadStatus) => (old: QueueElement[]) =>
  old.map((element) => (element.id === id ? { ...element, status } : element))

export const FilesUploadProvider = ({ children }: { children: ReactNode }) => {
  const updateCache = useUpdateCache()

  const [createFiles] = useMutation(CREATE_FILES)
  const [indexFiles] = useMutation(INDEX_FILES)
  const [filesMarkAsUploaded] = useMutation(FILES_MARK_AS_UPLOADED)
  const { openNotification, successNotification, errorNotification } =
    useNotification()

  const [queue, setQueue] = useState<QueueElement[]>([])

  useEffect(() => {
    queue
      .filter(({ status }) =>
        [UploadStatus.PENDING, UploadStatus.UPLOADING].includes(status)
      )
      .forEach(({ id, aws, file, status }, index) => {
        if (index < PARALLEL_DOWNLOADS && status === UploadStatus.PENDING) {
          setQueue(setStatus(id, UploadStatus.UPLOADING))

          openNotification({
            message: 'word.uploadingWithDots',
            duration: 0,
            icon: <LoadingOutlined />,
            ...getNotificationContent(file),
          })

          const form = new FormData()

          Object.entries(aws.fields).forEach(([key, value]) => {
            form.append(key, value as string)
          })

          form.append('file', file)

          const S3: {
            url: string
            method: string
            body: string | File | FormData | null
          } = {
            url:
              NODE_ENV === Env.DEV ? `${aws.url}/${aws.fields.key}` : aws.url,
            method: NODE_ENV === Env.DEV ? 'PUT' : 'POST',
            body: NODE_ENV === Env.DEV ? form.get('file') : form,
          }

          fetch(S3.url, {
            method: S3.method,
            body: S3.body,
          })
            .then(async (res) => {
              if (res.status !== 200) {
                throw new Error('Error while uploading to AWS S3')
              }
              return filesMarkAsUploaded({
                variables: { ids: [id] },
              }).then(() => {
                indexFiles({
                  variables: {
                    fileId: id,
                  },
                })
                setQueue(setStatus(id, UploadStatus.UPLOADED))
                successNotification({
                  message: 'word.documentSuccessfullyUploaded',
                  ...getNotificationContent(file),
                })
                updateCache([{ typename: 'File' }])
              })
            })
            .catch((error) => {
              console.error(error)
              setQueue(setStatus(id, UploadStatus.ERROR))
              errorNotification({
                message: 'error.default',
                ...getNotificationContent(file),
              })
            })
        }
      })
  }, [queue])

  const context = useMemo(
    (): IFilesUploadContext => ({
      queue,
      uploadFiles: ({ corporationId, files }) => {
        files.forEach((file) => {
          openNotification({
            message: 'word.preparingForUpload',
            icon: <LoadingOutlined />,
            duration: 0,
            ...getNotificationContent(file),
          })
        })
        return createFiles({
          variables: {
            corporationId,
            fileNames: files.map((file) => file.name),
          },
        })
          .then(({ data }) => {
            setQueue((old) => [
              ...old,
              ...data.createFiles.map(
                (createFile: CreateFile, index: number) => {
                  return {
                    ...createFile,
                    file: files[index],
                    status: UploadStatus.PENDING,
                  }
                }
              ),
            ])
            return data.createFiles.map(({ id }: CreateFile) => id)
          })
          .catch((error) => {
            console.error(error)
            files.forEach((file) => {
              errorNotification({
                message: 'error.default',
                ...getNotificationContent(file),
              })
            })
          })
      },
    }),
    [queue]
  )

  return (
    <FilesUploadContext.Provider value={context}>
      {children}
    </FilesUploadContext.Provider>
  )
}
