import React, { ElementType, ReactElement, useMemo } from 'react'

import Select, { SelectProps } from 'components/atoms/Form/Select'
import Tag from 'components/atoms/Tag'
import { ONE_SECOND } from 'config/date'
import { DEFAULT_HITS_PER_PAGE, useApiSearch } from 'providers/ApiSearch'
import {
  ApiSearchFiltersProvider,
  ApiSearchFiltersProviderProps,
  useApiSearchFilters,
} from 'providers/ApiSearchFilters'
import { castArray } from 'utils'

interface ApiSearchSelectContentProps extends Omit<SelectProps, 'options'> {
  renderItem: (id: string) => ReactElement | string
}

export interface ApiSearchSelectProps extends ApiSearchSelectContentProps {
  apiKey?: ElementType
  apiKeyProps?: Record<string, any>
  filtersProps?: Omit<ApiSearchFiltersProviderProps, 'children'>
}

const Content = React.memo(
  ({ renderItem, onChange, value, ...rest }: ApiSearchSelectContentProps) => {
    const { hits, loading, nbHits, setHitsPerPage } = useApiSearch()
    const { search, setSearch } = useApiSearchFilters()

    const selectedItems = search
      ? []
      : castArray(value as string | string[])
          .filter((v) => v && !hits.find(({ id }) => id === v))
          .map((v) => ({ label: renderItem(v), value: v }))

    const itemsWithoutCategories = hits.map((hit) => ({
      label: renderItem(hit.id),
      value: hit.id,
    }))

    const categories: {
      [key: string]: {
        label: string
        options: { label: ReactElement | string; value: string }[]
      }
    } = {}

    hits.forEach((hit) => {
      if (hit.apiSearchSelectCategory) {
        if (!categories[hit.apiSearchSelectCategory]) {
          categories[hit.apiSearchSelectCategory] = {
            label: 'apiSearchSelectCategory.' + hit.apiSearchSelectCategory,
            options: [],
          }
        }
        categories[hit.apiSearchSelectCategory].options.push({
          label: renderItem(hit.id),
          value: hit.id,
        })
      }
    })

    const categorizedHits = Object.values(categories)

    const itemsToAdd = hits.some((hit) => hit.apiSearchSelectCategory)
      ? categorizedHits
      : itemsWithoutCategories

    const options = useMemo(
      () => [
        ...selectedItems,
        ...itemsToAdd,
        // loading more items if necessary
        nbHits > hits.length
          ? {
              disabled: true,
              label: 'word.loadingWithDots',
              value: 1,
            }
          : undefined,
      ],
      [renderItem, value, hits, nbHits, search]
    )

    return (
      <Select
        value={value}
        tagRender={({ value, closable, onClose }) => (
          <Tag closable={closable} onClose={onClose}>
            {renderItem(value as string)}
          </Tag>
        )}
        showSearch
        loading={loading}
        options={options}
        filterOption={false}
        searchValue={search ?? undefined}
        onSearch={setSearch}
        onChange={(...args) => {
          if (onChange) {
            onChange(...args)
          }
          setSearch(null)
        }}
        onPopupScroll={(event) => {
          const target = event.target as HTMLSelectElement
          if (
            !loading &&
            nbHits > hits.length &&
            target.scrollTop + target.offsetHeight === target.scrollHeight
          ) {
            target.scrollTo(0, target.scrollHeight)
            setTimeout(() => {
              setHitsPerPage(
                (hitsPerPage) => hitsPerPage + DEFAULT_HITS_PER_PAGE
              )
            }, ONE_SECOND)
          }
        }}
        {...rest}
      />
    )
  }
)

const ApiSearchSelect = ({
  apiKey,
  apiKeyProps = {},
  filtersProps = {},
  ...rest
}: ApiSearchSelectProps) => {
  if (apiKey) {
    const ApiKey = apiKey

    return (
      <ApiSearchFiltersProvider {...filtersProps}>
        <ApiKey {...apiKeyProps}>
          <Content {...rest} />
        </ApiKey>
      </ApiSearchFiltersProvider>
    )
  }

  return <Content {...rest} />
}

export default React.memo(ApiSearchSelect)
