import { message } from 'antd'
import { MessageType as AntdMessageType } from 'antd/es/message/interface'
import { ArgsProps } from 'antd/lib/message'
import React, { useContext } from 'react'

import { MessageType } from 'components/atoms/Intl/Message'

export interface MessageConfigType extends ArgsProps {
  content: MessageType
}

export interface IMessageContext {
  infoMessage: (
    params: MessageType | MessageConfigType
  ) => AntdMessageType | void
  successMessage: (
    params: MessageType | MessageConfigType
  ) => AntdMessageType | void
  errorMessage: (
    params: MessageType | MessageConfigType
  ) => AntdMessageType | void
  warningMessage: (
    params: MessageType | MessageConfigType
  ) => AntdMessageType | void
  loadingMessage: (
    params: MessageType | MessageConfigType
  ) => AntdMessageType | void
  destroyMessage: (key?: string) => void
}

export const MessageContext = React.createContext<IMessageContext>({
  infoMessage: () => {},
  successMessage: () => {},
  errorMessage: () => {},
  warningMessage: () => {},
  loadingMessage: () => {},
  destroyMessage: (key?: string) => message.destroy(key),
})

export const useMessage = (): IMessageContext => useContext(MessageContext)
