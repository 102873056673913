// List of all the objects that doesn't provide any uniquely identifying fields. See below for more information:
// https://www.apollographql.com/docs/react/caching/cache-field-behavior/#configuring-merge-functions-for-types-rather-than-fields

export const MERGED_LEGACY_TYPES = [
  'AdminCorporations',
  'AdminLawFirms',
  'AggregatedTransactionFiles',
  'Contact',
  'CorporationAggregatedTransactions',
  'CorporationContacts',
  'CorporationCorporationInfo',
  'CorporationEvents',
  'CorporationFiles',
  'CorporationGovernanceGroups',
  'CorporationMeetings',
  'CorporationPools',
  'CorporationPortfolioRelations',
  'CorporationPortfolios',
  'CorporationSecurities',
  'CorporationUserRoles',
  'CorporationWallets',
  'EventAggregatedTransactions',
  'EventFiles',
  'FileAggregatedTransactions',
  'FileGovernanceGroups',
  'FileMeetings',
  'FilePools',
  'FilePortfolios',
  'FileSecurities',
  'FilesOps',
  'GovernanceGroupFiles',
  'GovernanceGroupMeetings',
  'GovernanceGroupObservers',
  'GovernanceGroupSecurities',
  'GovernanceGroupVoters',
  'LawFirmContacts',
  'LawFirmCorporations',
  'LawFirmUserRoles',
  'MeetingAttendees',
  'MeetingFiles',
  'MeProcedure',
  'PoolAggregatedTransactions',
  'PoolFiles',
  'PoolWallets',
  'PortfolioAggregatedTransactions',
  'PortfolioAggregatedWallets',
  'PortfolioEvents',
  'PortfolioFiles',
  'PortfolioGovernanceGroups',
  'PortfolioMeetings',
  'PortfolioPortfolioContacts',
  'PortfolioRelationPortfolios',
  'PortfolioRelationWallets',
  'PortfolioWallets',
  'Security',
  'SecurityAggregatedTransactions',
  'SecurityAggregatedWallets',
  'SecurityFiles',
  'SecurityWallets',
  'SmartFieldsData',
  'User',
  'Wallet',
  'WorkflowOps',
]

export const MERGED_NEST_TYPES = ['SecurityNest']
