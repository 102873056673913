import React from 'react'

import { useFormatMessage } from 'components/atoms/Intl/Message'
import SkeletonText from 'components/atoms/Skeleton/Text'
import { useFileLocale } from 'hooks/files/useFileLocale'

const FileLocale = ({ fileId }: { fileId: string }) => {
  const { locale, loading } = useFileLocale(fileId)
  const formatMessage = useFormatMessage()

  return (
    <SkeletonText loading={loading}>
      {formatMessage(`locale.${locale}.name`)}
    </SkeletonText>
  )
}

export default React.memo(FileLocale)
