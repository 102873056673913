import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_CONTACT_EMAIL } from 'hooks/contacts/useContactEmail/GET_CONTACT_EMAIL'

export const useContactEmail = (contactId?: string) => {
  const result = useQuery<{
    contact: {
      email: string | null
    }
  }>(GET_CONTACT_EMAIL, {
    variables: { contactId },
    skip: !contactId,
  })

  return useMemo(
    () => ({
      ...result,
      email: result?.data?.contact?.email,
    }),
    [result]
  )
}
