import React from 'react'

import Steps from 'components/atoms/Steps'
import { useWorkflow } from 'providers/Workflows'

export const WorkflowSteps = () => {
  const { progress } = useWorkflow()

  if (!progress) {
    return null
  }

  return (
    <Steps
      size="small"
      labelPlacement="vertical"
      responsive
      current={progress.current ?? undefined}
      items={progress.steps.map((step) => ({
        title: `workflow.steps.${step}`,
      }))}
    />
  )
}
