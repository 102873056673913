import React from 'react'

import Panel from 'components/atoms/Collapse/Panel'
import FileEventName from 'components/molecules/File/EventName'
import { FilesEditModalSection } from 'providers/FilesEdit'
import { Events } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Events'

const Event = ({
  selectedIds,
  fileId,
  sections,
  corporationId,
  ...rest
}: {
  corporationId: string
  fileId?: string
  selectedIds: string[]
  sections: FilesEditModalSection[]
}) => {
  if (!sections.includes(FilesEditModalSection.EVENT)) {
    return <Panel key="event" header="word.event" collapsible="disabled" />
  }

  return (
    <Panel
      {...rest}
      key="event"
      header="word.event"
      extra={fileId ? <FileEventName fileId={fileId} /> : undefined}
      message={
        <Events selectedIds={selectedIds} corporationId={corporationId} />
      }
    />
  )
}

export default React.memo(Event)
