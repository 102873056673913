import React from 'react'

import Message from 'components/atoms/Intl/Message'
import Modal, { ModalProps } from 'components/atoms/Modal'
import { useStartWorkflow } from 'providers/Workflows/hooks/useStartWorkflow'

const ModalReSendSignatureEmail = ({
  fileIds,
  onCancel,
  ...rest
}: ModalProps & { fileIds: string[] }) => {
  const [reSendSignatureEmail, loading] = useStartWorkflow({
    data: { fileIds },
    slug: 'reSendSignatureEmail',
    notification: {
      type: 'success',
      message: 'modal.reSendSignatureEmail.success',
    },
  })

  return (
    <Modal
      centered
      title="word.resendSignatureEmail"
      okText="word.send"
      okButtonProps={{ loading }}
      confirmLoading
      onOk={(event) => {
        reSendSignatureEmail()
        if (onCancel) {
          onCancel(event)
        }
      }}
      onCancel={onCancel}
      {...rest}
    >
      <Message value="modal.reSendSignatureEmail.content" />
    </Modal>
  )
}

export default ModalReSendSignatureEmail
