export enum HomeViewEnum {
  CORPORATION = 'corporations',
  LAWFIRM = 'law-firms',
  PORTFOLIO = 'portfolios',
}

export const isHomeViewType = (viewType: HomeViewEnum): boolean => {
  const pathName = window.location.pathname
  const pathArray = pathName.split('/')
  const location = pathArray[1]

  return location === viewType
}
