import { gql } from '@apollo/client'

export const GET_PROCEDURE_SIGN_URL = gql`
  query GET_PROCEDURE_SIGN_URL($procedureId: ID!) {
    procedure(id: $procedureId) {
      id
      me {
        signUrl {
          url
          provider
        }
      }
    }
  }
`
