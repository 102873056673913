import React from 'react'

import Panel from 'components/atoms/Collapse/Panel'
import FileLocale from 'components/molecules/File/Locale'
import FileLocaleIcon from 'components/molecules/File/LocaleIcon'
import { useFileLocale } from 'hooks/files/useFileLocale'
import { FilesEditModalSection } from 'providers/FilesEdit'
import { Locale } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Locale'
import { x } from 'utils/emotion'

const Language = ({
  selectedIds,
  fileId,
  sections,
  ...rest
}: {
  fileId?: string
  selectedIds: string[]
  sections: FilesEditModalSection[]
}) => {
  const { locale } = useFileLocale(fileId)

  if (!sections.includes(FilesEditModalSection.LANGUAGE) || !locale) {
    return (
      <Panel key="language" header="word.language" collapsible="disabled" />
    )
  }

  return (
    <Panel
      {...rest}
      key="language"
      header="word.language"
      extra={
        fileId && locale ? (
          <>
            <x.span mr={5}>
              <FileLocaleIcon fileId={fileId} />
            </x.span>
            <FileLocale fileId={fileId} />
          </>
        ) : undefined
      }
      message={<Locale selectedIds={selectedIds} />}
    />
  )
}

export default React.memo(Language)
