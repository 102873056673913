import { MoreOutlined } from '@ant-design/icons'
import React from 'react'

import Button, { ButtonProps } from 'components/atoms/Button'
import Dropdown from 'components/atoms/Dropdown'

export type ButtonMoreActionsProps = ButtonProps & {
  overlay: React.ReactElement
}

const ButtonMoreActions = ({
  overlay,
  disabled,
  ...rest
}: ButtonMoreActionsProps) => {
  if (disabled) {
    return (
      <Button
        icon={<MoreOutlined />}
        shape="circle"
        type="text"
        disabled
        {...rest}
      />
    )
  }

  return (
    <Dropdown
      placement="bottomRight"
      trigger={['click']}
      dropdownRender={() => overlay}
    >
      <Button
        icon={<MoreOutlined />}
        shape="circle"
        type="text"
        onClick={(event: any) => {
          event.stopPropagation()
          event.preventDefault()
        }}
        {...rest}
      />
    </Dropdown>
  )
}

export default React.memo(ButtonMoreActions)
