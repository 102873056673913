import React, { Dispatch, SetStateAction, useContext } from 'react'

import { DEFAULT_LOCALE, Locales } from 'config/locale'

interface ILocaleContext {
  locale: Locales
  setLocale: Dispatch<SetStateAction<Locales>>
  formattedLocale: string
}

export const LocaleContext = React.createContext<ILocaleContext>({
  locale: DEFAULT_LOCALE,
  setLocale: () => {},
  formattedLocale: '',
})

export const useLocale = (): ILocaleContext => useContext(LocaleContext)
