import { useQuery } from '@apollo/client'
import React from 'react'

import SkeletonAvatar from 'components/atoms/Skeleton/Avatar'
import { GET_CONTACT_AVATAR } from 'components/molecules/Avatar/Contact/GET_CONTACT_AVATAR'
import Avatar, { AvatarProps } from 'stories/components/atoms/Avatar/Avatar'

const AvatarContact = ({
  contactId,
  ...rest
}: AvatarProps & {
  contactId?: string
}) => {
  const { data, loading } = useQuery(GET_CONTACT_AVATAR, {
    variables: { contactId },
  })

  const name = data?.contact?.name || data?.contact?.email

  const initials = (
    data?.contact?.firstName || data?.contact?.lastName
      ? (data?.contact?.firstName?.charAt(0) || '') +
        (data?.contact?.lastName?.charAt(0) || '')
      : data?.contact?.email?.charAt(0)
  )?.toUpperCase()

  return (
    <SkeletonAvatar loading={loading} {...rest}>
      <Avatar
        shape="circle"
        src={data?.contact?.profilePicture}
        nameforbackgroundcolor={name}
        {...rest}
      >
        {initials}
      </Avatar>
    </SkeletonAvatar>
  )
}

export type AvatarContactType = {
  name?: string
  firstName?: string
  lastName?: string
  email?: string
  profilePicture?: string
}

export const AvatarContactStatic = ({
  contact,
  ...rest
}: AvatarProps & {
  contact: AvatarContactType
}) => {
  const name = contact.name || contact.email

  const initials = (
    contact.firstName || contact.lastName
      ? (contact.firstName?.charAt(0) || '') +
        (contact.lastName?.charAt(0) || '')
      : contact.email?.charAt(0)
  )?.toUpperCase()

  return (
    <Avatar
      shape="circle"
      src={contact.profilePicture}
      nameforbackgroundcolor={name}
      {...rest}
    >
      {initials}
    </Avatar>
  )
}

export default React.memo(AvatarContact)
