import {
  CheckOutlined,
  CloseOutlined,
  EyeOutlined,
  RightOutlined,
} from '@ant-design/icons'
import React, { useMemo, useState } from 'react'

import Button from 'components/atoms/Button'
import Dropdown from 'components/atoms/Dropdown'
import Menu from 'components/atoms/Menu'
import Tooltip from 'components/atoms/Tooltip'
import {
  CORPORATION_ROLES_PERMISSIONS,
  CorporationPermissions,
  CorporationRoles,
  LAW_FIRM_ROLES_PERMISSIONS,
  LawFirmPermissions,
  LawFirmRoles,
  PORTFOLIO_ROLES_PERMISSIONS,
  PermissionsType,
  PortfolioPermissions,
  PortfolioRoles,
} from 'config/roles'
import { WORKSPACE } from 'config/workspace'
import { useIsLaptopWindowSize } from 'hooks/useWindowSize'
import { useWorkspace } from 'hooks/useWorkspace'
import { usePermissions } from 'providers/Permissions'
import { cleanArray } from 'utils'
import {
  getCorporationUserRoleFromPermissions,
  getLawFirmUserRoleFromPermissions,
  getPortfolioUserRoleFromPermissions,
} from 'utils/roles'

export const Permissions = () => {
  const { workspace } = useWorkspace()
  const isLaptopWindowSize = useIsLaptopWindowSize()
  const { checkPermissions, permissions, setPermissions } = usePermissions()

  const [visible, setVisible] = useState(false)

  const workspaceAllPermissions = useMemo((): PermissionsType[] | undefined => {
    switch (workspace) {
      case WORKSPACE.CORPORATION:
        return Object.keys(CorporationPermissions) as CorporationPermissions[]
      case WORKSPACE.LAW_FIRM:
        return Object.keys(LawFirmPermissions) as LawFirmPermissions[]
      case WORKSPACE.PORTFOLIO:
        return Object.keys(PortfolioPermissions) as PortfolioPermissions[]
    }
  }, [workspace])

  const workspaceAllRoles = useMemo(() => {
    switch (workspace) {
      case WORKSPACE.CORPORATION:
        return [
          {
            icon: <RightOutlined />,
            key: CorporationRoles.CORPORATION_OWNER,
            message: `userRoles.${CorporationRoles.CORPORATION_OWNER}`,
            onClick: () =>
              setPermissions(CORPORATION_ROLES_PERMISSIONS.CORPORATION_OWNER),
          },
          {
            icon: <RightOutlined />,
            key: CorporationRoles.EDITOR,
            message: `userRoles.${CorporationRoles.EDITOR}`,
            onClick: () => setPermissions(CORPORATION_ROLES_PERMISSIONS.EDITOR),
          },
          {
            icon: <RightOutlined />,
            key: CorporationRoles.VIEWER_ONLY,
            message: `userRoles.${CorporationRoles.VIEWER_ONLY}`,
            onClick: () =>
              setPermissions(CORPORATION_ROLES_PERMISSIONS.VIEWER_ONLY),
          },
        ]
      case WORKSPACE.LAW_FIRM:
        return [
          {
            icon: <RightOutlined />,
            key: LawFirmRoles.EDITOR,
            message: `userRoles.${LawFirmRoles.EDITOR}`,
            onClick: () => setPermissions(LAW_FIRM_ROLES_PERMISSIONS.EDITOR),
          },
          {
            icon: <RightOutlined />,
            key: LawFirmRoles.VIEWER_ONLY,
            message: `userRoles.${LawFirmRoles.VIEWER_ONLY}`,
            onClick: () =>
              setPermissions(LAW_FIRM_ROLES_PERMISSIONS.VIEWER_ONLY),
          },
        ]
      case WORKSPACE.PORTFOLIO:
        return [
          {
            icon: <RightOutlined />,
            key: PortfolioRoles.STANDARD,
            message: `userRoles.${PortfolioRoles.STANDARD}`,
            onClick: () => setPermissions(PORTFOLIO_ROLES_PERMISSIONS.STANDARD),
          },
        ]
      default:
        return []
    }
  }, [workspace, permissions])

  const workspaceUserCurrentRole = useMemo(() => {
    switch (workspace) {
      case WORKSPACE.LAW_FIRM:
        return getLawFirmUserRoleFromPermissions(
          permissions as LawFirmPermissions[]
        )
      case WORKSPACE.CORPORATION:
        return getCorporationUserRoleFromPermissions(
          permissions as CorporationPermissions[]
        )
      case WORKSPACE.PORTFOLIO:
        return getPortfolioUserRoleFromPermissions(
          permissions as PortfolioPermissions[]
        )
    }
  }, [workspace, permissions])

  if (!workspaceAllPermissions || workspaceAllPermissions.length < 1) {
    return null
  }

  return (
    <Dropdown
      key="permissions"
      open={visible}
      onOpenChange={setVisible}
      trigger={['click']}
      dropdownRender={() => (
        <Menu
          selectedKeys={cleanArray([
            workspaceUserCurrentRole?.[0],
            ...workspaceAllPermissions.filter(checkPermissions),
          ])}
          menu={[
            {
              icon: <RightOutlined />,
              key: 'clearAllPermissions',
              message: 'Clear all permissions',
              disabled: permissions.length === 0,
              onClick: () => setPermissions([]),
            },
            {
              key: 'permissions',
              title: 'Permissions',
              group: workspaceAllPermissions.map(
                (permission: PermissionsType) => ({
                  icon: checkPermissions(permission) ? (
                    <CheckOutlined />
                  ) : (
                    <CloseOutlined />
                  ),
                  key: permission,
                  message: `userRoles.${permission}`,
                  onClick: () => {
                    if (checkPermissions(permission)) {
                      setPermissions(
                        permissions.filter((p) => p !== permission)
                      )
                    } else {
                      setPermissions([...permissions, permission])
                    }
                  },
                })
              ),
            },
            workspaceAllRoles.length
              ? {
                  key: 'roles',
                  title: 'Roles',
                  group: workspaceAllRoles,
                }
              : undefined,
          ]}
        />
      )}
    >
      <Tooltip title={isLaptopWindowSize ? undefined : 'Permissions'}>
        <Button
          icon={<EyeOutlined />}
          message={isLaptopWindowSize ? 'Permissions' : undefined}
        />
      </Tooltip>
    </Dropdown>
  )
}
