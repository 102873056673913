import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_SECURITY_CORPORATION_ID } from 'hooks/securities/useSecurityCorporationId/GET_SECURITY_CORPORATION_ID'

export const useSecurityCorporationId = (securityId?: string) => {
  const result = useQuery<{
    security: { corporation: { id: string } }
  }>(GET_SECURITY_CORPORATION_ID, {
    variables: { securityId },
    skip: !securityId,
  })

  return useMemo(
    () => ({
      ...result,
      corporationId: result?.data?.security?.corporation?.id,
    }),
    [result]
  )
}
