import { gql } from '@apollo/client'

export const GET_POOL_CORPORATION_ID = gql`
  query GET_POOL_CORPORATION_ID($poolId: ID!) {
    pool(id: $poolId) {
      id
      corporation {
        id
      }
    }
  }
`
