import React, { Dispatch, SetStateAction, useContext } from 'react'

interface IGraphQLContext {
  headers: Record<string, boolean>
  setHeaders: Dispatch<SetStateAction<Record<string, boolean>>>
}

export const GraphQLContext = React.createContext<IGraphQLContext>({
  headers: {},
  setHeaders: () => {},
})

export const useGraphQL = () => useContext(GraphQLContext)
