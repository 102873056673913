import { useMutation, useQuery } from '@apollo/client'
import { uniq } from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import ApiSearchSelectCorporationEvents from 'components/molecules/ApiSearchSelect/CorporationEvents'
import { ONE_SECOND } from 'config/date'
import { FILES_LINK_EVENTS } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Events/FILES_LINK_EVENTS'
import { GET_SELECTED_FILES_EVENTS } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Events/GET_SELECTED_FILES_EVENTS'

export const Events = ({
  selectedIds,
  corporationId,
}: {
  selectedIds: string[]
  corporationId: string
}) => {
  const [value, setValue] = useState<string | undefined>(undefined)
  const [changeCount, setChangeCount] = useState(0)
  const [debouncedChangeCount] = useDebounce(changeCount, ONE_SECOND)
  const [link] = useMutation(FILES_LINK_EVENTS)

  const { data, loading } = useQuery<{ files: { event?: { id: string } }[] }>(
    GET_SELECTED_FILES_EVENTS,
    {
      variables: { ids: selectedIds },
    }
  )

  useEffect(() => {
    if (debouncedChangeCount) {
      link({
        variables: {
          ids: selectedIds,
          eventIds: value ? [value] : [],
        },
      })
    }
  }, [debouncedChangeCount])

  useEffect(() => {
    const values: any[] = uniq(
      (data?.files || []).map((file) => file.event?.id)
    )
    if (values.length === 1) {
      setValue(values[0])
    } else {
      setValue(undefined)
    }
  }, [data])

  return (
    <ApiSearchSelectCorporationEvents
      loading={loading}
      corporationId={corporationId}
      allowClear
      value={value}
      onChange={(newValue) => {
        setValue(newValue)
        setChangeCount((old) => old + 1)
      }}
    />
  )
}
