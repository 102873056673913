import React, { ReactNode, useMemo, useState } from 'react'

import Modal from 'components/atoms/Modal'
import PDFPreviewUrl from 'components/atoms/PDF/Preview/PDFPreviewUrl'
import { useAuth } from 'providers/Auth'
import { FilePreviewUrlContext } from 'providers/FilePreviewUrl/FilePreviewUrlContext'

export const FilePreviewUrlProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [fileUrl, setFileUrl] = useState<string | undefined>()
  const { isAuth } = useAuth()

  const context = useMemo(
    () => ({
      filePreviewUrlIsOpen: Boolean(fileUrl),
      openFilePreviewUrl: setFileUrl,
    }),
    [fileUrl]
  )

  return (
    <FilePreviewUrlContext.Provider value={context}>
      {children}
      {isAuth && (
        <Modal
          open={Boolean(fileUrl)}
          footer={null}
          bodyStyle={{
            padding: '0px',
            height: '80vh',
            borderRadius: '4px',
            overflow: 'hidden',
            display: 'flex',
            backgroundColor: '#525659',
          }}
          width="80%"
          closable={false}
          destroyOnClose
          onCancel={() => {
            setFileUrl(undefined)
          }}
          zIndex={1100}
          style={{ maxWidth: '1000px' }}
        >
          {fileUrl ? <PDFPreviewUrl fileUrl={fileUrl} /> : null}
        </Modal>
      )}
    </FilePreviewUrlContext.Provider>
  )
}
