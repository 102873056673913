import { gql } from '@apollo/client'

export const GET_AGGREGATED_TRANSACTION_NAME = gql`
  query GET_AGGREGATED_TRANSACTION_NAME($aggregatedTransactionId: ID!) {
    aggregatedTransaction(id: $aggregatedTransactionId) {
      id
      date
      label
      fromQuantity
      toQuantity
      fromSecurity {
        id
        name
      }
      toSecurity {
        id
        name
      }
      fromPortfolio {
        id
        name
      }
      toPortfolio {
        id
        name
      }
    }
  }
`
