import { gql } from '@apollo/client'

export const GET_ME_ROLES = gql`
  query GET_ME_ROLES {
    me {
      id
      lawFirmRoles {
        id
        lawFirm {
          id
        }
      }
      corporationRoles(includeIndirect: true) {
        id
        corporation {
          id
        }
      }
      portfolioRoles {
        id
        portfolio {
          id
          corporation {
            id
          }
        }
      }
    }
  }
`
