import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_AGGREGATED_WALLET_SECURITY_ID } from 'hooks/aggregatedWallets/useAggregatedWalletSecurityId/GET_AGGREGATED_WALLET_SECURITY_ID'

export const useAggregatedWalletSecurityId = (aggregatedWalletId?: string) => {
  const result = useQuery<{
    aggregatedWallet: {
      security: {
        id: string
      }
    }
  }>(GET_AGGREGATED_WALLET_SECURITY_ID, {
    variables: { aggregatedWalletId },
    skip: !aggregatedWalletId,
  })

  return useMemo(
    () => ({
      ...result,
      securityId: result?.data?.aggregatedWallet?.security?.id,
    }),
    [result]
  )
}
