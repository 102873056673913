import { useMutation, useQuery } from '@apollo/client'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'

import { LocalStorage } from 'config/localStorage'
import { DEFAULT_LOCALE, languageDefaultLocale, Locales } from 'config/locale'
import { useAuth } from 'providers/Auth'
import { LocaleContext } from 'providers/Locale/LocaleContext'
import { UPDATE_LANGUAGE } from 'providers/Locale/graphql/mutations/UPDATE_LANGUAGE'
import { GET_ME_LANG } from 'providers/Locale/graphql/queries/GET_ME_LANG'
import { formatLocale } from 'providers/Locale/utils'
import { sanitize } from 'utils'

export const LocaleProvider = ({ children }: { children: ReactNode }) => {
  const { isAuth } = useAuth()
  const [updateLanguage] = useMutation(UPDATE_LANGUAGE)
  const { data, loading, refetch } = useQuery<{ me?: { lang: Locales } }>(
    GET_ME_LANG,
    {
      fetchPolicy: 'no-cache',
    }
  )

  const [locale, setLocale] = useState<Locales>(() => {
    // Local storage value
    const localStorageLocale = localStorage[LocalStorage.USER_LOCALE]
    if (localStorageLocale in Locales) {
      return localStorageLocale
    }

    // Navigator locale
    const navigatorLanguage = sanitize(navigator.language)
    if (navigatorLanguage in Locales) {
      return navigatorLanguage as Locales
    }

    // Navigator lang
    const navigatorLanguageDefaultLocale =
      // @ts-ignore
      languageDefaultLocale[navigatorLanguage.substring(0, 2)]
    if (navigatorLanguageDefaultLocale in Locales) {
      return navigatorLanguageDefaultLocale
    }

    // Fallback
    return DEFAULT_LOCALE
  })

  const formattedLocale = useMemo(() => formatLocale(locale, '-'), [locale])

  useEffect(() => {
    if (isAuth && !loading && (!data?.me?.lang || data.me.lang !== locale)) {
      updateLanguage({ variables: { newLanguage: locale } }).then(refetch)
    }
  }, [isAuth, data, loading, refetch, locale])

  // Update locale storage when locale changes
  useEffect(() => {
    if (localStorage[LocalStorage.USER_LOCALE] !== locale) {
      localStorage.setItem(LocalStorage.USER_LOCALE, locale)
    }
  }, [locale])

  const context = useMemo(
    () => ({
      locale,
      setLocale,
      formattedLocale,
    }),
    [locale]
  )

  return (
    <LocaleContext.Provider value={context}>{children}</LocaleContext.Provider>
  )
}
