import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { DrawerNames } from 'config/drawer'
import { useGovernanceGroupName } from 'hooks/governanceGroups/useGovernanceGroupName'
import { useDrawers } from 'providers/Drawers'
import { x } from 'utils/emotion'

export interface GovernanceGroupNameProps {
  governanceGroupId?: string
  withLink?: boolean
}

const GovernanceGroupName = ({
  governanceGroupId,
  withLink = false,
}: GovernanceGroupNameProps) => {
  const { openDrawer } = useDrawers()

  const { name, loading } = useGovernanceGroupName(governanceGroupId)

  const content = <SkeletonText loading={loading}>{name}</SkeletonText>

  if (withLink && governanceGroupId) {
    return (
      <x.div
        cursor="pointer"
        hoverTextDecoration="underline"
        onClick={() =>
          openDrawer({ name: DrawerNames.grp, id: governanceGroupId })
        }
      >
        {content}
      </x.div>
    )
  }

  return content
}

export default React.memo(GovernanceGroupName)
