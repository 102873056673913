import { useQuery } from '@apollo/client'
import React from 'react'

import Select, { SelectProps } from 'components/atoms/Form/Select'
import { GET_CORPORATION_FOLDERS } from 'components/molecules/Select/CorporationFolders/GET_CORPORATION_FOLDERS'
import { FolderType } from 'config/folders'

const SelectCorporationFolders = ({
  corporationId,
  ...rest
}: Omit<SelectProps, 'options'> & {
  corporationId: string
}) => {
  const { data, loading } = useQuery(GET_CORPORATION_FOLDERS, {
    variables: { corporationId },
  })

  const folders: FolderType[] = data?.corporation?.folders || []

  return (
    <Select
      options={folders.map(({ id, name, subFolders }) => {
        if (subFolders?.length) {
          return {
            label: name,
            value: id,
            options: subFolders.map(({ id, name }) => ({
              label: name,
              value: id,
            })),
          }
        }
        return {
          label: name,
          value: id,
        }
      })}
      loading={loading}
      {...rest}
    />
  )
}

export default React.memo(SelectCorporationFolders)
