import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import {
  GET_GOVERNANCE_GROUP_NAME,
  GET_GOVERNANCE_GROUPS_NAME,
} from 'hooks/governanceGroups/useGovernanceGroupName/GET_GOVERNANCE_GROUP_NAME'

export const useGovernanceGroupName = (governanceGroupId?: string) => {
  const result = useQuery<{ governanceGroup: { name: string } }>(
    GET_GOVERNANCE_GROUP_NAME,
    {
      variables: { governanceGroupId },
      skip: !governanceGroupId,
    }
  )

  return useMemo(
    () => ({
      ...result,
      name: result?.data?.governanceGroup?.name,
    }),
    [result]
  )
}

export const useGovernanceGroupsName = (governanceGroupsId?: string[]) => {
  const result = useQuery<{ governanceGroups: { name: string }[] }>(
    GET_GOVERNANCE_GROUPS_NAME,
    {
      variables: { governanceGroupsId },
      skip: !governanceGroupsId,
    }
  )

  return useMemo(
    () => ({
      ...result,
      governanceGroupsName: result?.data?.governanceGroups?.map((g) => g.name),
    }),
    [result]
  )
}
