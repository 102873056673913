export enum DRAFT_TRANSACTION_DOCUMENTS_OPTIONS {
  ADD_FILES = 'ADD_FILES',
  CONTINUE_LATER = 'CONTINUE_LATER',
}

export enum DRAFT_TRANSACTION_ACTIONS {
  EXIT = 'EXIT',
  CONTINUE = 'CONTINUE',
}

export enum DRAFT_TRANSACTION_SIGNATORIES_OPTIONS {
  SEND_FOR_SIGNATURE = 'SEND_FOR_SIGNATURE',
  CONTINUE_LATER = 'CONTINUE_LATER',
}
