import React, { useContext } from 'react'

export interface IRolesContext {
  loading: boolean
  lawFirmRoles: { id: string; lawFirm: { id: string } }[]
  corporationRoles: { id: string; corporation: { id: string } }[]
  portfolioRoles: { id: string; portfolio: { id: string } }[]
  hasLawFirmRole: (value: string) => boolean
  hasCorporationRole: (value: string) => boolean
  hasPortfolioRole: (value: string) => boolean
}

export const RolesContext = React.createContext<IRolesContext>({
  loading: true,
  lawFirmRoles: [],
  corporationRoles: [],
  portfolioRoles: [],
  hasLawFirmRole: () => false,
  hasCorporationRole: () => false,
  hasPortfolioRole: () => false,
})

export const useRoles = (): IRolesContext => useContext(RolesContext)
