import React from 'react'

import ApiSearchSelect, {
  ApiSearchSelectProps,
} from 'components/atoms/ApiSearch/Select'
import SecurityName from 'components/molecules/Security/Name'
import { ApiSearchCorporationSecurities } from 'providers/ApiSearch/CorporationSecurities'
import { ApiSearchFiltersProvider } from 'providers/ApiSearchFilters'

const ApiSearchSelectCorporationSecurities = ({
  corporationId,
  defaultFilters,
  ...rest
}: Omit<ApiSearchSelectProps, 'renderItem'> & {
  corporationId: string
  defaultFilters?: object
}) => (
  <ApiSearchFiltersProvider defaultFilters={defaultFilters}>
    <ApiSearchCorporationSecurities corporationId={corporationId}>
      <ApiSearchSelect
        placeholder="word.searchForASecurity"
        renderItem={(id) => <SecurityName securityId={id} />}
        {...rest}
      />
    </ApiSearchCorporationSecurities>
  </ApiSearchFiltersProvider>
)

export default React.memo(ApiSearchSelectCorporationSecurities)
