import {
  AlignLeftOutlined,
  FileWordOutlined,
  LinkOutlined,
  ShareAltOutlined,
} from '@ant-design/icons'
import { MenuInfo } from 'rc-menu/lib/interface.d'
import React from 'react'

import FontAwesomeIcon from 'components/atoms/FontAwesomeIcon'
import Menu from 'components/atoms/Menu'
import { useFileIsTemplate } from 'hooks/files/useFileIsTemplate'
import { useFileHasProcedure } from 'hooks/files/useFileProcedureFileId'
import { useFileHasSignatureGroups } from 'hooks/files/useFileSignatureGroups'
import { useIsCorporationWorkspace } from 'hooks/useWorkspace'
import { useDrawer } from 'providers/Drawer'
import { FileDrawerTab } from 'providers/Drawer/drawers/File'

const Sider = () => {
  const isCorporationWorkspace = useIsCorporationWorkspace()
  const { tab, changeTab, id } = useDrawer()
  const { isTemplate } = useFileIsTemplate(id)
  const hasSignatureGroups = useFileHasSignatureGroups(id)
  const hasProcedure = useFileHasProcedure(id)
  const handleClick = ({ key }: MenuInfo) => {
    changeTab(key as FileDrawerTab)
  }

  return (
    <Menu
      selectedKeys={[tab]}
      mode="inline"
      style={{ height: '100%' }}
      menu={[
        {
          icon: <AlignLeftOutlined />,
          title: 'word.details',
          key: FileDrawerTab.details,
          onClick: handleClick,
        },
        isCorporationWorkspace
          ? {
              icon: <LinkOutlined />,
              title: 'word.links',
              key: FileDrawerTab.links,
              onClick: handleClick,
            }
          : undefined,
        isCorporationWorkspace
          ? {
              icon: <ShareAltOutlined />,
              title: 'word.sharing',
              key: FileDrawerTab.shares,
              onClick: handleClick,
            }
          : undefined,
        isTemplate
          ? {
              icon: <FileWordOutlined />,
              title: 'word.smartFields',
              key: FileDrawerTab.smartFields,
              onClick: handleClick,
            }
          : undefined,
        !hasProcedure && hasSignatureGroups
          ? {
              icon: <FontAwesomeIcon name="signature" />,
              title: 'word.signatureFields',
              key: FileDrawerTab.signatureFields,
              onClick: handleClick,
            }
          : undefined,
        hasProcedure
          ? {
              icon: <FontAwesomeIcon name="signature" />,
              title: 'word.signatures',
              key: FileDrawerTab.signatures,
              onClick: handleClick,
            }
          : undefined,
      ]}
    />
  )
}

export default React.memo(Sider)
