import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_AGGREGATED_TRANSACTION_FROM_QUANTITY } from 'hooks/aggregatedTransactions/useAggregatedTransactionFromQuantity/GET_AGGREGATED_TRANSACTION_FROM_QUANTITY'

export const useAggregatedTransactionFromQuantity = (
  aggregatedTransactionId?: string
) => {
  const result = useQuery<{
    aggregatedTransaction: {
      fromQuantity: number
    }
  }>(GET_AGGREGATED_TRANSACTION_FROM_QUANTITY, {
    variables: { aggregatedTransactionId },
    skip: !aggregatedTransactionId,
  })

  return useMemo(
    () => ({
      ...result,
      fromQuantity: result?.data?.aggregatedTransaction?.fromQuantity,
    }),
    [result]
  )
}
