import React, { useContext } from 'react'

interface IProcedureContext {
  openProcedure: React.Dispatch<React.SetStateAction<string | undefined>>
}

export const ProcedureContext = React.createContext<IProcedureContext>({
  openProcedure: () => {},
})

export const useProcedure = (): IProcedureContext =>
  useContext(ProcedureContext)
