import { addDays, addMonths, addYears, sub } from 'date-fns'
import dayjs, { Dayjs } from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { DurationUnit } from 'components/atoms/Intl/Duration'
import { ONE_MINUTE } from 'config/date'

dayjs.extend(utc)
dayjs.extend(timezone)

export const formatDate = (date: Date) => {
  return dayjs(date).format('YYYY-MM-DD')
}

export const formatTime = (date: Date) => {
  return dayjs(date).format('HH:mm')
}

export const getFirstDayOfCurrentYear = (): Dayjs => {
  return dayjs().startOf('year')
}

export const getFirstDayOfPreviousYear = (): Dayjs => {
  return dayjs().add(-1, 'year').startOf('year')
}

export const getLastDayOfPreviousYear = (): Dayjs => {
  return dayjs().add(-1, 'year').endOf('year')
}

export const getFirstDayOfCurrentQuarter = (): Dayjs => {
  dayjs.extend(quarterOfYear)
  return dayjs().startOf('quarter')
}

export const getFirstDayOfPreviousQuarter = (): Dayjs => {
  dayjs.extend(quarterOfYear)
  return dayjs().add(-1, 'quarter').startOf('quarter')
}

export const getLastDayOfPreviousQuarter = (): Dayjs => {
  dayjs.extend(quarterOfYear)
  return dayjs().add(-1, 'quarter').endOf('quarter')
}

export const getFirstDateOfAllTime = () => {
  const firstDateOfTimestamp = new Date('1990-01-01')
  return formatDate(firstDateOfTimestamp)
}

export const today = () => {
  return formatDate(new Date())
}

export const getDateIn = (
  number: number,
  unit = DurationUnit.DAYS,
  startDate = new Date()
) => {
  switch (unit) {
    case DurationUnit.DAYS:
      return addDays(startDate, number)
    case DurationUnit.MONTHS:
      return addMonths(startDate, number)
    case DurationUnit.YEARS:
      return addYears(startDate, number)
    default:
      return null
  }
}

export const isDate = (value: any) => {
  const date = new Date(value)

  return !isNaN(date.getTime())
}

export const stringToDate = (value: Date | string) => {
  const date = new Date(value)

  if (isNaN(date.getTime())) {
    return undefined
  }

  if (typeof value === 'object') {
    return value
  }

  return new Date(date.valueOf() + date.getTimezoneOffset() * ONE_MINUTE)
}

export const getTimestamp = (value: Date | string) => {
  return stringToDate(value)?.getTime()
}

export const getTimezoneLabel = (timezone: string, name: string = timezone) => {
  const tz = dayjs().tz(timezone)

  const min = tz.utcOffset()
  const hr = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : Math.abs(min % 60))
  return `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${name}`
}

export const getDateMinusOneDay = (
  value: Date | string
): string | undefined => {
  const date = stringToDate(value)

  if (!date) {
    return undefined
  }

  const previousDay = sub(date, { days: 1 })
  return formatDate(previousDay)
}
