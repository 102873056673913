export enum IntegrationApiEnum {
  LUCCA = 'lucca',
  WORKATO = 'workato',
  WORKDAY = 'workday',
  PAYFIT = 'payfit',
}

export enum IntegrationStatusEnum {
  CONNECTED_CONFIGURED = 'ACTIVATED_CONFIGURED',
  CONNECTED_NOT_CONFIGURED = 'CONNECTED_NOT_CONFIGURED',
}

export type SyncConfigType = {
  type: IntegrationApiEnum
  status: IntegrationStatusEnum
  apiUrl?: string
  apiKey?: string
  username?: string
  password?: string
  hrisBearer?: string
  hrisCompanyId?: string
  defaultPortfolioRelationId: string
}

export type SplittedMappingItem = {
  customPropertyId?: string
  equifyField?: string
  hris1: string | null
  separator1: string | null
  hris2: string | null
  separator2: string | null
  hris3: string | null
  separator3: string | null
  hris4: string | null
  separator4: string | null
  hris5: string | null
}

export type SyncMappingValue = {
  id: string
  equifyPropertyName?: string
  customPropertyId?: string
  mapping: {
    syncFields: (string | null)[]
    separators: (string | null)[]
  }
}

export type SyncShortContactType = {
  hrisId: string
  firstName: string
  lastName: string
  email: string
}
