import { useApolloClient, useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'

import Button from 'components/atoms/Button'
import Message, { useFormatMessage } from 'components/atoms/Intl/Message'
import Modal from 'components/atoms/Modal'
import { GET_CORPORATION_NAME } from 'hooks/corporations/useCorporationName/GET_CORPORATION_NAME'
import { useWorkspace } from 'hooks/useWorkspace'
import { useAuth } from 'providers/Auth'

import { CLEAR_CORPORATION_CACHE } from './CLEAR_CORPORATION_CACHE'

export const ClearCacheButton = () => {
  const formatMessage = useFormatMessage()
  const { isAuth } = useAuth()
  const { workspace, workspaceId } = useWorkspace()
  const [workspaceName, setWorkspaceName] = useState('')
  const [isConfirmPopinOpen, setIsConfirmPopinOpen] = useState(false)
  const [isInfoPopinOpen, setIsInfoPopinOpen] = useState(false)
  const { query } = useApolloClient()
  const [corporationId] = useMutation(CLEAR_CORPORATION_CACHE)

  useEffect(() => {
    if (isAuth && workspaceId && workspace) {
      query({
        query: GET_CORPORATION_NAME,
        variables: { corporationId: workspaceId },
      }).then(({ data }) => {
        data.corporation &&
          setWorkspaceName(data.corporation.corporationInfo.name)
      })
      return
    }
    setWorkspaceName('')
  }, [workspaceId, workspace])

  const clearCache = () => {
    setIsConfirmPopinOpen(false)
    corporationId({
      variables: {
        corporationId: workspaceId,
      },
    }).then(() => {
      setIsInfoPopinOpen(true)
    })
  }

  return workspaceName.length > 0 ? (
    <>
      <Button
        message={formatMessage({
          id: 'word.clearCache.buttonTitle',
          values: {
            corporationName: workspaceName,
          },
        })}
        onClick={() => setIsConfirmPopinOpen(true)}
      />
      <Modal
        cancelText={'word.close'}
        okText={'word.confirm'}
        onCancel={() => setIsConfirmPopinOpen(false)}
        onOk={clearCache}
        title={'word.clearCache.confirmModal.title'}
        open={isConfirmPopinOpen}
      >
        <Message
          value={formatMessage({
            id: 'word.clearCache.confirmModal.message',
            values: {
              corporationName: workspaceName,
              corporationId: workspaceId,
            },
          })}
        />
      </Modal>
      <Modal
        okText={'word.ok'}
        onOk={() => setIsInfoPopinOpen(false)}
        title={'word.clearCache.infoModal.title'}
        open={isInfoPopinOpen}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Message
          value={formatMessage({
            id: 'word.clearCache.infoModal.message',
            values: {
              corporationName: workspaceName,
              corporationId: workspaceId,
            },
          })}
        />
      </Modal>
    </>
  ) : (
    <></>
  )
}
