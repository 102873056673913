import React from 'react'

import ApiSearchSelect, {
  ApiSearchSelectProps,
} from 'components/atoms/ApiSearch/Select'
import PoolName from 'components/molecules/Pool/Name'
import { ApiSearchCorporationPools } from 'providers/ApiSearch/CorporationPools'
import { ApiSearchFiltersProvider } from 'providers/ApiSearchFilters'

const ApiSearchSelectCorporationPools = ({
  corporationId,
  defaultFilters,
  ...rest
}: Omit<ApiSearchSelectProps, 'renderItem'> & {
  corporationId: string
  defaultFilters?: object
}) => {
  return (
    <ApiSearchFiltersProvider defaultFilters={defaultFilters}>
      <ApiSearchCorporationPools corporationId={corporationId}>
        <ApiSearchSelect
          placeholder="word.searchForAPool"
          renderItem={(id) => <PoolName poolId={id} />}
          {...rest}
        />
      </ApiSearchCorporationPools>
    </ApiSearchFiltersProvider>
  )
}

export default React.memo(ApiSearchSelectCorporationPools)
