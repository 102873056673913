import { useQuery } from '@apollo/client'
import React from 'react'

import SkeletonAvatar from 'components/atoms/Skeleton/Avatar'
import { GET_CORPORATION_INFO_AVATAR } from 'components/molecules/Avatar/CorporationInfo/GET_CORPORATION_INFO_AVATAR'
import Avatar, { AvatarProps } from 'stories/components/atoms/Avatar/Avatar'

export type AvatarCorporationInfoType = {
  corporationInfoId: string
}

const AvatarCorporationInfo = ({
  corporationInfoId,
  ...rest
}: AvatarProps & AvatarCorporationInfoType) => {
  const { data, loading } = useQuery(GET_CORPORATION_INFO_AVATAR, {
    variables: { corporationInfoId },
  })

  return (
    <SkeletonAvatar loading={loading} shape="square" {...rest}>
      <Avatar
        shape="square"
        src={data?.corporationInfo?.profilePicture}
        nameforbackgroundcolor={
          !data?.corporationInfo?.profilePicture && data?.corporationInfo?.name
            ? data?.corporationInfo?.name
            : undefined
        }
        {...rest}
      >
        {data?.corporationInfo?.name?.charAt(0)?.toUpperCase()}
        {data?.corporationInfo?.name?.charAt(1)}
      </Avatar>
    </SkeletonAvatar>
  )
}

export default React.memo(AvatarCorporationInfo)
