import React, { ReactNode } from 'react'

import { IApiSearchContext } from 'providers/ApiSearch/ApiSearchContext'
import { ApiSearchProvider } from 'providers/ApiSearch/ApiSearchProvider'
import { GET_CORPORATION_EVENTS } from 'providers/ApiSearch/CorporationEvents/GET_CORPORATION_EVENTS'

export type ApiSearchCorporationEventsProps = {
  children: ReactNode | ((context: IApiSearchContext) => ReactNode)
  corporationId: string
  saveFacets?: boolean
}

export const ApiSearchCorporationEvents = ({
  children,
  corporationId,
  saveFacets,
}: ApiSearchCorporationEventsProps) => {
  if (!corporationId) {
    return null
  }

  return (
    <ApiSearchProvider
      query={GET_CORPORATION_EVENTS}
      path={'corporation.events.list'}
      variables={{ corporationId }}
      saveFacets={saveFacets}
    >
      {children}
    </ApiSearchProvider>
  )
}
