import { gql } from '@apollo/client'

export const GET_AGGREGATED_WALLET_PORTFOLIO_ID = gql`
  query GET_AGGREGATED_WALLET_PORTFOLIO_ID($aggregatedWalletId: ID!) {
    aggregatedWallet(id: $aggregatedWalletId) {
      id
      portfolio {
        id
      }
    }
  }
`
