import React, { ReactNode } from 'react'

import { IApiSearchContext } from 'providers/ApiSearch/ApiSearchContext'
import { ApiSearchProvider } from 'providers/ApiSearch/ApiSearchProvider'
import { GET_CORPORATION_PORTFOLIOS } from 'providers/ApiSearch/CorporationPortfolios/GET_CORPORATION_PORTFOLIOS'

export type ApiSearchCorporationPortfoliosProps = {
  children: ReactNode | ((context: IApiSearchContext) => ReactNode)
  corporationId: string
  saveFacets?: boolean
}

export const ApiSearchCorporationPortfolios = ({
  children,
  corporationId,
  saveFacets,
}: ApiSearchCorporationPortfoliosProps) => {
  if (!corporationId) {
    return null
  }

  return (
    <ApiSearchProvider
      query={GET_CORPORATION_PORTFOLIOS}
      path={'corporation.portfolios.list'}
      variables={{ corporationId }}
      saveFacets={saveFacets}
    >
      {children}
    </ApiSearchProvider>
  )
}
