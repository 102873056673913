import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_FILE_EVENT_ID } from 'hooks/files/useFileEventId/GET_FILE_EVENT_ID'

export const useFileEventId = (fileId?: string) => {
  const result = useQuery<{
    file: { event?: { id: string } }
  }>(GET_FILE_EVENT_ID, {
    variables: { fileId },
    skip: !fileId,
  })

  return useMemo(
    () => ({
      ...result,
      eventId: result?.data?.file?.event?.id,
    }),
    [result]
  )
}
