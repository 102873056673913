import { gql } from '@apollo/client'

export const GET_AGGREGATED_TRANSACTION_FROM_QUANTITY = gql`
  query GET_AGGREGATED_TRANSACTION_FROM_QUANTITY(
    $aggregatedTransactionId: ID!
  ) {
    aggregatedTransaction(id: $aggregatedTransactionId) {
      id
      fromQuantity
    }
  }
`
