import { gql } from '@apollo/client'

export const GET_WORKFLOW_DATA = gql`
  query GET_WORKFLOW_DATA($workflowId: ID!) {
    workflow(id: $workflowId) {
      id
      slug
      data
      stateKey
      canGoBack
      updatedEntities {
        id
        typename
      }
      currentStep {
        slug
        payload
      }
      progress {
        current
        steps
      }
    }
  }
`
