import React from 'react'

import Message from 'components/atoms/Intl/Message'
import Space from 'components/atoms/Space'
import Tooltip from 'components/atoms/Tooltip'
import { useFileErrors } from 'hooks/files/useFileErrors'
import Badge from 'stories/components/atoms/Badge/Badge'

const FileBadgeErrors = ({ fileId }: { fileId: string }) => {
  const { errors } = useFileErrors(fileId)

  if (errors?.length) {
    return (
      <Tooltip
        title={
          <Space direction={'vertical'}>
            {errors?.map((e, index) => (
              <Message key={index} value={`error.file.${e.type}`} />
            ))}
          </Space>
        }
      >
        <Badge title={'word.error'} count={errors.length} />
      </Tooltip>
    )
  }

  return null
}

export default React.memo(FileBadgeErrors)
