import { gql } from '@apollo/client'

export const GET_SELECTED_FILES_LOCALES = gql`
  query GET_SELECTED_FILES_LOCALES($ids: [ID!]!) {
    files(ids: $ids) {
      id
      locale
    }
  }
`
