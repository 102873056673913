import { FormatNumberOptions } from '@formatjs/intl/src/types'
import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import Tooltip from 'components/atoms/Tooltip'
import { useEquibar } from 'providers/Equibar'
import { isUnset } from 'utils'
import { parseNumber } from 'utils/numbers'

export const useNumberSeparator = () => {
  const intl = useIntl()

  return useMemo(() => {
    return intl.formatNumber(1.2).match(/1(.)2/)?.[1] ?? '.'
  }, [intl])
}

export const useNumberSeparators = () => {
  const separator = useNumberSeparator()

  return useMemo(() => {
    return separator !== '.' ? separator + '.' : separator
  }, [separator])
}

export const useParseNumber = () => {
  const separators = useNumberSeparators()

  return useCallback(
    (value?: any) => {
      const regex = new RegExp(`[^-${separators}0-9]+`, 'g')
      const separatorsRegex = new RegExp(`[${separators}]`, 'g')
      return parseNumber(
        String(value)?.replace(regex, '')?.replace(separatorsRegex, '.')
      )
    },
    [separators]
  )
}

export const useFormatNumber = (options?: FormatNumberOptions) => {
  const intl = useIntl()
  const { seeTranslationKeys } = useEquibar()

  return useCallback(
    (value?: number, unit?: string) => {
      if (isUnset(value)) {
        return ''
      }

      // Yes that is done on purpose, this is done to prevent "-0" from displaying
      const number = intl.formatNumber(value === 0 ? 0 : value, options)

      if (!unit) {
        return number
      }

      if (seeTranslationKeys) {
        return `unit.${unit}`
      }

      return intl.formatMessage(
        { id: 'unit.value' },
        {
          value: number,
          unit: intl.formatMessage(
            { id: `unit.${unit}` },
            {
              value,
            }
          ),
        }
      )
    },
    [intl, seeTranslationKeys]
  )
}

export type NumberProps = { value?: number; unit?: string }

const Number = ({ value, unit }: NumberProps) => {
  const formatNumber = useFormatNumber()
  const preciseNumber = useFormatNumber({
    minimumFractionDigits: 0,
    maximumFractionDigits: 7,
  })

  if (isUnset(value)) {
    return null
  }

  const formatValue = formatNumber(value, unit)
  const preciseValue = preciseNumber(value, unit)

  if (preciseValue !== formatValue) {
    return <Tooltip title={preciseValue}>{formatValue}</Tooltip>
  }

  return <>{formatValue}</>
}

export default React.memo(Number)
