import {
  CloudDownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FileProtectOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import React, { useState } from 'react'

import ButtonMoreActions from 'components/atoms/Button/MoreActions'
import FontAwesomeIcon from 'components/atoms/FontAwesomeIcon'
import Menu from 'components/atoms/Menu'
import ModalReSendSignatureEmail from 'components/molecules/Modal/ReSendSignatureEmail'
import { ExportFormat, ExportType } from 'config/export'
import { SignatureStatus } from 'config/procedure'
import { CorporationPermissions } from 'config/roles'
import { useFileIsTemplate } from 'hooks/files/useFileIsTemplate'
import {
  useFileHasAtLeastOneSignedSignatureAndYousignIdV2,
  useFileHasAtLeastOnePendingSignature,
  useFileHasAllSignaturesSignedAndYousignIdV3,
} from 'hooks/files/useFileProcedureFileSignersStatus'
import { useFileProcedureMeSignUrl } from 'hooks/files/useFileProcedureMeSignUrl'
import { useIsCorporationWorkspace } from 'hooks/useWorkspace'
import { GET_FILE_PROCEDURE_SIGNERS } from 'providers/Drawer/drawers/File/Content/Signatures/GET_FILE_PROCEDURE_SIGNERS'
import { useExport } from 'providers/Export'
import { useFileDownload } from 'providers/FileDownload'
import { useFilePreview } from 'providers/FilePreview'
import { FilesEditProvider } from 'providers/FilesEdit'
import { useNotification } from 'providers/Notification'
import { usePermissions } from 'providers/Permissions'
import { useProcedure } from 'providers/Procedure'
import { useSkalin } from 'providers/Skalin'
import { useWorkflows } from 'providers/Workflows'

const ButtonMoreActionsFile = ({ fileId }: { fileId: string }) => {
  const { start } = useWorkflows()
  const { startExport } = useExport()
  const { skalin } = useSkalin()
  const { openProcedure } = useProcedure()
  const { fileDownload } = useFileDownload()
  const { openFilePreview } = useFilePreview()
  const { checkPermissions } = usePermissions()
  const { successNotification } = useNotification()
  const { signUrl } = useFileProcedureMeSignUrl(fileId)
  const [reSendSignatureEmailVisible, setReSendSignatureEmailVisible] =
    useState(false)
  const isCorporationWorkspace = useIsCorporationWorkspace()
  const { isTemplate } = useFileIsTemplate(fileId)
  const fileHasAtLeastOneSignedSignatureAndYousignIdV2 =
    useFileHasAtLeastOneSignedSignatureAndYousignIdV2(fileId)
  const fileHasAllSignaturesSignedAndYousignIdV3 =
    useFileHasAllSignaturesSignedAndYousignIdV3(fileId)
  const fileHasAtLeastOnePendingSignature =
    useFileHasAtLeastOnePendingSignature(fileId)

  const { data } = useQuery<{
    file: {
      procedureFile?: {
        procedure: {
          id: string
        }
        signers: {
          id: string
          firstName: string
          lastName: string
          status: SignatureStatus
          updatedAt: string
        }[]
      }
    }
  }>(GET_FILE_PROCEDURE_SIGNERS, {
    variables: { fileId },
    skip: !fileId,
  })

  return (
    <FilesEditProvider>
      {({ editFiles }) => (
        <>
          <ButtonMoreActions
            overlay={
              <Menu
                menu={[
                  signUrl
                    ? {
                        key: 'sign',
                        icon: <FontAwesomeIcon name="signature" />,
                        message: 'word.sign',
                        onClick: () => {
                          openProcedure(
                            data?.file?.procedureFile?.procedure?.id
                          )
                        },
                      }
                    : undefined,
                  {
                    key: 'preview',
                    icon: <EyeOutlined />,
                    message: 'word.preview',
                    onClick: () => {
                      openFilePreview(fileId)
                    },
                  },
                  {
                    key: 'download',
                    icon: <CloudDownloadOutlined />,
                    message: 'word.download',
                    onClick: () => {
                      fileDownload(fileId)
                    },
                  },
                  fileHasAtLeastOneSignedSignatureAndYousignIdV2 ||
                  fileHasAllSignaturesSignedAndYousignIdV3
                    ? {
                        key: 'exportYouSignProofFile',
                        icon: <FileProtectOutlined />,
                        message: 'word.signatureProofFile',
                        onClick: () => {
                          skalin.feature({
                            name: `${ExportType.YOU_SIGN_PROOF_FILE} Export Direct Download`,
                          })
                          startExport({
                            name: 'word.signatureProofFile',
                            type: ExportType.YOU_SIGN_PROOF_FILE,
                            format: ExportFormat.ZIP,
                            params: { fileId },
                          })
                        },
                      }
                    : undefined,
                  fileHasAtLeastOnePendingSignature &&
                  checkPermissions(CorporationPermissions.DOCUMENTS_MANAGE)
                    ? {
                        key: 'resendSignatureEmail',
                        icon: <MailOutlined />,
                        message: 'word.resendSignatureEmail',
                        onClick: () => {
                          setReSendSignatureEmailVisible(true)
                        },
                      }
                    : undefined,
                  isTemplate &&
                  isCorporationWorkspace &&
                  checkPermissions(CorporationPermissions.DOCUMENTS_MANAGE)
                    ? {
                        key: 'templating',
                        icon: <FontAwesomeIcon name="signature" />,
                        message: 'word.templating',
                        onClick: () => {
                          start({
                            slug: 'templating',
                            data: { fileIds: [fileId] },
                            onCompleted: (data) => {
                              successNotification({
                                message: {
                                  id: 'plural.documentSuccessfullySentForSignature',
                                  values: { value: data?.fileIds?.length },
                                },
                              })
                            },
                          })
                        },
                      }
                    : undefined,
                  isCorporationWorkspace &&
                  checkPermissions(CorporationPermissions.DOCUMENTS_MANAGE)
                    ? {
                        key: 'edit',
                        icon: <EditOutlined />,
                        message: 'word.edit',
                        onClick: () => {
                          editFiles([fileId])
                        },
                      }
                    : undefined,
                ]}
              />
            }
          />
          <ModalReSendSignatureEmail
            fileIds={[fileId]}
            open={reSendSignatureEmailVisible}
            onCancel={() => {
              setReSendSignatureEmailVisible(false)
            }}
          />
        </>
      )}
    </FilesEditProvider>
  )
}

export default React.memo(ButtonMoreActionsFile)
