import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_PROCEDURE_SIGN_URL } from 'hooks/procedures/useProcedureSignUrl/GET_PROCEDURE_SIGN_URL'

export enum SignatureProvider {
  YOUSIGNV2 = 'YOUSIGNV2',
  YOUSIGNV3 = 'YOUSIGNV3',
}

export interface SignatureLinkAndProvider {
  url: string
  provider: SignatureProvider
}

export const useProcedureSignUrl = (procedureId?: string) => {
  const result = useQuery<{
    procedure?: {
      me?: {
        signUrl?: SignatureLinkAndProvider | null
      }
    }
  }>(GET_PROCEDURE_SIGN_URL, {
    variables: { procedureId },
    skip: !procedureId,
  })

  return useMemo(
    () => ({
      ...result,
      signUrl: result?.data?.procedure?.me?.signUrl
        ? {
            url: result.data.procedure.me.signUrl.url,
            provider: result.data.procedure.me.signUrl.provider,
          }
        : null,
    }),
    [result]
  )
}
