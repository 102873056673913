import { gql } from '@apollo/client'

export const GET_LAW_FIRM_NAME = gql`
  query GET_LAW_FIRM_NAME($lawFirmId: ID!) {
    lawFirm(id: $lawFirmId) {
      id
      name
    }
  }
`
