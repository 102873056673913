import chroma from 'chroma-js'

import eqDSTheme from 'styles/eqDSTheme'
import { hashStringToInt } from 'utils'

export const colorRange = (
  length: number,
  color: string[] = [
    eqDSTheme.color.palette.blue50,
    eqDSTheme.color.palette.blue200,
  ]
) => {
  const scale = chroma.scale(color).mode('lab')

  return Array(length)
    .fill(0)
    .map((_, i) => scale(i / length).hex())
}

const backgroundColorsToUse = [
  eqDSTheme.color.palette.blue100,
  eqDSTheme.color.palette.gold100,
  eqDSTheme.color.palette.green100,
  eqDSTheme.color.palette.orange100,
  eqDSTheme.color.palette.pink100,
  eqDSTheme.color.palette.purple100,
  eqDSTheme.color.palette.red100,
  eqDSTheme.color.palette.yellow100,
]

export const getColorFromString = (string: string) => {
  const stringHash = hashStringToInt(string, { max: 360, min: 0 })
  // Use modulo to get an index in the list of colors
  const index = Math.abs(stringHash) % backgroundColorsToUse.length
  return backgroundColorsToUse[index]
}
