// https://github.com/react-component/field-form/blob/master/src/utils/messages.ts

/* eslint-disable no-template-curly-in-string */
const typeTemplate = 'The format is invalid'

export default {
  default: 'Validation error on the field',
  required: 'The field is required',
  enum: 'The field must be one of [${enum}]',
  whitespace: "'The field cannot be empty",
  date: {
    format: 'The date format is invalid',
    parse: 'The date format is invalid',
    invalid: 'The date format is invalid',
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
  string: {
    len: 'The field must be exactly ${len} characters',
    min: 'The field must be at least ${min} characters',
    max: 'The field cannot be longer than ${max} characters',
    range: 'The field must be between ${min} and ${max} characters',
  },
  number: {
    len: 'The field must equal to ${len}',
    min: 'The field cannot be less than ${min}',
    max: 'The field cannot be greater than ${max}',
    range: 'The field must be between ${min} and ${max}',
  },
  array: {
    len: 'The field must be exactly ${len} in length',
    min: 'The field cannot be less than ${min} in length',
    max: 'The field cannot be greater than ${max} in length',
    range: 'The field must be between ${min} and ${max} in length',
  },
  pattern: {
    mismatch: 'The field does not match pattern ${pattern}',
  },
}
