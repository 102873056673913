import { omit } from 'lodash/fp'
import { useReducer } from 'react'

import { identity } from 'utils'

export type ObjectState = Record<string, any>

export type ObjectStateAction = {
  add?: ObjectState
  set?: ObjectState
  pop?: string[]
}

function reducer(state: ObjectState, action: ObjectStateAction) {
  let tempState = state
  if (action.set) {
    tempState = action.set
  } else {
    if (action.add) {
      tempState = { ...state, ...action.add }
    }
    if (action.pop) {
      tempState = omit(action.pop, tempState)
    }
  }
  return tempState
}

const EMPTY_OBJECT = {}

export const useObjectState = (
  initialState: ObjectState = EMPTY_OBJECT,
  init: (arg: ObjectState) => ObjectState = identity
) => {
  return useReducer(reducer, initialState, init)
}
