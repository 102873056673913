import React from 'react'

import ApiSearchSelect, {
  ApiSearchSelectProps,
} from 'components/atoms/ApiSearch/Select'
import MeetingName from 'components/molecules/Meeting/Name'
import { ApiSearchCorporationMeetings } from 'providers/ApiSearch/CorporationMeetings'
import { ApiSearchFiltersProvider } from 'providers/ApiSearchFilters'

const ApiSearchSelectCorporationMeetings = ({
  corporationId,
  ...rest
}: Omit<ApiSearchSelectProps, 'renderItem'> & {
  corporationId: string
}) => {
  return (
    <ApiSearchFiltersProvider>
      <ApiSearchCorporationMeetings corporationId={corporationId}>
        <ApiSearchSelect
          placeholder="word.searchForAMeeting"
          renderItem={(id) => <MeetingName meetingId={id} />}
          {...rest}
        />
      </ApiSearchCorporationMeetings>
    </ApiSearchFiltersProvider>
  )
}

export default React.memo(ApiSearchSelectCorporationMeetings)
