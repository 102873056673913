// https://ant.design/components/date-picker/

import generatePicker from 'antd/es/date-picker/generatePicker'
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker'
import dayjs, { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import React, { useMemo, useState } from 'react'

import { useDateFormat, useIntlDateFormat } from 'components/atoms/Intl/Date'
import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'
import { isSet } from 'utils'

export type DateType = string | null

export interface DatePickerProps
  extends Omit<PickerDateProps<Dayjs>, 'placeholder' | 'value' | 'onChange'> {
  placeholder?: MessageType
  value?: DateType
  onChange?: (date: DateType) => void
  myRef?: any
}

export const AntdDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

const DatePicker = ({
  placeholder,
  onChange = () => {},
  value,
  style,
  myRef,
  onBlur = () => {},
  onFocus = () => {},
  showTime,
  ...rest
}: DatePickerProps) => {
  const formatMessage = useFormatMessage()
  const [focus, setFocus] = useState(false)
  const dateFormat = useDateFormat()
  const intlDateFormat = useIntlDateFormat()

  const format = useMemo(() => {
    if (showTime) {
      return 'DD MMM YYYY HH:mm'
    }

    if (focus) {
      return [dateFormat, 'YYYY-MM-DD']
    }

    return 'DD MMM YYYY'
  }, [focus, dateFormat, showTime])

  return (
    <AntdDatePicker
      ref={myRef}
      style={{ width: '100%', minWidth: 150, ...style }}
      value={value ? dayjs(value) : undefined}
      onChange={(date) => {
        onChange(
          date?.isValid()
            ? showTime
              ? new Date(date.format('YYYY-MM-DD HH:mm')).toISOString()
              : date.format('YYYY-MM-DD')
            : null
        )
      }}
      placeholder={
        isSet(placeholder)
          ? (formatMessage(placeholder) as string)
          : showTime
          ? `${intlDateFormat} HH:mm`
          : intlDateFormat
      }
      onFocus={(event) => {
        setFocus(true)
        onFocus(event)
      }}
      onBlur={(event) => {
        setFocus(false)
        onBlur(event)
      }}
      format={format}
      showTime={showTime}
      {...rest}
    />
  )
}

export default React.memo(DatePicker)
