import { gql } from '@apollo/client'

export const FILES_UPDATE_LOCALE = gql`
  mutation FILES_UPDATE_LOCALE($ids: [ID!]!, $newLocale: String!) {
    files(ids: $ids) {
      updateLocale(newLocale: $newLocale) {
        id
        locale
      }
    }
  }
`
