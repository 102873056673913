import { Formik } from 'formik'
import React, { ReactNode } from 'react'

import { useWorkflow } from 'providers/Workflows'

export const WorkflowForm = ({ children }: { children: ReactNode }) => {
  const { next, initialPayload } = useWorkflow()

  return (
    <Formik
      validateOnMount={true}
      initialValues={initialPayload}
      enableReinitialize
      onSubmit={async (payload, { setSubmitting, setErrors }) => {
        try {
          await next(payload)
        } catch ({ errors }) {
          setErrors(errors)
        }
        setSubmitting(false)
      }}
    >
      {children}
    </Formik>
  )
}
