import React from 'react'

import Panel from 'components/atoms/Collapse/Panel'
import { CorporationPermissions } from 'config/roles'
import { FilesEditModalSection } from 'providers/FilesEdit'
import { AggregatedTransactions as AggregatedTransactionsInput } from 'providers/FilesEdit/Modal/FileProperties/Inputs/AggregatedTransactions'
import { GovernanceGroups as GovernanceGroupsInput } from 'providers/FilesEdit/Modal/FileProperties/Inputs/GovernanceGroups'
import { Meetings as MeetingsInput } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Meetings'
import { Pools as PoolsInput } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Pools'
import { Portfolios as PortfoliosInput } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Portfolios'
import { Securities as SecuritiesInput } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Securities'
import { usePermissions } from 'providers/Permissions'

const Links = ({
  selectedIds,
  sections,
  corporationId,
  ...rest
}: {
  corporationId: string
  selectedIds: string[]
  sections: FilesEditModalSection[]
}) => {
  const { checkPermissions } = usePermissions()

  if (!sections.includes(FilesEditModalSection.LINKS)) {
    return <Panel key="links" header="word.links" collapsible="disabled" />
  }

  return (
    <Panel
      {...rest}
      key="links"
      header="word.links"
      message={
        <>
          <AggregatedTransactionsInput
            selectedIds={selectedIds}
            corporationId={corporationId}
          />
          <PortfoliosInput
            selectedIds={selectedIds}
            corporationId={corporationId}
          />
          <SecuritiesInput
            selectedIds={selectedIds}
            corporationId={corporationId}
          />
          <PoolsInput selectedIds={selectedIds} corporationId={corporationId} />
          {checkPermissions(CorporationPermissions.GOVERNANCE_READ) && (
            <>
              <GovernanceGroupsInput
                selectedIds={selectedIds}
                corporationId={corporationId}
              />
              <MeetingsInput
                selectedIds={selectedIds}
                corporationId={corporationId}
              />
            </>
          )}
        </>
      }
    />
  )
}

export default React.memo(Links)
