import { EditOutlined } from '@ant-design/icons'
import React from 'react'

import ButtonMoreActions from 'components/atoms/Button/MoreActions'
import Menu from 'components/atoms/Menu'
import { CorporationPermissions } from 'config/roles'
import { SLUGS } from 'config/slugs'
import { AggregatedTransactionLabel } from 'config/transaction'
import { useAggregatedTransactionLabel } from 'hooks/aggregatedTransactions/useAggregatedTransactionLabel'
import { usePermissions } from 'providers/Permissions'
import { useStartWorkflow } from 'providers/Workflows/hooks/useStartWorkflow'
import { isVirtual } from 'utils'

const ButtonMoreActionsAggregatedTransaction = ({
  aggregatedTransactionId,
}: {
  aggregatedTransactionId: string
}) => {
  const { label, loading } = useAggregatedTransactionLabel(
    aggregatedTransactionId
  )
  const { checkPermissions } = usePermissions()
  const [startEditAggregatedTransaction] = useStartWorkflow({
    data: { aggregatedTransactionIds: [aggregatedTransactionId] },
    slug: SLUGS.EDIT_AGGREGATED_TRANSACTION,
  })
  const [startEditAggregatedGrantTransaction] = useStartWorkflow({
    data: { aggregatedTransactionIds: [aggregatedTransactionId] },
    slug: SLUGS.EDIT_AGGREGATED_GRANT_TRANSACTION,
  })
  const [startEditVestingSuspensionTransaction] = useStartWorkflow({
    data: { aggregatedTransactionIds: [aggregatedTransactionId] },
    slug: SLUGS.EDIT_VESTING_SUSPENSION_TRANSACTIONS,
  })

  const startWorkflow = (e: any) => {
    if (label === AggregatedTransactionLabel.GRANT) {
      startEditAggregatedGrantTransaction(e)
    } else if (label === AggregatedTransactionLabel.SUSPENSION) {
      startEditVestingSuspensionTransaction(e)
    } else {
      startEditAggregatedTransaction(e)
    }
  }

  if (!checkPermissions(CorporationPermissions.TRANSACTIONS_MANAGE)) {
    return null
  }

  const disabled = isVirtual(aggregatedTransactionId)

  return (
    <ButtonMoreActions
      disabled={disabled}
      loading={loading}
      overlay={
        <Menu
          menu={[
            {
              key: 'edit',
              icon: <EditOutlined />,
              message: 'word.edit',
              onClick: startWorkflow,
            },
          ]}
        />
      }
    />
  )
}

export default React.memo(ButtonMoreActionsAggregatedTransaction)
