import { useQuery } from '@apollo/client'
import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { GET_EVENT_TAGS } from 'components/molecules/Event/Tags/GET_EVENT_TAGS'
import TagGeneric from 'components/molecules/Tag/Generic'

const EventTags = ({
  eventId,
  noSkeleton,
}: {
  eventId: string
  noSkeleton?: boolean
}) => {
  const { data, loading } = useQuery<{
    event: { tags: { id: string }[] }
  }>(GET_EVENT_TAGS, {
    variables: { eventId },
  })

  return (
    <SkeletonText loading={!noSkeleton && loading}>
      {(data?.event?.tags || []).map((tag) => (
        <TagGeneric key={tag.id} tagId={tag.id} />
      ))}
    </SkeletonText>
  )
}

export default React.memo(EventTags)
