import { gql } from '@apollo/client'

export const GET_SECURITY_CORPORATION_ID = gql`
  query GET_SECURITY_CORPORATION_ID($securityId: ID!) {
    security(id: $securityId) {
      id
      corporation {
        id
      }
    }
  }
`
