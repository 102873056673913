import React from 'react'

import ApiSearchSelect, {
  ApiSearchSelectProps,
} from 'components/atoms/ApiSearch/Select'
import PortfolioName from 'components/molecules/Portfolio/Name'
import { ApiSearchCorporationPortfolios } from 'providers/ApiSearch/CorporationPortfolios'
import { ApiSearchFiltersProvider } from 'providers/ApiSearchFilters'

const ApiSearchSelectCorporationPortfolios = ({
  corporationId,
  ...rest
}: Omit<ApiSearchSelectProps, 'renderItem'> & {
  corporationId: string
}) => (
  <ApiSearchFiltersProvider>
    <ApiSearchCorporationPortfolios corporationId={corporationId}>
      <ApiSearchSelect
        placeholder="word.searchForAHolder"
        renderItem={(id) => <PortfolioName portfolioId={id} />}
        {...rest}
      />
    </ApiSearchCorporationPortfolios>
  </ApiSearchFiltersProvider>
)

export default React.memo(ApiSearchSelectCorporationPortfolios)
