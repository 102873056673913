import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import SecurityName, {
  SecurityNameProps,
} from 'components/molecules/Security/Name'
import { useAggregatedWalletSecurityId } from 'hooks/aggregatedWallets/useAggregatedWalletSecurityId'

const AggregatedWalletSecurityName = ({
  aggregatedWalletId,
  ...rest
}: Omit<SecurityNameProps, 'securityId'> & {
  aggregatedWalletId: string
}) => {
  const { securityId, loading } =
    useAggregatedWalletSecurityId(aggregatedWalletId)

  return (
    <SkeletonText loading={loading}>
      {securityId && <SecurityName securityId={securityId} {...rest} />}
    </SkeletonText>
  )
}

export default React.memo(AggregatedWalletSecurityName)
