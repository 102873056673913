// Keep same order as displayed for defaultActiveKey
import React, { useContext } from 'react'

export enum FilesEditModalSection {
  FOLDER = 'FOLDER',
  LINKS = 'LINKS',
  DATE = 'DATE',
  EVENT = 'EVENT',
  SHARED_WITH = 'SHARED_WITH',
  LANGUAGE = 'LANGUAGE',
  SMART_FIELDS = 'SMART_FIELDS',
  SIGNATURE_FIELDS = 'SIGNATURE_FIELDS',
}

export interface IFilesEditContext {
  editFiles: (fileIds: string[], sections?: FilesEditModalSection[]) => void
}

export const FilesEditContext = React.createContext<IFilesEditContext>({
  editFiles: () => {},
})

export const useFilesEdit = (): IFilesEditContext =>
  useContext(FilesEditContext)
