import { gql } from '@apollo/client'

export const GET_POOL_STATUS = gql`
  query GET_POOL_STATUS($poolId: ID!, $date: Date) {
    pool(id: $poolId) {
      id
      status(date: $date)
    }
  }
`
