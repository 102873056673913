import React, { Dispatch, SetStateAction, useContext } from 'react'

interface IFileDownloadContext {
  fileDownload: (fileId: string) => void
}

export const FileDownloadContext = React.createContext<IFileDownloadContext>({
  fileDownload: () => {},
})

export const useFileDownload = (): IFileDownloadContext =>
  useContext(FileDownloadContext)
