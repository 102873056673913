// https://ant.design/components/form/

import { Form as AntdForm } from 'antd'
import { FormProps } from 'antd/lib/form'
import React from 'react'

const Form = (props: FormProps) => {
  return <AntdForm {...props} />
}

export default React.memo(Form)

export const useForm = AntdForm.useForm
