import React, { useContext } from 'react'

export const SANITIZED_EQUIFY_ADMIN = 'equifyadmin'

interface IRouterContext {
  defaultRoute: string
}

export const RouterContext = React.createContext<IRouterContext>({
  defaultRoute: '/',
})

export const useRouter = (): IRouterContext => useContext(RouterContext)
