// https://ant.design/components/steps/

import { Steps as AntdSteps } from 'antd'
import {
  StepProps as AntdStepProps,
  StepsProps as AntdStepsProps,
} from 'antd/lib/steps'
import React from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'

export interface StepProps extends AntdStepProps {
  title: MessageType
}
export interface StepsProps extends AntdStepsProps {}

const Steps = ({ items = [], ...rest }: StepsProps) => {
  const formatMessage = useFormatMessage()

  const formattedItems = items.map(({ title, ...rest }, index) => {
    return { key: index, title: formatMessage(title), ...rest }
  })

  return <AntdSteps items={formattedItems} {...rest} />
}

export default React.memo(Steps)
