import { gql } from '@apollo/client'

export const RUN_BACK = gql`
  mutation RUN_BACK($id: ID!, $stateKey: String!, $payload: JSON) {
    workflow(id: $id) {
      back(payload: $payload, stateKey: $stateKey) {
        id
        slug
        data
        stateKey
        canGoBack
        currentStep {
          slug
          payload
        }
        progress {
          current
          steps
        }
      }
    }
  }
`
