import { gql } from '@apollo/client'

export const GET_FILE_SMART_FIELD_GROUPS = gql`
  query GET_FILE_SMART_FIELD_GROUPS($fileId: ID!) {
    file(id: $fileId) {
      id
      smartFields {
        customSmartFields {
          name {
            frFR
            enGB
          }
        }
        smartFieldsGroups {
          type
          rawName
          name {
            frFR
            enGB
          }
          smartFields {
            name {
              enGB
              frFR
            }
            property
          }
        }
      }
    }
  }
`
