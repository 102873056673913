import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_WALLET_SECURITY_NAME } from 'hooks/wallets/useWalletSecurityName/GET_WALLET_SECURITY_NAME'

export const useWalletSecurityName = (walletId?: string) => {
  const result = useQuery<{ wallet: { security: { name: string } } }>(
    GET_WALLET_SECURITY_NAME,
    {
      variables: { walletId },
      skip: !walletId,
    }
  )

  return useMemo(
    () => ({
      ...result,
      securityName: result?.data?.wallet?.security?.name,
    }),
    [result]
  )
}
