export enum ExportType {
  AWARDS = 'AWARDS',
  AWARDS_PERIOD = 'AWARDS_PERIOD',
  CAP_TABLE = 'CAP_TABLE',
  EVENT_TRANSACTIONS = 'EVENT_TRANSACTIONS',
  CORPORATION_CONTACTS = 'CORPORATION_CONTACTS',
  HOLDERS_LIST = 'HOLDERS_LIST',
  HOLDING_STATEMENT = 'HOLDING_STATEMENT',
  TRANSACTION_LEDGER = 'TRANSACTION_LEDGER',
  YOU_SIGN_PROOF_FILE = 'YOU_SIGN_PROOF_FILE',
  POOL_HISTORY = 'POOL_HISTORY',
}

export enum ExportFormat {
  PDF = 'PDF',
  CSV = 'CSV',
  XLSX = 'XLSX',
  ZIP = 'ZIP',
}

export enum ExportStatus {
  CREATED = 'CREATED',
  STARTED = 'STARTED',
  FAILED = 'FAILED',
  DONE = 'DONE',
}

export enum TransactionExportOption {
  ALL_TRANSACTIONS = 'ALL_TRANSACTIONS',
  FILTERED_TRANSACTIONS = 'FILTERED_TRANSACTIONS',
  SELECTED_TRANSACTIONS = 'SELECTED_TRANSACTIONS',
}
