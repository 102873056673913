import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { CorporationType } from 'config/corporation'
import { GET_CORPORATION_INFO_TYPE } from 'hooks/corporationInfo/useCorporationInfoType/GET_CORPORATION_INFO_TYPE'

export const useCorporationInfoType = (corporationInfoId?: string) => {
  const result = useQuery<{
    corporationInfo: { type: CorporationType }
  }>(GET_CORPORATION_INFO_TYPE, {
    variables: { corporationInfoId },
    skip: !corporationInfoId,
  })

  return useMemo(
    () => ({
      ...result,
      type: result?.data?.corporationInfo?.type,
    }),
    [result]
  )
}
