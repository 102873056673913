import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import {
  TranslatedMessage,
  useFormatMessage,
} from 'components/atoms/Intl/Message'
import { GET_FILE_NAME } from 'hooks/files/useFileName/GET_FILE_NAME'

export const useFileName = (fileId?: string) => {
  const formatMessage = useFormatMessage()
  const result = useQuery<{
    file: {
      originalFileName: string
      subject: TranslatedMessage
      folder: {
        name: TranslatedMessage
      }
    }
  }>(GET_FILE_NAME, {
    variables: { fileId },
    skip: !fileId,
  })

  return useMemo(() => {
    const name = [
      formatMessage(result.data?.file?.folder?.name),
      formatMessage(result.data?.file?.subject),
    ].filter(Boolean)

    return {
      ...result,
      name: result.loading
        ? undefined
        : name.length
        ? name.join(' - ')
        : result.data?.file?.originalFileName || formatMessage('word.unTyped'),
    }
  }, [result, formatMessage])
}
