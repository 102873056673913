import React, { lazy, Suspense } from 'react'

import { useDrawer } from 'providers/Drawer'
import { FileDrawerTab } from 'providers/Drawer/drawers/File'

const Details = lazy(
  () => import('providers/Drawer/drawers/File/Content/Details')
)
const Links = lazy(() => import('providers/Drawer/drawers/File/Content/Links'))
const Shares = lazy(
  () => import('providers/Drawer/drawers/File/Content/Shares')
)
const SmartFields = lazy(
  () => import('providers/Drawer/drawers/File/Content/SmartFields')
)
const SignatureFields = lazy(
  () => import('providers/Drawer/drawers/File/Content/SignatureFields')
)
const Signatures = lazy(
  () => import('providers/Drawer/drawers/File/Content/Signatures')
)

const Content = () => {
  const { tab } = useDrawer()

  const Component = {
    details: Details,
    links: Links,
    shares: Shares,
    smartFields: SmartFields,
    signatureFields: SignatureFields,
    signatures: Signatures,
  }[tab as FileDrawerTab]

  if (!Component) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  )
}

export default React.memo(Content)
