import { useQuery } from '@apollo/client'
import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import PortfolioName, {
  PortfolioNameProps,
} from 'components/molecules/Portfolio/Name'
import { GET_WALLET_PORTFOLIO_ID } from 'components/molecules/Wallet/PortfolioName/GET_WALLET_PORTFOLIO_ID'

const WalletPortfolioName = ({
  walletId,
  ...rest
}: Omit<PortfolioNameProps, 'portfolioId'> & { walletId: string }) => {
  const { data, loading } = useQuery(GET_WALLET_PORTFOLIO_ID, {
    variables: { walletId },
  })

  return (
    <SkeletonText loading={loading}>
      <PortfolioName portfolioId={data?.wallet?.portfolio?.id} {...rest} />
    </SkeletonText>
  )
}

export default React.memo(WalletPortfolioName)
