import {
  AlignLeftOutlined,
  CalculatorOutlined,
  LineChartOutlined,
} from '@ant-design/icons'
import { MenuInfo } from 'rc-menu/lib/interface.d'
import React from 'react'

import Menu from 'components/atoms/Menu'
import { CorporationPermissions } from 'config/roles'
import { useWalletSecurityHasExercisePrice } from 'hooks/wallets/useWalletSecurityExercisePrice'
import { useDrawer } from 'providers/Drawer'
import { WalletDrawerTab } from 'providers/Drawer/drawers/Wallet'
import { usePermissions } from 'providers/Permissions'

const Sider = () => {
  const { id, tab, changeTab } = useDrawer()
  const { checkPermissions } = usePermissions()
  const walletSecurityHasExercisePrice = useWalletSecurityHasExercisePrice(id)

  const handleClick = ({ key }: MenuInfo) => {
    changeTab(key as WalletDrawerTab)
  }

  return (
    <Menu
      selectedKeys={[tab]}
      mode="inline"
      style={{ height: '100%' }}
      menu={[
        {
          icon: <AlignLeftOutlined />,
          title: 'word.details',
          key: WalletDrawerTab.details,
          onClick: handleClick,
        },
        {
          icon: <LineChartOutlined />,
          title: 'word.vesting',
          key: WalletDrawerTab.vesting,
          onClick: handleClick,
        },
        walletSecurityHasExercisePrice &&
        checkPermissions(CorporationPermissions.VALUATION_READ)
          ? {
              icon: <CalculatorOutlined />,
              title: 'word.simulator',
              key: WalletDrawerTab.simulator,
              onClick: handleClick,
            }
          : undefined,
      ]}
    />
  )
}

export default React.memo(Sider)
