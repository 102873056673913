// https://ant.design/components/skeleton/

import { Skeleton as AntdSkeleton } from 'antd'
import { SkeletonButtonProps as AntdSkeletonButtonProps } from 'antd/lib/skeleton/Button'
import React from 'react'

import { css, x } from 'utils/emotion'

export interface SkeletonButtonProps extends AntdSkeletonButtonProps {
  children?: any
  loading?: boolean
  block?: boolean
}

const SkeletonButton = ({
  loading,
  children,
  block,
  ...rest
}: SkeletonButtonProps) => {
  if (loading === false) {
    return children ?? null
  }

  const skeleton = <AntdSkeleton.Button active {...rest} />

  if (block) {
    return (
      <x.div
        className={css`
          .eq-skeleton,
          .eq-skeleton-button {
            width: 100%;
          }
        `}
      >
        {skeleton}
      </x.div>
    )
  }
  return skeleton
}

export default React.memo(SkeletonButton)
