import { gql } from '@apollo/client'

export const GET_EVENT_TAGS = gql`
  query GET_EVENT_TAGS($eventId: ID!) {
    event(id: $eventId) {
      id
      tags {
        id
      }
    }
  }
`
