import { useMutation, useQuery } from '@apollo/client'
import { isEqual, uniq, uniqWith } from 'lodash/fp'
import React, { useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'

import FormItem from 'components/atoms/Form/FormItem'
import Message from 'components/atoms/Intl/Message'
import Link from 'components/atoms/Typography/Link'
import ApiSearchSelectCorporationPortfolios from 'components/molecules/ApiSearchSelect/CorporationPortfolios'
import { ONE_SECOND } from 'config/date'
import { FILES_LINK_PORTFOLIOS } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Portfolios/FILES_LINK_PORTFOLIOS'
import { GET_SELECTED_FILES_PORTFOLIOS } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Portfolios/GET_SELECTED_FILES_PORTFOLIOS'
import { FILES_SHARE } from 'providers/FilesEdit/Modal/FileProperties/Inputs/SharedWith/FILES_SHARE'

export const Portfolios = ({
  selectedIds,
  corporationId,
}: {
  selectedIds: string[]
  corporationId: string
}) => {
  const [share, { loading: shareMutationLoading }] = useMutation(FILES_SHARE)
  const [value, setValue] = useState<string[]>([])
  const [changeCount, setChangeCount] = useState(0)
  const [debouncedChangeCount] = useDebounce(changeCount, ONE_SECOND)
  const [link, { loading: linkMutationLoading }] = useMutation(
    FILES_LINK_PORTFOLIOS
  )

  const { data, loading: queryLoading } = useQuery<{
    files: {
      portfolios: {
        portfolios: {
          id: string
        }[]
        sharedWith: {
          id: string
        }[]
      }
    }[]
  }>(GET_SELECTED_FILES_PORTFOLIOS, {
    variables: { ids: selectedIds },
  })

  const values: any[] = useMemo(() => {
    return uniqWith(
      isEqual,
      (data?.files || []).map((file) =>
        file.portfolios.portfolios.map(({ id }: { id: string }) => id)
      )
    )
  }, [data])

  const sharedWithIds = useMemo(() => {
    return uniq(
      data?.files.flatMap(({ portfolios }) =>
        portfolios.sharedWith.map(({ id }) => id)
      )
    )
  }, [data])

  const linkButNotSharedIds = useMemo(
    () =>
      values.length === 1
        ? uniq(
            data?.files
              .flatMap(({ portfolios }) =>
                portfolios.portfolios.map(({ id }) => id)
              )
              .filter((id) => !sharedWithIds.includes(id))
          )
        : [],
    [data, sharedWithIds, values]
  )

  useEffect(() => {
    if (debouncedChangeCount) {
      link({
        variables: {
          ids: selectedIds,
          portfolioIds: value,
        },
      })
    }
  }, [debouncedChangeCount])

  useEffect(() => {
    if (values.length === 1) {
      setValue(values[0])
    } else {
      setValue([])
    }
  }, [values])

  return (
    <FormItem
      style={{ marginBottom: '24px' }}
      label="word.holders"
      help={
        linkButNotSharedIds?.length &&
        !shareMutationLoading &&
        !linkMutationLoading ? (
          <div>
            <Message
              value={{
                id: 'provider.filesEdit.links.portfolios.help',
                values: {
                  documentsCount: selectedIds.length,
                  clickHere: (
                    <Link
                      message="word.clickHere"
                      onClick={() => {
                        share({
                          variables: {
                            ids: selectedIds,
                            portfolioIds: [
                              ...linkButNotSharedIds,
                              ...sharedWithIds,
                            ],
                          },
                        })
                      }}
                    />
                  ),
                },
              }}
            />
          </div>
        ) : undefined
      }
    >
      <ApiSearchSelectCorporationPortfolios
        loading={queryLoading}
        corporationId={corporationId}
        mode="multiple"
        allowClear
        value={value}
        onChange={(newValue) => {
          setValue(newValue)
          setChangeCount((old) => old + 1)
        }}
      />
    </FormItem>
  )
}
