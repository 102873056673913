import { gql } from '@apollo/client'

export const GET_FILE_DATE = gql`
  query GET_FILE_DATE($fileId: ID!) {
    file(id: $fileId) {
      id
      date
    }
  }
`
