import { useApolloClient } from '@apollo/client'
import { useCallback } from 'react'

export const useUpdateCache = () => {
  const client = useApolloClient()

  const removeFromApolloCache = useCallback(
    (data: { typename: string; id?: string }[]) => {
      // We remove from the apollo cache all occurrences of the
      // data received from the back
      data.forEach(({ typename, id }) => {
        if (id) {
          // @ts-ignore
          Object.keys(client.cache.data.data).forEach((key: string) => {
            if (key.includes(`${typename}:${id}`)) {
              // @ts-ignore
              client.cache.data.delete(key)
            }
          })
        }
      })

      // We remove from the apollo cache all tables that might
      // contain the occurrences received from the back
      data.forEach(({ typename }) => {
        // @ts-ignore
        Object.entries(client.cache.data.data).forEach(
          ([cacheKey, cacheValue]: [cacheKey: string, cacheValue: any]) => {
            const deleteArrayOfType = (value: any) => {
              if (
                Array.isArray(value) &&
                [typename].includes(value?.[0]?.typename)
              ) {
                // @ts-ignore
                client.cache.data.delete(cacheKey)
              }
              if (value && typeof value === 'object') {
                Object.values(value).forEach(deleteArrayOfType)
              }
            }
            deleteArrayOfType(cacheValue)
          }
        )
      })
    },
    // @ts-ignore
    [client.cache.data]
  )

  return useCallback(
    (data: { typename: string; id?: string }[] = []) => {
      if (!data.length) {
        return undefined
      }

      // We remove from the apollo cache
      removeFromApolloCache(data)

      // We re-fetch all the observable queries
      client.reFetchObservableQueries()
    },
    [client, removeFromApolloCache]
  )
}
