import { EditOutlined, ExportOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import React from 'react'

import ButtonMoreActions from 'components/atoms/Button/MoreActions'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import Menu from 'components/atoms/Menu'
import { GET_POOL_TERMINATED_AT } from 'components/molecules/Pool/TerminatedAt/GET_POOL_TERMINATED_AT'
import { GET_POOL_STATUS } from 'components/molecules/Tag/PoolStatus/GET_POOL_STATUS'
import { ExportFormat, ExportType } from 'config/export'
import { PoolStatus } from 'config/pool'
import { CorporationPermissions } from 'config/roles'
import { SLUGS } from 'config/slugs'
import { usePoolCorporationId } from 'hooks/pools/usePoolCorporationId'
import { usePoolName } from 'hooks/pools/usePoolName'
import { useExport } from 'providers/Export'
import { usePermissions } from 'providers/Permissions'
import { useStartWorkflow } from 'providers/Workflows/hooks/useStartWorkflow'
import { today } from 'utils/date'

const ButtonMoreActionsPool = ({ poolId }: { poolId: string }) => {
  const formatMessage = useFormatMessage()
  const { name: poolName } = usePoolName(poolId)
  const { data: poolStatusData } = useQuery(GET_POOL_STATUS, {
    variables: { poolId },
    skip: !poolId,
  })
  const { openExportModal } = useExport()
  const { checkPermissions } = usePermissions()
  const { corporationId, loading } = usePoolCorporationId(poolId)
  const [startEditPool] = useStartWorkflow({
    data: { poolId },
    slug: SLUGS.EDIT_POOL,
  })
  const [editPoolTermination] = useStartWorkflow({
    data: { poolId },
    slug: SLUGS.TERMINATE_POOL,
  })

  if (!checkPermissions(CorporationPermissions.POOLS_MANAGE)) {
    return null
  }

  if (!corporationId) {
    return null
  }
  return (
    <ButtonMoreActions
      loading={loading}
      overlay={
        <Menu
          menu={[
            {
              key: 'edit',
              icon: <EditOutlined />,
              message: 'word.edit',
              onClick: startEditPool,
            },
            {
              key: 'export',
              icon: <ExportOutlined />,
              message: 'word.export',
              onClick: () => {
                openExportModal({
                  name: `${poolName} - ${formatMessage(
                    'reports.POOL_HISTORY.name'
                  )}`,
                  type: ExportType.POOL_HISTORY,
                  formats: [ExportFormat.XLSX],
                  params: {
                    date: today(),
                    corporationId,
                    poolId,
                    addFileToLibrary: false,
                  },
                })
              },
            },
            {
              key: 'terminatePool',
              icon: <EditOutlined />,
              message:
                poolStatusData?.pool?.status === PoolStatus.TERMINATED
                  ? 'workflow.steps.editPoolTermination'
                  : 'workflow.steps.terminatePool',
              onClick: editPoolTermination,
            },
          ]}
        />
      }
    />
  )
}

export default React.memo(ButtonMoreActionsPool)
