import {
  AlignLeftOutlined,
  FileTextOutlined,
  LineChartOutlined,
  PieChartOutlined,
  ReadOutlined,
  SwapOutlined,
} from '@ant-design/icons'
import { MenuInfo } from 'rc-menu/lib/interface.d'
import React from 'react'

import Menu from 'components/atoms/Menu'
import { CorporationPermissions, PortfolioPermissions } from 'config/roles'
import { useSecurityDeparturePlan } from 'hooks/securities/useSecurityPlanRules'
import { useSecurityVestingScheduleId } from 'hooks/securities/useSecurityVestingSchedule'
import { useDrawer } from 'providers/Drawer'
import { SecurityDrawerTab } from 'providers/Drawer/drawers/Security'
import { usePermissions } from 'providers/Permissions'

const Sider = () => {
  const { id, tab, changeTab } = useDrawer()
  const { checkPermissions } = usePermissions()

  const handleClick = ({ key }: MenuInfo) => {
    changeTab(key as SecurityDrawerTab)
  }

  const vestingScheduleId = useSecurityVestingScheduleId(id)
  const departurePlan = useSecurityDeparturePlan(id)

  return (
    <Menu
      selectedKeys={[tab]}
      mode="inline"
      style={{ height: '100%' }}
      menu={[
        {
          icon: <AlignLeftOutlined />,
          title: 'word.details',
          key: SecurityDrawerTab.details,
          onClick: handleClick,
        },
        {
          icon: <PieChartOutlined />,
          title: 'word.capitalisation',
          key: SecurityDrawerTab.capitalisation,
          onClick: handleClick,
        },
        checkPermissions(CorporationPermissions.TRANSACTIONS_READ) ||
        checkPermissions(PortfolioPermissions.TRANSACTIONS_READ)
          ? {
              icon: <SwapOutlined />,
              title: 'word.transactions',
              key: SecurityDrawerTab.transactions,
              onClick: handleClick,
            }
          : undefined,
        vestingScheduleId
          ? {
              icon: <LineChartOutlined />,
              title: 'word.vestingSchedule',
              key: SecurityDrawerTab.vestingSchedule,
              onClick: handleClick,
            }
          : undefined,
        departurePlan
          ? {
              icon: <ReadOutlined />,
              title: 'word.plan',
              key: SecurityDrawerTab.plan,
              onClick: handleClick,
            }
          : undefined,
        checkPermissions(CorporationPermissions.DOCUMENTS_READ) ||
        checkPermissions(PortfolioPermissions.DOCUMENTS_READ)
          ? {
              icon: <FileTextOutlined />,
              title: 'word.documents',
              key: SecurityDrawerTab.documents,
              onClick: handleClick,
            }
          : undefined,
      ]}
    />
  )
}

export default React.memo(Sider)
