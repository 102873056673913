import React, { ReactNode, useCallback, useMemo } from 'react'
import { ArrayParam, useQueryParam, withDefault } from 'use-query-params'

import { DrawerNames } from 'config/drawer'
import { CorporationPermissions, PortfolioPermissions } from 'config/roles'
import {
  useIsCorporationWorkspace,
  useIsPortfolioWorkspace,
} from 'hooks/useWorkspace'
import {
  DrawerInitType,
  DrawersContext,
  DrawerType,
  TabType,
} from 'providers/Drawers/DrawersContext'
import {
  formatDrawer,
  formatDrawers,
  parseDrawers,
} from 'providers/Drawers/utils'
import { usePermissions } from 'providers/Permissions'
import { dropRight } from 'utils'

export const DrawersProvider = ({ children }: { children: ReactNode }) => {
  const { checkPermissions } = usePermissions()
  const isPortfolioWorkspace = useIsPortfolioWorkspace()
  const isCorporationWorkspace = useIsCorporationWorkspace()

  const [drawerSearchParam, setDrawerSearchParam] = useQueryParam(
    'd',
    withDefault(ArrayParam, [])
  )

  const drawers: DrawerType[] = useMemo(() => {
    return parseDrawers(drawerSearchParam as string[]).filter(
      ({ name }: { name: string }) => {
        if (!isPortfolioWorkspace || !isCorporationWorkspace) {
          return true
        }
        switch (name) {
          case DrawerNames.aggT:
          case DrawerNames.aggW:
          case DrawerNames.event:
          case DrawerNames.wall:
            return (
              checkPermissions(CorporationPermissions.TRANSACTIONS_READ) ||
              checkPermissions(PortfolioPermissions.TRANSACTIONS_READ)
            )
          case DrawerNames.contact:
          case DrawerNames.legalE:
            return checkPermissions(CorporationPermissions.CONTACTS_READ)
          case DrawerNames.file:
            return (
              checkPermissions(CorporationPermissions.DOCUMENTS_READ) ||
              checkPermissions(PortfolioPermissions.DOCUMENTS_READ)
            )
          case DrawerNames.grp:
          case DrawerNames.meet:
          case DrawerNames.communication:
            return checkPermissions(CorporationPermissions.GOVERNANCE_READ)
          case DrawerNames.pool:
            return checkPermissions(CorporationPermissions.POOLS_READ)
          case DrawerNames.port:
            return checkPermissions(CorporationPermissions.PORTFOLIOS_READ)
          case DrawerNames.sec:
            return checkPermissions(CorporationPermissions.SECURITIES_READ)
          default:
            return true
        }
      }
    )
  }, [checkPermissions, drawerSearchParam])

  const openDrawer = useCallback(
    ({ name, id, tab }: DrawerInitType): void => {
      const string = formatDrawer({ name, id, tab })
      if (string) {
        setDrawerSearchParam([...drawerSearchParam, string])
      }
    },
    [drawerSearchParam]
  )

  const closeDrawer = useCallback(
    (id?: string): void => {
      const result: string[] = []
      formatDrawers(
        // If no id is provided, just remove the last drawer
        id ? drawers.filter((drawer) => drawer.id !== id) : dropRight(drawers)
      ).forEach((string) => {
        result.push(string)
      })
      setDrawerSearchParam(result)
    },
    [drawerSearchParam, drawers]
  )

  const closeAllDrawers = useCallback((): void => {
    setDrawerSearchParam([])
  }, [drawerSearchParam])

  const changeTab = useCallback(
    ({ id, tab }: { id: string; tab: TabType }): void => {
      const result: string[] = []
      formatDrawers(
        drawers.map((drawer) =>
          drawer.id === id ? { ...drawer, tab } : drawer
        )
      ).forEach((string) => {
        result.push(string)
      })
      setDrawerSearchParam(result)
    },
    [drawerSearchParam, drawers]
  )

  const context = useMemo(
    () => ({ openDrawer, closeDrawer, closeAllDrawers, changeTab, drawers }),
    [openDrawer, closeDrawer, closeAllDrawers, changeTab, drawers]
  )

  return (
    <DrawersContext.Provider value={context}>
      {children}
    </DrawersContext.Provider>
  )
}
