// https://ant.design/components/modal/

import { Modal as AntdModal } from 'antd'
import { ModalProps as AntdModalProps } from 'antd/lib/modal'
import React, { ReactNode } from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'
import eqDSTheme from 'styles/eqDSTheme'

export interface ModalProps extends AntdModalProps {
  title?: MessageType
  children?: ReactNode
  okText?: MessageType
  cancelText?: MessageType
}

const Modal = ({
  title,
  okText,
  cancelText,
  bodyStyle,
  ...rest
}: ModalProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdModal
      bodyStyle={{
        padding: `${eqDSTheme.space.small} ${eqDSTheme.space.none}`,
        ...bodyStyle,
      }}
      title={title ? formatMessage(title) : undefined}
      okText={okText ? formatMessage(okText) : undefined}
      cancelText={cancelText ? formatMessage(cancelText) : undefined}
      {...rest}
    />
  )
}

export default React.memo(Modal)
