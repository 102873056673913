import {
  BuildOutlined,
  CheckOutlined,
  CloseOutlined,
  RightOutlined,
} from '@ant-design/icons'
import React from 'react'

import Button from 'components/atoms/Button'
import Dropdown from 'components/atoms/Dropdown'
import Menu from 'components/atoms/Menu'
import Tooltip from 'components/atoms/Tooltip'
import { LocalStorage } from 'config/localStorage'
import { useIsLaptopWindowSize } from 'hooks/useWindowSize'
import { cleanArray } from 'utils'

export const UI = ({
  autoCompleteDelete,
  disableResponsive,
  seeTranslationKeys,
  seeWorkflowValues,
  setAutoCompleteDelete,
  setDisableResponsive,
  setIsStartWorkflowVisible,
  setSeeTranslationKeys,
  setSeeWorkflowValues,
  setVirtualWorkflows,
  virtualWorkflows,
}: {
  autoCompleteDelete: boolean
  disableResponsive: boolean
  seeTranslationKeys: boolean
  seeWorkflowValues: boolean
  setAutoCompleteDelete: (value: any) => void
  setDisableResponsive: (value: any) => void
  setIsStartWorkflowVisible: React.Dispatch<React.SetStateAction<boolean>>
  setSeeTranslationKeys: (value: any) => void
  setSeeWorkflowValues: (value: any) => void
  setVirtualWorkflows: (value: any) => void
  virtualWorkflows: boolean
}) => {
  const isLaptopWindowSize = useIsLaptopWindowSize()

  return (
    <Dropdown
      key="ui"
      trigger={['click']}
      dropdownRender={() => (
        <Menu
          selectedKeys={cleanArray([
            autoCompleteDelete ? LocalStorage.AUTO_COMPLETE_DELETE : undefined,
            disableResponsive ? LocalStorage.DISABLE_RESPONSIVE : undefined,
            seeTranslationKeys ? LocalStorage.SEE_TRANSLATION_KEYS : undefined,
            seeWorkflowValues ? LocalStorage.SEE_WORKFLOW_VALUES : undefined,
            virtualWorkflows ? LocalStorage.VIRTUAL_WORKFLOWS : undefined,
          ])}
          menu={[
            {
              icon: <RightOutlined />,
              key: 'startWorkflow',
              message: 'Start workflow',
              onClick: () => {
                setIsStartWorkflowVisible(true)
              },
            },
            {
              icon: virtualWorkflows ? <CheckOutlined /> : <CloseOutlined />,
              key: LocalStorage.VIRTUAL_WORKFLOWS,
              message: 'Virtual workflows',
              onClick: () => {
                setVirtualWorkflows(!virtualWorkflows)
              },
            },
            {
              icon: seeWorkflowValues ? <CheckOutlined /> : <CloseOutlined />,
              key: LocalStorage.SEE_WORKFLOW_VALUES,
              message: 'See workflow values',
              onClick: () => {
                setSeeWorkflowValues(!seeWorkflowValues)
              },
            },
            {
              icon: autoCompleteDelete ? <CheckOutlined /> : <CloseOutlined />,
              key: LocalStorage.AUTO_COMPLETE_DELETE,
              message: 'Auto complete delete',
              onClick: () => {
                setAutoCompleteDelete(!autoCompleteDelete)
              },
            },
            {
              icon: disableResponsive ? <CheckOutlined /> : <CloseOutlined />,
              key: LocalStorage.DISABLE_RESPONSIVE,
              message: 'Disable responsive',
              onClick: () => {
                setDisableResponsive(!disableResponsive)
              },
            },
            {
              icon: seeTranslationKeys ? <CheckOutlined /> : <CloseOutlined />,
              key: LocalStorage.SEE_TRANSLATION_KEYS,
              message: 'See translation keys',
              onClick: () => {
                setSeeTranslationKeys(!seeTranslationKeys)
              },
            },
          ]}
        />
      )}
    >
      <Tooltip title={isLaptopWindowSize ? undefined : 'Interface'}>
        <Button
          icon={<BuildOutlined />}
          message={isLaptopWindowSize ? 'Interface' : undefined}
        />
      </Tooltip>
    </Dropdown>
  )
}
