import { useMutation } from '@apollo/client'
import React, { useCallback, useState } from 'react'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Flex from 'components/atoms/Section/Flex'
import Space from 'components/atoms/Space'
import { useIsLaptopWindowSize } from 'hooks/useWindowSize'
import { FilesEditModalSection } from 'providers/FilesEdit'
import { FileProperties } from 'providers/FilesEdit/Modal/FileProperties'
import { PreviewFile } from 'providers/FilesEdit/Modal/PreviewFile'
import { SelectFiles } from 'providers/FilesEdit/Modal/SelectFiles'
import { useStartWorkflow } from 'providers/Workflows/hooks/useStartWorkflow'
import { x } from 'utils/emotion'

import { INDEX_FILES } from '../../FilesUpload/graphql/mutations/INDEX_FILES'

export const FilesEditModal = ({
  visible,
  setVisible,
  fileIds,
  setFileIds,
  sections,
  onCompleted = () => {},
}: {
  visible: boolean
  setVisible: React.Dispatch<React.SetStateAction<boolean>>
  fileIds: string[]
  setFileIds: React.Dispatch<React.SetStateAction<string[]>>
  onCompleted?: (fileIds: string[]) => void
  sections?: FilesEditModalSection[]
}) => {
  const isLaptopWindowSize = useIsLaptopWindowSize()
  const [selectedIds, setSelectedIds] = useState<string[]>([])

  const [indexFiles] = useMutation(INDEX_FILES)
  const close = useCallback(() => {
    fileIds.forEach((id) => {
      indexFiles({ variables: { fileId: id } })
    })
    onCompleted(fileIds)
    setVisible(false)
    setFileIds([])
    setSelectedIds([])
  }, [fileIds])

  const [deleteFile] = useStartWorkflow({
    slug: 'deleteFile',
    data: { fileIds: selectedIds },
    onCompleted: () => {
      setFileIds((old) => old.filter((id) => !selectedIds.includes(id)))
      setSelectedIds([])
      selectedIds.forEach((id) => {
        indexFiles({ variables: { fileId: id } })
      })
    },
  })

  return (
    <Modal
      destroyOnClose
      open={Boolean(fileIds.length && visible)}
      onCancel={close}
      bodyStyle={{
        padding: '0px',
        height: '80vh',
        borderRadius: '4px 4px 0 0',
        overflow: 'hidden',
      }}
      footer={
        <x.div display="flex">
          <Button
            type="primary"
            message={
              selectedIds.length > 1
                ? {
                    id: 'button.delete.count',
                    values: { count: selectedIds.length },
                  }
                : 'word.delete'
            }
            danger
            onClick={deleteFile}
          />
          <Flex />
          <Space>
            <Button
              key="save"
              message="word.save"
              type="primary"
              onClick={close}
            />
          </Space>
        </x.div>
      }
      centered
      width="80%"
      closable={false}
      style={{ maxWidth: '1200px' }}
    >
      <x.div display="flex" h="100%">
        <SelectFiles
          fileIds={fileIds}
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
        />
        <FileProperties selectedIds={selectedIds} sections={sections} />
        {isLaptopWindowSize && <PreviewFile selectedIds={selectedIds} />}
      </x.div>
    </Modal>
  )
}
