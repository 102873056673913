import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_CORPORATION_CORPORATION_INFO_ID } from 'hooks/corporations/useCorporationCorporationInfoId/GET_CORPORATION_CORPORATION_INFO_ID'

export const useCorporationCorporationInfoId = (corporationId?: string) => {
  const result = useQuery<{
    corporation: { corporationInfo: { id: string } }
  }>(GET_CORPORATION_CORPORATION_INFO_ID, {
    variables: { corporationId },
    skip: !corporationId,
  })

  return useMemo(
    () => ({
      ...result,
      corporationInfoId: result?.data?.corporation?.corporationInfo?.id,
    }),
    [result]
  )
}
