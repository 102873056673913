// https://ant.design/components/list/

import { InfoCircleOutlined } from '@ant-design/icons'
import { List as AntdList } from 'antd'
import { ListProps as AntdListProps } from 'antd/lib/list'
import React, { ReactElement, useCallback } from 'react'

import Collapse from 'components/atoms/Collapse'
import Panel from 'components/atoms/Collapse/Panel'
import Message, {
  MessageType,
  useFormatMessage,
} from 'components/atoms/Intl/Message'
import Tooltip from 'components/atoms/Tooltip'
import eqDSTheme from 'styles/eqDSTheme'
import { cleanArray } from 'utils'
import { x } from 'utils/emotion'

export type ListType = {
  tooltip?: MessageType
  label: MessageType
  value?: ReactElement | string
  key?: string | number
  marginLeft?: string | number | undefined
  collapse?: MessageType // Item can be a collapse element
  expandIconPosition?: string
}

export interface ListProps extends AntdListProps<any> {
  header?: MessageType
  footer?: MessageType
  list?: (ListType | undefined)[]
}

const List = ({ header, footer, list, ...rest }: ListProps) => {
  const formatMessage = useFormatMessage()

  const formatList = useCallback(
    (list: ListType[]) => {
      return list.map(({ label, tooltip, ...rest }) => ({
        ...rest,
        label: tooltip ? (
          <Tooltip title={tooltip}>
            <Message value={label} /> <InfoCircleOutlined />
          </Tooltip>
        ) : (
          formatMessage(label)
        ),
      }))
    },
    [formatMessage]
  )

  return (
    <AntdList
      header={formatMessage(header)}
      footer={formatMessage(footer)}
      dataSource={list ? formatList(cleanArray(list)) : undefined}
      renderItem={(item) => (
        <>
          {!item.collapse ? (
            <AntdList.Item
              // Be careful if label is not a string, the key will always be different and
              // will cause to re-render same object in case of DOM update
              key={item.key ?? item.label}
              style={{
                padding: `${eqDSTheme.space.small} ${eqDSTheme.space.medium}`,
              }}
              extra={
                <x.div textAlign="right" marginLeft="small">
                  {item.value}
                </x.div>
              }
            >
              <x.div textAlign="left" marginLeft={item?.marginLeft}>
                {item.label}
              </x.div>
            </AntdList.Item>
          ) : (
            <Collapse
              ghost
              expandIconPosition={item.expandIconPosition ?? undefined}
            >
              <Panel
                key={item.key ?? item.label}
                style={{
                  borderBlockEnd: '1px solid rgba(5, 5, 5, 0.06)',
                }}
                header={
                  <AntdList.Item
                    key={item.key ?? item.label}
                    extra={
                      <x.div textAlign="right" marginLeft="small">
                        {item.value}
                      </x.div>
                    }
                    style={{ padding: eqDSTheme.space.none }}
                  >
                    <x.div textAlign="left" marginLeft={item?.marginLeft}>
                      {item.label}
                    </x.div>
                  </AntdList.Item>
                }
                message={item.collapse}
              />
            </Collapse>
          )}
        </>
      )}
      {...rest}
    />
  )
}

export default React.memo(List)
