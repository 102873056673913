import { gql } from '@apollo/client'

export const GET_CORPORATION_FOLDERS = gql`
  query GET_CORPORATION_FOLDERS($corporationId: ID!) {
    corporation(id: $corporationId) {
      id
      folders {
        id
        name {
          enGB
          frFR
        }
        subFolders {
          id
          name {
            enGB
            frFR
          }
        }
      }
    }
  }
`
