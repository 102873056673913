import React, { useEffect, useMemo, useState } from 'react'

import Input from 'components/atoms/Form/Input'
import Message, { useFormatMessage } from 'components/atoms/Intl/Message'
import Modal, { ModalProps } from 'components/atoms/Modal'
import { SLUGS } from 'config/slugs'
import { useEquibar } from 'providers/Equibar'
import { useStartWorkflow } from 'providers/Workflows/hooks/useStartWorkflow'
import Alert from 'stories/components/atoms/Alert/Alert'
import eqDSTheme from 'styles/eqDSTheme'
import { sanitize } from 'utils'

const ModalDeleteCommunication = ({
  communicationId,
  onCancel,
  onOk,
  ...rest
}: ModalProps & { communicationId: string }) => {
  const formatMessage = useFormatMessage()
  const { autoCompleteDelete } = useEquibar()
  const deleteWord = useMemo(
    () => formatMessage('word.delete') as string,
    [formatMessage]
  )
  const [inputValue, setInputValue] = useState<string | null>(
    autoCompleteDelete ? deleteWord : null
  )

  useEffect(() => {
    if (autoCompleteDelete && deleteWord) {
      setInputValue(deleteWord)
    }
  }, [deleteWord, autoCompleteDelete])

  const [deleteCommunication, loading] = useStartWorkflow({
    data: { communicationId },
    slug: SLUGS.DELETE_COMMUNICATION,
    notification: {
      type: 'success',
      message: 'modal.deleteCommunication.success',
    },
  })

  return (
    <Modal
      centered
      destroyOnClose
      title="word.deleteCommunication"
      okText="word.delete"
      cancelText="word.close"
      okButtonProps={{
        loading,
        danger: true,
        disabled: !inputValue || sanitize(inputValue) !== sanitize(deleteWord),
      }}
      confirmLoading
      onOk={(event) => {
        deleteCommunication()
        if (onCancel) {
          onCancel(event)
        }
      }}
      onCancel={onCancel}
      {...rest}
    >
      <Alert
        showIcon
        type="warning"
        message={formatMessage('word.warning')}
        description={formatMessage('modal.deleteCommunication.content')}
        {...alert}
      />
      <div style={{ marginTop: eqDSTheme.space.xlarge }}>
        <Message
          value={{
            id: 'deleteVerification.label',
            values: { value: deleteWord },
          }}
        />
        <Input
          placeholder={'word.delete'}
          value={inputValue}
          onChange={setInputValue}
        />
      </div>
    </Modal>
  )
}

export default ModalDeleteCommunication
