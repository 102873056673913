// Law firm
export enum LawFirmPermissions {
  LAWYERS_READ = 'LAWYERS_READ',
  LAWYERS_MANAGE = 'LAWYERS_MANAGE',
  CORPORATIONS_READ = 'CORPORATIONS_READ',
  CORPORATIONS_MANAGE = 'CORPORATIONS_MANAGE',
}

export enum LawFirmRoles {
  EDITOR = 'EDITOR',
  VIEWER_ONLY = 'VIEWER_ONLY',
}

export type LawFirmRolesType = {
  EDITOR: LawFirmPermissions[]
  VIEWER_ONLY: LawFirmPermissions[]
}

export const LAW_FIRM_ROLES_PERMISSIONS: LawFirmRolesType = {
  [LawFirmRoles.EDITOR]: [
    LawFirmPermissions.LAWYERS_READ,
    LawFirmPermissions.LAWYERS_MANAGE,
    LawFirmPermissions.CORPORATIONS_READ,
    LawFirmPermissions.CORPORATIONS_MANAGE,
  ],
  [LawFirmRoles.VIEWER_ONLY]: [
    LawFirmPermissions.LAWYERS_READ,
    LawFirmPermissions.CORPORATIONS_READ,
  ],
}

// Corporation
export enum CorporationPermissions {
  ADMIN_READ = 'ADMIN_READ',
  ADMIN_MANAGE = 'ADMIN_MANAGE',
  AUTHORIZATION_READ = 'AUTHORIZATION_READ',
  AUTHORIZATION_MANAGE = 'AUTHORIZATION_MANAGE',
  CAP_TABLE_READ = 'CAP_TABLE_READ',
  CONTACTS_READ = 'CONTACTS_READ',
  CONTACTS_MANAGE = 'CONTACTS_MANAGE',
  DETAILS_READ = 'DETAILS_READ',
  DETAILS_MANAGE = 'DETAILS_MANAGE',
  DOCUMENTS_READ = 'DOCUMENTS_READ',
  DOCUMENTS_MANAGE = 'DOCUMENTS_MANAGE',
  REPORTS_READ = 'REPORTS_READ',
  GOVERNANCE_READ = 'GOVERNANCE_READ',
  GOVERNANCE_MANAGE = 'GOVERNANCE_MANAGE',
  LOGS_READ = 'LOGS_READ',
  POOLS_READ = 'POOLS_READ',
  POOLS_MANAGE = 'POOLS_MANAGE',
  PORTFOLIOS_READ = 'PORTFOLIOS_READ',
  PORTFOLIOS_MANAGE = 'PORTFOLIOS_MANAGE',
  SECURITIES_READ = 'SECURITIES_READ',
  SECURITIES_MANAGE = 'SECURITIES_MANAGE',
  TRANSACTIONS_READ = 'TRANSACTIONS_READ',
  TRANSACTIONS_MANAGE = 'TRANSACTIONS_MANAGE',
  VALUATION_READ = 'VALUATION_READ',
  EQUITY_AWARDS_READ = 'EQUITY_AWARDS_READ',
  CORPORATION_MANAGE = 'CORPORATION_MANAGE',
}

export enum CorporationRoles {
  CORPORATION_OWNER = 'CORPORATION_OWNER',
  EDITOR = 'EDITOR',
  VIEWER_ONLY = 'VIEWER_ONLY',
}

export type CorporationRolesType = {
  EDITOR: CorporationPermissions[]
  VIEWER_ONLY: CorporationPermissions[]
  CORPORATION_OWNER: CorporationPermissions[]
}

export const CORPORATION_ROLES_PERMISSIONS: CorporationRolesType = {
  [CorporationRoles.CORPORATION_OWNER]: [
    CorporationPermissions.CORPORATION_MANAGE,
    CorporationPermissions.DOCUMENTS_READ,
    CorporationPermissions.DOCUMENTS_MANAGE,
    CorporationPermissions.PORTFOLIOS_READ,
    CorporationPermissions.PORTFOLIOS_MANAGE,
    CorporationPermissions.TRANSACTIONS_READ,
    CorporationPermissions.TRANSACTIONS_MANAGE,
    CorporationPermissions.CAP_TABLE_READ,
    CorporationPermissions.ADMIN_READ,
    CorporationPermissions.ADMIN_MANAGE,
    CorporationPermissions.DETAILS_READ,
    CorporationPermissions.DETAILS_MANAGE,
    CorporationPermissions.LOGS_READ,
    CorporationPermissions.POOLS_READ,
    CorporationPermissions.POOLS_MANAGE,
    CorporationPermissions.SECURITIES_READ,
    CorporationPermissions.SECURITIES_MANAGE,
    CorporationPermissions.GOVERNANCE_READ,
    CorporationPermissions.GOVERNANCE_MANAGE,
    CorporationPermissions.CONTACTS_READ,
    CorporationPermissions.CONTACTS_MANAGE,
    CorporationPermissions.AUTHORIZATION_READ,
    CorporationPermissions.AUTHORIZATION_MANAGE,
    CorporationPermissions.REPORTS_READ,
  ],
  [CorporationRoles.EDITOR]: [
    CorporationPermissions.DOCUMENTS_READ,
    CorporationPermissions.DOCUMENTS_MANAGE,
    CorporationPermissions.PORTFOLIOS_READ,
    CorporationPermissions.PORTFOLIOS_MANAGE,
    CorporationPermissions.TRANSACTIONS_READ,
    CorporationPermissions.TRANSACTIONS_MANAGE,
    CorporationPermissions.CAP_TABLE_READ,
    CorporationPermissions.ADMIN_READ,
    CorporationPermissions.ADMIN_MANAGE,
    CorporationPermissions.DETAILS_READ,
    CorporationPermissions.DETAILS_MANAGE,
    CorporationPermissions.LOGS_READ,
    CorporationPermissions.POOLS_READ,
    CorporationPermissions.POOLS_MANAGE,
    CorporationPermissions.SECURITIES_READ,
    CorporationPermissions.SECURITIES_MANAGE,
    CorporationPermissions.GOVERNANCE_READ,
    CorporationPermissions.GOVERNANCE_MANAGE,
    CorporationPermissions.CONTACTS_READ,
    CorporationPermissions.CONTACTS_MANAGE,
    CorporationPermissions.AUTHORIZATION_READ,
    CorporationPermissions.AUTHORIZATION_MANAGE,
    CorporationPermissions.REPORTS_READ,
  ],
  [CorporationRoles.VIEWER_ONLY]: [
    CorporationPermissions.DOCUMENTS_READ,
    CorporationPermissions.PORTFOLIOS_READ,
    CorporationPermissions.TRANSACTIONS_READ,
    CorporationPermissions.CAP_TABLE_READ,
    CorporationPermissions.ADMIN_READ,
    CorporationPermissions.DETAILS_READ,
    CorporationPermissions.LOGS_READ,
    CorporationPermissions.POOLS_READ,
    CorporationPermissions.SECURITIES_READ,
    CorporationPermissions.GOVERNANCE_READ,
    CorporationPermissions.CONTACTS_READ,
    CorporationPermissions.AUTHORIZATION_READ,
    CorporationPermissions.REPORTS_READ,
  ],
}

// Portfolio
export enum PortfolioPermissions {
  DOCUMENTS_READ = 'DOCUMENTS_READ',
  TRANSACTIONS_READ = 'TRANSACTIONS_READ',
  VALUATION_READ = 'VALUATION_READ',
  CORPORATION_GOVERNANCE_READ = 'CORPORATION_GOVERNANCE_READ',
  FULLY_DILUTED_READ = 'FULLY_DILUTED_READ',
  CAP_TABLE_READ = 'CAP_TABLE_READ',
  CORPORATION_VALUATION_READ = 'CORPORATION_VALUATION_READ',
}

export enum PortfolioRoles {
  STANDARD = 'STANDARD',
}

export type PortfolioRolesType = {
  STANDARD: PortfolioPermissions[]
}

export const PORTFOLIO_ROLES_PERMISSIONS: PortfolioRolesType = {
  [PortfolioRoles.STANDARD]: [
    PortfolioPermissions.DOCUMENTS_READ,
    PortfolioPermissions.TRANSACTIONS_READ,
  ],
}

export type PermissionsType =
  | LawFirmPermissions
  | CorporationPermissions
  | PortfolioPermissions

export type RolesType = LawFirmRoles | CorporationRoles | PortfolioRoles
