import { gql } from '@apollo/client'

export const FILES_LINK_MEETINGS = gql`
  mutation FILES_LINK_MEETINGS($ids: [ID!]!, $meetingIds: [ID!]) {
    files(ids: $ids) {
      link(meetingIds: $meetingIds) {
        id
        meetings {
          meetings {
            id
          }
        }
      }
    }
  }
`
