import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { DateType } from 'components/atoms/Form/DatePicker'
import { PriceType } from 'components/atoms/Intl/Price'
import { GET_WALLET_SECURITY_EXERCISE_PRICE } from 'hooks/wallets/useWalletSecurityExercisePrice/GET_WALLET_SECURITY_EXERCISE_PRICE'

export const useWalletSecurityExercisePrice = (
  walletId?: string,
  date?: DateType
) => {
  const result = useQuery<{
    wallet: {
      security: {
        exercisePrice: PriceType
        originalExercisePrice: PriceType
      }
    }
  }>(GET_WALLET_SECURITY_EXERCISE_PRICE, {
    variables: { walletId, date },
    skip: !walletId,
  })

  return useMemo(
    () => ({
      ...result,
      exercisePrice: result?.data?.wallet?.security?.exercisePrice,
      originalExercisePrice:
        result?.data?.wallet?.security?.originalExercisePrice,
    }),
    [result]
  )
}

export const useWalletSecurityHasExercisePrice = (
  walletId: string,
  date?: DateType
) => {
  const { exercisePrice } = useWalletSecurityExercisePrice(walletId, date)

  return Boolean(exercisePrice)
}
