import { useMutation, useQuery } from '@apollo/client'
import { uniq } from 'lodash/fp'
import React, { useEffect, useState } from 'react'

import FormItem from 'components/atoms/Form/FormItem'
import SelectCorporationFolders from 'components/molecules/Select/CorporationFolders'
import { FILES_MOVE } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Folder/FILES_MOVE'
import { GET_SELECTED_FILES_FOLDERS } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Folder/GET_SELECTED_FILES_FOLDERS'

export const Folder = ({
  selectedIds,
  corporationId,
}: {
  selectedIds: string[]
  corporationId: string
}) => {
  const [value, setValue] = useState<string | undefined>(undefined)
  const [move] = useMutation(FILES_MOVE)

  const { data, loading } = useQuery(GET_SELECTED_FILES_FOLDERS, {
    variables: { ids: selectedIds },
  })

  useEffect(() => {
    const values: string[] = uniq(
      (data?.files || []).map((file: any) => file.folder?.id)
    )
    if (values.length === 1) {
      setValue(values[0])
    } else {
      setValue(undefined)
    }
  }, [data])

  return (
    <FormItem label="word.type" required>
      <SelectCorporationFolders
        loading={loading}
        showSearch
        placeholder="word.selectAType"
        corporationId={corporationId}
        value={value}
        onChange={setValue}
        onSelect={(folderId) => {
          move({
            variables: { ids: selectedIds, folderId },
          })
        }}
      />
    </FormItem>
  )
}
