import { gql } from '@apollo/client'

export const FILES_LINK_AGGREGATED_TRANSACTIONS = gql`
  mutation FILES_LINK_AGGREGATED_TRANSACTIONS(
    $ids: [ID!]!
    $aggregatedTransactionIds: [ID!]
  ) {
    files(ids: $ids) {
      link(aggregatedTransactionIds: $aggregatedTransactionIds) {
        id
        aggregatedTransactions {
          aggregatedTransactions {
            id
          }
        }
        securities {
          securities {
            id
          }
        }
        portfolios {
          portfolios {
            id
          }
        }
        pools {
          pools {
            id
          }
        }
      }
    }
  }
`
