// https://ant.design/components/input/

import React from 'react'

import InputWithFormat, {
  InputWithFormatProps,
} from 'components/atoms/Form/InputWithFormat'
import { useParseInteger } from 'components/atoms/Intl/Integer'
import { useFormatNumber } from 'components/atoms/Intl/Number'
import { isNumber } from 'utils/numbers'

export interface InputIntegerProps extends InputWithFormatProps<number | null> {
  min?: number
  max?: number
}

const InputInteger = ({
  min = -Infinity,
  max = Infinity,
  ...rest
}: InputIntegerProps) => {
  const formatNumber = useFormatNumber()
  const parseInteger = useParseInteger()

  return (
    // @ts-ignore
    <InputWithFormat
      className="text-align-right-input"
      placeholder="#"
      parseValue={(value) => {
        const parsedValue = parseInteger(value)
        if (!isNumber(parsedValue)) {
          return null
        }

        if (Number(parsedValue) < min) {
          return parseInteger(min) ?? null
        }

        if (Number(parsedValue) > max) {
          return parseInteger(max) ?? null
        }

        return parsedValue
      }}
      formatValue={(value) => {
        if (isNumber(value)) {
          return formatNumber(Number(value))
        }

        return null
      }}
      {...rest}
    />
  )
}

export default React.memo(InputInteger)
