import { gql } from '@apollo/client'

export const UPDATE_LANGUAGE = gql`
  mutation UPDATE_LANGUAGE($newLanguage: String!) {
    updateLanguage(newLanguage: $newLanguage) {
      id
      lang
    }
  }
`
