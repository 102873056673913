import React from 'react'

import { useFormatNumber } from 'components/atoms/Intl/Number'
import { isUnset } from 'utils'

export type DurationType = {
  value?: number
  unit: DurationUnit
}

export type DaysOrMonths = {
  days: number | null
  months: number | null
}

export enum DurationUnit {
  DAYS = 'DAYS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export const useFormatDuration = () => {
  const formatNumber = useFormatNumber()

  return ({ value, unit }: DurationType) => {
    if (isUnset(value)) {
      return ''
    }

    switch (unit) {
      case DurationUnit.DAYS:
        return formatNumber(value, 'days')
      case DurationUnit.YEARS:
        return formatNumber(value, 'years')
      case DurationUnit.MONTHS: {
        if (value % 12 === 0) {
          return formatNumber(value / 12, 'years')
        }
        if (value > 12) {
          return `${formatNumber(
            Math.floor(value / 12),
            'years'
          )} ${formatNumber(value % 12, 'months')}`
        }
        return formatNumber(value, 'months')
      }
    }
  }
}

export const daysOrMonthsToDuration = ({ days, months }: DaysOrMonths) => {
  if (isUnset(days) && isUnset(months)) {
    return null
  }

  return {
    value: days ?? months ?? 0,
    unit: months ? DurationUnit.MONTHS : DurationUnit.DAYS,
  }
}

const Duration = ({ value, unit }: DurationType) => {
  const formatDuration = useFormatDuration()

  return <>{formatDuration({ value, unit })}</>
}

export default React.memo(Duration)
