import { gql } from '@apollo/client'

export const GET_PORTFOLIO_CORPORATION_ID = gql`
  query GET_PORTFOLIO_CORPORATION_ID($portfolioId: ID!) {
    portfolio(id: $portfolioId) {
      id
      corporation {
        id
      }
    }
  }
`
