import { useQuery } from '@apollo/client'
import React from 'react'

import Number from 'components/atoms/Intl/Number'
import SkeletonText from 'components/atoms/Skeleton/Text'
import { GET_FILE_SHARED_WITH } from 'components/molecules/File/SharedWithCount/GET_FILE_SHARED_WITH'

const FileSharedWithCount = ({ fileId }: { fileId: string }) => {
  const { data, loading } = useQuery(GET_FILE_SHARED_WITH, {
    variables: { fileId },
  })

  return (
    <SkeletonText loading={loading}>
      <Number value={data?.file?.portfolios?.sharedWith?.length} />
    </SkeletonText>
  )
}

export default React.memo(FileSharedWithCount)
