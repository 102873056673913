import { gql } from '@apollo/client'

export const GET_FILE_SIZE = gql`
  query GET_FILE_SIZE($fileId: ID!) {
    file(id: $fileId) {
      id
      sizeInBytes
    }
  }
`
