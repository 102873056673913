import { useQuery } from '@apollo/client'
import { uniq } from 'lodash/fp'
import React, { useMemo } from 'react'

import Center from 'components/atoms/Center'
import Collapse from 'components/atoms/Collapse'
import Form from 'components/atoms/Form'
import Bottom from 'components/atoms/Section/Bottom'
import Spin from 'components/atoms/Spin'
import { FilesEditModalSection } from 'providers/FilesEdit'
import { GET_FILES_CORPORATION_IDS } from 'providers/FilesEdit/Modal/FileProperties/GET_FILES_CORPORATION_IDS'
import Date from 'providers/FilesEdit/Modal/FileProperties/Panels/Date'
import Event from 'providers/FilesEdit/Modal/FileProperties/Panels/Event'
import Folder from 'providers/FilesEdit/Modal/FileProperties/Panels/Folder'
import Language from 'providers/FilesEdit/Modal/FileProperties/Panels/Language'
import Links from 'providers/FilesEdit/Modal/FileProperties/Panels/Links'
import SharedWith from 'providers/FilesEdit/Modal/FileProperties/Panels/SharedWith'
import SignatureFields from 'providers/FilesEdit/Modal/FileProperties/Panels/SignatureFields'
import SmartFields from 'providers/FilesEdit/Modal/FileProperties/Panels/SmartFields'
import { x } from 'utils/emotion'

export const FileProperties = ({
  selectedIds,
  sections = Object.values(FilesEditModalSection),
}: {
  selectedIds: string[]
  sections?: FilesEditModalSection[]
}) => {
  const fileId = useMemo(
    () => (selectedIds.length === 1 ? selectedIds[0] : undefined),
    [selectedIds]
  )
  const { data: filesData, loading } = useQuery<{
    files: {
      id: string
      corporation: {
        id: string
      }
    }[]
  }>(GET_FILES_CORPORATION_IDS, {
    variables: { fileIds: selectedIds },
    skip: !selectedIds?.length,
  })

  const corporationId = useMemo(
    () =>
      uniq(filesData?.files.map((file: any) => file.corporation.id) || [])
        .length === 1
        ? filesData?.files[0].corporation.id
        : undefined,
    [filesData]
  )

  const defaultActiveKey = useMemo(() => {
    if (!sections?.length) {
      return 0
    }
    const index = Object.keys(FilesEditModalSection).indexOf(sections[0])
    return index < 0 ? 0 : index
  }, [sections])

  return (
    <x.div backgroundColor="background" flex="1" overflowY="auto">
      {loading || !corporationId ? (
        <Center h="100%">
          <Spin />
        </Center>
      ) : (
        <Form layout="vertical">
          <Collapse
            accordion
            bordered={false}
            defaultActiveKey={[defaultActiveKey]}
          >
            <Folder
              fileId={fileId}
              sections={sections}
              selectedIds={selectedIds}
              corporationId={corporationId}
            />
            <Links
              selectedIds={selectedIds}
              sections={sections}
              corporationId={corporationId}
            />
            <Date
              selectedIds={selectedIds}
              fileId={fileId}
              sections={sections}
            />
            <Event
              selectedIds={selectedIds}
              fileId={fileId}
              sections={sections}
              corporationId={corporationId}
            />
            <SharedWith
              selectedIds={selectedIds}
              fileId={fileId}
              sections={sections}
              corporationId={corporationId}
            />
            <Language
              selectedIds={selectedIds}
              fileId={fileId}
              sections={sections}
            />
            <SmartFields fileId={fileId} sections={sections} />
            <SignatureFields fileId={fileId} sections={sections} />
          </Collapse>
        </Form>
      )}
      <Bottom />
    </x.div>
  )
}
