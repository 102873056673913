import { gql } from '@apollo/client'

export const FILES_MOVE = gql`
  mutation FILES_MOVE($ids: [ID!]!, $folderId: ID!) {
    files(ids: $ids) {
      move(folderId: $folderId) {
        id
        folder {
          id
        }
      }
    }
  }
`
