import { CloseOutlined, QuestionOutlined } from '@ant-design/icons'
import { useFormikContext } from 'formik'
import React, { ReactNode, useEffect, useState } from 'react'

import Button from 'components/atoms/Button'
import Center from 'components/atoms/Center'
import Form from 'components/atoms/Form'
import { MessageType } from 'components/atoms/Intl/Message'
import Section from 'components/atoms/Section'
import Bottom from 'components/atoms/Section/Bottom'
import Flex from 'components/atoms/Section/Flex'
import Space from 'components/atoms/Space'
import Spin from 'components/atoms/Spin'
import Tooltip from 'components/atoms/Tooltip'
import Text from 'components/atoms/Typography/Text'
import { useEquibar } from 'providers/Equibar'
import { useWorkflow } from 'providers/Workflows'
import { WorkflowButtons } from 'providers/Workflows/components/WorkflowButtons'
import { WorkflowDatas } from 'providers/Workflows/components/WorkflowDatas'
import { WorkflowErrorAlert } from 'providers/Workflows/components/WorkflowErrorAlert'
import { WorkflowFieldProps } from 'providers/Workflows/components/WorkflowField'
import { WorkflowFieldsSymbol } from 'providers/Workflows/components/WorkflowFields'
import { WorkflowSteps } from 'providers/Workflows/components/WorkflowSteps'
import { cleanArray } from 'utils'
import { x } from 'utils/emotion'

export const WorkflowFullPage = ({
  title,
  noBackgroundColor = false,
  hideCloseButton = false,
  buttons = <WorkflowButtons />,
  helpCenterLink,
  children,
  loading,
  maxWidth,
}: {
  title?: MessageType
  noBackgroundColor?: boolean
  hideCloseButton?: boolean
  helpCenterLink?: string
  buttons?: ReactNode
  children: ReactNode
  loading?: boolean
  maxWidth?: string
}) => {
  const { close } = useWorkflow()
  const { values, errors } = useFormikContext()
  const { seeWorkflowValues } = useEquibar()
  const [canUseNextButton, setCanUseNextButton] = useState(true)

  const getRequiredFields = (
    fields: (WorkflowFieldProps | WorkflowFieldProps[])[]
  ) => {
    let requiredFields: WorkflowFieldProps[] = []

    fields.forEach((field: WorkflowFieldProps | WorkflowFieldProps[]) => {
      if (Array.isArray(field)) {
        const requiredArrayFields = field
          ? field.filter((f: WorkflowFieldProps) => f && f.required)
          : []
        requiredFields = [...requiredFields, ...requiredArrayFields]
      } else if (field && field.required) {
        requiredFields.push(field)
      }
    })

    return requiredFields
  }

  const filterEmptyFields = (fields: WorkflowFieldProps[]) => {
    return fields.filter((field: any) => {
      const vals = values as Record<string, string>

      let isInValues = false

      if (field.name && typeof field.name === 'string') {
        const keys = field.name.split('.')

        const fieldValue = keys.reduce((acc: any, key: any) => acc[key], vals)

        isInValues =
          fieldValue !== undefined && fieldValue !== '' && fieldValue !== null
      }

      if (!isInValues) {
        const componentValueIsEmptyArray =
          !!field.componentProps &&
          Array.isArray(field.componentProps.value) &&
          cleanArray(field.componentProps.value).length === 0

        const isInValueFieldProps =
          field.componentProps &&
          field.componentProps.value !== undefined &&
          field.componentProps.value !== '' &&
          field.componentProps.value !== null &&
          !componentValueIsEmptyArray

        return !isInValueFieldProps
      }

      return !isInValues
    })
  }

  const getEmptyMandatoryFields = (
    workflowFieldsComponents: React.ElementType[]
  ): WorkflowFieldProps[] => {
    let fields: WorkflowFieldProps[] = []

    workflowFieldsComponents.forEach(
      (workflowFieldsComponent: React.ElementType) => {
        // we only treat fields from WorkflowFields component
        if (
          !workflowFieldsComponent ||
          (workflowFieldsComponent as any).type?.displayName !==
            WorkflowFieldsSymbol ||
          (workflowFieldsComponent as any).props.fields === undefined ||
          (workflowFieldsComponent as any).props.fields.length === 0
        ) {
          return
        }

        fields = [...fields, ...(workflowFieldsComponent as any).props.fields]
      }
    )

    const requiredFields = getRequiredFields(fields)

    return filterEmptyFields(requiredFields)
  }

  const searchForWorkflowFieldsElements = (
    elements: any
  ): React.ElementType[] => {
    if (Array.isArray(elements)) {
      let elementFounds: React.ElementType[] = []
      elements.forEach((element) => {
        elementFounds = [
          ...elementFounds,
          ...searchForWorkflowFieldsElements(element),
        ]
      })
    } else if (
      elements &&
      elements.type?.displayName === WorkflowFieldsSymbol
    ) {
      return [elements]
    }

    return []
  }

  const areMandatoryFieldsEmpty = (): boolean => {
    const workflowFieldsElementsFound =
      searchForWorkflowFieldsElements(children)

    if (!workflowFieldsElementsFound) {
      return false
    }

    const emptyMandatoryFields = getEmptyMandatoryFields(
      workflowFieldsElementsFound
    )

    return emptyMandatoryFields.length > 0
  }

  useEffect(() => {
    setCanUseNextButton(!areMandatoryFieldsEmpty())
  }, [children, values])

  return (
    <x.div
      top={0}
      left={0}
      right={0}
      bottom={0}
      overflow="auto"
      position="fixed"
      padding="30px 90px"
      backgroundColor="background"
    >
      <Section flex margin="0" padding="40px" justifyContent="center">
        <x.div w="100%" maxWidth="1000px" margin="auto">
          <WorkflowSteps />
        </x.div>
      </Section>
      <x.div
        w="100%"
        margin="auto"
        padding="large"
        border="classic"
        borderRadius="medium"
        borderColor={noBackgroundColor ? 'transparent' : 'lightgrey'}
        backgroundColor={noBackgroundColor ? 'transparent' : 'white'}
        maxWidth={maxWidth}
      >
        <x.div
          display="flex"
          fontSize="large"
          fontWeight={500}
          marginBottom="large"
        >
          {title && <Text message={title} />}
          <Flex />
          <Space>
            {helpCenterLink && (
              <Tooltip title="word.helpCenter">
                <Button
                  style={{ fontSize: 'small' }}
                  href={helpCenterLink}
                  target="_blank"
                  shape="circle"
                  icon={<QuestionOutlined />}
                  type="text"
                />
              </Tooltip>
            )}
            {hideCloseButton ? undefined : (
              <Button
                onClick={close}
                shape="circle"
                icon={<CloseOutlined />}
                type="text"
              />
            )}
          </Space>
        </x.div>
        {/* @ts-ignore */}
        {errors?.root && (
          <x.div marginBottom="medium">
            <WorkflowErrorAlert />
          </x.div>
        )}
        <Form layout="vertical">
          {loading ? (
            <Center h="200px">
              <Spin />
            </Center>
          ) : (
            children
          )}
        </Form>
        {seeWorkflowValues && (
          <x.div marginTop="xlarge">
            <WorkflowDatas />
          </x.div>
        )}
        {buttons && React.isValidElement(buttons) && (
          <x.div marginTop="xlarge" textAlign="right">
            {React.Children.map(buttons, (button) =>
              React.isValidElement(button)
                ? React.cloneElement(
                    button as React.ReactElement<{
                      disableNextButton?: boolean
                    }>,
                    {
                      disableNextButton:
                        'disableNextButton' in button.props
                          ? button.props.disableNextButton
                          : !canUseNextButton,
                    }
                  )
                : button
            )}
          </x.div>
        )}
      </x.div>
      <Bottom />
    </x.div>
  )
}
