// https://github.com/react-component/field-form/blob/master/src/utils/messages.ts

/* eslint-disable no-template-curly-in-string */
const typeTemplate = "Le format n'est pas valide"

export default {
  default: 'Erreur de validation du champ',
  required: 'Le champ est requis',
  enum: "Le champ doit être l'un de [$ {enum}]",
  whitespace: 'Le champ ne peut pas être vide',
  date: {
    format: "Le format de la date n'est pas valide",
    parse: "Le format de la date n'est pas valide",
    invalid: "Le format de la date n'est pas valide",
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
  string: {
    len: 'Le champ doit contenir exactement ${len} caractères',
    max: 'Le champ ne peut pas contenir plus de ${max} caractères',
    min: 'Le champ doit contenir au moins ${min} caractères',
    range: 'Le champ doit contenir entre ${min} et ${max} caractères',
  },
  number: {
    len: 'Le champ doit être égal à ${len}',
    max: 'Le champ ne peut pas être supérieur à ${max}',
    min: 'Le champ ne peut pas être inférieur à ${min}',
    range: 'Le champ doit être compris entre ${min} et ${max}',
  },
  array: {
    len: 'Le champ doit avoir exactement ${len} de longueur',
    min: 'La longueur du champ ne peut pas être inférieure à ${min}',
    max: 'La longueur du champ ne peut pas dépasser ${max}',
    range: 'Le champ doit avoir une longueur comprise entre ${min} et ${max}',
  },
  pattern: {
    mismatch: 'Le champ ne correspond pas au modèle ${pattern}',
  },
}
