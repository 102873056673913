import { gql } from '@apollo/client'

export const FILES_LINK_GOVERNANCE_GROUPS = gql`
  mutation FILES_LINK_GOVERNANCE_GROUPS(
    $ids: [ID!]!
    $governanceGroupIds: [ID!]
  ) {
    files(ids: $ids) {
      link(governanceGroupIds: $governanceGroupIds) {
        id
        governanceGroups {
          groups {
            id
          }
        }
      }
    }
  }
`
