import React, { lazy, Suspense, useMemo } from 'react'

import { CorporationPermissions, PortfolioPermissions } from 'config/roles'
import { useDrawer } from 'providers/Drawer'
import { AggregatedTransactionDrawerTab } from 'providers/Drawer/drawers/AggregatedTransaction'
import { usePermissions } from 'providers/Permissions'

const Details = lazy(
  () => import('providers/Drawer/drawers/AggregatedTransaction/Content/Details')
)
const Documents = lazy(
  () =>
    import('providers/Drawer/drawers/AggregatedTransaction/Content/Documents')
)

const Content = () => {
  const { tab } = useDrawer()
  const { checkPermissions } = usePermissions()

  const Component = useMemo(() => {
    switch (tab) {
      case AggregatedTransactionDrawerTab.details:
        return Details
      case AggregatedTransactionDrawerTab.documents:
        return checkPermissions(CorporationPermissions.DOCUMENTS_READ) ||
          checkPermissions(PortfolioPermissions.DOCUMENTS_READ)
          ? Documents
          : undefined
    }
    return undefined
  }, [checkPermissions, tab])

  if (!Component) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  )
}

export default React.memo(Content)
