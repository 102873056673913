import React, { lazy, Suspense, useMemo } from 'react'

import { CorporationPermissions, PortfolioPermissions } from 'config/roles'
import { useDrawer } from 'providers/Drawer'
import { SecurityDrawerTab } from 'providers/Drawer/drawers/Security'
import { usePermissions } from 'providers/Permissions'

const Capitalisation = lazy(
  () => import('providers/Drawer/drawers/Security/Content/Capitalisation')
)
const Details = lazy(
  () => import('providers/Drawer/drawers/Security/Content/Details')
)
const Documents = lazy(
  () => import('providers/Drawer/drawers/Security/Content/Documents')
)
const Plan = lazy(
  () => import('providers/Drawer/drawers/Security/Content/Plan')
)
const VestingSchedule = lazy(
  () => import('providers/Drawer/drawers/Security/Content/VestingSchedule')
)
const Transactions = lazy(
  () => import('providers/Drawer/drawers/Security/Content/Transactions')
)

const Content = () => {
  const { tab } = useDrawer()
  const { checkPermissions } = usePermissions()

  const Component = useMemo(() => {
    switch (tab) {
      case SecurityDrawerTab.capitalisation:
        return Capitalisation
      case SecurityDrawerTab.details:
        return Details
      case SecurityDrawerTab.plan:
        return Plan
      case SecurityDrawerTab.vestingSchedule:
        return VestingSchedule
      case SecurityDrawerTab.transactions:
        return checkPermissions(CorporationPermissions.TRANSACTIONS_READ) ||
          checkPermissions(PortfolioPermissions.TRANSACTIONS_READ)
          ? Transactions
          : undefined
      case SecurityDrawerTab.documents:
        return checkPermissions(CorporationPermissions.DOCUMENTS_READ) ||
          checkPermissions(PortfolioPermissions.DOCUMENTS_READ)
          ? Documents
          : undefined
    }
    return undefined
  }, [checkPermissions, tab])

  if (!Component) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  )
}

export default React.memo(Content)
