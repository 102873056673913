import { useState } from 'react'

import { useUpdateCache } from 'hooks/useUpdateCache'
import { useNotification, NotificationConfigType } from 'providers/Notification'
import { StartOptionsType, useWorkflows } from 'providers/Workflows'

export interface StartWorkflowProps extends StartOptionsType {
  notification?: NotificationConfigType
  updateEntities?: string[]
}

export const useStartWorkflow = ({
  onCompleted = () => null,
  updateEntities,
  notification,
  ...args
}: StartWorkflowProps): [(override?: any) => void, boolean] => {
  const { start } = useWorkflows()
  const { sendNotification } = useNotification()
  const [loading, setLoading] = useState(false)

  return [
    async (override) => {
      if (typeof override?.stopPropagation === 'function') {
        override.stopPropagation()
      }
      setLoading(true)
      try {
        await start({
          ...args,
          ...override,
          onCompleted: async (result: any) => {
            if (notification?.type) {
              const { type, ...notificationRest } = notification
              sendNotification(type)(notificationRest)
            }

            return (override?.onCompleted || onCompleted)(result)
          },
        })
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
    },
    loading,
  ]
}
