import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_FILE_DATE } from 'hooks/files/useFileDate/GET_FILE_DATE'

export const useFileDate = (fileId?: string) => {
  const result = useQuery<{
    file: {
      date: string
    }
  }>(GET_FILE_DATE, {
    variables: { fileId },
    skip: !fileId,
  })

  return useMemo(
    () => ({
      ...result,
      date: result.data?.file?.date,
    }),
    [result]
  )
}
