import { gql } from '@apollo/client'

export const FILES_LINK_PORTFOLIOS = gql`
  mutation FILES_LINK_PORTFOLIOS($ids: [ID!]!, $portfolioIds: [ID!]) {
    files(ids: $ids) {
      link(portfolioIds: $portfolioIds) {
        id
        portfolios {
          portfolios {
            id
          }
        }
      }
    }
  }
`
