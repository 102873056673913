import { gql } from '@apollo/client'

export const GET_CORPORATION_NAME = gql`
  query GET_CORPORATION_NAME($corporationId: ID!) {
    corporation(id: $corporationId) {
      id
      corporationInfo {
        id
        name
      }
    }
  }
`
