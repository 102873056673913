import React from 'react'

import List from 'components/atoms/List'
import { useFileSignatureGroups } from 'hooks/files/useFileSignatureGroups'
import { x } from 'utils/emotion'

const FileSignatureGroups = ({ fileId }: { fileId: string }) => {
  const { groups, loading } = useFileSignatureGroups(fileId)

  return (
    <List
      loading={loading}
      list={groups.map(({ name, rawName }, index) => ({
        key: index,
        label: name,
        value: <x.div color="grey">{`{${rawName}}`}</x.div>,
      }))}
    />
  )
}

export default React.memo(FileSignatureGroups)
