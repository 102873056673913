import eqDSTheme from 'styles/eqDSTheme'

export enum MEETING_ATTENDEES_RESPONSE {
  APPOINTED_PROXY = 'APPOINTED_PROXY',
  CONFIRMED = 'CONFIRMED',
  NOT_RESPONDED = 'NOT_RESPONDED',
  REJECTED = 'REJECTED',
}

export const MEETING_ATTENDEES_RESPONSE_COLOR = {
  [MEETING_ATTENDEES_RESPONSE.APPOINTED_PROXY]: eqDSTheme.color.primary,
  [MEETING_ATTENDEES_RESPONSE.CONFIRMED]: eqDSTheme.color.palette.green100,
  [MEETING_ATTENDEES_RESPONSE.NOT_RESPONDED]: eqDSTheme.color.secondary,
  [MEETING_ATTENDEES_RESPONSE.REJECTED]: eqDSTheme.color.palette.red100,
}
