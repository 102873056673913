import { useQuery } from '@apollo/client'
import prettyBytes from 'pretty-bytes'
import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { GET_FILE_SIZE } from 'components/molecules/File/Size/GET_FILE_SIZE'
import { isSet } from 'utils'

const FileSize = ({ fileId }: { fileId: string }) => {
  const { data, loading } = useQuery(GET_FILE_SIZE, {
    variables: { fileId },
  })

  return (
    <SkeletonText loading={loading}>
      {isSet(data?.file?.sizeInBytes)
        ? prettyBytes(data.file.sizeInBytes)
        : null}
    </SkeletonText>
  )
}

export default React.memo(FileSize)
