import { ConfigProvider } from 'antd'
import enGB from 'antd/lib/locale/en_GB'
import frFR from 'antd/lib/locale/fr_FR'
import { get } from 'lodash/fp'
import React, { ReactNode } from 'react'
import 'dayjs/locale/fr'
import 'dayjs/locale/en-gb'

import enGBFormRules from 'providers/Antd/formRules/enGB'
import frFRFormRules from 'providers/Antd/formRules/frFR'
import { useLocale } from 'providers/Locale'
import eqAntdTheme from 'styles/eqAntdTheme'

ConfigProvider.config({
  prefixCls: 'eq',
})

export const AntdProvider = ({ children }: { children: ReactNode }) => {
  const { locale } = useLocale()

  return (
    <ConfigProvider
      locale={get(locale, { frFR, enGB })}
      form={{
        validateMessages: get(locale, {
          frFR: frFRFormRules,
          enGB: enGBFormRules,
        }),
      }}
      prefixCls="eq"
      theme={eqAntdTheme}
    >
      {children}
    </ConfigProvider>
  )
}
