import React, { useMemo } from 'react'

import Drawer from 'components/atoms/Drawer'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import ButtonMoreActionsCorporationInfo from 'components/molecules/ButtonMoreActions/CorporationInfo'
import CorporationInfoName from 'components/molecules/CorporationInfo/Name'
import CorporationInfoType from 'components/molecules/CorporationInfo/Type'
import { WORKSPACE } from 'config/workspace'
import { useCorporationInfoName } from 'hooks/corporationInfo/useCorporationInfoName'
import { useWorkspace } from 'hooks/useWorkspace'
import { useDrawer } from 'providers/Drawer'
import Content from 'providers/Drawer/drawers/LegalEntity/Content'
import Sider from 'providers/Drawer/drawers/LegalEntity/Sider'

export enum LegalEntityDrawerTab {
  details = 'details',
  links = 'links',
}

function LegalEntity() {
  const { id } = useDrawer()
  const { name } = useCorporationInfoName(id)
  const { workspaceId, workspace } = useWorkspace()
  const corporationId = useMemo(
    () => (workspace === WORKSPACE.CORPORATION ? workspaceId : undefined),
    [workspace, workspaceId]
  )

  const formatMessage = useFormatMessage()

  return (
    <Drawer
      open
      helmetTitle={
        name
          ? `${formatMessage('word.legalEntity')} · ${name}`
          : 'word.legalEntity'
      }
      pageHeaderProps={{
        title: <CorporationInfoName corporationInfoId={id} />,
        subTitle: 'word.contact',
        tags: <CorporationInfoType corporationInfoId={id} />,
        extra: corporationId && (
          <ButtonMoreActionsCorporationInfo
            corporationInfoId={id}
            corporationId={corporationId}
          />
        ),
      }}
      sider={Sider}
      content={Content}
    />
  )
}

export default React.memo(LegalEntity)
