import { gql } from '@apollo/client'

export const GET_SELECTED_FILES_MEETINGS = gql`
  query GET_SELECTED_FILES_MEETINGS($ids: [ID!]!) {
    files(ids: $ids) {
      id
      meetings {
        meetings {
          id
        }
      }
    }
  }
`
