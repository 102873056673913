// https://ant.design/components/icon/
// https://fontawesome.com/how-to-use/on-the-web/using-with/react

// IMPORTANT:
// If your icon does appear, maybe you forgot to add it to the library: src/config/fontAwesome.ts

import Icon from '@ant-design/icons'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon as FortAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import React from 'react'

enum FontAwesomeType {
  Light = 'fal',
  Regular = 'far',
  Solid = 'fas',
  DualTone = 'fad',
}

const FontAwesomeIcon = ({
  type = FontAwesomeType.Light,
  name,
  ...rest
}: Omit<FontAwesomeIconProps, 'icon'> & {
  type?: FontAwesomeType
  name: IconName
}) => {
  return (
    <Icon component={() => <FortAwesomeIcon icon={[type, name]} {...rest} />} />
  )
}

export default React.memo(FontAwesomeIcon)
