import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import React, { useState } from 'react'

import ButtonMoreActions from 'components/atoms/Button/MoreActions'
import Menu from 'components/atoms/Menu'
import ModalDeleteCommunication from 'components/molecules/Modal/deleteCommunication'
import { CorporationPermissions } from 'config/roles'
import { SLUGS } from 'config/slugs'
import { usePermissions } from 'providers/Permissions'
import { useStartWorkflow } from 'providers/Workflows/hooks/useStartWorkflow'

const ButtonMoreActionsCommunication = ({
  communicationId,
  corporationId,
}: {
  communicationId: string
  corporationId: string
}) => {
  const { checkPermissions } = usePermissions()

  const [editCommunication] = useStartWorkflow({
    data: { corporationId, communicationId },
    slug: SLUGS.EDIT_COMMUNICATION,
    notification: {
      type: 'success',
      message: 'modal.editCommunication.success',
    },
  })

  const [deleteCommunicationModalVisible, setDeleteCommunicationModalVisible] =
    useState(false)

  if (!checkPermissions(CorporationPermissions.GOVERNANCE_MANAGE)) {
    return null
  }

  return (
    <ButtonMoreActions
      overlay={
        <>
          <Menu
            menu={[
              {
                key: 'editCommunication',
                icon: <EditOutlined />,
                message: 'word.edit',
                onClick: editCommunication,
              },
              {
                key: 'deleteCommunication',
                icon: <DeleteOutlined />,
                message: 'word.delete',
                onClick: () => setDeleteCommunicationModalVisible(true),
              },
            ]}
          />
          <ModalDeleteCommunication
            communicationId={communicationId}
            open={deleteCommunicationModalVisible}
            onCancel={() => setDeleteCommunicationModalVisible(false)}
          />
        </>
      }
    />
  )
}

export default React.memo(ButtonMoreActionsCommunication)
