import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import EventName, { EventNameProps } from 'components/molecules/Event/Name'
import { useFileEventId } from 'hooks/files/useFileEventId'

const FileEventName = ({
  fileId,
  ...rest
}: Omit<EventNameProps, 'eventId'> & { fileId: string }) => {
  const { eventId, loading } = useFileEventId(fileId)

  return (
    <SkeletonText loading={loading}>
      {eventId ? <EventName eventId={eventId} {...rest} /> : null}
    </SkeletonText>
  )
}

export default React.memo(FileEventName)
