// https://ant.design/components/notification/

import { useQuery, useSubscription } from '@apollo/client'
import mime from 'mime'
import React, { useCallback, useEffect, useState } from 'react'

import Progress from 'components/atoms/Progress'
import { ONE_SECOND } from 'config/date'
import { DrawerNames } from 'config/drawer'
import { ExportFormat, ExportStatus } from 'config/export'
import { useDrawers } from 'providers/Drawers'
import { useNotification } from 'providers/Notification'
import { GENERATED_FILE_UPDATES } from 'providers/Notification/hooks/useExportNotification/GENERATED_FILE_UPDATES'
import { GET_GENERATED_FILE } from 'providers/Notification/hooks/useExportNotification/GET_GENERATED_FILE'
import { downloadFile } from 'utils/files'

type GeneratedFile = {
  status: ExportStatus
  filePath: string
  format: ExportFormat
  file: { id: string }
}

const ExportNotificationContent = ({
  exportId,
  name,
}: {
  exportId: string
  name: string
}) => {
  const { openDrawer } = useDrawers()
  const { successNotification, errorNotification } = useNotification()
  const [progress, setProgress] = useState(0)

  const { data: subscriptionData } = useSubscription<{
    generatedFileUpdates: {
      generatedFile: GeneratedFile
      progress: number
    }
  }>(GENERATED_FILE_UPDATES, {
    variables: { generatedFileId: exportId },
    skip: Boolean(!exportId || progress === 1),
  })

  const {
    data: queryData,
    startPolling,
    stopPolling,
  } = useQuery<{
    generatedFile: GeneratedFile
  }>(GET_GENERATED_FILE, {
    variables: { generatedFileId: exportId },
    skip: Boolean(!exportId || subscriptionData || progress),
  })

  const handleDownloadFile = useCallback(
    ({
      filePath,
      format,
      fileId,
    }: {
      filePath?: string
      format: string
      fileId?: string
    }) => {
      stopPolling()
      if (fileId) {
        openDrawer({
          name: DrawerNames.file,
          id: fileId,
        })
      } else if (filePath) {
        const mimeType = mime.getType(format)

        downloadFile({
          url: filePath,
          name: mimeType ? `${name}.${mime.getExtension(mimeType)}` : name,
        })
      }

      successNotification({
        key: exportId,
        message: 'word.documentSuccessfullyDownloaded',
        description: (
          <div>
            <Progress percent={100} />
          </div>
        ),
      })
    },
    [exportId, name]
  )

  const handleError = useCallback(() => {
    stopPolling()

    errorNotification({
      key: exportId,
      message: 'word.anErrorOccurred',
      description: (
        <div>
          <Progress percent={progress * 100} status="exception" />
        </div>
      ),
    })
  }, [progress])

  useEffect(() => {
    const newProgress = Math.min(
      1,
      subscriptionData?.generatedFileUpdates?.progress || 0
    )

    if (newProgress > progress) {
      setProgress(newProgress)
    }
  }, [subscriptionData, progress])

  useEffect(() => {
    if (
      subscriptionData?.generatedFileUpdates?.generatedFile?.status ===
        ExportStatus.DONE &&
      (subscriptionData?.generatedFileUpdates?.generatedFile?.file?.id ||
        subscriptionData?.generatedFileUpdates?.generatedFile?.filePath)
    ) {
      handleDownloadFile({
        fileId: subscriptionData.generatedFileUpdates.generatedFile.file?.id,
        filePath: subscriptionData.generatedFileUpdates.generatedFile.filePath,
        format: subscriptionData.generatedFileUpdates.generatedFile.format,
      })
    } else if (
      subscriptionData?.generatedFileUpdates?.generatedFile?.status ===
      ExportStatus.FAILED
    ) {
      handleError()
    }
  }, [subscriptionData])

  useEffect(() => {
    if (exportId && !subscriptionData && !progress) {
      startPolling(2 * ONE_SECOND)
    }
  }, [exportId, subscriptionData, progress])

  useEffect(() => {
    if (
      queryData?.generatedFile?.status === ExportStatus.DONE &&
      (queryData?.generatedFile?.file?.id || queryData?.generatedFile?.filePath)
    ) {
      handleDownloadFile({
        fileId: queryData.generatedFile.file?.id,
        filePath: queryData.generatedFile.filePath,
        format: queryData.generatedFile.format,
      })
    } else if (queryData?.generatedFile?.status === ExportStatus.FAILED) {
      handleError()
    }
  }, [queryData])

  return (
    <div>
      <Progress percent={Math.round(progress * 100)} status="active" />
    </div>
  )
}

export const useExportNotification = () => {
  const { infoNotification } = useNotification()

  return (exportId: string, name: string) => {
    infoNotification({
      key: exportId,
      duration: 0,
      message: 'word.documentIsBeingPrepared',
      description: (
        <ExportNotificationContent exportId={exportId} name={name} />
      ),
    })
  }
}
