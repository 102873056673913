import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import Tag, { TagProps } from 'components/atoms/Tag'
import Tooltip from 'components/atoms/Tooltip'
import { SECURITY_COLOR, SecurityType } from 'config/security'
import { useSecurityType } from 'hooks/securities/useSecurityType'

const TagSecurityType = ({
  securityId,
  type,
  noSkeleton,
  ...rest
}: TagProps & {
  securityId?: string
  type?: SecurityType
  noSkeleton?: boolean
}) => {
  const { type: securityType, loading } = useSecurityType(securityId)

  return (
    <SkeletonText loading={!noSkeleton && loading}>
      {(type || securityType) && (
        <Tooltip title={`security.type.${type || securityType}`}>
          <Tag
            color={SECURITY_COLOR[(type || securityType) as SecurityType]}
            message={`security.acronym.${type || securityType}`}
            {...rest}
          />
        </Tooltip>
      )}
    </SkeletonText>
  )
}

export default React.memo(TagSecurityType)
