import { gql } from '@apollo/client'

export const GENERATED_FILE_UPDATES = gql`
  subscription GENERATED_FILE_UPDATES($generatedFileId: ID!) {
    generatedFileUpdates(id: $generatedFileId) {
      generatedFile {
        id
        status
        format
        filePath
        file {
          id
        }
      }
      progress
    }
  }
`
