import { gql } from '@apollo/client'

export const GET_FILE_PREVIEW_URL = gql`
  query GET_FILE_PREVIEW_URL($fileId: ID!) {
    file(id: $fileId) {
      id
      previewUrl
    }
  }
`
