import React, { Dispatch, SetStateAction, useContext } from 'react'

import { PermissionsType } from 'config/roles'

interface IPermissionsContext {
  loading: boolean
  permissions: PermissionsType[]
  setPermissions: Dispatch<SetStateAction<PermissionsType[]>>
  checkPermissions: (value: PermissionsType[] | PermissionsType) => boolean
}

export const PermissionsContext = React.createContext<IPermissionsContext>({
  loading: true,
  permissions: [],
  setPermissions: () => {},
  checkPermissions: () => false,
})

export const usePermissions = (): IPermissionsContext =>
  useContext(PermissionsContext)
