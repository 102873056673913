import { CopyOutlined, EditOutlined } from '@ant-design/icons'
import React from 'react'

import ButtonMoreActions from 'components/atoms/Button/MoreActions'
import Menu from 'components/atoms/Menu'
import { CorporationPermissions } from 'config/roles'
import { SLUGS } from 'config/slugs'
import { usePermissions } from 'providers/Permissions'
import { useStartWorkflow } from 'providers/Workflows/hooks/useStartWorkflow'

const ButtonMoreActionsSecurity = ({
  securityId,
  corporationId,
}: {
  securityId: string
  corporationId: string | undefined
}) => {
  const { checkPermissions } = usePermissions()
  const [startEditSecurity] = useStartWorkflow({
    data: { securityId },
    slug: 'editSecurity',
  })
  const [startDuplicateSecurity] = useStartWorkflow({
    data: { corporationId, copyFromSecurityId: securityId },
    slug: SLUGS.CREATE_SECURITY,
    notification: {
      type: 'success',
      message: 'word.securitySuccessfullyCreated',
    },
  })

  if (!checkPermissions(CorporationPermissions.SECURITIES_MANAGE)) {
    return null
  }

  return (
    <ButtonMoreActions
      overlay={
        <Menu
          menu={[
            {
              key: 'edit',
              icon: <EditOutlined />,
              message: 'word.edit',
              onClick: startEditSecurity,
            },
            {
              key: 'edit',
              icon: <CopyOutlined />,
              disabled: !corporationId,
              message: 'word.duplicate',
              onClick: startDuplicateSecurity,
            },
          ]}
        />
      }
    />
  )
}

export default React.memo(ButtonMoreActionsSecurity)
