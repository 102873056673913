import { gql } from '@apollo/client'

export const GET_ME = gql`
  query GET_ME {
    me {
      id
      name
      firstName
      lastName
      primaryEmail
      emails {
        id
        email
        emailVerified
        primaryEmail
        isLinked
      }
      phone
    }
  }
`
