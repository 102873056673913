import React, { ReactNode, useContext } from 'react'

export type SelectionProviderProps = {
  children: ReactNode | ((context: ISelectionContext) => ReactNode)
  defaultIds?: string[]
  disabled?: boolean
}

export interface ISelectionContext {
  ids?: string[]
  count: number
  add: (addedIds: string[]) => void
  set: React.Dispatch<React.SetStateAction<string[]>>
  toggle: (switchedIds: string[]) => void
  remove: (removedIds: string[]) => void
  has: (checkIds: string[]) => boolean
  clear: () => void
}

export const SelectionContext = React.createContext<ISelectionContext>({
  ids: undefined,
  count: 0,
  add: () => {},
  set: () => {},
  toggle: () => {},
  remove: () => {},
  has: () => false,
  clear: () => {},
})

export const useSelection = (): ISelectionContext =>
  useContext(SelectionContext)
