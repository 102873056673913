import { gql } from '@apollo/client'

export const GET_EVENT_DATE = gql`
  query GET_EVENT_DATE($eventId: ID!) {
    event(id: $eventId) {
      id
      date
    }
  }
`
