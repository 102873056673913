import { AlignLeftOutlined, FileTextOutlined } from '@ant-design/icons'
import { MenuInfo } from 'rc-menu/lib/interface.d'
import React, { ReactElement } from 'react'

import Menu from 'components/atoms/Menu'
import { CorporationPermissions, PortfolioPermissions } from 'config/roles'
import { useDrawer } from 'providers/Drawer'
import { AggregatedTransactionDrawerTab } from 'providers/Drawer/drawers/AggregatedTransaction'
import { usePermissions } from 'providers/Permissions'
import { isVirtual } from 'utils'

const Sider = (): ReactElement => {
  const { tab, changeTab, id } = useDrawer()
  const { checkPermissions } = usePermissions()

  const handleClick = ({ key }: MenuInfo) => {
    changeTab(key as AggregatedTransactionDrawerTab)
  }

  return (
    <Menu
      selectedKeys={[tab]}
      mode="inline"
      inlineCollapsed={true}
      style={{ height: '100%' }}
      menu={[
        {
          icon: <AlignLeftOutlined />,
          message: 'word.details',
          key: AggregatedTransactionDrawerTab.details,
          onClick: handleClick,
        },
        isVirtual(id)
          ? undefined
          : checkPermissions(CorporationPermissions.DOCUMENTS_READ) ||
            checkPermissions(PortfolioPermissions.DOCUMENTS_READ)
          ? {
              icon: <FileTextOutlined />,
              message: 'word.documents',
              key: AggregatedTransactionDrawerTab.documents,
              onClick: handleClick,
            }
          : undefined,
      ]}
    />
  )
}

export default React.memo(Sider)
