import { ROUTES } from 'config/routes'

export enum WORKSPACE {
  LAW_FIRM = 'LAW_FIRM',
  CORPORATION = 'CORPORATION',
  PORTFOLIO = 'PORTFOLIO',
}

export const ROUTE_TO_WORKSPACE = {
  [ROUTES.LAW_FIRMS]: WORKSPACE.LAW_FIRM,
  [ROUTES.CORPORATIONS]: WORKSPACE.CORPORATION,
  [ROUTES.PORTFOLIOS]: WORKSPACE.PORTFOLIO,
}
