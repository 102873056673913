import { CopyOutlined, LinkOutlined, UnlockOutlined } from '@ant-design/icons'
import copy from 'copy-to-clipboard'
import React from 'react'

import Button from 'components/atoms/Button'
import Dropdown from 'components/atoms/Dropdown'
import Menu from 'components/atoms/Menu'
import Tooltip from 'components/atoms/Tooltip'
import { useIsLaptopWindowSize } from 'hooks/useWindowSize'
import { useAuth } from 'providers/Auth'
import { useMessage } from 'providers/Message'
import { useToken } from 'providers/Token'
import { useUser } from 'providers/User'

export const Auth = () => {
  const { id } = useUser()
  const { isAuth } = useAuth()
  const { token } = useToken()
  const { infoMessage } = useMessage()
  const isLaptopWindowSize = useIsLaptopWindowSize()

  if (!isAuth) {
    return null
  }

  return (
    <Dropdown
      key="auth"
      trigger={['click']}
      dropdownRender={() => (
        <Menu
          menu={[
            {
              icon: <CopyOutlined />,
              key: 'copyUserId',
              message: 'Copy user ID',
              onClick: () => {
                copy(id ?? 'undefined')
                infoMessage('User ID copied to clipboard')
              },
            },
            {
              icon: <CopyOutlined />,
              key: 'copyHeaders',
              message: 'Copy headers',
              onClick: () => {
                copy(
                  JSON.stringify({ Authorization: `Bearer ${token}` }, null, 2)
                )
                infoMessage('Headers copied to clipboard')
              },
            },
            {
              icon: <CopyOutlined />,
              key: 'copyToken',
              message: 'Copy token',
              onClick: () => {
                copy(token ?? 'undefined')
                infoMessage('Token copied to clipboard')
              },
            },
            {
              icon: <LinkOutlined />,
              key: 'decodeToken',
              message: 'Decode token',
              onClick: () => {
                window.open(
                  `https://jwt.io/#debugger-io?token=${token}`,
                  '_blank'
                )
              },
            },
          ]}
        />
      )}
    >
      <Tooltip title={isLaptopWindowSize ? undefined : 'Auth'}>
        <Button
          icon={<UnlockOutlined />}
          message={isLaptopWindowSize ? 'Auth' : undefined}
        />
      </Tooltip>
    </Dropdown>
  )
}
