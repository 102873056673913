import React, { lazy } from 'react'
import { Redirect, Route } from 'react-router-dom'

import PrivateRoute from 'components/atoms/PrivateRoute'
import RouterSwitch from 'components/atoms/RouterSwitch'
import { ONE_SECOND } from 'config/date'
import PayfitCallback from 'providers/Workflows/steps/configureSyncDetails/PayfitCallback/PayfitCallback'

const InternalServerError = lazy(
  () => import('routes/Home/InternalServerError')
)
const Home = lazy(() => import('routes/Home'))
const Auth = lazy(() => import('routes/Auth'))
const Logout = lazy(() => import('routes/Logout'))
const NotificationLink = lazy(() => import('routes/NotificationLink'))
const Success = lazy(() => import('routes/Success'))
const SuccessSignup = lazy(() => import('routes/Auth/Signup/SuccessSignup'))
const VerifyEmail = lazy(() => import('routes/Auth/VerifyEmail'))
const SendVerifyEmail = lazy(
  () => import('routes/Auth/VerifyEmail/SendVerifyEmail')
)
const ResendVerifyEmail = lazy(
  () => import('routes/Auth/VerifyEmail/ResendVerifyEmail')
)
const SignatureResult = lazy(() => import('routes/SignatureResult'))
const ResendInvitationToMeeting = lazy(
  () =>
    import(
      'routes/NotificationLink/InvitationToMeeting/ResendInvitationToMeeting'
    )
)

function Root() {
  return (
    <RouterSwitch>
      <Route path="/server-unavailable" component={InternalServerError} />
      <Route path="/logout" component={Logout} />
      <Route
        path="/verify-email/expired-link/:email"
        component={ResendVerifyEmail}
      />
      <Route
        path="/verify-email/:timestamp/:token/:email"
        render={(props) =>
          props.match.params.timestamp * ONE_SECOND > new Date().getTime() ? (
            <VerifyEmail {...props} />
          ) : (
            <Redirect
              to={`/verify-email/expired-link/${props.match.params.email}`}
            />
          )
        }
      />
      <Route path="/verify-email/:email" component={SendVerifyEmail} />
      <Route
        path="/meetings/expired-link/:meetingAttendeeId/:email"
        component={ResendInvitationToMeeting}
      />
      <Route path="/success/:email" component={Success} />
      <Route path="/signup/success/:email" component={SuccessSignup} />
      <Route path="/signature-result" component={SignatureResult} />
      <Route path="/notification-link" component={NotificationLink} />
      <Route path="/payfit/callback" component={PayfitCallback} />
      <PrivateRoute path="/" component={Home} fallback={Auth} />
    </RouterSwitch>
  )
}

export default Root
