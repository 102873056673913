import { useApolloClient } from '@apollo/client'
import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useToken } from 'providers/Token'

export const useLogout = () => {
  const history = useHistory()
  const { setToken } = useToken()
  const client = useApolloClient()
  const { pathname } = useLocation()

  return useMemo(
    () => ({
      logout: (): void => {
        setToken()
        client.clearStore()
        if (pathname !== '/logout') {
          history.push('/')
        }
        window.location.reload()
      },
    }),
    [client, setToken, history, pathname]
  )
}
