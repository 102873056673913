import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_POOL_CORPORATION_ID } from 'hooks/pools/usePoolCorporationId/GET_POOL_CORPORATION_ID'

export const usePoolCorporationId = (poolId?: string) => {
  const result = useQuery<{ pool: { corporation: { id: string } } }>(
    GET_POOL_CORPORATION_ID,
    {
      variables: { poolId },
      skip: !poolId,
    }
  )

  return useMemo(
    () => ({
      ...result,
      corporationId: result?.data?.pool?.corporation?.id,
    }),
    [result]
  )
}
