import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import { Alert } from 'antd'
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  CellProps,
  Column as ColumnBase,
  ContextMenuItem,
  DataSheetGridProps as DataSheetGridComponentProps,
  DataSheetGridRef,
  DynamicDataSheetGrid,
  SimpleColumn,
} from 'react-datasheet-grid'

import 'react-datasheet-grid/dist/style.css'
import './style.css'

import { AddRows } from 'components/atoms/DataSheetGrid/AddRows'
import { wrapError } from 'components/atoms/DataSheetGrid/CellErrorWrapper'
import {
  ContextMenu,
  MoreActions,
  getFilteredMenuItems,
} from 'components/atoms/DataSheetGrid/ContextMenu'
import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'
import Number from 'components/atoms/Intl/Number'
import Space from 'components/atoms/Space'
import Tag from 'components/atoms/Tag'
import Tooltip from 'components/atoms/Tooltip'
import eqDSTheme from 'styles/eqDSTheme'
import { uniqueId } from 'utils'

// We implement our own Column type base on the native ColumnBase type to implement extra features
export type Column<T = any, C = any> = ColumnBase<T, C> & {
  required?: boolean
  tooltip?: MessageType
}

export type DataSheetGridError = {
  row: number
  key: string
  error: { type: string; context: any }
}

export type DataSheetDetectedChange = {
  rowId: number
  key: string
}

type DataSheetGridProps<T> = Pick<
  DataSheetGridComponentProps<T>,
  'height' | 'lockRows'
> & {
  value: T[]
  onChange: (value: T[]) => void
  className?: string
  columns: Column<T>[]
  dynamic?: boolean
  defaultRow?: T
  errors?: DataSheetGridError[]
  autoCreateDefaultRow?: boolean
  extra?: ReactNode
  detectedChanges?: DataSheetDetectedChange[]
  contextMenuActionsList?: Extract<ContextMenuItem, { type: string }>['type'][]
  limitedRows?: boolean
}

const GutterComponent = ({ rowIndex }: CellProps) => {
  return <Number value={rowIndex + 1} />
}

const gutterColumn: SimpleColumn = {
  component: GutterComponent,
}

export const DATASHEETGRID_MAX_ROWS = 80

export function DataSheetGrid<T extends object>({
  value,
  onChange,
  className,
  columns,
  height,
  lockRows,
  dynamic = false,
  defaultRow = {} as T,
  errors = [],
  autoCreateDefaultRow = false,
  extra,
  detectedChanges,
  contextMenuActionsList,
  limitedRows,
}: DataSheetGridProps<T>) {
  const formatMessage = useFormatMessage()
  const ref = useRef<DataSheetGridRef>(null)
  const [nextErrorIndex, setNextErrorIndex] = useState(0)
  const [shouldShowTooMuchLinesAlert, setShouldShowTooMuchLinesAlert] =
    useState(false)

  const firstColumns = useMemo(() => columns, [])

  const rowLimitReached =
    limitedRows && value ? value.length >= DATASHEETGRID_MAX_ROWS : false

  const getCellClassName = (
    rowIndex: number,
    columnId: string | undefined
  ): string | undefined => {
    if (errors.length) {
      return errors.some(
        (error) =>
          (error.key === undefined || error.key === columnId) &&
          error.row === rowIndex
      )
        ? 'dsg-cell-error'
        : undefined
    }
    if (detectedChanges?.length) {
      return detectedChanges.some(
        (change) => change.key === columnId && change.rowId === rowIndex
      )
        ? 'dsg-cell-was-changed'
        : undefined
    }
  }

  // We map columns from our own Column type to the native ColumnBase type
  const mappedColumns = useMemo<ColumnBase<T>[]>(
    () =>
      (dynamic ? columns : firstColumns)
        .filter(Boolean)
        .map((column, index) => ({
          minWidth: 150,
          ...column,
          component: wrapError(column.component),
          columnData: {
            errors: errors.filter(
              (error) =>
                (error.key === undefined && index === 0) ||
                error.key === column.id
            ),
            ...column.columnData,
          },
          cellClassName: ({ rowIndex }) =>
            getCellClassName(rowIndex, column.id),
          title: (
            <Tooltip title={column.tooltip}>
              {column.required && (
                <span className="datasheet-grid-title-required" />
              )}
              {formatMessage(column.title)}
              {column.tooltip && (
                <InfoCircleOutlined style={{ marginLeft: '4px' }} />
              )}
            </Tooltip>
          ),
        })),
    [dynamic ? columns : false, formatMessage, errors]
  )

  const createRow = useCallback(
    () => ({
      id: uniqueId(),
      ...defaultRow,
    }),
    [defaultRow]
  )

  const duplicateRow = useCallback(
    ({ rowData }: { rowData: T }) => ({
      ...rowData,
      id: uniqueId(),
    }),
    []
  )

  const onChangeRows = (value: T[]) => {
    if (value.length > DATASHEETGRID_MAX_ROWS && limitedRows) {
      setShouldShowTooMuchLinesAlert(true)
      onChange(value.slice(0, DATASHEETGRID_MAX_ROWS))
    } else {
      onChange(value)
    }
  }

  useEffect(() => {
    if (autoCreateDefaultRow && !value?.length) {
      onChange([createRow()])
    }
  }, [])

  const stickyRightColumn: SimpleColumn = {
    component: (props) => (
      <MoreActions
        {...props}
        disableNewLines={rowLimitReached}
        filteredMenuActionsList={contextMenuActionsList}
      />
    ),
  }

  const rowsBeforeLimitReached = DATASHEETGRID_MAX_ROWS - (value?.length ?? 0)

  const maxRowsToDisplay =
    rowsBeforeLimitReached === 0 ? 1 : rowsBeforeLimitReached

  return (
    <>
      {limitedRows && shouldShowTooMuchLinesAlert && (
        <Space
          direction="vertical"
          style={{ marginBottom: eqDSTheme.space.small }}
        >
          <Alert
            showIcon
            message={formatMessage(`transaction.linesLimit.exceeded`)}
            type="error"
          />
        </Space>
      )}
      {Boolean(errors.length) && (
        <Tag
          color="red"
          icon={<ExclamationCircleOutlined />}
          style={{ cursor: 'pointer', marginBottom: 10 }}
          onClick={() => {
            const index = nextErrorIndex >= errors.length ? 0 : nextErrorIndex
            const error = errors[index]
            ref.current?.setActiveCell({ row: error?.row, col: error?.key })
            setNextErrorIndex(index + 1)
          }}
        >
          <Number value={errors.length} unit="errors" />
        </Tag>
      )}

      <DynamicDataSheetGrid<T>
        className={className}
        value={
          rowLimitReached && value
            ? value.slice(0, DATASHEETGRID_MAX_ROWS)
            : value
        }
        ref={ref}
        onChange={onChangeRows}
        columns={mappedColumns}
        gutterColumn={gutterColumn}
        // Actions : at the end of row
        stickyRightColumn={lockRows ? undefined : stickyRightColumn}
        // Actions : right click on row
        contextMenuComponent={({ items, ...rest }) => (
          <ContextMenu
            {...rest}
            items={getFilteredMenuItems(items, contextMenuActionsList)}
            disableNewLines={rowLimitReached}
          />
        )}
        // "Add" button is disabled when row limit is reached, but should not even appear if "insert" option is unavailable
        addRowsComponent={(props) => {
          if (
            contextMenuActionsList &&
            !contextMenuActionsList.includes('INSERT_ROW_BELLOW')
          ) {
            return null
          }
          return (
            <AddRows
              extra={extra}
              disabled={rowLimitReached}
              maxValue={limitedRows ? maxRowsToDisplay : undefined}
              {...props}
            />
          )
        }}
        height={height}
        lockRows={lockRows}
        createRow={createRow}
        duplicateRow={duplicateRow}
      />
    </>
  )
}
