import { pickBy } from 'lodash/fp'
import logRocket from 'logrocket'
import React, { ReactNode, useEffect } from 'react'

import { useAuth } from 'providers/Auth'
import { useEquibar } from 'providers/Equibar'
import { useUser } from 'providers/User'
import { isSet } from 'utils'

export const LogRocketProvider = ({ children }: { children: ReactNode }) => {
  const {
    id,
    name,
    primaryEmail,
    firstName,
    lastName,
    phone,
    emails,
    isFromEquify,
  } = useUser()
  const { isAuth } = useAuth()
  const { recordLogRocket } = useEquibar()

  useEffect(() => {
    if (isAuth && id && (!isFromEquify || recordLogRocket)) {
      logRocket.init('zup4kk/equify', {
        dom: {
          inputSanitizer: true,
          textSanitizer: true,
        },
      })

      logRocket.identify(
        id,
        pickBy(isSet, {
          name,
          'First name': firstName || 'NA',
          'Last name': lastName || 'NA',
          'Primary email': primaryEmail || 'NA',
          ...emails
            .filter(({ primaryEmail }) => !primaryEmail)
            .reduce(
              (acc, cur, index) => ({
                ...acc,
                [`Email ${index + 2}`]: `${cur.email} (${
                  cur.emailVerified ? 'Verified' : 'Not verified'
                })`,
              }),
              {}
            ),
          'Phone number': phone || 'NA',
          'Is from equify team': isFromEquify || 'NA',
        })
      )
    }
  }, [isFromEquify, recordLogRocket, isAuth, id])

  return <>{children}</>
}
