import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { DaysOrMonths } from 'components/atoms/Intl/Duration'
import { EndpointGraphql } from 'config/endpointGraphql'
import { GET_SECURITY_PLAN_RULES } from 'hooks/securities/useSecurityPlanRules/GET_SECURITY_PLAN_RULES'

type OldDeparturePlanFormatType = {
  voluntary: DaysOrMonths
  involuntary: DaysOrMonths
  retirement: DaysOrMonths
  withCause: DaysOrMonths
  disability: DaysOrMonths
  death: DaysOrMonths
  contractualTermination: DaysOrMonths
}

type NewDeparturePlanFormatType = {
  expirationWithCauseInMonths: number | null
  expirationWithCauseInDays: number | null
  expirationVoluntaryInMonths: number | null
  expirationVoluntaryInDays: number | null
  expirationRetirementInMonths: number | null
  expirationRetirementInDays: number | null
  expirationInvoluntaryInMonths: number | null
  expirationInvoluntaryInDays: number | null
  expirationDisabilityInMonths: number | null
  expirationDisabilityInDays: number | null
  expirationDeathInMonths: number | null
  expirationDeathInDays: number | null
  expirationContractualTerminationInMonths: number | null
  expirationContractualTerminationInDays: number | null
}

const newPlanFormatToOldPlanFormat = (
  newPlanFormat: NewDeparturePlanFormatType
): OldDeparturePlanFormatType => {
  return {
    voluntary: {
      months: newPlanFormat.expirationVoluntaryInMonths,
      days: newPlanFormat.expirationVoluntaryInDays,
    },
    involuntary: {
      months: newPlanFormat.expirationInvoluntaryInMonths,
      days: newPlanFormat.expirationInvoluntaryInDays,
    },
    retirement: {
      months: newPlanFormat.expirationRetirementInMonths,
      days: newPlanFormat.expirationRetirementInDays,
    },
    withCause: {
      months: newPlanFormat.expirationWithCauseInMonths,
      days: newPlanFormat.expirationWithCauseInDays,
    },
    disability: {
      months: newPlanFormat.expirationDisabilityInMonths,
      days: newPlanFormat.expirationDisabilityInDays,
    },
    death: {
      months: newPlanFormat.expirationDeathInMonths,
      days: newPlanFormat.expirationDeathInDays,
    },
    contractualTermination: {
      months: newPlanFormat.expirationContractualTerminationInMonths,
      days: newPlanFormat.expirationContractualTerminationInDays,
    },
  }
}

export const useSecurityDeparturePlan = (securityId?: string) => {
  const result = useQuery<{
    findSecurityById: {
      departurePlan: NewDeparturePlanFormatType
    }
  }>(GET_SECURITY_PLAN_RULES, {
    variables: { securityId },
    skip: !securityId,
    context: { endpoint: EndpointGraphql.WUNDERGRAPH },
  })

  const departurePlanToReturn = result?.data?.findSecurityById?.departurePlan
    ? newPlanFormatToOldPlanFormat(
        result?.data?.findSecurityById?.departurePlan
      )
    : undefined

  return useMemo(
    () => ({
      ...result,
      departurePlan: departurePlanToReturn,
    }),
    [result]
  )
}
