import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { DrawerNames } from 'config/drawer'
import { useSecurityName } from 'hooks/securities/useSecurityName'
import { useDrawers } from 'providers/Drawers'
import { x } from 'utils/emotion'

export interface SecurityNameProps {
  securityId?: string
  withLink?: boolean
  noSkeleton?: boolean
}

const SecurityName = ({
  securityId,
  withLink = false,
  noSkeleton,
}: SecurityNameProps) => {
  const { openDrawer } = useDrawers()

  const { name, loading } = useSecurityName(securityId)

  const content = (
    <SkeletonText loading={!noSkeleton && loading}>{name}</SkeletonText>
  )

  if (withLink && securityId) {
    return (
      <x.span
        cursor="pointer"
        hoverTextDecoration="underline"
        onClick={() => {
          openDrawer({ name: DrawerNames.sec, id: securityId })
        }}
      >
        {content}
      </x.span>
    )
  }

  return content
}

export const SecurityNameStatic = ({ name }: { name?: string }) => {
  return <>{name}</>
}

export default React.memo(SecurityName)
