import { gql } from '@apollo/client'

export const GET_AGGREGATED_TRANSACTION_DRAFT = gql`
  query GET_AGGREGATED_TRANSACTION_DRAFT($aggregatedTransactionId: ID!) {
    aggregatedTransaction(id: $aggregatedTransactionId) {
      id
      draft
    }
  }
`
