import { useFormikContext } from 'formik'
import React from 'react'

import Date from 'components/atoms/Intl/Date'
import {
  useCheckMessage,
  useFormatMessage,
} from 'components/atoms/Intl/Message'
import ContactName from 'components/molecules/Contact/Name'
import CorporationInfoName from 'components/molecules/CorporationInfo/Name'
import PoolName from 'components/molecules/Pool/Name'
import PortfolioName from 'components/molecules/Portfolio/Name'
import SecurityName from 'components/molecules/Security/Name'
import TagPoolStatus from 'components/molecules/Tag/PoolStatus'
import WalletPortfolioName from 'components/molecules/Wallet/PortfolioName'
import WalletSecurityName from 'components/molecules/Wallet/SecurityName'
import Alert from 'stories/components/atoms/Alert/Alert'
import { deserializeAggregatedWalletId } from 'utils/aggregatedWallets'

export const WorkflowErrorAlert = () => {
  const formatMessage = useFormatMessage()
  const checkMessage = useCheckMessage()
  const { errors, setFieldError } = useFormikContext()
  // @ts-ignore
  const error = errors?.root

  if (!error) {
    return null
  }

  return (
    <Alert
      style={{ marginBottom: 'medium' }}
      closable
      showIcon
      type="warning"
      message={formatMessage({
        id: checkMessage('error.root.' + error.type)
          ? 'error.root.' + error.type
          : 'error.default',
        values: {
          ...(error.context || {}),
          date: <Date value={error?.context?.date} />,
          corporationInfoName: (
            <CorporationInfoName corporationInfoId={error?.context?.id} />
          ),
          contactName: <ContactName contactId={error?.context?.id} />,
          poolName: <PoolName poolId={error?.context?.id} />,
          poolStatus: (
            <TagPoolStatus
              poolId={error?.context?.id}
              date={error?.context?.date}
              style={{ marginRight: '0px' }}
            />
          ),
          portfolioName: <PortfolioName portfolioId={error?.context?.id} />,
          securityName: <SecurityName securityId={error?.context?.id} />,
          walletPortfolioName: (
            <WalletPortfolioName walletId={error?.context?.id} />
          ),
          walletSecurityName: (
            <WalletSecurityName walletId={error?.context?.id} />
          ),
          aggregatedWalletPortfolioName: (
            <PortfolioName
              portfolioId={
                error?.context?.id
                  ? deserializeAggregatedWalletId(error.context.id)?.portfolioId
                  : undefined
              }
            />
          ),
          aggregatedWalletSecurityName: (
            <SecurityName
              securityId={
                error?.context?.id
                  ? deserializeAggregatedWalletId(error.context.id)?.securityId
                  : undefined
              }
            />
          ),
        },
      })}
      // @ts-ignore
      onClose={() => setFieldError('root', undefined)}
    />
  )
}
