export enum AggregatedTransactionStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
}

export enum AggregatedTransactionLabel {
  ACCELERATION = 'ACCELERATION',
  BUY_BACK = 'BUY_BACK',
  SUBSCRIPTION = 'SUBSCRIPTION',
  GRANT = 'GRANT',
  PARTIAL_IMPORT = 'PARTIAL_IMPORT',
  TRANSFER = 'TRANSFER',
  SALE = 'SALE',
  CONVERSION = 'CONVERSION',
  EXERCISE = 'EXERCISE',
  MILESTONE_COMPLETION = 'MILESTONE_COMPLETION',
  CANCEL = 'CANCEL',
  TERMINATION = 'TERMINATION',
  AUTO_VESTING = 'AUTO_VESTING',
  EXPIRATION = 'EXPIRATION',
  STOCK_SPLIT = 'STOCK_SPLIT',
  SUSPENSION = 'SUSPENSION',
}

export enum TransactionWorkflowType {
  buyBack = 'buyBack',
  cancel = 'cancel',
  conversion = 'conversion',
  exercise = 'exercise',
  grant = 'grant',
  subscription = 'subscription',
  termination = 'termination',
  transfer = 'transfer',
  stockSplit = 'stockSplit',
  draftGrant = 'grantDraft',
  acceleration = 'acceleration',
  suspension = 'suspension',
}

export enum HOLDER_CONSENT_SMART_FIELDS {
  exercisedSecurity = 'smartFields.exercisedSecurity',
  exercisePrice = 'smartFields.exercisePrice',
  exercisePriceInLetters = 'smartFields.exercisePriceInLetters',
  holderName = 'smartFields.holderName',
  quantity = 'smartFields.quantity',
  quantityInLetters = 'smartFields.quantityInLetters',
  receivedSecurity = 'smartFields.receivedSecurity',
  securityName = 'smartFields.securityName',
  totalAmount = 'smartFields.totalAmount',
  totalAmountInLetters = 'smartFields.totalAmountInLetters',
  unitPrice = 'smartFields.unitPrice',
  unitPriceInLetters = 'smartFields.unitPriceInLetters',
}

export const TRANSACTION_SMART_FIELDS = {
  [TransactionWorkflowType.exercise]: [
    HOLDER_CONSENT_SMART_FIELDS.exercisedSecurity,
    HOLDER_CONSENT_SMART_FIELDS.holderName,
    HOLDER_CONSENT_SMART_FIELDS.quantity,
    HOLDER_CONSENT_SMART_FIELDS.quantityInLetters,
    HOLDER_CONSENT_SMART_FIELDS.receivedSecurity,
    HOLDER_CONSENT_SMART_FIELDS.unitPrice,
    HOLDER_CONSENT_SMART_FIELDS.unitPriceInLetters,
    HOLDER_CONSENT_SMART_FIELDS.totalAmount,
    HOLDER_CONSENT_SMART_FIELDS.totalAmountInLetters,
  ],
  [TransactionWorkflowType.grant]: [
    HOLDER_CONSENT_SMART_FIELDS.holderName,
    HOLDER_CONSENT_SMART_FIELDS.securityName,
    HOLDER_CONSENT_SMART_FIELDS.quantity,
    HOLDER_CONSENT_SMART_FIELDS.quantityInLetters,
    HOLDER_CONSENT_SMART_FIELDS.exercisePrice,
    HOLDER_CONSENT_SMART_FIELDS.exercisePriceInLetters,
  ],
  [TransactionWorkflowType.subscription]: [
    HOLDER_CONSENT_SMART_FIELDS.holderName,
    HOLDER_CONSENT_SMART_FIELDS.securityName,
    HOLDER_CONSENT_SMART_FIELDS.quantity,
    HOLDER_CONSENT_SMART_FIELDS.quantityInLetters,
    HOLDER_CONSENT_SMART_FIELDS.totalAmount,
    HOLDER_CONSENT_SMART_FIELDS.totalAmountInLetters,
  ],
  [TransactionWorkflowType.transfer]: [
    HOLDER_CONSENT_SMART_FIELDS.holderName,
    HOLDER_CONSENT_SMART_FIELDS.securityName,
    HOLDER_CONSENT_SMART_FIELDS.quantity,
    HOLDER_CONSENT_SMART_FIELDS.quantityInLetters,
    HOLDER_CONSENT_SMART_FIELDS.unitPrice,
    HOLDER_CONSENT_SMART_FIELDS.unitPriceInLetters,
    HOLDER_CONSENT_SMART_FIELDS.totalAmount,
    HOLDER_CONSENT_SMART_FIELDS.totalAmountInLetters,
  ],
}
