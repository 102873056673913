import React from 'react'

import Number from 'components/atoms/Intl/Number'
import SkeletonText from 'components/atoms/Skeleton/Text'
import { useAggregatedTransactionToQuantity } from 'hooks/aggregatedTransactions/useAggregatedTransactionToQuantity'

const AggregatedTransactionToQuantity = ({
  aggregatedTransactionId,
}: {
  aggregatedTransactionId: string
}) => {
  const { toQuantity, loading } = useAggregatedTransactionToQuantity(
    aggregatedTransactionId
  )

  return (
    <SkeletonText loading={loading}>
      <Number value={toQuantity} />
    </SkeletonText>
  )
}

export default React.memo(AggregatedTransactionToQuantity)
