import { useQuery } from '@apollo/client'
import React from 'react'

import Message from 'components/atoms/Intl/Message'
import { GET_FILE_PREVIEW_URL } from 'components/atoms/PDF/Preview/GET_FILE_PREVIEW_URL'
import Spin from 'components/atoms/Spin'
import { DocxErrorType } from 'config/file'
import { useFileErrors } from 'hooks/files/useFileErrors'
import eqDSTheme from 'styles/eqDSTheme'

const PDFPreview = ({ fileId }: { fileId: string }) => {
  const { errors } = useFileErrors(fileId)
  const { data } = useQuery<{
    file: {
      previewUrl?: string
    }
  }>(GET_FILE_PREVIEW_URL, {
    variables: { fileId },
    fetchPolicy: 'cache-and-network',
  })

  if (errors?.some(({ type }) => type === DocxErrorType.CORRUPT)) {
    return (
      <div style={{ color: eqDSTheme.color.lightgrey, margin: 'auto' }}>
        <Message value={'word.corruptedDocument'} />
      </div>
    )
  }

  if (!data?.file?.previewUrl) {
    return (
      <div style={{ margin: 'auto' }}>
        <Spin />
      </div>
    )
  }

  return (
    <iframe
      src={(data?.file?.previewUrl as string) + '#toolbar=0'}
      width="100%"
      height="100%"
    />
  )
}

export default React.memo(PDFPreview)
