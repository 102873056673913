// https://ant.design/components/menu/

import { Menu as AntdMenu, ConfigProvider } from 'antd'
import {
  ItemType,
  MenuDividerType,
  MenuItemGroupType,
  MenuItemType,
  SubMenuType,
} from 'antd/es/menu/hooks/useItems'
import { MenuProps as AntdMenuProps, MenuItemGroupProps } from 'antd/lib/menu'
import { MenuItemProps } from 'antd/lib/menu/MenuItem'
import { SubMenuProps } from 'antd/lib/menu/SubMenu'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { DividerProps } from 'components/atoms/Divider'
import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'
import { eqDarkAntdTheme } from 'styles/eqAntdTheme'
import eqDSTheme from 'styles/eqDSTheme'
import { cleanArray } from 'utils'

const { SubMenu, Item, ItemGroup, Divider } = AntdMenu

interface IItemGroup extends MenuItemGroupProps {
  title: MessageType
  group: (MenuType | undefined)[]
}

interface ISubMenu extends SubMenuProps {
  title: MessageType
  menu: (MenuType | undefined)[]
  key: string
  to?: string
}

interface IItem extends MenuItemProps {
  title?: MessageType
  message?: MessageType
  key: string
  to?: string
}

export type MenuType =
  | (DividerProps & { divider: true })
  | IItemGroup
  | ISubMenu
  | IItem

export interface MenuProps extends AntdMenuProps {
  menu?: (MenuType | undefined)[]
  myRef?: any
  customDarkTheme?: boolean
}

const Menu = ({ menu = [], myRef, customDarkTheme, ...rest }: MenuProps) => {
  const formatMessage = useFormatMessage()
  const history = useHistory()

  const formatMenu = useCallback(
    (menu: MenuType[]): ItemType[] => {
      return menu.map((item, index) => {
        if ('divider' in item) {
          const menuDivider: MenuDividerType = {
            type: 'divider',
            key: index,
            dashed: item.dashed,
            className: item.className,
            style: item.style,
          }
          return menuDivider
        }
        if ('group' in item) {
          const menuItemGroup: MenuItemGroupType = {
            type: 'group',
            key: index,
            label: formatMessage(item.title),
            children: formatMenu(cleanArray(item.group)),
            className: item.className,
            style: item.style,
          }
          return menuItemGroup
        }
        if ('menu' in item) {
          const subMenu: SubMenuType = {
            label: formatMessage(item.title),
            onTitleClick: (title) => {
              item.onTitleClick && item.onTitleClick(title)
              if (item.to) {
                history.push(item.to)
              }
            },
            icon: item.icon,
            key: item.key,
            style: { borderRadius: eqDSTheme.borderRadius.none },
            children: formatMenu(cleanArray(item.menu)),
          }
          return subMenu
        }

        const menuItem: MenuItemType = {
          key: item.key,
          title: formatMessage(item.title) as string,
          onClick: (info) => {
            info.domEvent.stopPropagation()
            info.domEvent.preventDefault()
            item.onClick && item.onClick(info)
            if (item.to) {
              history.push(item.to)
            }
          },
          label: formatMessage(item.message) as string,
          icon: item.icon,
          className: 'classname-position-relative',
          style: { borderRadius: eqDSTheme.borderRadius.none },
          disabled: item.disabled,
        }
        return menuItem
      })
    },
    [formatMessage, history]
  )

  if (!menu.length) {
    return null
  }

  const antdMenu = (
    <AntdMenu
      ref={myRef}
      items={formatMenu(cleanArray(menu))}
      {...rest}
    ></AntdMenu>
  )

  return (
    <>
      {!customDarkTheme ? (
        antdMenu
      ) : (
        <ConfigProvider theme={eqDarkAntdTheme}>{antdMenu}</ConfigProvider>
      )}
    </>
  )
}

export default React.memo(Menu)
