import { ApolloError, useApolloClient, useMutation } from '@apollo/client'
import { get, set, update } from 'lodash/fp'
import React, {
  ReactNode,
  Suspense,
  lazy,
  useCallback,
  useMemo,
  useState,
} from 'react'

import Center from 'components/atoms/Center'
import Spin from 'components/atoms/Spin'
import { useUpdateCache } from 'hooks/useUpdateCache'
import { useAuth } from 'providers/Auth'
import { useEquibar } from 'providers/Equibar'
import { useNotification } from 'providers/Notification'
import { StartWorkflow } from 'providers/Workflows/StartWorkflow'
import {
  WorkflowContext,
  WorkflowType,
} from 'providers/Workflows/WorkflowContext'
import { WorkflowsContext } from 'providers/Workflows/WorkflowsContext'
import { WorkflowAsync } from 'providers/Workflows/components/WorkflowAsync'
import { WorkflowForm } from 'providers/Workflows/components/WorkflowForm'
import { WorkflowPortal } from 'providers/Workflows/components/WorkflowPortal'
import { RUN_BACK } from 'providers/Workflows/graphql/mutations/RUN_BACK'
import { RUN_NEXT } from 'providers/Workflows/graphql/mutations/RUN_NEXT'
import { RUN_SAVE_CURRENT } from 'providers/Workflows/graphql/mutations/RUN_SAVE_CURRENT'
import { RUN_START } from 'providers/Workflows/graphql/mutations/RUN_START'
import { GET_WORKFLOW_DATA } from 'providers/Workflows/graphql/queries/GET_WORKFLOW_DATA'
import { dropRight, last } from 'utils'
import { getErrors } from 'utils/graphql'

const fallback = (
  <Center
    backgroundColor="#f7f9fc"
    position="fixed"
    top={0}
    left={0}
    right={0}
    bottom={0}
  >
    <Spin />
  </Center>
)

export const WorkflowsProvider = ({ children }: { children: ReactNode }) => {
  const { isAuth } = useAuth()
  const { query } = useApolloClient()
  const updateCache = useUpdateCache()
  const { errorNotification } = useNotification()
  const { isStartWorkflowVisible, virtualWorkflows } = useEquibar()

  const [runStart] = useMutation(RUN_START)
  const [runNext] = useMutation(RUN_NEXT)
  const [runBack] = useMutation(RUN_BACK)
  const [runSaveCurrent] = useMutation(RUN_SAVE_CURRENT)

  const [loading, setLoading] = useState<boolean>(false)
  const [workflows, setWorkflows] = useState<WorkflowType[]>([])
  const [loadedComponents, setLoadedComponents] = useState({})

  const getComponent = useCallback(
    (name: string) => {
      const component = get('name', loadedComponents)

      if (component) {
        return component
      }

      try {
        const component = lazy(() =>
          import(`providers/Workflows/steps/${name}`).catch((error) => {
            if (virtualWorkflows) {
              return import(`providers/Workflows/steps/virtualStep`)
            }
            throw new Error(error)
          })
        )

        setLoadedComponents(set(name, component))

        return component
      } catch (error) {
        throw new Error(`Could not import step "${name}"`)
      }
    },
    [loadedComponents]
  )

  const close = useCallback((): void => {
    setWorkflows(dropRight)
  }, [workflows])

  const start = useCallback(
    ({
      slug,
      data = {},
      onCompleted = () => {},
    }: {
      slug: string
      data?: Record<string, any>
      onCompleted?: (data: Record<string, any>) => void
    }) => {
      if (loading) {
        return
      }

      setLoading(true)

      runStart({ variables: { slug, data } })
        .then(({ data }) => {
          const workflow = data.startWorkflow
          updateCache(workflow.updatedEntities)

          if (workflow.__typename === 'FinishedWorkflow') {
            onCompleted(workflow.data)
          } else {
            const component = getComponent(workflow.currentStep.slug)
            setWorkflows((w) => [
              ...w,
              {
                workflow,
                length: 1,
                loading: false,
                onCompleted,
                component,
              },
            ])
          }
        })
        .catch((error) => {
          console.error(error)
          errorNotification({
            message: 'error.default',
          })
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [getComponent, runStart, updateCache, loading, isStartWorkflowVisible]
  )

  const continueWorkflow = useCallback(
    ({
      id,
      onCompleted = () => {},
    }: {
      id: string
      onCompleted?: (data: Record<string, any>) => void
    }) => {
      if (loading) {
        return
      }

      setLoading(true)

      query({
        query: GET_WORKFLOW_DATA,
        variables: { workflowId: id },
        fetchPolicy: 'network-only',
      })
        .then(({ data }) => {
          const workflow = data.workflow
          updateCache(workflow.updatedEntities)

          if (workflow.__typename === 'FinishedWorkflow') {
            onCompleted(workflow.data)
          } else {
            const component = getComponent(workflow.currentStep.slug)
            setWorkflows((w) => [
              ...w,
              {
                workflow,
                length: 1,
                loading: false,
                onCompleted,
                component,
              },
            ])
          }
        })
        .catch((error) => {
          console.error(error)
          errorNotification({
            message: 'error.default',
          })
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [getComponent, query, updateCache, loading, isStartWorkflowVisible]
  )

  const saveCurrent = useCallback(
    (payload = {}) => {
      if (last(workflows).loading || loading) {
        return
      }

      setLoading(true)
      setWorkflows(set([workflows.length - 1, 'loading'], true))

      return runSaveCurrent({
        variables: { id: last(workflows).workflow.id, payload },
      })
        .then(() => {
          close()
          setWorkflows(set([workflows.length - 1, 'loading'], true))
        })
        .catch((error: ApolloError) => {
          const errors = getErrors(error)
          console.error('Error: ' + JSON.stringify(errors))

          const savingCurrentPayloadError = new Error(
            'SAVING_CURRENT_PAYLOAD_ERROR'
          )
          // @ts-ignore
          savingCurrentPayloadError.errors = errors

          setWorkflows(set([workflows.length - 1, 'loading'], false))

          throw savingCurrentPayloadError
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [close, runSaveCurrent, loading, workflows]
  )

  const next = useCallback(
    (payload = {}) => {
      if (last(workflows).loading || loading) {
        return
      }

      setLoading(true)
      setWorkflows(set([workflows.length - 1, 'loading'], true))

      return runNext({
        variables: {
          payload,
          id: last(workflows).workflow.id,
          stateKey: last(workflows).workflow.stateKey,
        },
      })
        .then(({ data }) => {
          const workflow = data.workflow.next
          updateCache(workflow.updatedEntities)

          if (workflow.__typename === 'FinishedWorkflow') {
            close()
            last(workflows).onCompleted(workflow.data)
          } else if (workflow.__typename === 'AsyncWorkflow') {
            setWorkflows(set([workflows.length - 1, 'async'], true))
          } else {
            const component = getComponent(workflow.currentStep.slug)
            setWorkflows(
              update(workflows.length - 1, (w) => ({
                ...w,
                workflow,
                loading: false,
                length: w.length + 1,
                component,
              }))
            )
          }
        })
        .catch((error: ApolloError) => {
          const errors = getErrors(error)
          console.error('Error: ' + JSON.stringify(errors))

          const badUserInputError = new Error('BAD_USER_INPUT')
          // @ts-ignore
          badUserInputError.errors = errors

          setWorkflows(set([workflows.length - 1, 'loading'], false))

          throw badUserInputError
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [close, runNext, getComponent, workflows, updateCache, loading]
  )

  const back = useCallback(
    (payload) => {
      if (last(workflows).loading || loading) {
        return
      }

      setLoading(true)

      setWorkflows(set([workflows.length - 1, 'loading'], true))

      runBack({
        variables: {
          payload,
          id: last(workflows).workflow.id,
          stateKey: last(workflows).workflow.stateKey,
        },
      })
        .then(({ data }) => {
          const workflow = data.workflow.back
          const component = getComponent(workflow.currentStep.slug)
          setWorkflows(
            update(workflows.length - 1, (w) => ({
              ...w,
              workflow,
              loading: false,
              length: w.length - 1,
              component,
            }))
          )
        })
        .catch((error) => {
          setWorkflows(set([workflows.length - 1, 'loading'], false))
          console.error(error)
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [getComponent, runBack, workflows, loading]
  )

  const context = useMemo(
    () => ({ start, continueWorkflow }),
    [start, continueWorkflow]
  )

  return (
    <WorkflowsContext.Provider value={context}>
      {children}
      <StartWorkflow />
      {isAuth &&
        workflows.map((workflow, index) => {
          const isLast = index === workflows.length - 1

          if (!workflow.workflow) {
            return null
          }

          const error = () => {
            throw new Error('Not top workflow')
          }
          const { component: Component } = workflow
          const backCallback = isLast ? back : error

          return (
            <WorkflowContext.Provider
              key={workflow.workflow.id}
              value={{
                loading: workflow.loading,
                close: isLast ? close : error,
                next: isLast ? next : error,
                saveCurrent: isLast ? saveCurrent : error,
                back: workflow.workflow.canGoBack ? backCallback : null,
                data: workflow.workflow.data,
                progress: workflow.workflow.progress,
                slug: workflow.workflow.currentStep.slug,
                currentStep: workflow.workflow.currentStep,
                initialPayload: workflow.workflow.currentStep.payload,
              }}
            >
              <WorkflowPortal>
                <WorkflowForm>
                  <>
                    <Suspense fallback={workflow.length > 1 ? fallback : null}>
                      <Component />
                    </Suspense>
                    {workflow.async && (
                      <WorkflowAsync
                        workflowId={workflow.workflow.id}
                        workflows={workflows}
                        setWorkflows={setWorkflows}
                        getComponent={getComponent}
                      />
                    )}
                  </>
                </WorkflowForm>
              </WorkflowPortal>
            </WorkflowContext.Provider>
          )
        })}
    </WorkflowsContext.Provider>
  )
}
