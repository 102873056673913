import { gql } from '@apollo/client'

export const GET_CONTACT_AVATAR = gql`
  query GET_CONTACT_AVATAR($contactId: ID!) {
    contact(id: $contactId) {
      id
      firstName
      lastName
      name
      email
      profilePicture
    }
  }
`
