import React, { ReactNode, Suspense } from 'react'

import { useAuth } from 'providers/Auth'
import { DrawerContext } from 'providers/Drawer/DrawerContext'
import { component } from 'providers/Drawer/config'
import { useDrawers } from 'providers/Drawers'
import { usePermissions } from 'providers/Permissions'

export const DrawerProvider = ({ children }: { children: ReactNode }) => {
  const { isAuth } = useAuth()
  const { permissions } = usePermissions()
  const { drawers, closeDrawer, changeTab } = useDrawers()

  return (
    <>
      {children}
      {isAuth &&
        Boolean(permissions?.length) &&
        drawers.map(({ name, id, tab }, index) => {
          const Component = component[name]

          return (
            <DrawerContext.Provider
              key={index}
              value={{
                index,
                name,
                id,
                tab,
                closeDrawer: () => {
                  closeDrawer()
                },
                changeTab: (newTab) => {
                  changeTab({ id, tab: newTab })
                },
              }}
            >
              <Suspense fallback={null}>
                <Component />
              </Suspense>
            </DrawerContext.Provider>
          )
        })}
    </>
  )
}
