import { gql } from '@apollo/client'

export const GET_SELECTED_FILES_DATES = gql`
  query GET_SELECTED_FILES_DATES($ids: [ID!]!) {
    files(ids: $ids) {
      id
      date
    }
  }
`
