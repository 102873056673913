import { gql } from '@apollo/client'

export const GET_WALLET_PORTFOLIO_NAME = gql`
  query GET_WALLET_PORTFOLIO_NAME($walletId: ID!) {
    wallet(id: $walletId) {
      id
      portfolio {
        id
        name
      }
    }
  }
`
