import { Env, NODE_ENV } from 'config/env'

export const isDevEnv = NODE_ENV === Env.DEV

export const getEnvColor = (env: Env) => {
  switch (env) {
    case Env.PROD:
      return 'red'
    case Env.TEST:
      return 'blue'
    case Env.DEV:
      return 'green'
    default:
      return 'gray'
  }
}

/**
 * NODE_ENV does not work in staging so we use domain validation
 */
export const getEnvUsingDomain = () => {
  const domain = window.location.hostname
  const subdomain = domain.split('.')[0]

  if (domain.includes('localhost')) {
    return Env.DEV
  }
  if (subdomain === 'staging') {
    return Env.STAGING
  }
  if (domain.includes('test')) {
    return Env.TEST
  }
  return Env.PROD
}
