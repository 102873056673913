export enum HelpCenterLink {
  HEADER_EN = 'https://aide.equify.eu/en/knowledge',
  HEADER_FR = 'https://aide.equify.eu/knowledge',
  DOCUMENT_STEP_EN = 'https://aide.equify.eu/en/knowledge/how-to-i-add-a-document',
  DOCUMENT_STEP_FR = 'https://aide.equify.eu/knowledge/comment-ajouter-un-document',
  SIGNATURES_EN = 'https://aide.equify.eu/en/knowledge/library/electronic-signature',
  SIGNATURES_FR = 'https://aide.equify.eu/knowledge/comment-pr%C3%A9parer-un-document-%C3%A0-la-signature-%C3%A9lectronique',
  SMART_FIELDS_EN = 'https://aide.equify.eu/en/knowledge/how-to-prepare-a-document-for-templating',
  SMART_FIELDS_FR = 'https://aide.equify.eu/knowledge/comment-pr%C3%A9parer-un-document-pour-le-publipostage',
  TRANSACTION_SIGNATORIES_EN = 'https://aide.equify.eu/en/knowledge/how-to-send-documents-for-signature-on-equify',
  TRANSACTION_SIGNATORIES_FR = 'https://aide.equify.eu/knowledge/comment-faire-signer-des-documents-sur-equify',
  VESTING_EN = 'https://aide.equify.eu/en/knowledge/how-to-1',
  VESTING_FR = 'https://aide.equify.eu/knowledge/comment-param%C3%A9trer-un-calendrier-dacquisition-ou-vesting',
  LINK_CONTACT_FR = 'https://aide.equify.eu/knowledge/comment-consulter-et-modifier-les-informations-du-contact-dun-compte',
  LINK_CONTACT_EN = 'https://aide.equify.eu/en/knowledge/how-to-link-a-contact-to-an-account',
  EDIT_HOLDERS_DETAILS_GUIDE_FR = 'https://aide.equify.eu/knowledge/comment-puis-je-autoriser-mes-titulaires-%C3%A0-modifier-eux-m%C3%AAmes-leurs-informations',
  EDIT_HOLDERS_DETAILS_GUIDE_EN = 'https://aide.equify.eu/en/knowledge/how-do-you-allow-your-holders-to-change-their-personal-information-themselves',
}
