import { useQuery } from '@apollo/client'
import React, { useMemo } from 'react'

import Drawer from 'components/atoms/Drawer'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import ButtonMoreActionsCommunication from 'components/molecules/ButtonMoreActions/Communication'
import CommunicationTitle from 'components/molecules/Communication/Title'
import { GET_COMMUNICATION_TITLE } from 'components/molecules/Communication/Title/GET_COMMUNICATION_TITLE'
import { WORKSPACE } from 'config/workspace'
import { useWorkspace } from 'hooks/useWorkspace'
import { useDrawer } from 'providers/Drawer'
import Content from 'providers/Drawer/drawers/Communication/Content'
import Sider from 'providers/Drawer/drawers/Communication/Sider'

export enum CommunicationDrawerTab {
  details = 'details',
  contentTab = 'contentTab',
  recipients = 'recipients',
  documents = 'documents',
}

function Communication() {
  const { id } = useDrawer()
  const formatMessage = useFormatMessage()

  const { workspaceId, workspace } = useWorkspace()
  const corporationId = useMemo(
    () => (workspace === WORKSPACE.CORPORATION ? workspaceId : undefined),
    [workspace, workspaceId]
  )
  const { data } = useQuery(GET_COMMUNICATION_TITLE, {
    variables: { communicationId: id },
  })

  const title = data?.communication?.title

  return (
    <Drawer
      open
      helmetTitle={
        title
          ? `${formatMessage('word.communication')} · ${title}`
          : 'word.communication'
      }
      pageHeaderProps={{
        title: <CommunicationTitle communicationId={id} />,
        subTitle: 'word.communication',
        extra: corporationId && (
          <ButtonMoreActionsCommunication
            communicationId={id}
            corporationId={corporationId}
          />
        ),
      }}
      sider={Sider}
      content={Content}
    />
  )
}

export default React.memo(Communication)
