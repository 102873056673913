import React, { useEffect, useRef } from 'react'

import { Env, NODE_ENV } from 'config/env'
import { EmbeddedSignatureInterface } from 'providers/Procedure/factories/EmbeddedSignatureComponentFactory'
import { getEnvUsingDomain } from 'utils/env'

import { Yousign } from '../../../sdks/Yousign/yousignV3-sdk'

interface YousignServiceOptionsV3 extends EmbeddedSignatureInterface {
  signatureLink: string
}

/**
 * YouSignEmbeddedSignatureComponent is a React functional component that embeds a YouSign signature iframe.
 * It initializes the YouSign instance with the provided signature link and sets up event listeners for success,
 * decline, and error events.
 *
 * @param {YousignServiceOptionsV3} props - The properties required to configure the YouSign instance.
 * @param {string} props.signatureLink - The link to the YouSign signature page.
 * @param {() => void} props.onSuccess - A callback function to execute when the signature is successfully completed.
 * @param {() => void} props.onDeclined - A callback function to execute when the signature is declined.
 * @param {() => void} props.onError - A callback function to execute when an error occurs during the signature process.
 *
 * @returns {JSX.Element} A div element containing the YouSign iframe.
 *
 * @component
 *
 * @example
 * <YouSignEmbeddedSignatureComponent
 *   signatureLink="https://example.com/signature"
 *   closeModal={() => console.log('Modal closed')}
 * />
 */
export const YouSignEmbeddedSignatureComponentV3 = (
  props: YousignServiceOptionsV3
): JSX.Element => {
  const iframeContainerReference = useRef<HTMLDivElement>(null)
  const youSignInstanceReference = useRef<any>(null)

  useEffect(() => {
    if (iframeContainerReference.current && props.signatureLink) {
      youSignInstanceReference.current = new Yousign({
        signatureLink: props.signatureLink,
        iframeContainerId: iframeContainerReference.current.id,
        // NODE_ENV is equal to prod in Staging environnement at the moment
        isSandbox: NODE_ENV !== Env.PROD || getEnvUsingDomain() !== Env.PROD,
        iframeAttributes: {
          referrerPolicy: 'origin-when-cross-origin',
        },
      })

      youSignInstanceReference.current.onSuccess(() => {
        props.onSuccess()
      })
      youSignInstanceReference.current.onDeclined(() => {
        props.onDeclined()
      })
      youSignInstanceReference.current.onError(() => {
        props.onError()
      })
    }

    return () => {
      if (youSignInstanceReference.current) {
        youSignInstanceReference.current.removeMessageListener()
      }
    }
  }, [props.signatureLink])

  return (
    <div
      id="yousign-iframe-container"
      ref={iframeContainerReference}
      style={{ height: '100%' }}
    ></div>
  )
}
