import { gql } from '@apollo/client'

export const SAVE_PAYFIT_OAUTH_SYNC_CONFIG = gql`
  mutation SAVE_PAYFIT_OAUTH_SYNC_CONFIG(
    $id: String!
    $type: IntegrationApiEnum!
    $oauth: String!
  ) {
    savePayfitOauthTokenInSyncConfig(id: $id, type: $type, oauthCode: $oauth)
  }
`
