import React from 'react'

import Drawer from 'components/atoms/Drawer'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import ButtonMoreActionsGovernanceGroup from 'components/molecules/ButtonMoreActions/GovernanceGroup'
import GovernanceGroupName from 'components/molecules/Group/Name'
import { useGovernanceGroupName } from 'hooks/governanceGroups/useGovernanceGroupName'
import { useDrawer } from 'providers/Drawer'
import Content from 'providers/Drawer/drawers/Group/Content'
import Sider from 'providers/Drawer/drawers/Group/Sider'

export enum GroupDrawerTab {
  details = 'details',
  documents = 'documents',
  meetings = 'meetings',
  communications = 'communications',
  members = 'members',
}

function Group() {
  const { id } = useDrawer()
  const { name } = useGovernanceGroupName(id)
  const formatMessage = useFormatMessage()

  return (
    <Drawer
      open
      helmetTitle={
        name ? `${formatMessage('word.group')} · ${name}` : 'word.group'
      }
      pageHeaderProps={{
        title: <GovernanceGroupName governanceGroupId={id} />,
        subTitle: 'word.group',
        extra: <ButtonMoreActionsGovernanceGroup governanceGroupId={id} />,
      }}
      sider={Sider}
      content={Content}
    />
  )
}

export default React.memo(Group)
