// https://ant.design/components/input/

import { Input } from 'antd'
import { SearchProps as AntdSearchProps } from 'antd/lib/input'
import React from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'

export interface SearchInputProps
  extends Omit<AntdSearchProps, 'placeholder' | 'onChange' | 'value'> {
  placeholder?: MessageType
  onChange?: (value: string | null) => void
  value?: string | null
}

const Search = ({
  placeholder,
  onChange = () => {},
  value,
  ...rest
}: SearchInputProps) => {
  const formatMessage = useFormatMessage()

  return (
    <Input.Search
      value={value ?? undefined}
      onChange={(event) => {
        event.stopPropagation()
        event.preventDefault()
        onChange(event?.target?.value ?? null)
      }}
      placeholder={
        placeholder ? (formatMessage(placeholder) as string) : undefined
      }
      {...rest}
    />
  )
}

export default React.memo(Search)
