import { gql } from '@apollo/client'

export const GET_MEETING_ATTENDEES = gql`
  query GET_MEETING_ATTENDEES(
    $meetingId: ID!
    $filters: MeetingAttendeesFilters
  ) {
    meeting(id: $meetingId) {
      id
      attendees {
        list(filters: $filters) {
          hits {
            id
            contact {
              id
              name
              email
            }
            response
          }
        }
      }
    }
  }
`
