export enum DrawerNames {
  aggW = 'aggW',
  contact = 'contact',
  event = 'event',
  file = 'file',
  grp = 'grp',
  legalE = 'legalE',
  meet = 'meet',
  communication = 'communication',
  pool = 'pool',
  port = 'port',
  sec = 'sec',
  aggT = 'aggT',
  wall = 'wall',
  intH = 'intH',
}
