import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_AGGREGATED_WALLET_PORTFOLIO_ID } from 'hooks/aggregatedWallets/useAggregatedWalletPortfolioId/GET_AGGREGATED_WALLET_PORTFOLIO_ID'

export const useAggregatedWalletPortfolioId = (aggregatedWalletId?: string) => {
  const result = useQuery<{
    aggregatedWallet: {
      portfolio: {
        id: string
      }
    }
  }>(GET_AGGREGATED_WALLET_PORTFOLIO_ID, {
    variables: { aggregatedWalletId },
    skip: !aggregatedWalletId,
  })

  return useMemo(
    () => ({
      ...result,
      portfolioId: result?.data?.aggregatedWallet?.portfolio?.id,
    }),
    [result]
  )
}
