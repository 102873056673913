import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { EndpointGraphql } from 'config/endpointGraphql'
import { SecurityType } from 'config/security'
import { GET_SECURITY_TYPE } from 'hooks/securities/useSecurityType/GET_SECURITY_TYPE'

export const useSecurityType = (securityId?: string) => {
  const result = useQuery<{ findSecurityById: { type: SecurityType } }>(
    GET_SECURITY_TYPE,
    {
      variables: { securityId },
      skip: !securityId,
      context: { endpoint: EndpointGraphql.WUNDERGRAPH },
    }
  )

  return useMemo(
    () => ({
      ...result,
      type: result?.data?.findSecurityById?.type,
    }),
    [result]
  )
}
