// https://ant.design/components/avatar/

import { Avatar as AntdAvatar, AvatarProps as AntdAvatarProps } from 'antd'
import React from 'react'

import { getColorFromString } from 'utils/colors'

export interface AvatarProps extends AntdAvatarProps {
  shape?: 'circle' | 'square'
  size?: 'large' | 'small' | 'default'
  src?: React.ReactNode
  icon?: React.ReactNode
  children?: React.ReactNode
  style?: any
  nameforbackgroundcolor?: string
}

const Avatar = (props: AvatarProps) => {
  return (
    <AntdAvatar
      {...props}
      style={{
        ...props.style,
        backgroundColor: props.nameforbackgroundcolor
          ? getColorFromString(props.nameforbackgroundcolor)
          : props.style?.backgroundColor,
      }}
    />
  )
}

export default React.memo(Avatar)
