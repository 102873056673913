import { Buffer } from 'buffer'
import { deburr } from 'lodash/fp'
import stringHash from 'string-hash'

export const isUnset = (value: any): value is null | undefined => {
  return [null, undefined].includes(value)
}

export const isSet = <T>(value: T): value is Exclude<T, null | undefined> => {
  return !isUnset(value)
}

export const eqSet = (as: Set<any>, bs: Set<any>): boolean => {
  if (as.size !== bs.size) return false
  for (const a of as) if (!bs.has(a)) return false
  return true
}

export const sanitize = (string: string): string => {
  return deburr(string)
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '')
}

export const sanitizeEmail = (email: string): string => {
  return deburr(email)
    .replace(/[^a-z0-9_.+@-]+/gi, '')
    .toLowerCase()
}

export const isValidEmail = (string: string): boolean => {
  return /^.+@.+\..+$/.test(string)
}

export const parseEmail = (value: any): string | undefined => {
  const sanitizedEmail = sanitizeEmail(String(value))
  return isValidEmail(sanitizedEmail) ? sanitizedEmail : undefined
}

export const isEquifyEmail = (string: string): boolean => {
  return string.endsWith('@equify.eu')
}

export const sanitizeUrl = (url: string): string => {
  return url.replace(/\s+/gi, '').toLowerCase()
}

export const isValidUrl = (string: string): boolean => {
  return /^.+\..+$/.test(string)
}

export const parseUrl = (value: any): string | undefined => {
  const url = sanitizeUrl(String(value))
  if (!isValidUrl(url)) {
    return undefined
  }
  if (/^https?:\/\//.test(value)) {
    return url
  }
  return `https://${url}`
}

export const uniqueId = (prefix: string | undefined = ''): string => {
  return prefix + Math.random().toString(36).substring(2)
}

export const last = <T>(array: T[]): T => {
  return array[array.length - 1]
}

export const dropRight = <T>(array: T[]): T[] => {
  return array.slice(0, -1)
}

export const castArray = <T>(value: T[] | T): T[] => {
  if (Array.isArray(value)) {
    return value
  }
  return [value]
}

export const identity = <T>(value: T): T => {
  return value
}

export const cleanArray = <T>(value: (T | undefined | null | false)[]): T[] => {
  return value.filter(Boolean) as T[]
}

export const hashStringToInt = (
  string: string,
  { min = 0, max = Infinity } = {}
) => {
  const charCodeSum = stringHash(sanitize(string))
  const length = max - min + 1
  return min + (charCodeSum % length)
}

export function base64Encode(input: string): string {
  return Buffer.from(input).toString('base64')
}

export function base64Decode(input: string): string {
  return Buffer.from(input, 'base64').toString('utf8')
}

export const base64ToFriendly = (input: string): string => {
  return input.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+/, '')
}

export const base64FromFriendly = (input: string): string => {
  return (
    input.replace(/-/g, '+').replace(/_/g, '/') +
    '==='.slice(0, 4 - (input.length % 4))
  )
}

export const isVirtual = (id: string): boolean => {
  const decodedId = base64Decode(base64FromFriendly(id))
  return decodedId.startsWith('virt')
}

export const objectFlip = (obj: Record<string, string>) => {
  return Object.entries(obj).reduce((ret, entry) => {
    const [key, value] = entry
    ret[value] = key
    return ret
  }, {} as Record<string, string>)
}

export const enumKeys = (targetEnum: Record<string, unknown>) =>
  Object.keys(targetEnum).filter((x) => isNaN(Number(x)))

export function swapElement<T>(
  array: T[],
  indexA: number,
  indexB: number
): T[] {
  const copy = array.slice(0)
  ;[copy[indexA], copy[indexB]] = [copy[indexB], copy[indexA]]
  return copy
}

export const sanitizeExecutableTextExceptEmTag = (input: string) => {
  // Secure < and > characters except for em tag
  let escapedString = input.replace(/<([^em][^/em>]*?)>/g, function (match) {
    return match.replace(/</g, '&lt;').replace(/>/g, '&gt;')
  })

  // Secure other characters
  escapedString = escapedString.replace(/[&"']/g, (match) => {
    switch (match) {
      case '&':
        return '&amp;'
      case '"':
        return '&quot;'
      case "'":
        return '&#39;'
    }
    return ''
  })

  // Convert <em>...</em> into bold
  escapedString = escapedString.replace(
    /<em>(.*?)<\/em>/g,
    function (match, content) {
      return '<b>' + content + '</b>'
    }
  )
  return escapedString
}

export const sanitizeExecutableArrayExceptEmTag = (input: string[]) => {
  return input.map((text) => sanitizeExecutableTextExceptEmTag(text)).join(' ')
}
