import React, { lazy, Suspense } from 'react'

import { useDrawer } from 'providers/Drawer'
import { WalletDrawerTab } from 'providers/Drawer/drawers/Wallet'

const Details = lazy(
  () => import('providers/Drawer/drawers/Wallet/Content/Details')
)
const Vesting = lazy(
  () => import('providers/Drawer/drawers/Wallet/Content/Vesting')
)
const Simulator = lazy(
  () => import('providers/Drawer/drawers/Wallet/Content/Simulator')
)

const Content = () => {
  const { tab } = useDrawer()

  const Component = {
    details: Details,
    vesting: Vesting,
    simulator: Simulator,
  }[tab as WalletDrawerTab]

  if (!Component) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  )
}

export default React.memo(Content)
