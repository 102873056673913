import { gql } from '@apollo/client'

export const GET_SELECTED_FILES_EVENTS = gql`
  query GET_SELECTED_FILES_EVENTS($ids: [ID!]!) {
    files(ids: $ids) {
      id
      event {
        id
      }
    }
  }
`
