import { useMutation, useQuery } from '@apollo/client'
import React from 'react'

import { DataSheetGrid } from 'components/atoms/DataSheetGrid'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import Modal, { ModalProps } from 'components/atoms/Modal'
import { MEETING_ATTENDEES_RESPONSE } from 'config/meetingAttendees'
import { MeetingAttendeesType } from 'config/meetings'
import { useNotification } from 'providers/Notification'
import Alert from 'stories/components/atoms/Alert/Alert'
import eqDSTheme from 'styles/eqDSTheme'

import { GET_MEETING_ATTENDEES } from './GET_MEETING_ATTENDEES'
import { RESEND_MEETING_INVITATIONS } from './RESEND_MEETING_INVITATIONS'

interface Attendee {
  contact: {
    name: string
    email: string
  }
  response: string
}

const ModalResendMeetingInvitations = ({
  meetingId,
  type,
  onCancel,
  open,
  ...rest
}: ModalProps & {
  meetingId: string
  type: MeetingAttendeesType
}) => {
  const formatMessage = useFormatMessage()
  const { successNotification } = useNotification()

  const {
    data,
    loading: loadingAttendees,
    error,
  } = useQuery(GET_MEETING_ATTENDEES, {
    variables: {
      meetingId,
      filters: {
        type: type,
      },
    },
    fetchPolicy: 'network-only',
  })

  const [resendInvitations, { loading: loadingResend }] = useMutation(
    RESEND_MEETING_INVITATIONS
  )
  const attendeesWithoutResponse =
    data?.meeting?.attendees?.list?.hits.filter(
      (attendee: Attendee) =>
        attendee.response === MEETING_ATTENDEES_RESPONSE.NOT_RESPONDED
    ) || []
  const hasAttendeesWithoutResponse = attendeesWithoutResponse.length > 0

  const handleResend = async () => {
    try {
      await resendInvitations({
        variables: {
          meetingId,
          type,
        },
      })

      successNotification({
        message: 'meeting.invitations.resent',
      })
      if (onCancel) {
        onCancel({} as React.MouseEvent<HTMLButtonElement>)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal
      centered
      destroyOnClose
      open={open}
      width={800}
      title={
        type === MeetingAttendeesType.VOTER
          ? 'meeting.resendInvitationsToVoters'
          : 'meeting.resendInvitationsToObservers'
      }
      okText="word.send"
      okButtonProps={{
        loading: loadingResend,
        disabled: !hasAttendeesWithoutResponse,
      }}
      onOk={handleResend}
      onCancel={onCancel}
      {...rest}
    >
      {hasAttendeesWithoutResponse ? (
        <>
          <Alert
            showIcon
            type="info"
            message={formatMessage(
              type === MeetingAttendeesType.VOTER
                ? 'meeting.resendInvitationsToVotersConfirmation'
                : 'meeting.resendInvitationsToObserversConfirmation'
            )}
          />
          <div style={{ marginTop: eqDSTheme.space.large }}>
            <DataSheetGrid<Attendee>
              value={attendeesWithoutResponse}
              onChange={() => {}}
              lockRows
              columns={[
                {
                  title: 'word.name',
                  id: 'name',
                  component: ({ rowData }) => (
                    <div style={{ padding: eqDSTheme.space.medium }}>
                      {rowData.contact.name}
                    </div>
                  ),
                },
                {
                  title: 'word.email',
                  id: 'email',
                  component: ({ rowData }) => (
                    <div style={{ padding: eqDSTheme.space.medium }}>
                      {rowData.contact.email}
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </>
      ) : (
        <Alert
          showIcon
          type="info"
          message={formatMessage(
            type === MeetingAttendeesType.VOTER
              ? 'meeting.allVotersHaveResponded'
              : 'meeting.allObserversHaveResponded'
          )}
        />
      )}
    </Modal>
  )
}

export default ModalResendMeetingInvitations
