import { useQuery } from '@apollo/client'
import React, { ReactNode, useCallback, useMemo } from 'react'

import { useAuth } from 'providers/Auth'
import { RolesContext } from 'providers/Roles/RolesContext'
import { GET_ME_ROLES } from 'providers/Roles/graphql/queries/GET_ME_ROLES'

export const RolesProvider = ({ children }: { children: ReactNode }) => {
  const { isAuth } = useAuth()

  const { data, loading } = useQuery<{
    me: {
      id: string
      lawFirmRoles: {
        id: string
        lawFirm: {
          id: string
        }
      }[]
      corporationRoles: {
        id: string
        corporation: {
          id: string
        }
      }[]
      portfolioRoles: {
        id: string
        portfolio: {
          id: string
        }
      }[]
    }
  }>(GET_ME_ROLES, { skip: !isAuth })

  const hasLawFirmRole = useCallback(
    (value: string) =>
      Boolean(
        data?.me?.lawFirmRoles.find(({ lawFirm }) => lawFirm.id === value)
      ),
    [data]
  )

  const hasCorporationRole = useCallback(
    (value: string) =>
      Boolean(
        data?.me?.corporationRoles.find(
          ({ corporation }) => corporation.id === value
        )
      ),
    [data]
  )

  const hasPortfolioRole = useCallback(
    (value: string) =>
      Boolean(
        data?.me?.portfolioRoles.find(({ portfolio }) => portfolio.id === value)
      ),
    [data]
  )

  const context = useMemo(
    () => ({
      loading,
      lawFirmRoles: data?.me?.lawFirmRoles || [],
      corporationRoles: data?.me?.corporationRoles || [],
      portfolioRoles: data?.me?.portfolioRoles || [],
      hasLawFirmRole,
      hasCorporationRole,
      hasPortfolioRole,
    }),
    [loading, data?.me]
  )

  return (
    <RolesContext.Provider value={context}>{children}</RolesContext.Provider>
  )
}
