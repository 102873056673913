import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { SignatureStatus } from 'config/procedure'
import { GET_FILE_PROCEDURE_FILE_SIGNERS_STATUS } from 'hooks/files/useFileProcedureFileSignersStatus/GET_FILE_PROCEDURE_FILE_SIGNERS_STATUS'

export const useFileProcedureFileSignersStatus = (fileId?: string) => {
  const result = useQuery<{
    file: {
      isYousignV2: boolean
      procedureFile: { signers: { status: SignatureStatus }[] }
    }
  }>(GET_FILE_PROCEDURE_FILE_SIGNERS_STATUS, {
    variables: { fileId },
    skip: !fileId,
  })

  return useMemo(
    () => ({
      ...result,
      isYousignV2: result?.data?.file?.isYousignV2,
      signers: result?.data?.file?.procedureFile?.signers ?? [],
    }),
    [result]
  )
}

export const useFileHasAtLeastOneSignedSignatureAndYousignIdV2 = (
  fileId: string
) => {
  const { signers, isYousignV2 } = useFileProcedureFileSignersStatus(fileId)

  return isYousignV2
    ? signers.some(({ status }) => status === SignatureStatus.SIGNED)
    : false
}

export const useFileHasAtLeastOnePendingSignature = (fileId: string) => {
  const { signers } = useFileProcedureFileSignersStatus(fileId)

  return signers.some(({ status }) => status === SignatureStatus.PENDING)
}

/**
 * Use this hook to check if all signatures are signed for YousignIdV3 file
 * @param fileId
 * Proof file only available for YousignIdV3 when all signatures are signed
 * @returns boolean
 * @see https://developers.yousign.com/reference/get-signature_requests-signaturerequestid-audit_trails-download
 * @description "Yousign doc citation: Download the PDF version of all the Audit Trails attached to a given Signature Request.
 * Each Audit Trail is bound to a different Signer. Only possible when the Signature Request status is done."
 */
export const useFileHasAllSignaturesSignedAndYousignIdV3 = (fileId: string) => {
  const { signers, isYousignV2 } = useFileProcedureFileSignersStatus(fileId)

  return !isYousignV2
    ? signers.every(({ status }) => status === SignatureStatus.SIGNED)
    : false
}
