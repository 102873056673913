import React, { useContext } from 'react'

import { ExportFormat, ExportType } from 'config/export'

export interface IExportContext {
  openExportModal: (params: {
    formats: ExportFormat[]
    type: ExportType
    params: Record<string, any>
    name: string
  }) => void
  startExport: (params: {
    format: ExportFormat
    type: ExportType
    params: Record<string, any>
    name: string
  }) => void
}

export const ExportContext = React.createContext<IExportContext>({
  openExportModal: () => {},
  startExport: () => {},
})

export const useExport = (): IExportContext => useContext(ExportContext)
