import {
  base64FromFriendly,
  base64Decode,
  base64ToFriendly,
  base64Encode,
} from 'utils'

export const serialize = (value: {
  securityId: string
  portfolioId: string
}) => {
  const { securityId, portfolioId } = value

  return base64ToFriendly(base64Encode(`${securityId}/${portfolioId}`))
}

export const deserializeAggregatedWalletId = (value: string) => {
  const [securityId, portfolioId] = base64Decode(
    base64FromFriendly(value)
  ).split('/')

  return { portfolioId, securityId }
}
