export enum Languages {
  French = 'fr',
  English = 'en',
}

export enum Locales {
  enGB = 'enGB',
  frFR = 'frFR',
}

export const DEFAULT_LOCALE = Locales.enGB

export const languageDefaultLocale = {
  [Languages.French]: Locales.frFR,
  [Languages.English]: Locales.enGB,
}

export const LOCALE_ICON_FONT = {
  [Locales.enGB]: 'icon-united-kingdom',
  [Locales.frFR]: 'icon-france',
}
