import React, { useContext } from 'react'

import { DrawerNames } from 'config/drawer'
import { AggregatedTransactionDrawerTab } from 'providers/Drawer/drawers/AggregatedTransaction'
import { AggregatedWalletDrawerTab } from 'providers/Drawer/drawers/AggregatedWallet'
import { CommunicationDrawerTab } from 'providers/Drawer/drawers/Communication'
import { ContactDrawerTab } from 'providers/Drawer/drawers/Contact'
import { EventDrawerTab } from 'providers/Drawer/drawers/Event'
import { FileDrawerTab } from 'providers/Drawer/drawers/File'
import { GroupDrawerTab } from 'providers/Drawer/drawers/Group'
import { IntegrationHistoryDrawerTab } from 'providers/Drawer/drawers/IntegrationHistory'
import { LegalEntityDrawerTab } from 'providers/Drawer/drawers/LegalEntity'
import { MeetingDrawerTab } from 'providers/Drawer/drawers/Meeting'
import { PoolDrawerTab } from 'providers/Drawer/drawers/Pool'
import { PortfolioDrawerTab } from 'providers/Drawer/drawers/Portfolio'
import { SecurityDrawerTab } from 'providers/Drawer/drawers/Security'
import { WalletDrawerTab } from 'providers/Drawer/drawers/Wallet'

export type TabType =
  | AggregatedTransactionDrawerTab
  | AggregatedWalletDrawerTab
  | ContactDrawerTab
  | EventDrawerTab
  | FileDrawerTab
  | GroupDrawerTab
  | LegalEntityDrawerTab
  | MeetingDrawerTab
  | CommunicationDrawerTab
  | PoolDrawerTab
  | PortfolioDrawerTab
  | SecurityDrawerTab
  | WalletDrawerTab
  | IntegrationHistoryDrawerTab

export type DrawerInitType = {
  name: DrawerNames
  id: string
  tab?: TabType
}

export type DrawerType = { name: DrawerNames; id: string; tab: TabType }

interface IDrawersContext {
  openDrawer: (drawer: DrawerInitType) => void
  closeDrawer: (id?: string) => void
  changeTab: (element: { id: string; tab: TabType }) => void
  closeAllDrawers: () => void
  drawers: DrawerType[]
}

export const DrawersContext = React.createContext<IDrawersContext>({
  openDrawer: () => {},
  closeDrawer: () => {},
  changeTab: () => {},
  closeAllDrawers: () => {},
  drawers: [],
})

export const useDrawers = (): IDrawersContext => useContext(DrawersContext)
