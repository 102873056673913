// https://ant.design/components/skeleton/

import { Skeleton as AntdSkeleton } from 'antd'
import { AvatarProps as AntdAvatarProps } from 'antd/lib/avatar'
import React from 'react'

export interface SkeletonAvatarProps extends Omit<AntdAvatarProps, 'size'> {
  children?: any
  loading?: boolean
  size?: any
}

const SkeletonAvatar = ({
  loading,
  children,
  ...rest
}: SkeletonAvatarProps) => {
  if (loading === false) {
    return children ?? null
  }

  return <AntdSkeleton.Avatar active {...rest} />
}

export default React.memo(SkeletonAvatar)
