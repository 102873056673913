// https://ant.design/components/result/

import { Result as AntdResult } from 'antd'
import { ResultProps as AntdResultProps } from 'antd/lib/result'
import React from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'

interface ResultProps extends AntdResultProps {
  title?: MessageType
  subTitle?: MessageType
}

const Result = ({ title, subTitle, ...rest }: ResultProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdResult
      title={title ? formatMessage(title) : undefined}
      subTitle={subTitle ? formatMessage(subTitle) : undefined}
      {...rest}
    />
  )
}

export default React.memo(Result)
