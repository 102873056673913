import { gql } from '@apollo/client'

export const CREATE_FILES = gql`
  mutation CREATE_FILES($corporationId: ID!, $fileNames: [String!]!) {
    createFiles(corporationId: $corporationId, fileNames: $fileNames) {
      id
      aws {
        url
        fields
      }
    }
  }
`
