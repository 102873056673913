import { useMutation, useQuery } from '@apollo/client'
import { uniq } from 'lodash/fp'
import React, { useEffect, useState } from 'react'

import FormItem from 'components/atoms/Form/FormItem'
import Input from 'components/atoms/Form/Input'
import { TranslatedMessage } from 'components/atoms/Intl/Message'
import { FILES_SET_SUBJECT } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Subject/FILES_SET_SUBJECT'
import { GET_SELECTED_FILES_SUBJECTS } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Subject/GET_SELECTED_FILES_SUBJECTS'
import { useLocale } from 'providers/Locale'

export const Subject = ({ selectedIds }: { selectedIds: string[] }) => {
  const [value, setValue] = useState<string | null>(null)
  const [setSubject] = useMutation(FILES_SET_SUBJECT)
  const { locale } = useLocale()

  const { data } = useQuery<{
    files: {
      id: string
      subject: TranslatedMessage
    }[]
  }>(GET_SELECTED_FILES_SUBJECTS, {
    variables: { ids: selectedIds },
  })

  useEffect(() => {
    const values: string[] = uniq(
      (data?.files || []).map((file: any) => file.subject?.[locale])
    )
    if (values.length === 1) {
      setValue(values[0])
    } else {
      setValue(null)
    }
  }, [data])

  return (
    <FormItem
      label="word.additionalInformation"
      tooltip="file.additionalInformation.description"
    >
      <Input
        allowClear
        value={value}
        onChange={setValue}
        onBlur={() => {
          setSubject({
            variables: { ids: selectedIds, subject: value || null },
          })
        }}
        showCount={true}
        maxLength={255}
      />
    </FormItem>
  )
}
