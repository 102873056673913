import React, { useMemo } from 'react'
import { CellProps } from 'react-datasheet-grid'

import { DataSheetGridError } from 'components/atoms/DataSheetGrid/index'
import Tooltip from 'components/atoms/Tooltip'
import eqDSTheme from 'styles/eqDSTheme'
import { x } from 'utils/emotion'

export const CellErrorWrapper: React.FC<{
  error?: DataSheetGridError['error']
}> = ({ error, children }) => {
  const title = useMemo(() => {
    if (!error) {
      return undefined
    }

    return {
      id: 'error.field.' + error.type,
      values: error.context || {},
    }
  }, [error])

  return (
    <>
      {error && (
        <Tooltip title={title}>
          <x.div
            position="absolute"
            top="0"
            right="0"
            borderTop="solid 9px"
            borderTopColor={eqDSTheme.color.error}
            borderLeft="solid 9px transparent"
          />
        </Tooltip>
      )}
      {children}
    </>
  )
}

export const wrapError = (Component: any) => (props: CellProps) => {
  return (
    <CellErrorWrapper
      error={
        props.columnData.errors?.find(
          (error: DataSheetGridError) => error.row === props.rowIndex
        )?.error
      }
    >
      <Component {...props} />
    </CellErrorWrapper>
  )
}
