import { gql } from '@apollo/client'

export const GET_AGGREGATED_TRANSACTION_TO_SECURITY_ID = gql`
  query GET_AGGREGATED_TRANSACTION_TO_SECURITY_ID(
    $aggregatedTransactionId: ID!
  ) {
    aggregatedTransaction(id: $aggregatedTransactionId) {
      id
      toSecurity {
        id
      }
    }
  }
`
