import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { useFileName } from 'hooks/files/useFileName'

const FileName = ({ fileId }: { fileId: string }) => {
  const { name, loading } = useFileName(fileId)

  return <SkeletonText loading={loading}>{name}</SkeletonText>
}

export default React.memo(FileName)
