import { FormikValues } from 'formik'
import React, { ElementType, useContext } from 'react'

export interface WorkflowType {
  async?: boolean
  workflow: any
  length: number
  onCompleted: (data?: any) => void
  loading: boolean
  component: ElementType
}

interface IWorkflowContext<T = Record<string, any>, U = Record<string, any>> {
  loading: boolean
  close: () => void
  next: (payload: FormikValues) => void
  back: ((payload: Record<string, any>) => void) | null
  saveCurrent: (payload: FormikValues) => void
  data: T
  progress: {
    current: number | null
    steps: string[]
  }
  currentStep: object
  slug: string
  initialPayload: U
}

export const WorkflowContext = React.createContext<IWorkflowContext>({
  loading: true,
  close: () => {},
  next: () => {},
  back: () => {},
  saveCurrent: () => {},
  data: {},
  progress: {
    current: null,
    steps: [],
  },
  currentStep: {},
  slug: '',
  initialPayload: {},
})

export const useWorkflow = function <T, U = Record<string, any>>() {
  return useContext(WorkflowContext) as IWorkflowContext<T, U>
}
