import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_CONTACT_NAME } from 'hooks/contacts/useContactName/GET_CONTACT_NAME'

export const useContactName = (contactId?: string) => {
  const result = useQuery<{ contact: { name: string } }>(GET_CONTACT_NAME, {
    variables: { contactId },
    skip: !contactId,
  })

  return useMemo(
    () => ({
      ...result,
      name: result?.data?.contact?.name,
    }),
    [result]
  )
}
