import { gql } from '@apollo/client'

export const GET_SELECTED_FILES_AGGREGATED_TRANSACTIONS = gql`
  query GET_SELECTED_FILES_AGGREGATED_TRANSACTIONS($ids: [ID!]!) {
    files(ids: $ids) {
      id
      aggregatedTransactions {
        aggregatedTransactions {
          id
        }
      }
    }
  }
`
