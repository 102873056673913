// https://ant.design/components/input/

import React, { ReactElement, useEffect, useState } from 'react'

import Input, { InputProps, InputValueType } from 'components/atoms/Form/Input'

export interface InputWithFormatProps<T = InputValueType>
  extends Omit<InputProps, 'onChange' | 'value'> {
  formatValue?: (value?: InputValueType) => InputValueType
  parseValue?: (value?: InputValueType) => T
  convertInValue?: (value?: T) => T
  convertOutValue?: (value?: T) => T
  forceFocus?: boolean
  onChange?: (value: T) => void
  value?: T
  changeOnBlur?: boolean
}

const InputWithFormat = (({
  forceFocus,
  value,
  onBlur = () => {},
  onFocus = () => {},
  formatValue = (value) => value ?? null,
  parseValue = (value) => value ?? null,
  convertInValue = (value) => value ?? null,
  convertOutValue = (value) => value ?? null,
  onChange = () => {},
  changeOnBlur,
  ...rest
}: InputWithFormatProps) => {
  const [focus, setFocus] = useState(false)
  const [renderValue, setRenderValue] = useState<InputValueType>(
    convertInValue(value)
  )

  useEffect(() => {
    if (value !== convertOutValue(parseValue(renderValue))) {
      setRenderValue(convertInValue(value) ?? null)
    }
  }, [value])

  return (
    <Input
      value={
        focus || forceFocus
          ? renderValue
          : formatValue(convertOutValue(parseValue(renderValue)))
      }
      onFocus={(event) => {
        setFocus(true)
        onFocus(event)
      }}
      onBlur={(event) => {
        const parsedValue = parseValue(renderValue)
        if (changeOnBlur) {
          onChange(convertOutValue(parsedValue))
        }
        setRenderValue(parsedValue)
        setFocus(false)
        onBlur(event)
      }}
      onChange={(value) => {
        if (!changeOnBlur) {
          onChange(convertOutValue(parseValue(value)))
        }
        setRenderValue(value)
      }}
      {...rest}
    />
  )
}) as <T>(props: InputWithFormatProps<T>) => ReactElement

export default React.memo(InputWithFormat)
