import React from 'react'

import { x, XProps } from 'utils/emotion'

const Center = ({ ...rest }: XProps) => {
  return (
    <x.div
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...rest}
    />
  )
}

export default React.memo(Center)
