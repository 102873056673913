import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_CORPORATION_INFO_NAME } from 'hooks/corporationInfo/useCorporationInfoName/GET_CORPORATION_INFO_NAME'

export const useCorporationInfoName = (corporationInfoId?: string) => {
  const result = useQuery<{
    corporationInfo: { name: string }
  }>(GET_CORPORATION_INFO_NAME, {
    variables: { corporationInfoId },
    skip: !corporationInfoId,
  })

  return useMemo(
    () => ({
      ...result,
      name: result?.data?.corporationInfo?.name,
    }),
    [result]
  )
}
