import * as Sentry from '@sentry/react'
import 'config/fontAwesome'
import { createBrowserHistory } from 'history'
import React from 'react'
import ReactDOM from 'react-dom'

import 'styles/global.css'

import App from 'App'
import { Env, NODE_ENV, REACT_APP_SENTRY_DSN } from 'config/env'
import reportWebVitals from 'reportWebVitals'
// https://github.com/software-mansion/react-native-reanimated/issues/4140
import 'setimmediate'

// Create Custom Sentry Route component

const history = createBrowserHistory()

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      tracePropagationTargets: [window.location.hostname, /^\//],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: NODE_ENV,
  enabled: NODE_ENV !== Env.DEV,
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
