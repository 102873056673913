import { gql } from '@apollo/client'

export const ASYNC_WORKFLOW = gql`
  subscription ASYNC_WORKFLOW($workflowId: ID!) {
    asyncWorkflow(id: $workflowId) {
      ... on Workflow {
        id
        slug
        data
        stateKey
        canGoBack
        updatedEntities {
          id
          typename
        }
        currentStep {
          slug
          payload
        }
        progress {
          current
          steps
        }
      }
      ... on FinishedWorkflow {
        data
        updatedEntities {
          id
          typename
        }
      }
      ... on AsyncWorkflowProgress {
        asyncProgress: progress
      }
    }
  }
`
