import {
  AlignLeftOutlined,
  ReadOutlined,
  FileTextOutlined,
  TeamOutlined,
  MailOutlined,
} from '@ant-design/icons'
import { MenuInfo } from 'rc-menu/lib/interface.d'
import React from 'react'

import Menu from 'components/atoms/Menu'
import { CorporationPermissions, PortfolioPermissions } from 'config/roles'
import { useIsCorporationWorkspace } from 'hooks/useWorkspace'
import { useDrawer } from 'providers/Drawer'
import { MeetingDrawerTab } from 'providers/Drawer/drawers/Meeting'
import { usePermissions } from 'providers/Permissions'

const Sider = () => {
  const { tab, changeTab } = useDrawer()
  const { checkPermissions } = usePermissions()
  const isCorporationWorkspace = useIsCorporationWorkspace()

  const handleClick = ({ key }: MenuInfo) => {
    changeTab(key as MeetingDrawerTab)
  }

  return (
    <Menu
      selectedKeys={[tab]}
      mode="inline"
      style={{ height: '100%' }}
      menu={[
        {
          icon: <AlignLeftOutlined />,
          title: 'word.details',
          key: MeetingDrawerTab.details,
          onClick: handleClick,
        },
        checkPermissions(CorporationPermissions.PORTFOLIOS_READ)
          ? {
              icon: <TeamOutlined />,
              title: 'word.attendees',
              key: MeetingDrawerTab.members,
              onClick: handleClick,
            }
          : undefined,
        {
          icon: <ReadOutlined />,
          title: 'word.agenda',
          key: MeetingDrawerTab.agenda,
          onClick: handleClick,
        },
        isCorporationWorkspace
          ? {
              icon: <MailOutlined />,
              title: 'word.email',
              key: MeetingDrawerTab.email,
              onClick: handleClick,
            }
          : undefined,
        checkPermissions(CorporationPermissions.DOCUMENTS_READ) ||
        checkPermissions(PortfolioPermissions.DOCUMENTS_READ)
          ? {
              icon: <FileTextOutlined />,
              title: 'word.documents',
              key: MeetingDrawerTab.documents,
              onClick: handleClick,
            }
          : undefined,
      ]}
    />
  )
}

export default React.memo(Sider)
