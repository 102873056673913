import { gql } from '@apollo/client'

export const GET_MEETING_FROM_DATE = gql`
  query GET_MEETING_FROM_DATE($meetingId: ID!) {
    meeting(id: $meetingId) {
      id
      fromDate
    }
  }
`
