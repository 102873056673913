import { gql } from '@apollo/client'

export const GET_SYNC_HISTORY_SYNC_TYPE = gql`
  query GET_SYNC_HISTORY_SYNC_TYPE($syncHistoryId: ID!) {
    syncHistory(id: $syncHistoryId) {
      id
      syncType
    }
  }
`
