import React, { useMemo } from 'react'

import { x, XProps } from 'utils/emotion'

interface SectionProps extends Omit<XProps, 'flex'> {
  flex?: boolean
  visible?: boolean
}

const Section = ({ flex, visible, ...rest }: SectionProps) => {
  const display = useMemo(() => {
    if (visible === false) {
      return 'none'
    }
    if (flex) {
      return 'flex'
    }
    return undefined
  }, [visible, flex])

  return (
    <x.div
      margin="large"
      display={display}
      alignItems={flex ? 'center' : undefined}
      {...rest}
    />
  )
}

export default React.memo(Section)
