import { gql } from '@apollo/client'

export const GET_CONTACT_NAME = gql`
  query GET_CONTACT_NAME($contactId: ID!) {
    contact(id: $contactId) {
      id
      name
    }
  }
`
