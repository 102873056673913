import { gql } from '@apollo/client'

export const GET_POOL_NAME = gql`
  query GET_POOL_NAME($poolId: ID!) {
    pool(id: $poolId) {
      id
      name
    }
  }
`
