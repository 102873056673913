import { PlusOutlined, QuestionOutlined } from '@ant-design/icons'
import React, { ReactNode, useState } from 'react'
import { AddRowsComponentProps } from 'react-datasheet-grid'

import Button from 'components/atoms/Button'
import InputInteger from 'components/atoms/Form/InputInteger'
import Message from 'components/atoms/Intl/Message'
import Flex from 'components/atoms/Section/Flex'
import Tooltip from 'components/atoms/Tooltip'
import { isNumber } from 'utils/numbers'

export const AddRows = ({
  addRows,
  extra,
  disabled,
  maxValue,
}: AddRowsComponentProps & {
  extra?: ReactNode
  disabled?: boolean
  maxValue?: number
}) => {
  const [value, setValue] = useState<number>(1)

  return (
    <div className="dsg-add-row">
      <Button
        icon={<PlusOutlined />}
        message={'word.add'}
        onClick={() => {
          addRows(value)
        }}
        disabled={disabled}
      />{' '}
      <InputInteger
        style={{ width: 50 }}
        onPressEnter={(event) => {
          // @ts-ignore
          if (isNumber(event?.target?.value)) {
            // @ts-ignore
            addRows(Number(event.target.value))
          }
        }}
        value={value}
        min={1}
        max={maxValue ?? 99}
        onChange={(v) => {
          setValue(v || 1)
        }}
      />
      <Message
        value={{
          id: 'unit.lines',
          values: { value },
        }}
      />
      <Flex />
      {extra ?? (
        <Tooltip
          title={{
            id: 'datasheetgrid.help.tooltip',
            values: { newLine: <br /> },
          }}
          placement="topLeft"
        >
          <Button type="text" icon={<QuestionOutlined />} shape="circle" />
        </Tooltip>
      )}
    </div>
  )
}
