import { useEffect, useMemo } from 'react'
import ReactDOM from 'react-dom'

export const WorkflowPortal = ({
  zIndex = '1000',
  children,
}: {
  zIndex?: any
  children: any
}) => {
  const element = useMemo(() => {
    const e = document.createElement('div')
    e.style.zIndex = zIndex
    e.style.position = 'absolute'

    return e
  }, [zIndex])

  // @ts-ignore
  useEffect(() => {
    document.body.appendChild(element)

    return () => document.body.removeChild(element)
  }, [element])

  return ReactDOM.createPortal(children, element)
}
