import { gql } from '@apollo/client'

export const FILES_SET_DATE = gql`
  mutation FILES_SET_DATE($ids: [ID!]!, $date: Date) {
    files(ids: $ids) {
      setDate(date: $date) {
        id
        date
      }
    }
  }
`
