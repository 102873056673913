import { useQuery } from '@apollo/client'
import React from 'react'

import Message, { TranslatedMessage } from 'components/atoms/Intl/Message'
import List from 'components/atoms/List'
import Space from 'components/atoms/Space'
import Tooltip from 'components/atoms/Tooltip'
import { GET_FILE_SMART_FIELD_GROUPS } from 'components/molecules/List/FileSmartFieldGroups/GET_FILE_SMART_FIELD_GROUPS'
import { SmartFieldsGroupType, SmartFieldsProperty } from 'config/file'
import { x } from 'utils/emotion'

const FileSmartFieldGroups = ({ fileId }: { fileId: string }) => {
  const { data, loading } = useQuery<{
    file: {
      id: string
      smartFields: {
        customSmartFields: {
          name: TranslatedMessage
        }[]
        smartFieldsGroups: {
          type: SmartFieldsGroupType
          rawName: string
          name: TranslatedMessage
          smartFields: {
            name: TranslatedMessage
            property: SmartFieldsProperty
          }[]
        }[]
      }
    }
  }>(GET_FILE_SMART_FIELD_GROUPS, {
    variables: { fileId },
  })

  return (
    <List
      loading={loading}
      list={[
        ...(data?.file?.smartFields?.smartFieldsGroups ?? []).map(
          ({ name, rawName, smartFields }, index) => {
            return {
              label: (
                <div>
                  <Message value={name} />{' '}
                  <x.span fontSize="small" color="grey">
                    {`{${rawName}}`}
                  </x.span>
                </div>
              ),
              key: 'smartFieldsGroups' + index,
              value: (
                <Space
                  wrap={true}
                  split={<span style={{ marginLeft: '-3px' }}>,</span>}
                >
                  {smartFields.map(({ name, property }, index) => {
                    if (property === SmartFieldsProperty.UNKNOWN) {
                      return (
                        <Tooltip
                          key={index}
                          title="word.smartFieldNotRecognized"
                        >
                          <x.div color="grey">
                            <Message value={name} />
                          </x.div>
                        </Tooltip>
                      )
                    }

                    return <Message value={name} key={index} />
                  })}
                </Space>
              ),
            }
          }
        ),
        ...(data?.file?.smartFields?.customSmartFields ?? []).map(
          ({ name }, index) => {
            return {
              key: 'customSmartFields' + index,
              label: <Message value={name} key={index} />,
            }
          }
        ),
      ]}
    />
  )
}

export default React.memo(FileSmartFieldGroups)
