import React from 'react'

import ApiSearchSelect, {
  ApiSearchSelectProps,
} from 'components/atoms/ApiSearch/Select'
import EventDate from 'components/molecules/Event/Date'
import EventName from 'components/molecules/Event/Name'
import { ApiSearchCorporationEvents } from 'providers/ApiSearch/CorporationEvents'
import { ApiSearchFiltersProvider } from 'providers/ApiSearchFilters'
import { x } from 'utils/emotion'

const ApiSearchSelectCorporationEvents = ({
  corporationId,
  ...rest
}: Omit<ApiSearchSelectProps, 'renderItem'> & {
  corporationId: string
}) => {
  return (
    <ApiSearchFiltersProvider>
      <ApiSearchCorporationEvents corporationId={corporationId}>
        <ApiSearchSelect
          placeholder="word.searchForAnEvent"
          renderItem={(id) => (
            <x.div display={'inline'} whiteSpace={'normal'}>
              <EventDate eventId={id} /> · <EventName eventId={id} />
            </x.div>
          )}
          {...rest}
        />
      </ApiSearchCorporationEvents>
    </ApiSearchFiltersProvider>
  )
}

export default React.memo(ApiSearchSelectCorporationEvents)
