import React, { useContext } from 'react'

interface IAuthContext {
  isAuth: boolean
}

export const AuthContext = React.createContext<IAuthContext>({
  isAuth: false,
})

export const useAuth = (): IAuthContext => useContext(AuthContext)
