import React, { useContext } from 'react'

import { ExportFormat, ExportType } from 'config/export'
import { ObjectStateAction } from 'hooks/useObjectState'

interface IExportModalContext {
  formats: ExportFormat[]
  type?: ExportType
  name?: string
  params: Record<string, any>
  setParams: React.Dispatch<ObjectStateAction>
}

export const ExportModalContext = React.createContext<IExportModalContext>({
  formats: [],
  type: undefined,
  name: undefined,
  params: {},
  setParams: () => {},
})

export const useExportModal = (): IExportModalContext =>
  useContext(ExportModalContext)
