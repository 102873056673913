import { gql } from '@apollo/client'

export const GET_TAG_DATA = gql`
  query GET_TAG_DATA($tagId: ID!) {
    tag(id: $tagId) {
      id
      name {
        enGB
        frFR
      }
      color
    }
  }
`
