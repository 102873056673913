import React from 'react'

import Panel from 'components/atoms/Collapse/Panel'
import FileSharedWithCount from 'components/molecules/File/SharedWithCount'
import { FilesEditModalSection } from 'providers/FilesEdit'
import { SharedWith as SharedWithInput } from 'providers/FilesEdit/Modal/FileProperties/Inputs/SharedWith'

const SharedWith = ({
  selectedIds,
  fileId,
  sections,
  corporationId,
  ...rest
}: {
  corporationId: string
  fileId?: string
  selectedIds: string[]
  sections: FilesEditModalSection[]
}) => {
  if (!sections.includes(FilesEditModalSection.SHARED_WITH)) {
    return (
      <Panel
        key="shareWith"
        header="word.shareWithHolders"
        collapsible="disabled"
      />
    )
  }

  return (
    <Panel
      {...rest}
      key="shareWith"
      header="word.shareWithHolders"
      extra={fileId ? <FileSharedWithCount fileId={fileId} /> : undefined}
      message={
        <SharedWithInput
          selectedIds={selectedIds}
          corporationId={corporationId}
        />
      }
    />
  )
}

export default React.memo(SharedWith)
