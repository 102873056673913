import {
  EditOutlined,
  FileTextOutlined,
  LoadingOutlined,
  TableOutlined,
  ContactsOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import React, { useMemo } from 'react'

import ButtonMoreActions from 'components/atoms/Button/MoreActions'
import Menu from 'components/atoms/Menu'
import { DrawerNames } from 'config/drawer'
import { ExportFormat, ExportType } from 'config/export'
import { CorporationPermissions } from 'config/roles'
import { SLUGS } from 'config/slugs'
import { usePortfolioCorporationId } from 'hooks/portfolios/usePortfolioCorporationId'
import { usePortfolioHolderTypename } from 'hooks/portfolios/usePortfolioHolderTypename'
import { usePortfolioName } from 'hooks/portfolios/usePortfolioName'
import { useDrawers } from 'providers/Drawers'
import { useExport } from 'providers/Export'
import { usePermissions } from 'providers/Permissions'
import { useStartWorkflow } from 'providers/Workflows/hooks/useStartWorkflow'
import { today } from 'utils/date'

const ButtonMoreActionsHolder = ({ portfolioId }: { portfolioId: string }) => {
  const { openDrawer } = useDrawers()
  const { openExportModal } = useExport()
  const { checkPermissions } = usePermissions()
  const { corporationId } = usePortfolioCorporationId(portfolioId)
  const { name, loading } = usePortfolioName(portfolioId)
  const { __typename: type, id: holderId } =
    usePortfolioHolderTypename(portfolioId)

  const [editPortfolio] = useStartWorkflow({
    data: { corporationId, portfolioIds: [portfolioId] },
    slug: SLUGS.EDIT_HOLDERS,
  })

  const [editAccount] = useStartWorkflow({
    slug: type === 'Contact' ? 'editContacts' : 'editCorporationInfo',
    data:
      type === 'Contact'
        ? { contactIds: [holderId], corporationId }
        : { corporationInfoIds: [holderId], corporationId },
  })

  const targetDrawerName = useMemo(() => {
    return type === 'Contact' ? DrawerNames.contact : DrawerNames.legalE
  }, [type])

  const [managePortfolioContacts] = useStartWorkflow({
    data: { portfolioId },
    slug: 'managePortfolioContacts',
  })

  if (!checkPermissions(CorporationPermissions.PORTFOLIOS_MANAGE)) {
    return null
  }

  return (
    <ButtonMoreActions
      overlay={
        <Menu
          menu={[
            {
              key: 'editAccount',
              icon: <EditOutlined />,
              message: 'word.editAccount',
              onClick: editPortfolio,
            },
            checkPermissions(CorporationPermissions.CONTACTS_READ)
              ? {
                  key: 'editOwner',
                  icon: <ContactsOutlined />,
                  message: 'word.viewInformation',
                  onClick: () => {
                    if (holderId) {
                      openDrawer({
                        id: holderId,
                        name: targetDrawerName,
                      })
                    }
                  },
                }
              : undefined,
            checkPermissions(CorporationPermissions.CONTACTS_MANAGE)
              ? {
                  key: 'managePortfolioContacts',
                  icon: <UserSwitchOutlined />,
                  message: 'word.manageContacts',
                  onClick: managePortfolioContacts,
                }
              : undefined,
            {
              disabled: loading || !name,
              key: 'exportPdf',
              icon: loading ? <LoadingOutlined /> : <FileTextOutlined />,
              message: 'word.createHoldingStatement',
              onClick: () => {
                if (name) {
                  openExportModal({
                    type: ExportType.HOLDING_STATEMENT,
                    formats: [ExportFormat.PDF],
                    name,
                    params: {
                      date: today(),
                      addFileToLibrary: true,
                      portfolioId,
                      corporationId,
                    },
                  })
                }
              },
            },
            {
              disabled: loading || !name,
              key: 'exportExcel',
              icon: loading ? <LoadingOutlined /> : <TableOutlined />,
              message: 'word.toExport',
              onClick: () => {
                if (name) {
                  openExportModal({
                    type: ExportType.HOLDING_STATEMENT,
                    formats: [ExportFormat.XLSX],
                    name,
                    params: {
                      date: today(),
                      addFileToLibrary: false,
                      portfolioId,
                      corporationId,
                    },
                  })
                }
              },
            },
          ]}
        />
      }
    />
  )
}

export default React.memo(ButtonMoreActionsHolder)
