import { gql } from '@apollo/client'

export const GET_AGGREGATED_TRANSACTION_TO_QUANTITY = gql`
  query GET_AGGREGATED_TRANSACTION_TO_QUANTITY($aggregatedTransactionId: ID!) {
    aggregatedTransaction(id: $aggregatedTransactionId) {
      id
      toQuantity
    }
  }
`
