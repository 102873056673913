import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_AGGREGATED_TRANSACTION_DATE } from 'hooks/aggregatedTransactions/useAggregatedTransactionDate/GET_AGGREGATED_TRANSACTION_DATE'

export const useAggregatedTransactionDate = (
  aggregatedTransactionId?: string
) => {
  const result = useQuery<{
    aggregatedTransaction: {
      date: string
    }
  }>(GET_AGGREGATED_TRANSACTION_DATE, {
    variables: { aggregatedTransactionId },
    skip: !aggregatedTransactionId,
  })

  return useMemo(
    () => ({
      ...result,
      date: result?.data?.aggregatedTransaction?.date,
    }),
    [result]
  )
}
