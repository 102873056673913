import { useQuery } from '@apollo/client'
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import {
  CorporationPermissions,
  PermissionsType,
  LawFirmPermissions,
  PortfolioPermissions,
} from 'config/roles'
import { WORKSPACE } from 'config/workspace'
import { useIsMobileWindowSize } from 'hooks/useWindowSize'
import { useWorkspace } from 'hooks/useWorkspace'
import { useAuth } from 'providers/Auth'
import { PermissionsContext } from 'providers/Permissions/PermissionsContext'
import { GET_ME_ROLES_PERMISSIONS } from 'providers/Permissions/graphql/queries/GET_ME_ROLES_PERMISSIONS'

export const PermissionsProvider = ({ children }: { children: ReactNode }) => {
  const { isAuth } = useAuth()
  const { workspace, workspaceId } = useWorkspace()
  const isMobileWindowSize = useIsMobileWindowSize()
  const [permissions, setPermissions] = useState<PermissionsType[]>([])

  const { data, loading } = useQuery<{
    me: {
      id: string
      lawFirmRoles: {
        id: string
        permissions: LawFirmPermissions[]
        lawFirm: {
          id: string
        }
      }[]
      corporationRoles: {
        id: string
        permissions: CorporationPermissions[]
        corporation: {
          id: string
        }
      }[]
      portfolioRoles: {
        id: string
        permissions: PortfolioPermissions[]
        portfolio: {
          id: string
        }
      }[]
    }
  }>(GET_ME_ROLES_PERMISSIONS, { skip: !isAuth })

  const workspaceRole = useMemo(() => {
    switch (workspace) {
      case WORKSPACE.CORPORATION:
        return data?.me?.corporationRoles?.find(({ corporation }) => {
          return corporation.id === workspaceId
        })
      case WORKSPACE.LAW_FIRM:
        return data?.me?.lawFirmRoles?.find(({ lawFirm }) => {
          return lawFirm.id === workspaceId
        })
      case WORKSPACE.PORTFOLIO:
        return data?.me?.portfolioRoles?.find(({ portfolio }) => {
          return portfolio.id === workspaceId
        })
    }
  }, [data, workspace, workspaceId])

  useEffect(() => {
    setPermissions(workspaceRole?.permissions ?? [])
  }, [workspaceRole])

  const checkPermissions = useCallback(
    (value: PermissionsType[] | PermissionsType) => {
      if (Array.isArray(value)) {
        if (value.some((v) => v.includes('MANAGE')) && isMobileWindowSize) {
          return false
        }
        return value.every((permission) => permissions.includes(permission))
      }
      if (value.includes('MANAGE') && isMobileWindowSize) {
        return false
      }
      return permissions.includes(value)
    },
    [permissions, isMobileWindowSize]
  )

  const context = useMemo(
    () => ({
      loading,
      permissions,
      setPermissions,
      checkPermissions,
    }),
    [loading, permissions, checkPermissions]
  )

  return (
    <PermissionsContext.Provider value={context}>
      {children}
    </PermissionsContext.Provider>
  )
}
