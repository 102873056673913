import { useQuery } from '@apollo/client'
import React from 'react'

import SkeletonAvatar from 'components/atoms/Skeleton/Avatar'
import AvatarContact from 'components/molecules/Avatar/Contact'
import AvatarCorporationInfo from 'components/molecules/Avatar/CorporationInfo'
import { GET_PORTFOLIO_AVATAR } from 'components/molecules/Avatar/Portfolio/GET_PORTFOLIO_AVATAR'
import { PortfolioHolderType } from 'config/portfolio'
import { AvatarProps } from 'stories/components/atoms/Avatar/Avatar'

const AvatarPortfolio = ({
  portfolioId,
  ...rest
}: AvatarProps & {
  portfolioId?: string
}) => {
  const { data, loading } = useQuery(GET_PORTFOLIO_AVATAR, {
    variables: { portfolioId },
    skip: !portfolioId,
  })

  if (loading) {
    return <SkeletonAvatar {...rest} />
  }

  switch (data?.portfolio?.holder?.__typename) {
    case PortfolioHolderType.Contact:
      return <AvatarContact contactId={data?.portfolio?.holder?.id} {...rest} />
    case PortfolioHolderType.CorporationInfo:
      return (
        <AvatarCorporationInfo
          corporationInfoId={data?.portfolio?.holder?.id}
          {...rest}
        />
      )
  }
  return null
}

export default React.memo(AvatarPortfolio)
