import { useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import React, { ReactNode, useEffect, useMemo } from 'react'

import { User } from 'config/users'
import { useAuth } from 'providers/Auth'
import { UserContext } from 'providers/User/UserContext'
import { GET_ME } from 'providers/User/graphql/queries/GET_ME'
import { isEquifyEmail } from 'utils'

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const { isAuth } = useAuth()

  const { data, loading, refetch } = useQuery<{
    me: User
  }>(GET_ME, { skip: !isAuth })

  useEffect(() => {
    if (isAuth) {
      refetch()
    }
  }, [isAuth])

  useEffect(() => {
    if (data?.me?.id) {
      Sentry.setUser({
        id: data.me.id,
        username: `${data?.me?.firstName} ${data?.me?.lastName}`,
        email: data?.me?.primaryEmail,
      })
    }
  }, [data])

  const verifiedEmails = useMemo(
    () =>
      data?.me?.emails
        .filter(({ emailVerified }) => emailVerified)
        .map(({ email }) => email) ?? [],
    [data]
  )

  const context = useMemo(
    () => ({
      emails: [],
      ...(data?.me ?? {}),
      isFromEquify: verifiedEmails.some((email) => isEquifyEmail(email)),
      loading,
    }),
    [data]
  )

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}
