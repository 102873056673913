import React, { ReactNode, useCallback, useMemo, useState } from 'react'

import { ExportFormat, ExportType } from 'config/export'
import { useObjectState } from 'hooks/useObjectState'
import { useAuth } from 'providers/Auth'
import { ExportContext, IExportContext } from 'providers/Export/ExportContext'
import { ExportModal } from 'providers/Export/ExportModal'
import { ExportModalContext } from 'providers/Export/ExportModalContext'
import { useCreateExport } from 'providers/Export/hooks/useCreateExport'

export const ExportProvider = ({ children }: { children: ReactNode }) => {
  const { isAuth } = useAuth()
  const [formats, setFormats] = useState<ExportFormat[]>([])
  const [type, setType] = useState<ExportType>()
  const [params, setParams] = useObjectState()
  const [name, setName] = useState<string>()
  const createExport = useCreateExport()

  const context = useMemo(
    (): IExportContext => ({
      openExportModal: ({ formats, type, params, name }) => {
        setFormats(formats)
        setType(type)
        setParams({ set: params })
        setName(name)
      },
      startExport: ({ format, type, params, name }) => {
        createExport({
          name,
          type,
          format,
          params,
        })
      },
    }),
    []
  )

  const modalContext = useMemo(
    () => ({
      formats,
      type,
      params,
      name,
      setParams,
    }),
    [formats, type, params, name]
  )

  const onCancel = useCallback(() => {
    setFormats([])
    setType(undefined)
    setParams({ set: {} })
    setName(undefined)
  }, [])

  return (
    <ExportContext.Provider value={context}>
      {children}
      {isAuth && (
        <ExportModalContext.Provider value={modalContext}>
          <ExportModal
            open={Boolean(name && type && formats.length)}
            onCancel={onCancel}
          />
        </ExportModalContext.Provider>
      )}
    </ExportContext.Provider>
  )
}
