import React from 'react'

import Drawer from 'components/atoms/Drawer'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import ButtonMoreActionsEvent from 'components/molecules/ButtonMoreActions/Event'
import EventName from 'components/molecules/Event/Name'
import EventTags from 'components/molecules/Event/Tags'
import { useEventName } from 'hooks/events/useEventName'
import { useDrawer } from 'providers/Drawer'
import Content from 'providers/Drawer/drawers/Event/Content'
import Sider from 'providers/Drawer/drawers/Event/Sider'

export enum EventDrawerTab {
  details = 'details',
  aggregatedTransactions = 'aggregatedTransactions',
  documents = 'documents',
  meetings = 'meetings',
}

function Event() {
  const { id } = useDrawer()
  const { name } = useEventName(id)
  const formatMessage = useFormatMessage()

  return (
    <Drawer
      open
      helmetTitle={
        name ? `${formatMessage('word.event')} · ${name}` : 'word.event'
      }
      pageHeaderProps={{
        title: <EventName eventId={id} />,
        subTitle: 'word.event',
        tags: <EventTags eventId={id} />,
        extra: <ButtonMoreActionsEvent eventId={id} />,
      }}
      sider={Sider}
      content={Content}
    />
  )
}

export default React.memo(Event)
