import React from 'react'

import Panel from 'components/atoms/Collapse/Panel'
import FileDate from 'components/molecules/File/Date'
import { FilesEditModalSection } from 'providers/FilesEdit'
import { Date as DateInput } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Date'

const Date = ({
  selectedIds,
  fileId,
  sections,
  ...rest
}: {
  fileId?: string
  selectedIds: string[]
  sections: FilesEditModalSection[]
}) => {
  if (!sections.includes(FilesEditModalSection.DATE)) {
    return <Panel key="date" header="word.date" collapsible="disabled" />
  }

  return (
    <Panel
      {...rest}
      key="date"
      header="word.date"
      extra={fileId ? <FileDate fileId={fileId} /> : undefined}
      message={<DateInput selectedIds={selectedIds} />}
    />
  )
}

export default React.memo(Date)
