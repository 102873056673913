import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_POOL_NAME } from 'hooks/pools/usePoolName/GET_POOL_NAME'

export const usePoolName = (poolId?: string) => {
  const result = useQuery<{ pool: { name: string } }>(GET_POOL_NAME, {
    variables: { poolId },
    skip: !poolId,
  })

  return useMemo(
    () => ({
      ...result,
      name: result?.data?.pool?.name,
    }),
    [result]
  )
}
