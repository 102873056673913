import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { DocxError } from 'config/file'
import { GET_FILE_ERRORS } from 'hooks/files/useFileErrors/GET_FILE_ERRORS'

export const useFileErrors = (fileId?: string) => {
  const result = useQuery<{ file: { errors: DocxError[] } }>(GET_FILE_ERRORS, {
    variables: { fileId },
    skip: !fileId,
  })

  return useMemo(
    () => ({
      ...result,
      errors: result?.data?.file?.errors,
    }),
    [result]
  )
}
