import { useMutation, useQuery } from '@apollo/client'
import { uniq } from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import Select from 'components/atoms/Form/Select'
import { ONE_SECOND } from 'config/date'
import { Locales } from 'config/locale'
import { FILES_UPDATE_LOCALE } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Locale/FILES_UPDATE_LOCALE'
import { GET_SELECTED_FILES_LOCALES } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Locale/GET_SELECTED_FILES_LOCALES'

export const Locale = ({ selectedIds }: { selectedIds: string[] }) => {
  const [value, setValue] = useState<string | undefined>(undefined)
  const [changeCount, setChangeCount] = useState(0)
  const [debouncedChangeCount] = useDebounce(changeCount, ONE_SECOND)
  const [updateLocale] = useMutation(FILES_UPDATE_LOCALE)

  const { data, loading } = useQuery<{ files: { locale?: Locales }[] }>(
    GET_SELECTED_FILES_LOCALES,
    {
      variables: { ids: selectedIds },
    }
  )

  useEffect(() => {
    if (debouncedChangeCount) {
      updateLocale({
        variables: {
          ids: selectedIds,
          newLocale: value,
        },
      })
    }
  }, [debouncedChangeCount])

  useEffect(() => {
    const values: any[] = uniq((data?.files || []).map((file) => file.locale))
    if (values.length === 1) {
      setValue(values[0])
    } else {
      setValue(undefined)
    }
  }, [data])

  return (
    <Select
      loading={loading}
      options={Object.values(Locales).map((locale) => ({
        value: locale,
        label: `locale.${locale}.name`,
      }))}
      value={value}
      onChange={(newValue) => {
        setValue(newValue)
        setChangeCount((old) => old + 1)
      }}
    />
  )
}
