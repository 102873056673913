import { useQuery } from '@apollo/client'
import React from 'react'

import Message from 'components/atoms/Intl/Message'
import SkeletonText from 'components/atoms/Skeleton/Text'
import { GET_COMMUNICATION_TITLE } from 'components/molecules/Communication/Title/GET_COMMUNICATION_TITLE'

const CommunicationTitle = ({
  communicationId,
}: {
  communicationId: string
}) => {
  const { data, loading } = useQuery(GET_COMMUNICATION_TITLE, {
    variables: { communicationId },
    skip: !communicationId,
  })

  return (
    <SkeletonText loading={loading}>
      <Message value={data?.communication?.title} />
    </SkeletonText>
  )
}

export default React.memo(CommunicationTitle)
