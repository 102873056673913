import { gql } from '@apollo/client'

export const GET_CORPORATION_PORTFOLIOS = gql`
  query GET_CORPORATION_PORTFOLIOS(
    $corporationId: ID!
    $page: BigInt
    $hitsPerPage: BigInt
    $search: String
    $facets: [String!]
    $filters: CorporationPortfoliosFilters
  ) {
    corporation(id: $corporationId) {
      id
      portfolios {
        list(
          page: $page
          hitsPerPage: $hitsPerPage
          search: $search
          facets: $facets
          filters: $filters
        ) {
          hits {
            id
            name
            type
            holder {
              id
              name
            }
          }
          nbHits
          page
          nbPages
          hitsPerPage
          facets
        }
      }
    }
  }
`
