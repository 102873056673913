// https://ant.design/components/input/

import { Input as AntdInput } from 'antd'
import { InputProps as AntdInputProps } from 'antd/lib/input'
import React from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'

export type InputValueType = string | null
export interface InputProps<T = InputValueType>
  extends Omit<AntdInputProps, 'placeholder' | 'onChange' | 'value'> {
  placeholder?: MessageType
  onChange?: (value: T) => void
  value?: T
  myRef?: any
}

const Input = ({
  placeholder,
  onChange = () => {},
  value,
  myRef,
  ...rest
}: InputProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdInput
      ref={myRef}
      autoComplete="off"
      data-lpignore="true"
      value={value ?? undefined}
      onChange={(event) => {
        event.stopPropagation()
        event.preventDefault()
        onChange(event?.target?.value || null)
      }}
      placeholder={
        placeholder ? (formatMessage(placeholder) as string) : undefined
      }
      {...rest}
    />
  )
}

export default React.memo(Input)
