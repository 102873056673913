// All variables can be found at
// https://ant.design/docs/react/customize-theme#theme

import { theme, ThemeConfig } from 'antd'

import eqDSTheme from 'styles/eqDSTheme'

const eqAntdTheme: ThemeConfig = {
  algorithm: theme.defaultAlgorithm,
  token: {
    colorPrimary: eqDSTheme.color.info,
    colorInfo: eqDSTheme.color.info,
    colorSuccess: eqDSTheme.color.success,
    colorError: eqDSTheme.color.error,
    colorHighlight: eqDSTheme.color.error,
    colorWarning: eqDSTheme.color.warning,
    colorWhite: eqDSTheme.color.white,
    colorTextHeading: eqDSTheme.color.primaryLight,
    colorTextBase: eqDSTheme.color.primary,
    motionDurationSlow: eqDSTheme.motionDuration,
    motionDurationMid: eqDSTheme.motionDuration,
    colorBgLayout: eqDSTheme.color.background,
  },
  components: {
    Layout: {
      colorBgHeader: eqDSTheme.color.primary,
      colorBgTrigger: eqDSTheme.color.primary,
      colorText: eqDSTheme.color.white,
    },
    Tooltip: {
      colorBgDefault: eqDSTheme.color.primary,
    },
    Tree: {
      colorBgContainer: 'transparent',
    },
    Notification: {
      zIndexPopup: 2050,
    },
  },
}

export default eqAntdTheme

export const eqDarkAntdTheme: ThemeConfig = {
  components: {
    ...eqAntdTheme.components,
    Menu: {
      colorTextBase: eqDSTheme.color.secondary,
      // Items
      colorItemBg: eqDSTheme.color.primary,
      colorItemText: eqDSTheme.color.secondary,
      colorSubItemBg: eqDSTheme.color.primary,
      // Selected Items
      colorItemBgSelected: eqDSTheme.color.primaryLight,
      colorItemTextSelected: eqDSTheme.color.white,
      colorItemTextHover: eqDSTheme.color.white,
      // Second level menu
      colorBgElevated: eqDSTheme.color.primary,
    },
  },
}
