import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { AggregatedTransactionLabel } from 'config/transaction'
import { GET_AGGREGATED_TRANSACTION_LABEL } from 'hooks/aggregatedTransactions/useAggregatedTransactionLabel/GET_AGGREGATED_TRANSACTION_LABEL'

export const useAggregatedTransactionLabel = (
  aggregatedTransactionId?: string
) => {
  const result = useQuery<{
    aggregatedTransaction: {
      label: AggregatedTransactionLabel
    }
  }>(GET_AGGREGATED_TRANSACTION_LABEL, {
    variables: { aggregatedTransactionId },
    skip: !aggregatedTransactionId,
  })

  return useMemo(
    () => ({
      ...result,
      label: result?.data?.aggregatedTransaction?.label,
    }),
    [result]
  )
}
