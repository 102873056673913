import { useQuery } from '@apollo/client'
import React from 'react'

import Drawer from 'components/atoms/Drawer'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import { IntegrationApiEnum } from 'config/integration'
import { useDrawer } from 'providers/Drawer'
import Content from 'providers/Drawer/drawers/IntegrationHistory/Content'
import { GET_SYNC_HISTORY_SYNC_TYPE } from 'providers/Drawer/drawers/IntegrationHistory/GET_SYNC_HISTORY_SYNC_TYPE'
import Sider from 'providers/Drawer/drawers/IntegrationHistory/Sider'

export enum IntegrationHistoryDrawerTab {
  details = 'details',
}
function IntegrationHistory() {
  const { id } = useDrawer()
  const formatMessage = useFormatMessage()

  const { data, loading } = useQuery<{
    syncHistory: {
      syncType: IntegrationApiEnum
    }
  }>(GET_SYNC_HISTORY_SYNC_TYPE, {
    variables: { syncHistoryId: id },
  })

  return (
    <>
      {!loading && (
        <Drawer
          open
          helmetTitle={'Detail history'}
          pageHeaderProps={{
            title: formatMessage(
              `integration.${data?.syncHistory.syncType}.name`
            ),
            subTitle: formatMessage('integration.synchronization'),
          }}
          sider={Sider}
          content={Content}
        />
      )}
    </>
  )
}

export default React.memo(IntegrationHistory)
