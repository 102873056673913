import { gql } from '@apollo/client'

export const GET_FILE_PROCEDURE_SIGNERS = gql`
  query GET_FILE_PROCEDURE_SIGNERS($fileId: ID!) {
    file(id: $fileId) {
      id
      procedureFile {
        id
        procedure {
          id
        }
        signers {
          id
          firstName
          lastName
          status
          updatedAt
        }
      }
    }
  }
`
