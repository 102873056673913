import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_MEETING_FROM_DATE } from 'hooks/meetings/useMeetingFromDate/GET_MEETING_FROM_DATE'

export const useMeetingFromDate = (meetingId?: string) => {
  const result = useQuery<{ meeting: { fromDate: string } }>(
    GET_MEETING_FROM_DATE,
    {
      variables: { meetingId },
      skip: !meetingId,
    }
  )

  return useMemo(
    () => ({
      ...result,
      fromDate: result?.data?.meeting?.fromDate,
    }),
    [result]
  )
}
