import { useMutation } from '@apollo/client'

import { ExportFormat, ExportType } from 'config/export'
import { CREATE_EXPORT } from 'providers/Export/hooks/useCreateExport/CREATE_EXPORT'
import { useExportNotification } from 'providers/Notification/hooks/useExportNotification'

export const useCreateExport = () => {
  const [createExport] = useMutation(CREATE_EXPORT)
  const exportNotification = useExportNotification()

  return ({
    name,
    type,
    format,
    params,
    onSuccess,
  }: {
    name: string
    type: ExportType
    format: ExportFormat
    params: Record<string, any>
    onSuccess?: () => void
  }) => {
    return createExport({
      variables: { type, format, params },
    }).then(({ data }) => {
      exportNotification(data.createExport.id, data.createExport.fileName)
      if (onSuccess) {
        onSuccess()
      }
    })
  }
}
