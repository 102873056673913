import { ArrowRightOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import React, { useMemo } from 'react'

import Message from 'components/atoms/Intl/Message'
import SkeletonText from 'components/atoms/Skeleton/Text'
import Tooltip from 'components/atoms/Tooltip'
import AggregatedTransactionDate from 'components/molecules/AggregatedTransaction/Date'
import AggregatedTransactionFromPortfolioName from 'components/molecules/AggregatedTransaction/FromPortfolioName'
import AggregatedTransactionFromQuantity from 'components/molecules/AggregatedTransaction/FromQuantity'
import AggregatedTransactionFromSecurityName from 'components/molecules/AggregatedTransaction/FromSecurityName'
import AggregatedTransactionLabel from 'components/molecules/AggregatedTransaction/Label'
import { GET_AGGREGATED_TRANSACTION_NAME } from 'components/molecules/AggregatedTransaction/Name/GET_AGGREGATED_TRANSACTION_NAME'
import AggregatedTransactionToPortfolioName from 'components/molecules/AggregatedTransaction/ToPortfolioName'
import AggregatedTransactionToQuantity from 'components/molecules/AggregatedTransaction/ToQuantity'
import AggregatedTransactionToSecurityName from 'components/molecules/AggregatedTransaction/ToSecurityName'

const AggregatedTransactionName = ({
  aggregatedTransactionId,
  withTooltip = false,
}: {
  aggregatedTransactionId: string
  withTooltip?: boolean
}) => {
  const { data, loading } = useQuery(GET_AGGREGATED_TRANSACTION_NAME, {
    variables: { aggregatedTransactionId },
    skip: !aggregatedTransactionId,
  })

  const portfolios = useMemo(() => {
    if (
      data?.aggregatedTransaction?.fromPortfolio?.id &&
      data?.aggregatedTransaction?.toPortfolio?.id
    ) {
      return (
        <>
          <AggregatedTransactionFromPortfolioName
            aggregatedTransactionId={aggregatedTransactionId}
          />
          <ArrowRightOutlined />
          <AggregatedTransactionToPortfolioName
            aggregatedTransactionId={aggregatedTransactionId}
          />
        </>
      )
    }
    if (data?.aggregatedTransaction?.fromPortfolio?.id) {
      return (
        <AggregatedTransactionFromPortfolioName
          aggregatedTransactionId={aggregatedTransactionId}
        />
      )
    }
    if (data?.aggregatedTransaction?.toPortfolio?.id) {
      return (
        <AggregatedTransactionToPortfolioName
          aggregatedTransactionId={aggregatedTransactionId}
        />
      )
    }

    return ''
  }, [
    data?.aggregatedTransaction?.fromPortfolio?.id,
    data?.aggregatedTransaction?.toPortfolio?.id,
  ])

  const quantities = useMemo(() => {
    if (
      data?.aggregatedTransaction?.fromQuantity === 0 &&
      data?.aggregatedTransaction?.toQuantity === 0
    ) {
      return (
        <AggregatedTransactionLabel
          aggregatedTransactionId={aggregatedTransactionId}
        />
      )
    }
    if (
      data?.aggregatedTransaction?.fromQuantity &&
      data?.aggregatedTransaction?.fromSecurity?.id &&
      data?.aggregatedTransaction?.toQuantity &&
      data?.aggregatedTransaction?.toSecurity?.id
    ) {
      return (
        <>
          <AggregatedTransactionLabel
            aggregatedTransactionId={aggregatedTransactionId}
          />{' '}
          <Message value="word.lowerCase.of" />{' '}
          <AggregatedTransactionFromQuantity
            aggregatedTransactionId={aggregatedTransactionId}
          />{' '}
          <AggregatedTransactionFromSecurityName
            aggregatedTransactionId={aggregatedTransactionId}
          />{' '}
          <Message value="word.lowerCase.for" />{' '}
          <AggregatedTransactionToQuantity
            aggregatedTransactionId={aggregatedTransactionId}
          />{' '}
          <AggregatedTransactionToSecurityName
            aggregatedTransactionId={aggregatedTransactionId}
          />
        </>
      )
    }
    if (
      data?.aggregatedTransaction?.fromQuantity &&
      data?.aggregatedTransaction?.fromSecurity?.id
    ) {
      return (
        <>
          <AggregatedTransactionLabel
            aggregatedTransactionId={aggregatedTransactionId}
          />{' '}
          <Message value="word.lowerCase.of" />{' '}
          <AggregatedTransactionFromQuantity
            aggregatedTransactionId={aggregatedTransactionId}
          />{' '}
          <AggregatedTransactionFromSecurityName
            aggregatedTransactionId={aggregatedTransactionId}
          />
        </>
      )
    }
    if (
      data?.aggregatedTransaction?.toQuantity &&
      data?.aggregatedTransaction?.toSecurity?.id
    ) {
      return (
        <>
          <AggregatedTransactionLabel
            aggregatedTransactionId={aggregatedTransactionId}
          />{' '}
          <Message value="word.lowerCase.of" />{' '}
          <AggregatedTransactionToQuantity
            aggregatedTransactionId={aggregatedTransactionId}
          />{' '}
          <AggregatedTransactionToSecurityName
            aggregatedTransactionId={aggregatedTransactionId}
          />
        </>
      )
    }

    return ''
  }, [
    data?.aggregatedTransaction?.fromQuantity?.id,
    data?.aggregatedTransaction?.fromSecurity?.id,
    data?.aggregatedTransaction?.toQuantity?.id,
    data?.aggregatedTransaction?.toSecurity?.id,
  ])

  const content = (
    <>
      <AggregatedTransactionDate
        aggregatedTransactionId={aggregatedTransactionId}
      />{' '}
      · {portfolios} · {quantities}
    </>
  )

  return (
    <SkeletonText loading={loading}>
      {withTooltip ? (
        <Tooltip mouseEnterDelay={0.5} title={content}>
          {content.props.children}
        </Tooltip>
      ) : (
        content.props.children
      )}
    </SkeletonText>
  )
}

export default React.memo(AggregatedTransactionName)
