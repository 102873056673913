import { ApolloError } from '@apollo/client'

export type ErrorType = Record<string, Record<string, any>>

export const getErrors = (error: ApolloError) => {
  const errors: ErrorType = error.graphQLErrors
    .filter((error) => error?.extensions?.code === 'BAD_USER_INPUT')
    .map((error) => error.extensions?.errors)
    .reduce((acc, cur) => ({ ...acc, ...cur }), {})

  if (!Object.keys(errors).length) {
    errors.root = { type: 'default' }
  }

  return errors
}
