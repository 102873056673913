import React from 'react'
import { Redirect } from 'react-router-dom'

import Drawer from 'components/atoms/Drawer'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import ButtonMoreActionsSecurity from 'components/molecules/ButtonMoreActions/Security'
import SecurityName from 'components/molecules/Security/Name'
import TagSecurityType from 'components/molecules/Tag/SecurityType'
import { CorporationPermissions } from 'config/roles'
import { useSecurityCorporationId } from 'hooks/securities/useSecurityCorporationId'
import { useSecurityName } from 'hooks/securities/useSecurityName'
import { useDrawer } from 'providers/Drawer'
import Content from 'providers/Drawer/drawers/Security/Content'
import Sider from 'providers/Drawer/drawers/Security/Sider'
import { usePermissions } from 'providers/Permissions'

export enum SecurityDrawerTab {
  capitalisation = 'capitalisation',
  details = 'details',
  documents = 'documents',
  plan = 'plan',
  vestingSchedule = 'vestingSchedule',
  transactions = 'transactions',
}

function Security() {
  const { id } = useDrawer()
  const { name } = useSecurityName(id)
  const { corporationId } = useSecurityCorporationId(id)
  const formatMessage = useFormatMessage()
  const { checkPermissions } = usePermissions()

  return !checkPermissions(CorporationPermissions.SECURITIES_READ) ? (
    <Redirect to={`/corporations/${corporationId}`} />
  ) : (
    <Drawer
      open
      helmetTitle={
        name ? `${formatMessage('word.security')} · ${name}` : 'word.security'
      }
      pageHeaderProps={{
        title: <SecurityName securityId={id} />,
        subTitle: 'word.security',
        tags: <TagSecurityType securityId={id} />,
        extra: (
          <ButtonMoreActionsSecurity
            securityId={id}
            corporationId={corporationId}
          />
        ),
      }}
      sider={Sider}
      content={Content}
    />
  )
}

export default React.memo(Security)
