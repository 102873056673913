import React from 'react'

import Drawer from 'components/atoms/Drawer'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import ButtonMoreActionsMeeting from 'components/molecules/ButtonMoreActions/Meeting'
import MeetingName from 'components/molecules/Meeting/Name'
import { useMeetingName } from 'hooks/meetings/useMeetingName'
import { useDrawer } from 'providers/Drawer'
import Content from 'providers/Drawer/drawers/Meeting/Content'
import Sider from 'providers/Drawer/drawers/Meeting/Sider'

export enum MeetingDrawerTab {
  agenda = 'agenda',
  details = 'details',
  documents = 'documents',
  email = 'email',
  members = 'members',
}

function Meeting() {
  const { id } = useDrawer()
  const { name } = useMeetingName(id)
  const formatMessage = useFormatMessage()

  return (
    <Drawer
      open
      helmetTitle={
        name ? `${formatMessage('word.meeting')} · ${name}` : 'word.meeting'
      }
      pageHeaderProps={{
        title: <MeetingName meetingId={id} />,
        subTitle: 'word.meeting',
        extra: <ButtonMoreActionsMeeting meetingId={id} />,
      }}
      sider={Sider}
      content={Content}
    />
  )
}

export default React.memo(Meeting)
