import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_FILE_PROCEDURE_ME_SIGN_URL } from 'hooks/files/useFileProcedureMeSignUrl/GET_FILE_PROCEDURE_ME_SIGN_URL'

export const useFileProcedureMeSignUrl = (fileId?: string) => {
  const result = useQuery<{
    file: { procedureFile: { procedure: { me: { signUrl: { url: string } } } } }
  }>(GET_FILE_PROCEDURE_ME_SIGN_URL, {
    variables: { fileId },
    skip: !fileId,
  })

  return useMemo(
    () => ({
      ...result,
      signUrl: result?.data?.file?.procedureFile?.procedure?.me?.signUrl?.url,
    }),
    [result]
  )
}
