import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { DrawerNames } from 'config/drawer'
import { useMeetingName } from 'hooks/meetings/useMeetingName'
import { useDrawers } from 'providers/Drawers'
import { x } from 'utils/emotion'

export interface MeetingNameProps {
  meetingId?: string
  withLink?: boolean
}

const MeetingName = ({ meetingId, withLink = false }: MeetingNameProps) => {
  const { openDrawer } = useDrawers()

  const { name, loading } = useMeetingName(meetingId)

  const content = <SkeletonText loading={loading}>{name}</SkeletonText>

  if (withLink && meetingId) {
    return (
      <x.div
        cursor="pointer"
        hoverTextDecoration="underline"
        onClick={() => openDrawer({ name: DrawerNames.meet, id: meetingId })}
      >
        {content}
      </x.div>
    )
  }

  return content
}

export default React.memo(MeetingName)
