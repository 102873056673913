import { gql } from '@apollo/client'

export const GET_SELECTED_FILES_SUBJECTS = gql`
  query GET_SELECTED_FILES_SUBJECTS($ids: [ID!]!) {
    files(ids: $ids) {
      id
      subject {
        enGB
        frFR
      }
    }
  }
`
