import React, { useContext } from 'react'

export const EQUIBAR_SHORTCUT = 'equibar'

export interface IEquibarContext {
  autoCompleteDelete: boolean
  copyIdWithName: boolean
  disableResponsive: boolean
  isStartWorkflowVisible: boolean
  recordLogRocket: boolean
  seeTranslationKeys: boolean
  seeWorkflowValues: boolean
  setIsStartWorkflowVisible: (value: boolean) => void
  showEquifyPrivileges: boolean
  virtualWorkflows: boolean
}

export const EquibarContext = React.createContext<IEquibarContext>({
  autoCompleteDelete: false,
  copyIdWithName: false,
  disableResponsive: false,
  isStartWorkflowVisible: false,
  recordLogRocket: false,
  seeTranslationKeys: false,
  seeWorkflowValues: false,
  setIsStartWorkflowVisible: () => {},
  showEquifyPrivileges: false,
  virtualWorkflows: false,
})

export const useEquibar = (): IEquibarContext => useContext(EquibarContext)
