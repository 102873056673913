import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_MEETING_DEADLINE_DATE } from 'hooks/meetings/useMeetingDeadlineDate/GET_MEETING_DEADLINE_DATE'

export const useMeetingDeadlineDate = (meetingId?: string) => {
  const result = useQuery<{ meeting: { deadlineDate: string } }>(
    GET_MEETING_DEADLINE_DATE,
    {
      variables: { meetingId },
      skip: !meetingId,
    }
  )

  return useMemo(
    () => ({
      ...result,
      deadlineDate: result?.data?.meeting?.deadlineDate,
    }),
    [result]
  )
}
