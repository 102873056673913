import { RcFile } from 'antd/lib/upload'
import React, { useContext } from 'react'

export const PARALLEL_DOWNLOADS = 1

export enum UploadStatus {
  PENDING = 'PENDING',
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  ERROR = 'ERROR',
}

export type CreateFile = {
  id: string
  aws: { url: string; fields: Record<string, string> }
}

export type QueueElement = CreateFile & {
  file: RcFile
  status: UploadStatus
}

export interface IFilesUploadContext {
  uploadFiles: (params: {
    corporationId: string
    files: RcFile[]
  }) => Promise<string[]>
  queue: QueueElement[]
}

export const FilesUploadContext = React.createContext<IFilesUploadContext>({
  uploadFiles: () => Promise.resolve([]),
  queue: [],
})

export const useFilesUpload = (): IFilesUploadContext =>
  useContext(FilesUploadContext)
