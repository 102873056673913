import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { DrawerNames } from 'config/drawer'
import { usePortfolioName } from 'hooks/portfolios/usePortfolioName'
import { useDrawers } from 'providers/Drawers'
import { x } from 'utils/emotion'

export interface PortfolioNameProps {
  portfolioId?: string
  withLink?: boolean
  ellipsisSize?: number
}

const PortfolioName = ({
  portfolioId,
  withLink = false,
  ellipsisSize,
}: PortfolioNameProps) => {
  const { openDrawer } = useDrawers()

  const { name, loading } = usePortfolioName(portfolioId)

  const styleToApply: React.CSSProperties | undefined = ellipsisSize
    ? {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: `${ellipsisSize}px`,
        display: 'block',
      }
    : undefined

  const content = (
    <SkeletonText loading={loading}>
      <span style={styleToApply}>{name}</span>
    </SkeletonText>
  )

  if (withLink && portfolioId) {
    return (
      <x.span
        cursor="pointer"
        hoverTextDecoration="underline"
        onClick={() => {
          openDrawer({ name: DrawerNames.port, id: portfolioId })
        }}
      >
        {content}
      </x.span>
    )
  }

  return content
}

export const PortfolioNameStatic = ({ name }: { name?: string }) => {
  return <>{name}</>
}

export default React.memo(PortfolioName)
