import { useFormikContext } from 'formik'
import React, { useState } from 'react'

import Button, { ButtonProps } from 'components/atoms/Button'
import { MessageType } from 'components/atoms/Intl/Message'
import Section from 'components/atoms/Section'
import Flex from 'components/atoms/Section/Flex'
import Space from 'components/atoms/Space'
import Tooltip, { TooltipProps } from 'components/atoms/Tooltip'
import { DRAFT_TRANSACTION_ACTIONS } from 'config/draftTransactions'
import { useWorkflow } from 'providers/Workflows'
import { WorkflowModalConfirm } from 'providers/Workflows/components/WorkflowModalConfirm'
import { WorkflowModalDelete } from 'providers/Workflows/components/WorkflowModalDelete'
import { AlertProps } from 'stories/components/atoms/Alert/Alert'

const NextButton = ({
  message = 'word.next',
  disableNextButton,
  ...rest
}: ButtonProps & { disableNextButton?: boolean }) => {
  const { isValid, handleSubmit } = useFormikContext()
  const { loading } = useWorkflow()

  const disableButton = !isValid || disableNextButton

  return (
    <Button
      loading={loading}
      message={message}
      type="primary"
      htmlType="button"
      onClick={(event: any) => {
        event.stopPropagation()
        event.preventDefault()
        handleSubmit(event)
      }}
      disabled={disableButton}
      {...rest}
    />
  )
}

const NextWithConfirmButton = ({
  wordToWrite,
  needConfirmModal,
  title,
  ...rest
}: ButtonProps & {
  wordToWrite: string
  needConfirmModal: boolean
  title: MessageType
}) => {
  const { values } = useFormikContext()
  const [visible, setVisible] = useState(false)

  if (!needConfirmModal) {
    return <NextButton {...rest} />
  }
  return (
    <>
      <NextButton
        onClick={() => {
          setVisible(true)
        }}
        {...rest}
      />
      <WorkflowModalConfirm
        wordToWrite={wordToWrite}
        open={visible}
        onCancel={() => setVisible(false)}
        title={title}
        payload={values}
      />
    </>
  )
}

export type BackOrCloseButtonProps = {
  onBack?: () => void
  onClose?: () => void
}

const BackOrCloseButton = (props: BackOrCloseButtonProps) => {
  const { values } = useFormikContext<Record<string, any>>()
  const { back, close, loading } = useWorkflow()

  if (!back) {
    const onClickClose = () => {
      close()
      props.onClose && props.onClose()
    }

    return (
      <Button disabled={loading} onClick={onClickClose} message="word.close" />
    )
  }

  const onClickBack = () => {
    back(values)
    props.onBack && props.onBack()
  }

  return <Button disabled={loading} onClick={onClickBack} message="word.back" />
}

const DeleteButton = ({
  message = 'word.delete',
  alert,
  disabled,
  payload,
  ...rest
}: ButtonProps & { alert?: AlertProps; payload?: any }) => {
  const [visible, setVisible] = useState(false)
  const { loading } = useWorkflow()

  return (
    <>
      <Button
        danger
        message={message}
        type="primary"
        htmlType="button"
        onClick={() => {
          setVisible(true)
        }}
        style={{ pointerEvents: disabled || loading ? 'none' : undefined }}
        disabled={disabled || loading}
        {...rest}
      />
      <WorkflowModalDelete
        open={visible}
        onCancel={() => setVisible(false)}
        title={message}
        alert={alert}
        payload={payload}
      />
    </>
  )
}

export const WorkflowNextButtonWithConfirm = (
  props: ButtonProps & {
    wordToWrite: string
    needConfirmModal: boolean
    title: MessageType
  }
) => {
  return (
    <Space>
      <NextWithConfirmButton {...props} />
    </Space>
  )
}

export const WorkflowNextButton = (props: ButtonProps) => {
  return (
    <Space>
      <NextButton {...props} />
    </Space>
  )
}

type WorkflowButtonsProps = {
  nextButton?: ButtonProps
  backOrCloseButton?: BackOrCloseButtonProps
  disableNextButton?: boolean
}

export const WorkflowButtons = (props: WorkflowButtonsProps) => {
  return (
    <Space>
      <BackOrCloseButton {...props.backOrCloseButton} />
      <NextButton
        {...props.nextButton}
        disableNextButton={props.disableNextButton}
      />
    </Space>
  )
}

export const WorkflowSubmitDeleteButtons = ({
  deletionDisabled,
  alert,
  tooltip = {},
  payload,
  ...rest
}: ButtonProps & {
  deletionDisabled?: boolean
  alert?: AlertProps
  tooltip?: TooltipProps
  payload?: any
  disableNextButton?: boolean
}) => {
  return (
    <Section flex margin="0px">
      <Tooltip {...tooltip}>
        <div>
          <DeleteButton
            disabled={deletionDisabled}
            alert={alert}
            payload={payload}
          />
        </div>
      </Tooltip>
      <Flex />
      <Space>
        <BackOrCloseButton />
        <NextButton {...rest} />
      </Space>
    </Section>
  )
}

export const ConfirmDeleteButton = ({
  payload = { action: 'DELETE' },
  ...props
}: ButtonProps & { payload?: any }) => {
  const { loading, next } = useWorkflow()

  return (
    <Button
      danger
      message="word.delete"
      type="primary"
      htmlType="button"
      onClick={() => next(payload)}
      loading={loading}
      {...props}
    />
  )
}

export const WorkflowDraftMeetingsButtons = ({
  payload,
  disableNextButton,
  ...rest
}: ButtonProps & { payload?: any; disableNextButton?: boolean }) => {
  const { saveCurrent } = useWorkflow()
  return (
    <Section flex margin="0px">
      <Tooltip title={'workflow.saveAsDraft.title'}>
        <Button
          onClick={() => {
            saveCurrent(payload)
          }}
          message="word.saveAndExit"
        />
      </Tooltip>
      <Flex />
      <Space>
        <BackOrCloseButton />
        <NextButton
          message="word.saveAndContinue"
          type="primary"
          disabled={!!disableNextButton}
          {...rest}
        />
      </Space>
    </Section>
  )
}

export const WorkflowDraftGrantEventButtons = ({
  payload,
  disableNextButton,
  ...rest
}: ButtonProps & { payload?: any; disableNextButton?: boolean }) => {
  const { next } = useWorkflow()
  return (
    <Space>
      <BackOrCloseButton />
      <Button
        onClick={() => {
          next({ ...payload, action: DRAFT_TRANSACTION_ACTIONS.EXIT })
        }}
        message="word.saveAndExit"
        {...rest}
      />
      <Button
        onClick={() => {
          next({ ...payload, action: DRAFT_TRANSACTION_ACTIONS.CONTINUE })
        }}
        message="word.saveAndContinue"
        type="primary"
        disabled={!!disableNextButton}
        {...rest}
      />
    </Space>
  )
}

export const WorkflowPublishDraftButtons = (props: ButtonProps) => {
  const { next } = useWorkflow()
  return (
    <Space>
      <Button
        onClick={() => {
          next({ publish: 'EXIT' })
        }}
        message="word.keepAsDraft"
      />
      <Button
        onClick={() => {
          next({ publish: 'PUBLISH' })
        }}
        type="primary"
        message="word.publishNow"
      />
    </Space>
  )
}
