import { css, cx, injectGlobal, keyframes } from '@emotion/css'
import styled, { x, SystemProps } from '@xstyled/emotion'
import { HTMLAttributes } from 'react'

export type XProps<T = HTMLDivElement> = Omit<
  SystemProps & HTMLAttributes<T>,
  'style'
>

export { x, css, cx, injectGlobal, keyframes }

export default styled
