import { EditOutlined } from '@ant-design/icons'
import React from 'react'

import ButtonMoreActions from 'components/atoms/Button/MoreActions'
import Menu from 'components/atoms/Menu'
import { CorporationPermissions } from 'config/roles'
import { SLUGS } from 'config/slugs'
import { usePermissions } from 'providers/Permissions'
import { useStartWorkflow } from 'providers/Workflows/hooks/useStartWorkflow'

const ButtonMoreActionsGovernanceGroup = ({
  governanceGroupId,
}: {
  governanceGroupId: string
}) => {
  const { checkPermissions } = usePermissions()
  const [startEditGovernanceGroup] = useStartWorkflow({
    data: { governanceGroupId },
    slug: SLUGS.EDIT_GOVERNANCE_GROUP,
  })

  if (!checkPermissions(CorporationPermissions.GOVERNANCE_MANAGE)) {
    return null
  }

  return (
    <ButtonMoreActions
      overlay={
        <Menu
          menu={[
            {
              key: 'edit',
              icon: <EditOutlined />,
              message: 'word.edit',
              onClick: startEditGovernanceGroup,
            },
          ]}
        />
      }
    />
  )
}

export default React.memo(ButtonMoreActionsGovernanceGroup)
