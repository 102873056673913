import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_MEETING_TIMEZONE } from 'hooks/meetings/useMeetingTimezone/GET_MEETING_TIMEZONE'

export const useMeetingTimezone = (meetingId?: string) => {
  const result = useQuery<{ meeting: { timezone: string } }>(
    GET_MEETING_TIMEZONE,
    {
      variables: { meetingId },
      skip: !meetingId,
    }
  )

  return useMemo(
    () => ({
      ...result,
      timezone: result?.data?.meeting?.timezone,
    }),
    [result]
  )
}
