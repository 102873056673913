import { useQuery } from '@apollo/client'
import React, { ReactNode, useMemo, useState } from 'react'

import Button from 'components/atoms/Button'
import FontAwesomeIcon from 'components/atoms/FontAwesomeIcon'
import Modal from 'components/atoms/Modal'
import PDFPreview from 'components/atoms/PDF/Preview'
import Flex from 'components/atoms/Section/Flex'
import { SignatureStatus } from 'config/procedure'
import { useFileProcedureMeSignUrl } from 'hooks/files/useFileProcedureMeSignUrl'
import { useAuth } from 'providers/Auth'
import { GET_FILE_PROCEDURE_SIGNERS } from 'providers/Drawer/drawers/File/Content/Signatures/GET_FILE_PROCEDURE_SIGNERS'
import { FilePreviewContext } from 'providers/FilePreview/FilePreviewContext'
import { useProcedure } from 'providers/Procedure'
import eqDSTheme from 'styles/eqDSTheme'
import { x } from 'utils/emotion'

export const FilePreviewProvider = ({ children }: { children: ReactNode }) => {
  const [fileId, setFileId] = useState<string | undefined>()
  const { isAuth } = useAuth()
  const { openProcedure } = useProcedure()
  const { signUrl } = useFileProcedureMeSignUrl(fileId)

  const { data } = useQuery<{
    file: {
      procedureFile?: {
        procedure: {
          id: string
        }
        signers: {
          id: string
          firstName: string
          lastName: string
          status: SignatureStatus
          updatedAt: string
        }[]
      }
    }
  }>(GET_FILE_PROCEDURE_SIGNERS, {
    variables: { fileId },
    skip: !fileId,
  })

  const context = useMemo(
    () => ({
      filePreviewIsOpen: Boolean(fileId),
      openFilePreview: setFileId,
    }),
    [fileId]
  )

  return (
    <FilePreviewContext.Provider value={context}>
      {children}
      {isAuth && (
        <Modal
          open={Boolean(fileId)}
          footer={
            signUrl ? (
              <x.div display="flex">
                <Flex />
                <Button
                  icon={<FontAwesomeIcon name="signature" />}
                  type="primary"
                  message="word.sign"
                  onClick={() => {
                    setFileId(undefined)
                    openProcedure(data?.file?.procedureFile?.procedure?.id)
                  }}
                />
              </x.div>
            ) : null
          }
          bodyStyle={{
            padding: eqDSTheme.space.xsmall,
            height: '80vh',
            borderRadius: '4px',
            overflow: 'hidden',
            display: 'flex',
          }}
          width="80%"
          destroyOnClose
          onCancel={() => {
            setFileId(undefined)
          }}
          zIndex={1100}
          style={{ maxWidth: '1000px' }}
        >
          {fileId ? <PDFPreview fileId={fileId} /> : null}
        </Modal>
      )}
    </FilePreviewContext.Provider>
  )
}
