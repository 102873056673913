import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { useCorporationInfoName } from 'hooks/corporationInfo/useCorporationInfoName'

const CorporationInfoName = ({
  corporationInfoId,
  fallbackValue,
}: {
  corporationInfoId?: string
  fallbackValue?: string
}) => {
  const { name, loading } = useCorporationInfoName(corporationInfoId)

  return <SkeletonText loading={loading}>{name || fallbackValue}</SkeletonText>
}

export default React.memo(CorporationInfoName)
