import { gql } from '@apollo/client'

export const GET_WALLET_SECURITY_ID = gql`
  query GET_WALLET_SECURITY_ID($walletId: ID!) {
    wallet(id: $walletId) {
      id
      security {
        id
      }
    }
  }
`
