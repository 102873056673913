import { useFormikContext } from 'formik'
import { get } from 'lodash/fp'
import React from 'react'

export interface FormikProps extends Record<string, any> {
  name: string
  component: React.ElementType
}

const Formik = ({ name, component, ...rest }: FormikProps) => {
  const { values, handleBlur, setFieldValue, setFieldTouched } =
    useFormikContext()
  const Component = component

  return (
    <Component
      name={name}
      onBlur={(event: FocusEvent) => {
        handleBlur(event)
      }}
      value={get(name, values)}
      onChange={(newValue: any) => {
        setFieldTouched(name)
        setFieldValue(name, newValue)
      }}
      {...rest}
    />
  )
}

export default React.memo(Formik)
