// https://ant.design/components/button/

import { Button as AntdButton } from 'antd'
import { ButtonProps as AntdButtonProps } from 'antd/lib/button'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'

export interface ButtonProps extends AntdButtonProps {
  to?: string
  message?: MessageType
  myRef?: any
}

const Button = ({
  to,
  children,
  message,
  onClick = () => {},
  myRef,
  ...rest
}: ButtonProps) => {
  const history = useHistory()
  const formatMessage = useFormatMessage()

  return (
    <AntdButton
      ref={myRef}
      onClick={(event: any) => {
        onClick(event)
        if (to) {
          history.push(to)
        }
      }}
      {...rest}
    >
      {children || formatMessage(message)}
    </AntdButton>
  )
}

export default React.memo(Button)
