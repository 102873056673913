// https://ant.design/components/radio/

import { Radio as AntdRadio } from 'antd'
import { CheckboxOptionType as AntdCheckboxOptionType } from 'antd/lib/checkbox'
import { RadioGroupProps as AntdRadioGroupProps } from 'antd/lib/radio'
import React, { useCallback } from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'
import Space from 'components/atoms/Space'
import { cleanArray } from 'utils'

const { Group } = AntdRadio

export interface RadioGroupOptionsType extends AntdCheckboxOptionType {
  label: MessageType
}

export interface RadioGroupProps
  extends Omit<AntdRadioGroupProps, 'onChange' | 'options'> {
  options: (RadioGroupOptionsType | undefined)[]
  onChange: (value: any) => void
  direction?: 'vertical' | 'horizontal'
}

const RadioGroup = ({
  options,
  onChange,
  direction = 'horizontal',
  ...rest
}: RadioGroupProps) => {
  const formatMessage = useFormatMessage()
  const formatOptions = useCallback(
    (
      options: (RadioGroupOptionsType | undefined)[]
    ): AntdCheckboxOptionType[] => {
      return cleanArray(options).map((option) => {
        const { label, ...rest } = option
        return {
          label: formatMessage(label),
          ...rest,
        }
      })
    },
    [formatMessage]
  )

  if (direction === 'vertical') {
    return (
      <Group onChange={(event) => onChange(event?.target?.value)} {...rest}>
        <Space direction={direction}>
          {formatOptions(options).map((option, index) => {
            return (
              <AntdRadio key={index} {...option}>
                {option.label}
              </AntdRadio>
            )
          })}
        </Space>
      </Group>
    )
  }

  return (
    <Group
      options={formatOptions(options)}
      onChange={(event) => onChange(event?.target?.value)}
      {...rest}
    />
  )
}

export default React.memo(RadioGroup)
