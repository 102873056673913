import { gql } from '@apollo/client'

export const FILES_SET_SUBJECT = gql`
  mutation FILES_SET_SUBJECT($ids: [ID!]!, $subject: String) {
    files(ids: $ids) {
      setSubject(subject: $subject) {
        id
        subject {
          enGB
          frFR
        }
      }
    }
  }
`
