import { get, omit, pickBy, set } from 'lodash/fp'
import React, { useCallback, useMemo, useState } from 'react'

import { Sorting } from 'config/sorting'
import {
  ApiSearchFiltersContext,
  ApiSearchFiltersProviderProps,
} from 'providers/ApiSearchFilters/ApiSearchFiltersContext'
import { isSet } from 'utils'

export const ApiSearchFiltersProvider = ({
  children,
  defaultSearch = null,
  defaultFilters = {},
  permanentFilters = {},
  defaultSort = null,
}: ApiSearchFiltersProviderProps) => {
  const [search, setSearch] = useState<string | null>(defaultSearch)
  const [filters, setFilters] = useState<{ [p: string]: any }>({
    ...defaultFilters,
    ...permanentFilters,
  })
  const [sort, setSort] = useState<Sorting | null>(defaultSort)

  const count = useMemo<number>(
    () =>
      (search ? 1 : 0) +
      Object.entries(filters).filter(
        ([item, value]) =>
          !get(item, {
            ...defaultFilters,
            ...permanentFilters,
          }) && isSet(value)
      ).length +
      (Object.keys({
        ...defaultFilters,
        ...permanentFilters,
      }).every(
        (item) =>
          Object.prototype.hasOwnProperty.bind(filters) &&
          get(item, filters) ===
            get(item, {
              ...defaultFilters,
              ...permanentFilters,
            })
      )
        ? 0
        : 1) +
      (sort ? 1 : 0),
    [search, filters, sort, defaultFilters, permanentFilters]
  )

  const getFilter = useCallback(
    (filter: string): any => get(filter, filters),
    [filters]
  )

  const setFilter = useCallback(
    (filter: string) =>
      (value: any): void =>
        setFilters((oldFilters) => {
          if (
            (Array.isArray(value) && !value.length) ||
            (!Array.isArray(value) && value === undefined)
          ) {
            return omit(filter, oldFilters)
          }
          return set(filter, value, oldFilters)
        }),
    []
  )

  const resetFilters = useCallback(() => {
    setSearch(defaultSearch)
    setFilters({
      ...defaultFilters,
      ...permanentFilters,
    })
    setSort(defaultSort)
  }, [defaultSearch, defaultFilters, permanentFilters, defaultSort])

  const context = useMemo(
    () => ({
      count,
      search: search ?? defaultSearch,
      setSearch,
      filters: pickBy(isSet, {
        ...defaultFilters,
        ...filters,
        ...permanentFilters,
      }),
      setFilters,
      sort: sort ?? defaultSort,
      setSort,
      getFilter,
      setFilter,
      resetFilters,
    }),
    [
      count,
      search,
      filters,
      sort,
      defaultSearch,
      defaultFilters,
      permanentFilters,
      defaultSort,
    ]
  )

  return (
    <ApiSearchFiltersContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </ApiSearchFiltersContext.Provider>
  )
}
