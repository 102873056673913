import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_AGGREGATED_TRANSACTION_TO_QUANTITY } from 'hooks/aggregatedTransactions/useAggregatedTransactionToQuantity/GET_AGGREGATED_TRANSACTION_TO_QUANTITY'

export const useAggregatedTransactionToQuantity = (
  aggregatedTransactionId?: string
) => {
  const result = useQuery<{
    aggregatedTransaction: {
      toQuantity: number
    }
  }>(GET_AGGREGATED_TRANSACTION_TO_QUANTITY, {
    variables: { aggregatedTransactionId },
    skip: !aggregatedTransactionId,
  })

  return useMemo(
    () => ({
      ...result,
      toQuantity: result?.data?.aggregatedTransaction?.toQuantity,
    }),
    [result]
  )
}
