import { gql } from '@apollo/client'

export const GET_COMMUNICATION_TITLE = gql`
  query GET_COMMUNICATION_TITLE($communicationId: ID!) {
    communication(id: $communicationId) {
      id
      title
    }
  }
`
