import { useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { IntegrationApiEnum } from 'config/integration'
import { SAVE_PAYFIT_OAUTH_SYNC_CONFIG } from 'graphql/mutations/SAVE_PAYFIT_OAUTH_SYNC_CONFIG'
import { OAUTH_BROADCAST_CHANNEL_NAME } from 'providers/Workflows/steps/configureSyncDetails/index'

export default function PayfitCallback() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const code = params.get('code')

  const [corporationInfoId, setCorporationInfoId] = useState<string | null>(
    null
  )

  const [savePayfitOauthTokenInSyncConfig] = useMutation(
    SAVE_PAYFIT_OAUTH_SYNC_CONFIG
  )

  const bc = new BroadcastChannel(OAUTH_BROADCAST_CHANNEL_NAME)

  bc.onmessage = function (ev) {
    if (
      ev.data.equifyTrigger &&
      ev.data.equifyTrigger === 'configureSyncDetails' &&
      ev.data.equifyData &&
      ev.data.equifyData.corporationInfoId &&
      corporationInfoId === null
    ) {
      setCorporationInfoId(ev.data.equifyData.corporationInfoId)
    }
  }

  useEffect(() => {
    bc.postMessage({ equifyTrigger: 'hrisCallbackCalled' })
  }, [])

  useEffect(() => {
    if (corporationInfoId) {
      savePayfitOauthTokenInSyncConfig({
        variables: {
          id: corporationInfoId,
          type: IntegrationApiEnum.PAYFIT,
          oauth: code,
        },
      })
        .then(() => {
          bc.postMessage({
            equifyTrigger: 'hrisCallbackClosedSuccess',
          })
        })
        .catch((e) => {
          bc.postMessage({
            equifyTrigger: 'hrisCallbackClosedError',
          })
        })
        .finally(() => {
          bc.close()
          window.close()
        })
    }
  }, [corporationInfoId])

  return <>loading...</>
}
