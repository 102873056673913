// https://ant.design/components/tooltip/

import { Tooltip as AntdTooltip } from 'antd'
import { TooltipProps as AntdTooltipProps } from 'antd/lib/tooltip'
import React from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'

export interface TooltipProps extends Omit<AntdTooltipProps, 'title'> {
  title?: MessageType
}

const Tooltip = ({ title, ...rest }: TooltipProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdTooltip title={title ? formatMessage(title) : undefined} {...rest} />
  )
}

export default React.memo(Tooltip)
