import React, { ReactNode, useContext } from 'react'

import { Sorting } from 'config/sorting'

export interface IApiSearchFiltersContext {
  count: number
  search: string | null
  setSearch: React.Dispatch<React.SetStateAction<string | null>>
  filters: { [p: string]: any }
  setFilters: React.Dispatch<React.SetStateAction<object>>
  sort: Sorting | null
  setSort: React.Dispatch<React.SetStateAction<Sorting | null>>
  getFilter: (filter: string) => any
  setFilter: (filter: string) => any
  resetFilters: () => void
}

export type ApiSearchFiltersProviderProps = {
  children: ReactNode | ((context: IApiSearchFiltersContext) => ReactNode)
  defaultSearch?: string | null
  defaultFilters?: object
  permanentFilters?: object
  defaultSort?: Sorting | null
}

export const ApiSearchFiltersContext =
  React.createContext<IApiSearchFiltersContext>({
    count: 0,
    search: null,
    setSearch: () => {},
    filters: {},
    setFilters: () => {},
    sort: null,
    setSort: () => {},
    getFilter: () => {},
    setFilter: () => {},
    resetFilters: () => {},
  })

export const useApiSearchFilters = (): IApiSearchFiltersContext =>
  useContext(ApiSearchFiltersContext)
