import React, { useMemo, lazy, Suspense } from 'react'

import { useDrawer } from 'providers/Drawer/DrawerContext'
import { LegalEntityDrawerTab } from 'providers/Drawer/drawers/LegalEntity'

const Details = lazy(
  () => import('providers/Drawer/drawers/LegalEntity/Content/Details')
)

const Links = lazy(
  () => import('providers/Drawer/drawers/LegalEntity/Content/Links')
)

const Content = () => {
  const { tab } = useDrawer()

  const Component = useMemo(() => {
    switch (tab) {
      case LegalEntityDrawerTab.details:
        return Details

      case LegalEntityDrawerTab.links:
        return Links
      default:
        return undefined
    }
  }, [tab])

  if (!Component) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  )
}

export default React.memo(Content)
