import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { GET_PORTFOLIO_CORPORATION_ID } from 'hooks/portfolios/usePortfolioCorporationId/GET_PORTFOLIO_CORPORATION_ID'

export const usePortfolioCorporationId = (portfolioId?: string) => {
  const result = useQuery<{
    portfolio: { corporation: { id: string } }
  }>(GET_PORTFOLIO_CORPORATION_ID, {
    variables: { portfolioId },
    skip: !portfolioId,
  })

  return useMemo(
    () => ({
      ...result,
      corporationId: result?.data?.portfolio?.corporation?.id,
    }),
    [result]
  )
}
