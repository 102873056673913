import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { TranslatedMessage } from 'components/atoms/Intl/Message'
import { GET_FILE_SIGNATURE_GROUPS } from 'hooks/files/useFileSignatureGroups/GET_FILE_SIGNATURE_GROUPS'

export const useFileSignatureGroups = (fileId: string) => {
  const result = useQuery<{
    file: {
      id: string
      smartFields: {
        signatureGroups: {
          rawName: string
          name: TranslatedMessage
        }[]
      }
    }
  }>(GET_FILE_SIGNATURE_GROUPS, {
    variables: { fileId },
    skip: !fileId,
  })

  return useMemo(
    () => ({
      ...result,
      groups: result?.data?.file?.smartFields?.signatureGroups ?? [],
    }),
    [result]
  )
}

export const useFileHasSignatureGroups = (fileId: string) => {
  const { groups } = useFileSignatureGroups(fileId)

  return groups.length > 0
}
