import {
  ApolloClient,
  ApolloProvider,
  from,
  HttpLink,
  InMemoryCache,
  ServerError,
  split,
} from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { ServerParseError } from '@apollo/client/link/http'
import { RetryLink } from '@apollo/client/link/retry'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { CloseCode, createClient } from 'graphql-ws'
import React, { ReactNode, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  LEGACY_GRAPHQL_ENDPOINT,
  WS_ENDPOINT,
  WUNDERGRAPH_GRAPHQL_ENDPOINT,
} from 'config/env'
import { GraphQLContext } from 'providers/GraphQL/GraphQLContext'
import {
  MERGED_LEGACY_TYPES,
  MERGED_NEST_TYPES,
} from 'providers/GraphQL/config'
import { useToken } from 'providers/Token'
import { isDevEnv } from 'utils/env'

import { EndpointGraphql } from '../../config/endpointGraphql'
import { getCurrentTokenExpiredIn } from '../Auth/AuthProvider'

const createLegacyHttpLink = () =>
  new BatchHttpLink({
    uri: LEGACY_GRAPHQL_ENDPOINT,
    batchKey: ({ operationName }) => {
      // For the upload documents we do not want to batch the mutation
      // or else it takes too much time in case of upload of multiple documents in a row
      return operationName === 'CREATE_FILES'
        ? 'solo' + Math.random()
        : 'grouped'
    },
    batchMax: 50,
    batchInterval: 100,
  })

const createWundergraphHttpLink = () =>
  new HttpLink({
    uri: WUNDERGRAPH_GRAPHQL_ENDPOINT,
  })

export const GraphQLProvider = ({ children }: { children: ReactNode }) => {
  const { token, expiresAt } = useToken()
  const history = useHistory()

  const [headers, setHeaders] = useState({})

  const tokenRef = useRef(token)
  tokenRef.current = token

  const expiresAtRef = useRef(expiresAt)
  expiresAtRef.current = expiresAt

  const client = useMemo(() => {
    let tokenExpiryTimeout: NodeJS.Timeout | null = null

    const wsLink = new GraphQLWsLink(
      createClient({
        url: WS_ENDPOINT,
        connectionParams: async () => {
          return {
            Authorization: tokenRef.current ? `Bearer ${tokenRef.current}` : '',
          }
        },
        // @see https://the-guild.dev/graphql/ws/recipes#ws-server-and-client-auth-usage-with-token-expiration-validation-and-refresh
        on: {
          connected: (socket) => {
            // clear timeout on every connect for debouncing the expiry
            if (tokenExpiryTimeout !== null) {
              clearTimeout(tokenExpiryTimeout)
            }

            // set a token expiry timeout for closing the socket
            // with an `4403: Forbidden` close event indicating
            // that the token expired. the `closed` event listener below
            // will set the token refresh flag to true
            tokenExpiryTimeout = setTimeout(() => {
              if ((socket as WebSocket).readyState === WebSocket.OPEN) {
                ;(socket as WebSocket).close(CloseCode.Forbidden, 'Forbidden')
              }
            }, getCurrentTokenExpiredIn(expiresAtRef.current || 0))
          },
        },
      })
    )

    const authLink = setContext((_, { h }) => {
      return {
        headers: {
          ...headers,
          ...h,
          authorization: tokenRef.current ? `Bearer ${tokenRef.current}` : '',
        },
      }
    })

    const errorLink = onError(
      ({ forward, graphQLErrors, networkError, operation }: ErrorResponse) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, path }) => {
            console.error(
              `[GraphQL error]: MessageType: ${message}, Query: ${operation.operationName}, Path: ${path}`
            )
          })
          return forward(operation)
        }

        if (networkError) {
          if (
            (networkError as ServerError | ServerParseError)?.statusCode ===
              500 ||
            (networkError as ServerError | ServerParseError)?.statusCode === 503
          ) {
            history.push('/server-unavailable')
          }

          console.error(`[Network error]: ${networkError}`)
        }
      }
    )

    const retryLink = new RetryLink()

    const legacyHttpLink = createLegacyHttpLink()
    const wundergraphHttpLink = createWundergraphHttpLink()

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      split(
        ({ getContext }) =>
          getContext().endpoint === EndpointGraphql.WUNDERGRAPH,
        from([authLink, errorLink, retryLink, wundergraphHttpLink]),
        from([authLink, errorLink, retryLink, legacyHttpLink])
      )
    )

    return new ApolloClient({
      link: splitLink,
      connectToDevTools: isDevEnv,
      cache: new InMemoryCache({
        typePolicies: MERGED_LEGACY_TYPES.concat(MERGED_NEST_TYPES).reduce(
          (acc, cur) => ({ ...acc, [cur]: { merge: true } }),
          {}
        ),
      }),
    })
  }, [headers])

  return (
    <GraphQLContext.Provider
      value={{
        headers,
        setHeaders,
      }}
    >
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </GraphQLContext.Provider>
  )
}
