import { gql } from '@apollo/client'

export const GET_GOVERNANCE_GROUP_NAME = gql`
  query GET_GOVERNANCE_GROUP_NAME($governanceGroupId: ID!) {
    governanceGroup(id: $governanceGroupId) {
      id
      name
    }
  }
`

export const GET_GOVERNANCE_GROUPS_NAME = gql`
  query GET_GOVERNANCE_GROUP_NAME($governanceGroupsId: [ID!]!) {
    governanceGroups(ids: $governanceGroupsId) {
      id
      name
    }
  }
`
