import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { TagProps } from 'components/atoms/Tag'
import TagSecurityType from 'components/molecules/Tag/SecurityType'
import { useWalletSecurityId } from 'hooks/wallets/useWalletSecurityId'

const TagWalletSecurityType = ({
  walletId,
}: TagProps & { walletId: string }) => {
  const { securityId, loading } = useWalletSecurityId(walletId)

  return (
    <SkeletonText loading={loading}>
      {securityId && <TagSecurityType securityId={securityId} />}
    </SkeletonText>
  )
}

export default React.memo(TagWalletSecurityType)
