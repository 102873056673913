import { LeftOutlined } from '@ant-design/icons'
import React, { ReactNode } from 'react'

import Button from 'components/atoms/Button'
import Result from 'components/atoms/Result'

export class ReactErrorProvider extends React.Component<
  {
    children: ReactNode
  },
  { hasError: boolean }
> {
  constructor(props: { children: ReactNode }) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error)
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Result
          style={{ paddingTop: 100 }}
          status="500"
          subTitle="reactError.description"
          extra={
            <Button
              type="primary"
              icon={<LeftOutlined />}
              href={window.location.origin}
              message="word.backToHomePage"
            />
          }
        />
      )
    }

    return this.props.children
  }
}
