import React from 'react'

import Drawer from 'components/atoms/Drawer'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import Space from 'components/atoms/Space'
import ButtonMoreActionsFile from 'components/molecules/ButtonMoreActions/File'
import FileBadgeErrors from 'components/molecules/File/BadgeErrors'
import FileName from 'components/molecules/File/Name'
import FileIsTemplateTag from 'components/molecules/Tag/FileIsTemplate'
import { useFileName } from 'hooks/files/useFileName'
import { useDrawer } from 'providers/Drawer'
import Content from 'providers/Drawer/drawers/File/Content'
import Sider from 'providers/Drawer/drawers/File/Sider'

export enum FileDrawerTab {
  details = 'details',
  links = 'links',
  shares = 'shares',
  smartFields = 'smartFields',
  signatures = 'signatures',
  signatureFields = 'signatureFields',
}

function File() {
  const { id } = useDrawer()
  const { name } = useFileName(id)
  const formatMessage = useFormatMessage()

  return (
    <Drawer
      open
      helmetTitle={
        name ? `${formatMessage('word.document')} · ${name}` : 'word.document'
      }
      pageHeaderProps={{
        title: (
          <Space>
            <FileBadgeErrors fileId={id} />
            <FileName fileId={id} />
          </Space>
        ),
        subTitle: 'word.document',
        tags: <FileIsTemplateTag fileId={id} />,
        extra: <ButtonMoreActionsFile fileId={id} />,
      }}
      sider={Sider}
      content={Content}
    />
  )
}

export default React.memo(File)
