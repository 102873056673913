import React from 'react'

import Select, { SelectProps } from 'components/atoms/Form/Select'
import { Locales } from 'config/locale'
import { useLocale } from 'providers/Locale'

const SelectLocale = (props: Omit<SelectProps, 'options'>) => {
  const { locale, setLocale } = useLocale()

  return (
    <Select
      value={locale}
      options={[
        { value: Locales.enGB, label: 'English' },
        { value: Locales.frFR, label: 'Français' },
      ]}
      onChange={(value) => {
        if (value !== locale) {
          setLocale(value)
        }
      }}
      {...props}
    />
  )
}

export default React.memo(SelectLocale)
