import { useLocation } from 'react-router-dom'

import { ROUTES } from 'config/routes'
import { ROUTE_TO_WORKSPACE, WORKSPACE } from 'config/workspace'

export const useWorkspace = (): {
  workspace: WORKSPACE | undefined
  workspaceId: string | undefined
} => {
  const { pathname } = useLocation()

  const [, firstRoute, firstId] = pathname.split('/')

  if (!firstRoute || !firstId) {
    return {
      workspace: undefined,
      workspaceId: undefined,
    }
  }

  return {
    workspace: ROUTE_TO_WORKSPACE[firstRoute as ROUTES],
    workspaceId: firstId,
  }
}

export const useIsLawFirmWorkspace = () => {
  const { workspace } = useWorkspace()

  return workspace === WORKSPACE.LAW_FIRM
}

export const useIsCorporationWorkspace = () => {
  const { workspace } = useWorkspace()

  return workspace === WORKSPACE.CORPORATION
}

export const useIsPortfolioWorkspace = () => {
  const { workspace } = useWorkspace()

  return workspace === WORKSPACE.PORTFOLIO
}
