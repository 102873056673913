import React from 'react'

import SkeletonText from 'components/atoms/Skeleton/Text'
import { DrawerNames } from 'config/drawer'
import { usePoolName } from 'hooks/pools/usePoolName'
import { useDrawers } from 'providers/Drawers'
import { x } from 'utils/emotion'

export interface PoolNameProps {
  poolId?: string
  withLink?: boolean
}

const PoolName = ({ poolId, withLink = false }: PoolNameProps) => {
  const { openDrawer } = useDrawers()

  const { name, loading } = usePoolName(poolId)

  const content = <SkeletonText loading={loading}>{name}</SkeletonText>

  if (withLink && poolId) {
    return (
      <x.div
        cursor="pointer"
        hoverTextDecoration="underline"
        onClick={() => {
          openDrawer({ name: DrawerNames.pool, id: poolId })
        }}
      >
        {content}
      </x.div>
    )
  }

  return content
}

export const PoolNameStatic = ({ name }: { name?: string }) => {
  return <>{name}</>
}

export default React.memo(PoolName)
