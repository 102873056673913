// https://ant.design/components/divider/

import { Divider as AntdDivider } from 'antd'
import { DividerProps as AntdDividerProps } from 'antd/lib/divider'
import React from 'react'

import { MessageType, useFormatMessage } from 'components/atoms/Intl/Message'

export interface DividerProps extends AntdDividerProps {
  message?: MessageType
}

const Divider = ({ message, children, ...rest }: DividerProps) => {
  const formatMessage = useFormatMessage()

  return (
    <AntdDivider {...rest}>{children || formatMessage(message)}</AntdDivider>
  )
}

export default React.memo(Divider)
