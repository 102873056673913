import { uniq, xor } from 'lodash/fp'
import React, { useCallback, useMemo, useState } from 'react'

import {
  SelectionContext,
  SelectionProviderProps,
} from 'providers/Selection/SelectionContext'
import { castArray } from 'utils'

export const SelectionProvider = ({
  children,
  defaultIds = [],
  disabled = false,
}: SelectionProviderProps) => {
  const [ids, setIds] = useState<string[]>(defaultIds)

  const count = useMemo(() => ids.length, [ids.length])

  const add = useCallback((addedIds: string[] = []) => {
    setIds((oldIds) => {
      const ids: any[] = uniq([...oldIds, ...castArray(addedIds)]).filter(
        Boolean
      )
      return ids
    })
  }, [])

  const toggle = useCallback((switchedIds: string[] = []) => {
    setIds((oldIds) => {
      const ids: any[] = xor(castArray(switchedIds), oldIds).filter(Boolean)
      return ids
    })
  }, [])

  const remove = useCallback((removedIds: string[] = []) => {
    const removedIdsArray = castArray(removedIds)
    setIds((oldIds) =>
      oldIds.filter((id) => !removedIdsArray.includes(id)).filter(Boolean)
    )
  }, [])

  const has = useCallback(
    (checkIds: string[]) => castArray(checkIds).every((id) => ids.includes(id)),
    [ids]
  )

  const clear = useCallback(() => setIds([]), [])

  const context = useMemo(
    () => ({
      ids: disabled ? undefined : ids,
      count,
      add,
      set: setIds,
      toggle,
      remove,
      has,
      clear,
    }),
    [ids, disabled]
  )

  return (
    <SelectionContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </SelectionContext.Provider>
  )
}
