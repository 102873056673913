export enum PortfolioHolderType {
  Contact = 'Contact',
  CorporationInfo = 'CorporationInfo',
}

export enum PortfolioType {
  INDIVIDUAL = 'INDIVIDUAL',
  CORPORATION = 'CORPORATION',
  INVESTMENT_VEHICLE = 'INVESTMENT_VEHICLE',
}
