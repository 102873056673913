import { gql } from '@apollo/client'

export const GET_AGGREGATED_TRANSACTION_TO_PORTFOLIO_NAME = gql`
  query GET_AGGREGATED_TRANSACTION_TO_PORTFOLIO_NAME(
    $aggregatedTransactionId: ID!
  ) {
    aggregatedTransaction(id: $aggregatedTransactionId) {
      id
      toPortfolio {
        id
        name
      }
    }
  }
`
