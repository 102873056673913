import { gql } from '@apollo/client'

export const GET_CORPORATION_INFO_TYPE = gql`
  query GET_CORPORATION_INFO_TYPE($corporationInfoId: ID!) {
    corporationInfo(id: $corporationInfoId) {
      id
      type
    }
  }
`
