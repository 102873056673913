import { QuestionOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import React, { useCallback, useEffect, useMemo } from 'react'

import Panel from 'components/atoms/Collapse/Panel'
import Message, { TranslatedMessage } from 'components/atoms/Intl/Message'
import Number from 'components/atoms/Intl/Number'
import Space from 'components/atoms/Space'
import Tooltip from 'components/atoms/Tooltip'
import FileSmartFieldGroups from 'components/molecules/List/FileSmartFieldGroups'
import { HelpCenterLink } from 'config/helpCenterLink'
import { Locales } from 'config/locale'
import { FilesEditModalSection } from 'providers/FilesEdit'
import { GET_FILE_SMART_FIELDS } from 'providers/FilesEdit/Modal/FileProperties/Panels/SmartFields/GET_FILE_SMART_FIELDS'
import { useLocale } from 'providers/Locale'
import { useSkalin } from 'providers/Skalin'

const SmartFields = ({
  fileId,
  sections,
  ...rest
}: {
  fileId?: string
  sections: FilesEditModalSection[]
}) => {
  const { skalin } = useSkalin()
  const { locale } = useLocale()
  const { data } = useQuery<{
    file: {
      id: string
      smartFields: {
        customSmartFields: {
          name: TranslatedMessage
        }[]
        smartFieldsGroups: {
          name: TranslatedMessage
        }[]
      }
    }
  }>(GET_FILE_SMART_FIELDS, {
    variables: { fileId },
    skip: !fileId,
  })

  const smartFieldsCount = useMemo(
    () =>
      (data?.file?.smartFields?.smartFieldsGroups?.length ?? 0) +
      (data?.file?.smartFields?.customSmartFields?.length ?? 0),
    [data]
  )

  const openHelpCenter = useCallback(() => {
    window.open(
      locale === Locales.frFR
        ? HelpCenterLink.SMART_FIELDS_FR
        : HelpCenterLink.SMART_FIELDS_EN,
      '_blank'
    )
  }, [locale])

  useEffect(() => {
    if (smartFieldsCount) {
      skalin.feature({ name: 'Uploaded a template' })
    }
  }, [smartFieldsCount])

  if (
    !fileId ||
    !smartFieldsCount ||
    !sections.includes(FilesEditModalSection.SMART_FIELDS)
  ) {
    return (
      <Panel
        key="smartFields"
        header="word.smartFields"
        collapsible="disabled"
      />
    )
  }

  return (
    <Panel
      {...rest}
      key="smartFields"
      header={
        <Space>
          <Message value={'word.smartFields'} />
          <Tooltip title="word.helpCenter">
            <QuestionOutlined onClick={openHelpCenter} />
          </Tooltip>
        </Space>
      }
      extra={fileId ? <Number value={smartFieldsCount} /> : undefined}
      message={<FileSmartFieldGroups fileId={fileId} />}
    />
  )
}

export default React.memo(SmartFields)
