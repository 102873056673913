import { gql } from '@apollo/client'

export const GET_SELECTED_FILES_GOVERNANCE_GROUPS = gql`
  query GET_SELECTED_FILES_GOVERNANCE_GROUPS($ids: [ID!]!) {
    files(ids: $ids) {
      id
      governanceGroups {
        groups {
          id
        }
      }
    }
  }
`
