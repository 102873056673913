import { gql } from '@apollo/client'

export const GET_CORPORATION_INFO_NAME = gql`
  query GET_CORPORATION_INFO_NAME($corporationInfoId: ID!) {
    corporationInfo(id: $corporationInfoId) {
      id
      name
    }
  }
`
