import { useWindowWidth } from '@react-hook/window-size'

import { useEquibar } from 'providers/Equibar'

const DEBOUNCE_TIME = 400

export const useIsLaptopWindowSize = () => {
  const { disableResponsive } = useEquibar()
  const windowWidth = useWindowWidth({ wait: DEBOUNCE_TIME })

  if (disableResponsive) {
    return true
  }

  return windowWidth >= 1152
}

export const useIsMobileWindowSize = () => {
  const { disableResponsive } = useEquibar()
  const windowWidth = useWindowWidth({ wait: DEBOUNCE_TIME })

  if (disableResponsive) {
    return false
  }

  return windowWidth <= 425
}
