import { useQuery } from '@apollo/client'
import React from 'react'

import Drawer from 'components/atoms/Drawer'
import { useFormatMessage } from 'components/atoms/Intl/Message'
import Space from 'components/atoms/Space'
import AggregatedTransactionDraft from 'components/molecules/AggregatedTransaction/Draft'
import AggregatedTransactionLabel from 'components/molecules/AggregatedTransaction/Label'
import ButtonMoreActionsAggregatedTransaction from 'components/molecules/ButtonMoreActions/AggregatedTransaction'
import PortfolioName from 'components/molecules/Portfolio/Name'
import SecurityNameWithType from 'components/organisms/SecurityNameWithType'
import { CorporationPermissions } from 'config/roles'
import { AggregatedTransactionLabel as AggregatedTransactionLabelEnum } from 'config/transaction'
import { useIsCorporationWorkspace } from 'hooks/useWorkspace'
import { useDrawer } from 'providers/Drawer'
import Content from 'providers/Drawer/drawers/AggregatedTransaction/Content'
import { GET_AGGREGATED_TRANSACTION_SECURITY_IDS } from 'providers/Drawer/drawers/AggregatedTransaction/GET_AGGREGATED_TRANSACTION_SECURITY_IDS'
import Sider from 'providers/Drawer/drawers/AggregatedTransaction/Sider'
import { usePermissions } from 'providers/Permissions'

export enum AggregatedTransactionDrawerTab {
  details = 'details',
  documents = 'documents',
}

function AggregatedTransaction() {
  const formatMessage = useFormatMessage()
  const isCorporationWorkspace = useIsCorporationWorkspace()
  const { checkPermissions } = usePermissions()
  const { id } = useDrawer()
  const { data } = useQuery<{
    aggregatedTransaction: {
      label: AggregatedTransactionLabelEnum
      fromSecurity?: {
        id: string
      }
      toSecurity?: {
        id: string
      }
      fromPortfolio?: {
        id: string
        name: string
      }
      toPortfolio?: {
        id: string
        name: string
      }
    }
  }>(GET_AGGREGATED_TRANSACTION_SECURITY_IDS, {
    variables: { aggregatedTransactionId: id },
  })

  return (
    <Drawer
      open
      helmetTitle={
        (data?.aggregatedTransaction?.fromPortfolio?.name ||
          data?.aggregatedTransaction?.toPortfolio?.name) &&
        data?.aggregatedTransaction?.label
          ? `${formatMessage(
              `transaction.label.${data?.aggregatedTransaction?.label}`
            )} · ${
              data?.aggregatedTransaction?.fromPortfolio?.name ||
              data?.aggregatedTransaction?.toPortfolio?.name
            }`
          : 'word.transaction'
      }
      pageHeaderProps={{
        title: (
          <>
            <AggregatedTransactionLabel aggregatedTransactionId={id} /> ·{' '}
            <PortfolioName
              portfolioId={
                data?.aggregatedTransaction?.fromPortfolio?.id ||
                data?.aggregatedTransaction?.toPortfolio?.id
              }
              withLink={
                isCorporationWorkspace &&
                checkPermissions(CorporationPermissions.PORTFOLIOS_READ)
              }
            />
          </>
        ),
        subTitle: 'word.transaction',
        tags: (
          <Space direction="horizontal" style={{ width: 'fit-content' }}>
            <AggregatedTransactionDraft aggregatedTransactionId={id} />
            <SecurityNameWithType
              withLink={
                isCorporationWorkspace &&
                checkPermissions(CorporationPermissions.SECURITIES_READ)
              }
              securityId={
                data?.aggregatedTransaction?.fromSecurity?.id ||
                data?.aggregatedTransaction?.toSecurity?.id
              }
            />
          </Space>
        ),
        extra: (
          <ButtonMoreActionsAggregatedTransaction
            aggregatedTransactionId={id}
          />
        ),
      }}
      sider={Sider}
      content={Content}
    />
  )
}

export default React.memo(AggregatedTransaction)
