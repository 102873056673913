import { useMemo, useState } from 'react'

import { isUnset } from 'utils'

export const useLocalStorage = (
  name: string,
  defaultValue?: any
): [any, (value: any) => void] => {
  const [state = defaultValue, setState] = useState(localStorage[name])

  return useMemo(
    () => [
      state,
      (value: any) => {
        setState((old: any) => {
          if (isUnset(value)) {
            localStorage.removeItem(name)
            return undefined
          }

          if (typeof value === 'function') {
            const newValue = value(old)

            if (isUnset(newValue)) {
              localStorage.removeItem(name)
              return undefined
            }

            localStorage.setItem(name, newValue)
            return newValue
          }

          localStorage.setItem(name, value)
          return value
        })
      },
    ],
    [state, name]
  )
}

export const useLocalStorageBoolean = (
  name: string,
  defaultValue = false
): [boolean, (value: any) => void] => {
  const [state = defaultValue ? '1' : '0', setState] = useLocalStorage(name)

  return useMemo(
    () => [
      state === '1',
      (value: any) => {
        setState((old: any) => {
          if (isUnset(value)) {
            return undefined
          }

          if (typeof value === 'function') {
            const newValue = value(old === '1')

            if (isUnset(value)) {
              return undefined
            }

            return newValue ? '1' : '0'
          }

          return value ? '1' : '0'
        })
      },
    ],
    [state]
  )
}

export const useLocalStorageNumber = (
  name: string,
  defaultValue = 0
): [number, (value: any) => void] => {
  const [state = defaultValue, setState] = useLocalStorage(name)

  return useMemo(() => [Number(state), setState], [state])
}
