import React from 'react'

import Number from 'components/atoms/Intl/Number'
import SkeletonText from 'components/atoms/Skeleton/Text'
import { useAggregatedTransactionFromQuantity } from 'hooks/aggregatedTransactions/useAggregatedTransactionFromQuantity'

const AggregatedTransactionFromQuantity = ({
  aggregatedTransactionId,
}: {
  aggregatedTransactionId: string
}) => {
  const { fromQuantity, loading } = useAggregatedTransactionFromQuantity(
    aggregatedTransactionId
  )

  return (
    <SkeletonText loading={loading}>
      <Number value={fromQuantity} />
    </SkeletonText>
  )
}

export default React.memo(AggregatedTransactionFromQuantity)
