import React, { useEffect, useState } from 'react'
import {
  CellProps,
  ContextMenuComponentProps,
  ContextMenuItem,
} from 'react-datasheet-grid'

import ButtonMoreActions from 'components/atoms/Button/MoreActions'
import Dropdown from 'components/atoms/Dropdown'
import Menu, { MenuType } from 'components/atoms/Menu'
import { x } from 'utils/emotion'

const getItemProps = (item: ContextMenuItem): MenuType => {
  const itemProps: MenuType = { key: item.type }
  switch (item.type) {
    case 'DELETE_ROW':
      itemProps.message = 'word.deleteRow'
      break
    case 'DELETE_ROWS':
      itemProps.message = {
        id: 'word.deleteRows',
        values: { from: item.fromRow, to: item.toRow },
      }
      break
    case 'INSERT_ROW_BELLOW':
      itemProps.message = 'word.insertRowBelow'
      break
    case 'DUPLICATE_ROW':
      itemProps.message = 'word.duplicateRow'
      break
    case 'DUPLICATE_ROWS':
      itemProps.message = {
        id: 'word.duplicateRows',
        values: { from: item.fromRow, to: item.toRow },
      }
      break
    case 'COPY':
      itemProps.message = 'word.copyRow'
      break
    case 'CUT':
      itemProps.message = 'word.cutRow'
      break
    case 'PASTE':
      itemProps.message = 'word.pasteRow'
      break
  }

  return itemProps
}

export const ContextMenu = ({
  clientX,
  clientY,
  items,
  close,
  disableNewLines,
}: ContextMenuComponentProps & { disableNewLines: boolean }) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const clickInside = document
        .getElementById('datasheet-grid-context-menu')
        ?.contains(event.target as Node)

      if (!clickInside) {
        close()
      }
    }

    document.addEventListener('mousedown', listener)

    return () => document.removeEventListener('mousedown', listener)
  }, [])

  const itemsProps = items.map((item) => ({
    ...getItemProps(item),
    onClick: () => {
      item.action()
      close()
    },
    disabled:
      !!disableNewLines &&
      ['DUPLICATE_ROW', 'DUPLICATE_ROWS', 'INSERT_ROW_BELLOW'].includes(
        item.type
      ),
  }))

  return (
    <Dropdown
      open
      dropdownRender={() => (
        <Menu id="datasheet-grid-context-menu" menu={itemsProps} />
      )}
    >
      <x.div
        left={clientX + 'px'}
        top={clientY + 'px'}
        position="fixed"
        zIndex={1050}
        padding="5px 0"
      />
    </Dropdown>
  )
}

export const getFilteredMenuItems = (
  items: ContextMenuItem[],
  filteredItemsType?: Extract<ContextMenuItem, { type: string }>['type'][]
): ContextMenuItem[] => {
  return filteredItemsType?.length
    ? items.filter((item) => {
        return !!filteredItemsType.find((action) => action === item.type)
      })
    : items
}

export const MoreActions = ({
  getContextMenuItems,
  disableNewLines,
  filteredMenuActionsList: filteredMenuItems,
}: CellProps & {
  disableNewLines: boolean
  filteredMenuActionsList?: Extract<ContextMenuItem, { type: string }>['type'][]
}) => {
  const [items, setItems] = useState<ContextMenuItem[]>([])

  const itemsProps = items.map((item) => ({
    ...getItemProps(item),
    onClick: () => {
      item.action()
      setItems([])
    },
    disabled:
      !!disableNewLines &&
      ['DUPLICATE_ROW', 'DUPLICATE_ROWS', 'INSERT_ROW_BELLOW'].includes(
        item.type
      ),
  }))

  return (
    <ButtonMoreActions
      onClick={() =>
        setItems(getFilteredMenuItems(getContextMenuItems(), filteredMenuItems))
      }
      style={{ margin: 'auto' }}
      overlay={<Menu menu={itemsProps} />}
    />
  )
}
