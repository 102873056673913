import { useMutation, useQuery } from '@apollo/client'
import { isEqual, uniqWith } from 'lodash/fp'
import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import FormItem from 'components/atoms/Form/FormItem'
import ApiSearchSelectCorporationMeetings from 'components/molecules/ApiSearchSelect/CorporationMeetings'
import { ONE_SECOND } from 'config/date'
import { FILES_LINK_MEETINGS } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Meetings/FILES_LINK_MEETINGS'
import { GET_SELECTED_FILES_MEETINGS } from 'providers/FilesEdit/Modal/FileProperties/Inputs/Meetings/GET_SELECTED_FILES_MEETINGS'

export const Meetings = ({
  selectedIds,
  corporationId,
}: {
  selectedIds: string[]
  corporationId: string
}) => {
  const [value, setValue] = useState<string[]>([])
  const [changeCount, setChangeCount] = useState(0)
  const [debouncedChangeCount] = useDebounce(changeCount, ONE_SECOND)
  const [setMeetings] = useMutation(FILES_LINK_MEETINGS)

  const { data, loading } = useQuery(GET_SELECTED_FILES_MEETINGS, {
    variables: { ids: selectedIds },
  })

  useEffect(() => {
    if (debouncedChangeCount) {
      setMeetings({
        variables: {
          ids: selectedIds,
          meetingIds: value,
        },
      })
    }
  }, [debouncedChangeCount])

  useEffect(() => {
    const values: any[] = uniqWith(
      isEqual,
      (data?.files || []).map((file: any) =>
        file.meetings.meetings.map(({ id }: { id: string }) => id)
      )
    )
    if (values.length === 1) {
      setValue(values[0])
    } else {
      setValue([])
    }
  }, [data])

  return (
    <FormItem label="word.meetings">
      <ApiSearchSelectCorporationMeetings
        loading={loading}
        corporationId={corporationId}
        mode="multiple"
        allowClear
        value={value}
        onChange={(newValue) => {
          setValue(newValue)
          setChangeCount((old) => old + 1)
        }}
      />
    </FormItem>
  )
}
