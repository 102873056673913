import { gql } from '@apollo/client'

export const GET_CONTACT_EMAIL = gql`
  query GET_CONTACT_EMAIL($contactId: ID!) {
    contact(id: $contactId) {
      id
      email
    }
  }
`
