export enum SLUGS {
  ADD_FILES_TO_DRAFT_TRANSACTIONS = 'addFilesToDraftTransactions',
  CLONE_DRAFT_AGGREGATED_TRANSACTIONS = 'cloneDraftAggregatedTransactions',
  CONFIGURE_SYNC = 'configureSync',
  EDIT_COMMUNICATION = 'editCommunication',
  DELETE_COMMUNICATION = 'deleteCommunication',
  EDIT_HOLDERS = 'editHolders',
  EDIT_HOLDERS_INFO = 'editHoldersInfo',
  EDIT_GOVERNANCE_GROUP = 'editGovernanceGroup',
  EDIT_ADMINS = 'editAdmins',
  EDIT_CONTACTS = 'editContacts',
  EDIT_POOL = 'editPool',
  EDIT_CORPORATION = 'editCorporation',
  EDIT_CONFIGURE_SYNC_MAPPING = 'editConfigureSyncMapping',
  EDIT_LEGAL_ENTITY_INFO = 'editLegalEntityInfo',
  CREATE_EVENT = 'createEvent',
  EDIT_EVENT = 'editEvent',
  CREATE_SECURITY = 'createSecurity',
  CREATE_POOL = 'createPool',
  CREATE_TRANSACTION = 'createTransaction',
  APPOINT_PROXY = 'appointProxy',
  RUN_SYNC = 'runSync',
  TEMPLATING = 'templating',
  EDIT_AGGREGATED_TRANSACTION = 'editAggregatedTransaction',
  EDIT_AGGREGATED_GRANT_TRANSACTION = 'editAggregatedGrantTransactions',
  EDIT_CORPORATION_INFO = 'editCorporationInfo',
  EDIT_EVENT_LINKED_AGGREGATED_TRANSACTIONS = 'editEventLinkedAggregatedTransactions',
  EDIT_AGGREGATED_TRANSACTION_LINKED_FILES = 'editAggregatedTransactionLinkedFiles',
  SEND_DRAFT_TRANSACTION_FILES_FOR_SIGNATURE = 'sendDraftTransactionFilesForSignature',
  MANAGE_ACCESS = 'manageBulkPortfoliosContacts',
  PUBLISH_DRAFT_TRANSACTIONS = 'publishDraftTransactions',
  LINK_MEETINGS_TO_EVENT = 'linkMeetingsToEvent',
  EDIT_EVENT_MEETINGS = 'editEventMeetings',
  TERMINATE_POOL = 'terminatePool',
  EDIT_VESTING_SUSPENSION_TRANSACTIONS = 'editVestingSuspensionTransactions',
}
