import { gql } from '@apollo/client'

export const GET_FILE_PROCEDURE_FILE_SIGNERS_STATUS = gql`
  query GET_FILE_PROCEDURE_FILE_SIGNERS_STATUS($fileId: ID!) {
    file(id: $fileId) {
      id
      isYousignV2
      procedureFile {
        id
        signers {
          id
          status
        }
      }
    }
  }
`
