import React, { useEffect, useState } from 'react'

import Button from 'components/atoms/Button'
import Input from 'components/atoms/Form/Input'
import Message from 'components/atoms/Intl/Message'
import Modal, { ModalProps } from 'components/atoms/Modal'
import { useEquibar } from 'providers/Equibar'
import { useWorkflow } from 'providers/Workflows'
import { WorkflowNextButton } from 'providers/Workflows/components/WorkflowButtons'
import eqDSTheme from 'styles/eqDSTheme'
import { sanitizeEmail } from 'utils'

export const WorkflowModalConfirm = ({
  visible,
  onCancel,
  title,
  payload,
  wordToWrite,
  ...rest
}: ModalProps & {
  payload?: any
  wordToWrite: string
}) => {
  const { loading, next } = useWorkflow()
  const { autoCompleteDelete } = useEquibar()
  const [inputValue, setInputValue] = useState<string | null>(
    autoCompleteDelete ? wordToWrite : null
  )

  useEffect(() => {
    if (autoCompleteDelete && wordToWrite) {
      setInputValue(wordToWrite)
    }
  }, [wordToWrite, autoCompleteDelete])

  return (
    <Modal
      centered
      destroyOnClose
      open={visible}
      title={title}
      onCancel={onCancel}
      footer={[
        <Button
          key="loading"
          disabled={loading}
          onClick={(
            e: React.MouseEvent<
              HTMLAnchorElement | HTMLButtonElement,
              MouseEvent
            >
          ) =>
            onCancel &&
            onCancel(e as React.MouseEvent<HTMLButtonElement, MouseEvent>)
          }
          message="word.close"
        />,
        <WorkflowNextButton
          key="confirm"
          loading={loading}
          onClick={() => next(payload)}
          message="word.confirm"
          disabled={
            !inputValue ||
            sanitizeEmail(inputValue) !== sanitizeEmail(wordToWrite)
          }
        />,
      ]}
      {...rest}
    >
      <div
        style={{
          marginTop: eqDSTheme.space.xlarge,
        }}
      >
        <Message
          value={{
            id: 'confirmVerification.label',
            values: { value: wordToWrite },
          }}
        />
        <Input
          placeholder={title}
          value={inputValue}
          onChange={setInputValue}
        />
      </div>
    </Modal>
  )
}
