import { gql } from '@apollo/client'

export const GET_PORTFOLIO_AVATAR = gql`
  query GET_PORTFOLIO_AVATAR($portfolioId: ID!) {
    portfolio(id: $portfolioId) {
      id
      holder {
        id
      }
    }
  }
`
