import { gql } from '@apollo/client'

export const GET_MEETING_MEETING_ATTENDEE_ID = gql`
  query GET_MEETING_MEETING_ATTENDEE_ID(
    $meetingId: ID!
    $contactId: ID!
    $portfolioId: ID!
    $userId: ID
  ) {
    meeting(id: $meetingId) {
      id
      meetingAttendee(
        portfolioId: $portfolioId
        contactId: $contactId
        userId: $userId
      ) {
        id
      }
    }
  }
`
