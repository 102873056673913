import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import {
  GET_PORTFOLIO_NAME,
  GET_PORTFOLIOS_NAME,
} from 'hooks/portfolios/usePortfolioName/GET_PORTFOLIO_NAME'

export const usePortfolioName = (portfolioId?: string) => {
  const result = useQuery<{ portfolio: { name: string } }>(GET_PORTFOLIO_NAME, {
    variables: { portfolioId },
    skip: !portfolioId,
  })

  return useMemo(
    () => ({
      ...result,
      name: result?.data?.portfolio?.name,
    }),
    [result]
  )
}

export const usePortfoliosName = (portfolioIds?: string[]) => {
  const result = useQuery<{ portfolios: { name: string }[] }>(
    GET_PORTFOLIOS_NAME,
    {
      variables: { portfolioIds },
      skip: !portfolioIds,
    }
  )

  return useMemo(
    () => ({
      ...result,
      portfoliosName: result?.data?.portfolios.map((p) => p.name),
    }),
    [result]
  )
}
