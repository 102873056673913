import React, { lazy, Suspense, useMemo } from 'react'

import { CorporationPermissions, PortfolioPermissions } from 'config/roles'
import { useDrawer } from 'providers/Drawer'
import { GroupDrawerTab } from 'providers/Drawer/drawers/Group'
import { usePermissions } from 'providers/Permissions'

const Details = lazy(
  () => import('providers/Drawer/drawers/Group/Content/Details')
)
const Documents = lazy(
  () => import('providers/Drawer/drawers/Group/Content/Documents')
)
const Meetings = lazy(
  () => import('providers/Drawer/drawers/Group/Content/Meetings')
)
const Communications = lazy(
  () => import('providers/Drawer/drawers/Group/Content/Communications')
)
const Members = lazy(
  () => import('providers/Drawer/drawers/Group/Content/Members')
)

const Content = () => {
  const { tab } = useDrawer()

  const { checkPermissions } = usePermissions()

  const Component = useMemo(() => {
    switch (tab) {
      case GroupDrawerTab.details:
        return Details
      case GroupDrawerTab.meetings:
        return checkPermissions(CorporationPermissions.GOVERNANCE_READ)
          ? Meetings
          : undefined
      case GroupDrawerTab.members:
        return checkPermissions(CorporationPermissions.PORTFOLIOS_READ)
          ? Members
          : undefined
      case GroupDrawerTab.communications:
        return checkPermissions(CorporationPermissions.GOVERNANCE_READ)
          ? Communications
          : undefined
      case GroupDrawerTab.documents:
        return checkPermissions(CorporationPermissions.DOCUMENTS_READ) ||
          checkPermissions(PortfolioPermissions.DOCUMENTS_READ)
          ? Documents
          : undefined
    }
    return undefined
  }, [checkPermissions, tab])

  if (!Component) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <Component />
    </Suspense>
  )
}

export default React.memo(Content)
