import { gql } from '@apollo/client'

export const GET_FILE_LOCALE = gql`
  query GET_FILE_LOCALE($fileId: ID!) {
    file(id: $fileId) {
      id
      locale
    }
  }
`
