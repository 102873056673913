import { gql } from '@apollo/client'

export const GET_CORPORATION_INFO_AVATAR = gql`
  query GET_CORPORATION_INFO_AVATAR($corporationInfoId: ID!) {
    corporationInfo(id: $corporationInfoId) {
      id
      name
      profilePicture
    }
  }
`
