import { gql } from '@apollo/client'

export const REFRESH_TOKEN = gql`
  mutation REFRESH_TOKEN {
    refreshToken {
      token
      expiresAt
    }
  }
`
