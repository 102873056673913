import { gql } from '@apollo/client'

export const GET_SELECTED_FILES_SHARED_WITH = gql`
  query GET_SELECTED_FILES_SHARED_WITH($ids: [ID!]!) {
    files(ids: $ids) {
      id
      portfolios {
        sharedWith {
          id
        }
        portfolios {
          id
        }
      }
    }
  }
`
