import { QueryParams } from 'config/queryParams'

export enum Env {
  PROD = 'production',
  DEV = 'development',
  TEST = 'test',
  STAGING = 'staging',
}

export const NODE_ENV: Env = process.env.NODE_ENV as Env

const getLegacyEndpoint = (): string => {
  const searchParams = new URLSearchParams(window.location.search)

  return NODE_ENV !== Env.PROD &&
    searchParams.has(QueryParams.OVERRIDE_API_ENDPOINT)
    ? searchParams.get(QueryParams.OVERRIDE_API_ENDPOINT) ||
        (process.env.REACT_APP_LEGACY_ENDPOINT as string)
    : (process.env.REACT_APP_LEGACY_ENDPOINT as string)
}

// LEGACY
export const LEGACY_ENDPOINT = getLegacyEndpoint()
export const LEGACY_GRAPHQL_ENDPOINT = LEGACY_ENDPOINT + '/graphql'
export const WS_ENDPOINT = LEGACY_GRAPHQL_ENDPOINT.replace(
  /^http(s?):\/\//,
  'ws$1://'
)

// NEST
export const WUNDERGRAPH_ENDPOINT = process.env
  .REACT_APP_WUNDERGRAPH_ENDPOINT as string
export const WUNDERGRAPH_GRAPHQL_ENDPOINT = WUNDERGRAPH_ENDPOINT + '/graphql'

// Google
export const REACT_APP_GOOGLE_API_KEY: string = process.env
  .REACT_APP_GOOGLE_API_KEY as string

// Payfit
export const REACT_APP_PAYFIT_CLIENT_ID: string = process.env
  .REACT_APP_PAYFIT_CLIENT_ID as string
export const REACT_APP_PAYFIT_CALLBACK: string = process.env
  .REACT_APP_PAYFIT_CALLBACK as string
export const REACT_APP_PAYFIT_SCOPE: string = process.env
  .REACT_APP_PAYFIT_SCOPE as string
// Sentry
export const REACT_APP_SENTRY_DSN: string = process.env
  .REACT_APP_SENTRY_DSN as string
